import React from 'react';
import {connect} from 'react-redux';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from "react-bootstrap-table2-paginator";
import {DebounceSearchInput} from "../../common/DebounceSearchInput";
import {Button} from "reactstrap"
import {ocmGreen, ocmOrange, ocmRed} from "../../../constants/ui";
import {createAnalyseByIdSelector} from "../../selectors/analyse";
import {createClientSelectorById} from "../../selectors/clients";
import {
  createAllZonesExploitationSelector,
  createZoneExploitationByIdsSelector
} from "../../selectors/zoneExploitation";
import {getI2cColor} from "../../../tools/helperFunctions";
import {setModal} from "../../../actions/modals";
import {addZoneExploitationInProgressPlan} from "../../../actions/orm/ProgressPlan";
import {fetchClientResponsables} from "../../../actions/orm/Responsable";
import {byItemI2cFetch} from "../../tools/Filters/updateFunctions";
import {_questionnaireItemType} from "../../../constants/types";
import {createProgressZonesExploitationByProgressPlanIdSelector} from "../../selectors/progressZoneExploitation";
import {updateProgressZone} from "../../../actions/orm/ProgressZone";

const clientSelector = createClientSelectorById();
const analyseSelector = createAnalyseByIdSelector();
const zonesSelector = createAllZonesExploitationSelector();
const zoneByIdsSelector = createZoneExploitationByIdsSelector();
const progressZonesExploitationSelector = createProgressZonesExploitationByProgressPlanIdSelector();

@connect((state, {analyseId, zeIds}) => ({
  analyse: analyseSelector({id: analyseId}),
  client: clientSelector({id: state.session.currentClientId}),
  zonesExploitation: zonesSelector().filter(z => !zeIds.includes(z.id))
}))
export class SummaryPerimetre extends React.PureComponent{
  constructor(props){
    super(props);

    this.columns = [
      {
        dataField: 'label',
        text: t('common.name'),
        sort: true,
      },
      {
        dataField: 'i2c',
        text: this.props.client.label_i2c,
        headerAlign: 'center',
        align: 'center',
        sort: true,
        formatter: (col, row) => {
          const {analyse: {passage_low, passage_high}} = this.props;
          const value = row.i2c;

          return (
            <strong style={{color: getI2cColor(value, passage_low, passage_high)}}>
              {value === null ? t('common.not_applicable') : `${value.toFixed(0)}%`}
            </strong>
          )
        },
        style: this.getI2cColor,
      }
    ];

    if (this.props.progressPlanId)
      this.columns.push({
        dataField: '',
        text: '',
        isDummyField: true,
        headerStyle: {
          width: 100
        },
        formatter: (cell, row) => (
          <div class="d-flex">
            <Button
              title={'Changer responsable'}
              className={'mr-2 btn btn-secondary btn-sm'}
              onClick={() => this.modifyResponsable(row)}>
              <span className="oi oi-pencil"/>
            </Button>
          </div>
        )
      });

    this.state = {
      zoneExploitations: [],
      zesAdded: []
    }
  }

  componentDidMount(){
    this.props.dispatch(fetchClientResponsables({client_id: this.props.client.id}));

    this.formatZeDatas();
  }

  getI2cColor = (cell, row, rowIndex, colIndex) => {
    const {analyse} = this.props;

    if(row.valueDisplayed !== 'N/C'){
      if(row.value < analyse.passage_low)
        return {color: ocmRed, fontWeight: 'bold'};
      else if(row.value < analyse.passage_high)
        return {color: ocmOrange, fontWeight: 'bold'};

      return {color: ocmGreen, fontWeight: 'bold'};
    }

    return {color: 'black', fontWeight: 'bold'}
  };

  formatZeDatas = async () => {
    const {analyseId, vague} = this.props;
    const {zesAdded} = this.state;

    const targetObjects = this.props.themes.objects.map(i => i.id);
    const zeIds = this.props.zeIds.concat(zesAdded);

    const typeFilter = this.props.themes.type === 'questions' ? [_questionnaireItemType.question] : [_questionnaireItemType.theme];
    const zones = await byItemI2cFetch({
      params: {},
      by: 'zoneExploitation',
      returnType: 'object',
      filters: {
        type: typeFilter,
        zones_exploitation: zeIds,
        analyses: [analyseId],
        vagues: [vague],
        questionnaireItems: targetObjects
      }
    });

    let selectedZones = zoneByIdsSelector({zeIds: zeIds}, true);
    selectedZones.forEach(zone => {
      const i2cItem = zones[zone.id];

      zone.i2c = !!i2cItem ? i2cItem.value : null
    });

    this.setState({zoneExploitations: selectedZones});
  };

  formatAddZeDatas = async (zeIds) => {
    const {analyseId, vague} = this.props;

    const targetObjects = this.props.themes.objects.map(i => i.id);
    // const zeIds = this.props.zeIds.concat(zes);

    const typeFilter = this.props.themes.type === 'questions' ? [_questionnaireItemType.question] : [_questionnaireItemType.theme];
    const zones = await byItemI2cFetch({
      params: {},
      by: 'zoneExploitation',
      returnType: 'object',
      filters: {
        type: typeFilter,
        zones_exploitation: zeIds,
        analyses: [analyseId],
        vagues: [vague],
        questionnaireItems: targetObjects
      }
    });

    let selectedZones = zoneByIdsSelector({zeIds: zeIds}, true);
    selectedZones.forEach(zone => {
      const i2cItem = zones[zone.id];
      const {analyse: {passage_low, passage_high}} = this.props;

      zone.i2cColor = getI2cColor(!!i2cItem ? i2cItem.value : null, passage_low, passage_high);
      zone.i2c = !!i2cItem ? i2cItem.value : null
    });

    return selectedZones;
  };

  addZoneExploitation = async () => {
    const {zesAdded} = this.state;
    const {zonesExploitation, progressPlanId} = this.props;

    const zesWithI2c = await this.formatAddZeDatas(zonesExploitation.filter(ze => !zesAdded.includes(ze.id)).map(ze => ze.id));
    await this.props.dispatch(setModal('addZoneExploitationInProgressPlan', true, {
      data: zesWithI2c
    })).then(async r => {
      await this.props.dispatch(addZoneExploitationInProgressPlan({
        user_id: r.responsable,
        progress_id: progressPlanId,
        zone_exploitation_id: r.zoneSelected
      }));

      this.setState({zesAdded: [...this.state.zesAdded, r.zoneSelected]});

      this.formatZeDatas();
      this.props.editProgresZones();
    });
  };

  modifyResponsable = async (row) => {
    const {progressPlanId} = this.props;
    const progressZones = progressZonesExploitationSelector({progressPlanId}).slice();
    const PZ = progressZones.find(pz => pz.zone_exploitation_id === row.id);

    await this.props.dispatch(setModal('addZoneExploitationInProgressPlan', true, {
      data: [row],
      user_id: PZ.user_id
    })).then(async r => {
      await this.props.dispatch(updateProgressZone({
        id: PZ.id,
        user_id: r.responsable
      }));
      this.props.editProgresZones();
    });
  };

  render(){
    const {analyse, client, progressPlanId} = this.props;
    const {zoneExploitations} = this.state;
    const labelZoneExploitation = capitalize(client.label_zone_exploitation);

    let themesLength = null;
    let themes = '';
    if(!!this.props.themes){
      themesLength = this.props.themes.objects.length;
      if(themesLength > 1){
        themes = this.props.themes.objects.map((theme, index) => {
          return(
            <li key={index}>
              <span className="badge badge-info">
                {this.props.themes.type === 'themes' ? t('common.theme_badge') : t('common.question_badge')}
              </span>&nbsp;{this.props.themes.type === 'themes' ? theme.label : theme.title}
            </li>
          );
        })
      } else if(themesLength === 1) {
        themes = (
          <React.Fragment>
             <span className="badge badge-info">
                {this.props.themes.type === 'themes' ? t('common.theme_badge') : t('common.question_badge')}
              </span>&nbsp;{this.props.themes.type === 'themes' ?
            this.props.themes.objects[0].label : this.props.themes.objects[0].title}
          </React.Fragment>
        );
      } else
        themes = t('common.none_masculine');
    }

    return(
      <section className='card card-fluid'>
        <div className='card-body'>
          <h4 className='card-title'>
            {t('pp.resume_perimeter')}
          </h4>
          <p><b>{t('common.analyse')} :</b> {!!analyse ? analyse.label : ''}</p>
          <p>
            <b>{t('pp.pp_:')} </b>
            {themesLength <= 1 &&
            themes
            }
          </p>
          {themesLength > 1 &&
          <ul>
            {themes}
          </ul>
          }
          <p>
            <b>{labelZoneExploitation} : </b>{zoneExploitations.length}
            {!!progressPlanId &&
            <Button
              size={'sm'}
              onClick={this.addZoneExploitation}
              className={'ml-2 btn btn-secondary'}
              title={`${t('common.add')} ${labelZoneExploitation}`}>
              {t('common.add')} {labelZoneExploitation} <span className="fa fa-sm fa-plus"/>
            </Button>}
          </p>
          <ToolkitProvider
            search
            keyField={'id'}
            columns={this.columns}
            bootstrap4={true}
            data={zoneExploitations}>
            {props => (
              <div>
                <DebounceSearchInput onChange={(e) => props.searchProps.onSearch(e.target.value)}/>
                <BootstrapTable
                  defaultSorted={[{
                    dataField: 'label',
                    order: 'asc'
                  }]}
                  bordered={false}
                  classes={'bulk-selection'}
                  noDataIndication={() => t('common.no_data')}
                  pagination={paginationFactory({sizePerPage: 5})}
                  {...props.baseProps}/>
              </div>
            )}
          </ToolkitProvider>
        </div>
      </section>
    );
  }
}
