import {
  clientSelector,
  analysesSelector,
  analyseVaguesSelector,
  zonesExploitationSelector,
  clientQuestionnaireTreeSelector
} from "../../tools/Filters/filterItemsSelectors";

export const Red = "#d80000";
export const Orange = "#ff9900";
export const Green = "#57a806";
export const Black = "#353535";

export const filters = {
  clients: {
    loading: false,
    type: 'clients',
    options: [],
    value: [{value: 18}],
    parents: [],
    selectorFunc: clientSelector,
    isMulti: false,
    isClearable: false,
    defaultSelection: {
      state: true,
      value: 'value',
      id: 18
    }
  },
  analyses: {
    loading: true,
    type: 'analyses',
    options: [],
    value: [],
    parents: [
      "clients"
    ],
    selectorFunc: analysesSelector,
    isMulti: false,
    isClearable: false,
    defaultSelection: {
      state: true,
      value: 'first'
    }
  },
  vagues: {
    loading: true,
    type:"vagues",
    options: [],
    value: [],
    parents: [
      "analyses"
    ],
    selectorFunc: analyseVaguesSelector,
    isMulti: false,
    isClearable: false,
    defaultSelection: {
      state: true,
      value: 'first'
    }
  },
  zones_exploitation: {
    loading: true,
    type: 'zones_exploitation',
    options: [],
    value: [],
    parents: [
      "clients",
      "analyses"
    ],
    selectorFunc: zonesExploitationSelector,
    isMulti: false,
    isClearable: false,
    defaultSelection: {
      state: true,
      value: 'first'
    }
  },
  themesTree: {
    loading: true,
    options: [],
    value: {
      question: [],
      theme: []
    },
    type: {
      question: 'questions',
      theme: 'themes'
    },
    parents: [
      "clients"
    ],
    selectorFunc: clientQuestionnaireTreeSelector,
    isTree: true,
    isMulti: false,
    isClearable: false,
    defaultSelection: {
      state: true,
      value: 'last'
    }
  }
};
