import createSelector from "../../tools/createSelector";

export const createAvailableProfilesByOrganisationSelector = () => createSelector(
  (orm, {type, id}) => {
    const entity = type === 'client' ? orm.Client.withId(id) : type === 'firm' ? orm.Firm.withId(id) : orm.Prestataire.withId(id);
    const genericProfiles = orm.Profile.all().filter(i => i.model === 'generic').toRefArray();
    const profiles = entity.profiles.toRefArray();

    return genericProfiles.concat(profiles);
  }
);
