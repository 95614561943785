import React from "react";
import {connect} from "react-redux";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Input} from "reactstrap";
import {auditsByClientId} from "../../ormSelectors/audit";
import {setModal} from "../../actions/modals";
import {getClientAnalysesSelector} from "../../ormSelectors/analyse";

@connect(
  ({session: {currentClientId}, ocmModals: {editGeneralCoefficient: {data, resolve, reject}}}) => ({
    data,
    audits: auditsByClientId(currentClientId),
    analyses: getClientAnalysesSelector({client_id: currentClientId}),
    resolve,
    reject
  })
)
class EditGeneralCoefficient extends React.PureComponent {
  state = {
    closing: false,
    data: []
  };

  componentDidMount(){
    const {data} = this.props;
    let newData = [];

    data.map(d => {
      newData.push({
        ...d,
        label: d.type === 'Analyse' ? this.props.analyses.find(a => a.id === d.id).label :
          this.props.audits.find(a => a.id === d.id).label
      })
    });
    this.setState({data: newData});
  }

  changeCoefficient = (e, {row}) => {
    const data = this.state.data.slice();

    data.find(d => row.type === d.type && d.id === row.id).coefficient = e.target.value;
    this.setState({data: data});
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setModal('editGeneralCoefficient', false));
  };

  resolve = () => {
    const data = this.state.data.slice();

    data.map(d => {
      if (d.coefficient < 0)
        d.coefficient = 0;
    });
    this.props.resolve({data});
    this.closing();
  };

  reject = () => {
    this.props.resolve('rejected');
    this.closing();
  };

  render(){
    const {closing, data} = this.state;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.reject}>{t('modals.manage_ponderation_coefficient')}</ModalHeader>
        <ModalBody>
          {data.map((d, i) => (
            <div key={i} className='w-100 mb-3'>
              <span>{d.label}</span>
              <Input value={d.coefficient}
                     style={{maxWidth:155, float: 'right'}}
                     bsSize="sm"
                     min={0}
                     name='coefficient'
                     max={100}
                     type="number"
                     placeholder={t('common.coefficient')}
                     onChange={e => this.changeCoefficient(e,{row: d})}/>
            </div>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.reject}>{t('common.close')}</Button>
          <Button color="success" onClick={this.resolve}>{t('common.valid')}</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export {EditGeneralCoefficient};
