import React from "react";
import {connect} from "react-redux";
import {Col} from "reactstrap";
import {Tree, decorators} from "@neqo34/neqo-tree";
import {EvolText, I2CText} from "../../Reporting/TextRenderers";
import {getClientAnalysesSelector} from "../../../../ormSelectors/analyse";
import {createReportByClientIdObjectSelector} from "../../../selectors/report";
import {createQuestionsByClientIdSelector} from "../../../selectors/question";
import {createQuestionnaireByClientIdSelector} from "../../../selectors/questionnaire";
import {createAuditsFullByClientIdSelector} from "../../../selectors/audit";
import {PhotoPlaceholder} from "../../../common/PhotoPlaceholder";

const Responses = ({node, tree, hideEvol, passageLow, passageHigh}) => {
  const comment = !!node.comment ? node.comment.replace(/\n\r?/g, '<br />') : null;
  return (
    <span className={`ocmTree__header d-flex flex-grow-1 w-100 ${!node.title ? 'flex-column' : null}`}>
      {(!node.oneSource || !!node.title) &&
      <Col sm={!!hideEvol ? 11 : 9}>
        {!node.title && <span className={!!tree ? null : 'ml-2'}><u>{t('common.audit')}</u> : </span>}
        <span
          className="flex-grow-1"
          dangerouslySetInnerHTML={{__html: !!node.title ? `<div><b>${node.numericValue}</b> ${node.label}</div>` : node.label}}/>
      </Col>}
      {!!node.title &&
      <>
        {!hideEvol &&
        <Col sm={1}>
          <div><b><I2CText value={node.lastVagueValue} passageLow={passageLow} passageHigh={passageHigh} /></b></div>
        </Col>}
        <Col sm={1}>
          <div><b><I2CText value={node.value} passageLow={passageLow} passageHigh={passageHigh} /></b></div>
        </Col>
        {!hideEvol && (!!node.diff || node.diff === 0) && (!!node.lastVagueValue || node.lastVagueValue === 0) &&
        <Col sm={1}><b><EvolText value={node.diff} passageLow={passageLow} passageHigh={passageHigh} /></b></Col>}
      </>}
      {!node.title &&
      <div className={`ml-${!!tree ? '3' : '4'}`}>
        {!!node.reportLabel &&
        <>
          <span className="flex-grow-1" style={{wordWrap: 'break-word'}}>
            <b>{t('common.vague')} {t('common.audit')} :</b> {node.reportLabel}
          </span>
          <br/>
        </>}
        {!!node?.type?.includes('free') && !!node.objectif && 
        <>
        {!!node && !!node.objectif &&
        <>
          <span className="flex-grow-1" style={{wordWrap: 'break-word'}}>
            <b>{t('admin.audit_view.goal')} :</b> <i>{node.objectif}</i>
          </span>
          <br/>
        </>}
        {!!node.parentNode.children && !!node.parentNode.children.length && !!node.parentNode.children &&
        <>
          <span className="flex-grow-1" style={{wordWrap: 'break-word'}}>
            <b>{t('admin.audit_view.done')} :</b> <i>{node?.value}</i>
          </span>
          <br/>
        </>}
        </>
        }
        {!!node.comment && node.comment !== '' &&
        <>
          <span className="flex-grow-1" style={{wordWrap: 'break-word'}}>
            <b className="no-print">{t('common.comment')} :</b> <i><div dangerouslySetInnerHTML={{__html: comment}}/></i>
          </span>
          <br/>
        </>}
        {!!node.photos && !!node.photos.length &&
        <span className="flex-grow-1">
          <b class="no-print">{t('common.photos')} :</b>
          <div className="d-flex flex-wrap">
            {node.photos.map(({path, filename}, index) => (
              <div className="d-flex mb-2">
                <div key={index} className="photo-ct mr-2">
                  <PhotoPlaceholder src={path} />
                  <span>{!!filename ? filename : t('common.photo')}</span>
                </div>
              </div>
            ))}
            </div>
          </span>}
        {!!node.files && !!node.files.length &&
        <span className="mb-1">
          <b>{t('common.files')} :</b>
          <div className="d-flex flex-column">
            {node.files.map(({filename, path}, index) => (
              <div key={index}>
                <a href={`${path}`} target="_blank">
                  {filename}
                </a>
              </div>
            ))}
          </div>
        </span>}
      </div>}
    </span>
  )
};

const getReports = createReportByClientIdObjectSelector();
const getQuestionsByClientId = createQuestionsByClientIdSelector();
const getQuestionnaires = createQuestionnaireByClientIdSelector();
const getAudits = createAuditsFullByClientIdSelector();

@connect(({session: {currentClientId}}) => ({
  client_id: currentClientId,
  audits: getAudits({clientId: currentClientId}),
  reports: getReports({clientId: currentClientId}),
  questions: getQuestionsByClientId({clientId: currentClientId}),
  questionnaires: getQuestionnaires({client_id: currentClientId}),
  analyses: getClientAnalysesSelector({client_id: currentClientId})
}))
class QuestionsTree extends React.PureComponent {
  localDecorators = {
    Container: ({terminal, node, listeners, onClick, decorators}) => {
      const hideEvol = this.props.vague === 1;

      return (
        <div className="ocmTree__container" style={{
          borderBottom: !!node.comment || !node.toggled ? '2px dashed #BEBEBE' : null
        }}>
          {!!node.title && ((!!node.children && !!node.children.length)) &&
          <decorators.Toggle node={node} onClick={onClick}/>}
          <Responses passageLow={this.props.passageLow} passageHigh={this.props.passageHigh} node={node} hideEvol={hideEvol} tree/>
        </div>
      )
    }  
  };

  render() {
    const {analyseId, zoneId, vague : filterVague, audits, questions, analyses, row, print, detailed, themeId, reports,passageLow , passageHigh, toggled} = this.props;
    const analyse = analyses.find(a => a.id === analyseId);
    const vague = !!analyse ? analyse.vagues.find(v => v.vague === filterVague) : null;

    // # Récupérer report_id et générer label pour classement    
    const reports_id = (vague?.sources || []).map(v => {
      // Si source non null : récupérer l'id de la source, parcourir la source et obtenir id du report
      // Si soucre null : récupérer directement l'id
      let report_id = v?.source ? analyses?.find(a => a?.id === v?.source)?.vagues?.find(v2 => v2?.vague === v?.id)?.sources?.[0]?.id : v?.id;
      // Récupérer le nom de la vague (MM-AAAA) et le transformer pour s'en servir comme classement (AAAAMM)
      let labelForSort = typeof v?.label === "string" ? `${v?.label?.substring(3, v?.label?.length).concat(v?.label?.substring(0, 2))}` : v?.label;
      return { id: report_id, labelForSort: labelForSort };
    });

    const multiVagues = !!analyse ? vague.sources.filter(s =>
      s.model === 'Report' &&
      !!vague.sources.find(s2 => s2.id !== s.id && !!reports[s2.id] && reports[s.id] && reports[s2.id].audit_id === reports[s.id].audit_id)).map(s => s.id) : [];
    
    const zeId = zoneId;
    let questionnaires = [];
    let data = [];

    this.props.questionnaires.map(q => {
      questionnaires.push(q);
    });

    data.push({
      ...questions.find(q => row.id === q.id),
      oneSource: !!row.audits && Object.keys(row.audits).filter(aId =>
        !!analyse.sources.find(s => s.model === 'Audit' && Number(s.id) === row.audits[aId].id)).length < 2,
      theme_id: themeId,
      children: [],
      toggled: toggled,
      diff: row.diff,
      value: row.value,
      numericValue: row.numericValue,
      lastVagueValue: row.lastVagueValue
    });

    questionnaires.filter(q => !!q.themes_question && q.themes_question.question_id === row.id && q.themes_question.theme_id === themeId).map(q => {
      if (!!q.response) {
        const responseFiltered = q.response.filter(r => ((!!r.comment && r.comment !== '') ||
          (!!r.files && !!r.files.length) || (!!r.photos && !!r.photos.length)) || ((!!q.objectif && !!q.objectif !== null) && (!!q.type && !!q.type.includes('free'))) &&
          !!reports_id?.some(e => e.id === r.report_id) && r.zone_exploitation_id === zeId);

        responseFiltered.map(r => {
          // Afficher uniquement les/le report(s) présent dans les vagues 
          if (!reports_id?.some(e => e.id === r.report_id)) return;
           // Afficher uniquement la zone d'exploitation concernée (pour éviter les commentaires d'autres rapports)
          if (r.zone_exploitation_id != zoneId) return;

          data[0].toggled = toggled;
          data[0].children.push({
            ...r,
            oneSource: data[0].oneSource,
            parentNode: q,
            objectif: q.objectif,
            type: q.type,
            label: audits.find(a => a.id === q.audit_id).label,
            reportLabel: !!multiVagues.length && multiVagues.includes(r.report_id) ? vague.sources.find(a => a.id === r.report_id).label : null,
            labelForSort: !!reports_id ? reports_id?.find(e => e.id === r.report_id)?.labelForSort : null
          });
        });
      }
    });

    return (
      <>
        {!!print && data.map((node, i) => (
          <div key={i} className='mb-4'>
            <Responses node={node} hideEvol={vague.vague === 1}/>
            {!!detailed && node.children.map((c, idx) => <Responses key={idx} node={c} hideEvol={vague.vague === 1}/>)}
          </div>
        ))}
        {!print &&
        <Tree
          data={data}
          decorators={this.localDecorators}
          passageLow={passageLow} passageHigh={passageHigh}/>}
      </>
    )
  }
}

export {QuestionsTree};
export default QuestionsTree;
