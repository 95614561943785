import {fk, attr, Model} from 'redux-orm';
import {_ResponseUploads, _User, _Response} from "../constants/orm";

export class ResponseUploads extends Model{
    static parse(data){
        return data;
    }

    static get fields(){
        return {
            id: attr(),
            type: attr(),
            response_id: fk(_Response),
            path: attr(),
            filename: attr(),
            created: attr(),
            modified: attr(),
            user_id: fk(_User),
        }
    }
}

ResponseUploads.modelName = _ResponseUploads;
