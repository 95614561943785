import api from "../../tools/api";
import {
  _Audit,
  _Client,
  _Complement,
  _Parameter, _Question,
  _Report, _Responsable,
  _Site,
  _Taxonomy, _Theme, _ThemeQuestion,
  _User,
  _Visit,
  _ZoneExploitation
} from "../../constants/orm";
import {deleteEntity, forceUpdateEnitities, hydrateEntities, updateEntities} from "../orm";
import _ from "lodash";
import {SET_CURRENT_CLIENT_ID} from "../session";
import {actionCallsTracker} from "../actionsTracker";
import update from "immutability-helper";
import {setDefaultUserClient} from "../../tools/localStorageHelpers";

export const fetchClientParameters = ({client_id}) => {
  return async dispatch => {
    const parameters = await api.client.getParameters({client_id});

    dispatch(hydrateEntities({
      payload: [
        {type:_Parameter, entities: parameters}
      ]
    }));

    return parameters;
  }
};

export const fetchClientThemesTree = ({client_id}) => {
  return async dispatch => {
    if (actionCallsTracker.isSaved('themes', 'client', client_id))
      return;
    const {themes, themes_questions, questions} = await api.client.getThemes({client_id});

    dispatch(forceUpdateEnitities({
      payload: [
        {type: _Theme, entities: themes},
        {type: _Question, entities: questions},
        {type: _ThemeQuestion, entities: themes_questions}
      ]
    }));

    actionCallsTracker.save('themes', 'client', client_id);
    return {
      themes,
      themes_questions,
      questions
    }
  }
};

export const fetchClientTaxonomies = ({client_id}) => {
  return async dispatch => {
    if (actionCallsTracker.isSaved('taxonomies', 'client', client_id))
      return;

    const taxonomies = await api.client.getTaxonomies({client_id});

    dispatch(hydrateEntities({
      payload: [
        {type: _Taxonomy, entities: taxonomies}
      ]
    }));

    actionCallsTracker.save('taxonomies', 'client', client_id);
    return taxonomies;
  }
};

export const fetchClientComplements = ({client_id}) => {
  return async dispatch => {
    const complements = await api.audits.getClientComplements({client_id});

    dispatch(hydrateEntities({
      payload: [
        {type: _Complement, entities: complements}
      ]
    }));
    return complements;
  }
};

//ne marche pas car on peut pas hydrater l'orm avec la valeur de retour
export const fetchClientAudits = ({client_id}) => {
  return async dispatch => {
    if (actionCallsTracker.isSaved('audits', 'client', client_id))
      return;

    const {audits} = await api.client.getAudits({client_id});
    let users = [];
    let zone_exploitations = [];

    if (audits) {
      audits.forEach(audit => {
        users = users.concat(audit.users.map(u => u.user));
        zone_exploitations = zone_exploitations.concat(audit.zone_exploitations);
        audit.prestataires = [];
        let prestaIds = [];
        audit.zone_exploitations.forEach(ze => {
          if(ze.prestataires.length > 0 && !prestaIds.includes(ze.prestataires[0].id)) {
            audit.prestataires.push(ze.prestataires[0]);
            prestaIds.push(ze.prestataires[0].id);
          }
        });
      });
    }

    dispatch(hydrateEntities({
      payload: [
        {type: _Audit, entities: audits},
        {type: _User, entities: users},
        {type: _ZoneExploitation, entities: zone_exploitations}
      ]
    }));
    actionCallsTracker.save('audits', 'client', client_id);
    return audits;
  }
};

export const fetchClientClients = ({client_id}) => {
  return async dispatch => {
    const clients = await api.client.getClients({client_id});

    dispatch(hydrateEntities({
      payload: [
        {type: _Client, entities: clients}
      ]
    }));

    return clients;
  }
};

export const fetchClientReports = ({client_id}) => {
  return async dispatch => {
    const reports = await api.client.getReports({client_id});

    dispatch(hydrateEntities({
      payload: [{type: _Report, entities: reports}]
    }));
    return reports;
  }
};

export const fetchClientOrganisation = ({client_id}) => {
  return async dispatch => {
    if (actionCallsTracker.isSaved('organisation', 'client', client_id))
      return;
    const organisation = await api.client.getOrganisation({client_id});
    let clients = [...organisation];
    let sites = [];
    let zone_exploitations = [];

    function setupTree(items){
      items.forEach(item => {
        if (!!item.sites && !!item.sites.length){
          sites = [...sites, ...item.sites];
          setupTree(item.sites);
        } else if (!!item.zone_exploitations && !!item.zone_exploitations.length){
          zone_exploitations = [...zone_exploitations, ...item.zone_exploitations];
        }
      })
    }
    setupTree(organisation);
    dispatch(hydrateEntities({
      payload: [
        {type: _Client, entities: clients},
        {type: _Site, entities: sites},
        {type: _ZoneExploitation, entities: zone_exploitations}
      ]
    }));

    actionCallsTracker.save('organisation', 'client', client_id);
    return organisation;
  }
};

export const fetchClientUsers = ({client_id}) => {
  return async dispatch => {
    const users = await api.client.getUsers({client_id});

    dispatch(hydrateEntities({
      payload: [
        {type: _User, entities: users}
      ]
    }));

    return users;
  }
};

export const fetchClientVisits = ({client_id}) => {
  return async dispatch => {
    const visits = await api.client.getVisits({client_id});
    let users = visits.map(v => v.user);
    users = users.filter(u => !!u);
    _.uniqBy(users, 'id');

    dispatch(hydrateEntities({
      payload: [
        {type: _Visit, entities: visits},
        {type: _User, entities: users}
      ]
    }));

    return visits;
  }
};

export const addClient = ({parent_id, label, adresse, code_postal, ville, pays, phone, path, label_i2c, label_zone_exploitation, label_audit, responsables, main_responsables,  characteristics, numerical}) => {
  return async dispatch => {
    const client = await api.client.add({
      parent_id,
      label,
      adresse,
      code_postal,
      ville,
      pays,
      phone,
      path,
      label_i2c,
      label_zone_exploitation,
      label_audit,
      responsables,
      main_responsables,
      characteristics,
      numerical
    });

    dispatch(hydrateEntities({
      payload: [
        {type: _Client, entities: [client]}
      ]
    }));
    return client;
  }
};

export const updateClient = ({id, parent_id, label, adresse, code_postal, ville, pays, phone, path, prestataireIds, label_i2c, label_zone_exploitation,  label_audit, responsables, main_responsables, characteristics, numerical}) => {
  return async (dispatch, getState) => {
    const client = await api.client.update({
      id,
      parent_id,
      label,
      adresse,
      code_postal,
      ville,
      pays,
      phone,
      prestataireIds,
      path,
      label_i2c,
      label_zone_exploitation,
      label_audit,
      responsables,
      main_responsables,
      characteristics,
      numerical
    });

    const oldResponsable = Object.keys(getState().db.Responsable.itemsById).filter(r =>
      getState().db.Responsable.itemsById[r].model === 'clients' &&
      getState().db.Responsable.itemsById[r].client_id === client.id);

    if (oldResponsable.length)
      oldResponsable.forEach(r => {
        if (!client.responsables.find(zr => zr.id === r.id))
          dispatch(deleteEntity({
            type: _Responsable, id: r.id,
          }))
      });

    dispatch(hydrateEntities({
      payload: [
        {type: _Responsable, entities: client.responsables}
      ]
    }));
    dispatch(updateEntities({
      payload: [
        {type: _Client, entities: [client]}
      ]
    }));
    return client;
  }
};

export const fetchCurrentClientData = () => {
  return async (dispatch, getState) => {
    const {currentClientId} = getState().session;
    await Promise.all([
      dispatch(fetchClientOrganisation({client_id: currentClientId})),
      dispatch(fetchClientAudits({client_id: currentClientId}))
    ]);
  }
};

export const setCurrentClientId = ({client_id}) => {
  return async (dispatch, getState) => {
    // await Promise.all([
    //   dispatch(fetchClientOrganisation({client_id})),
    //   dispatch(fetchClientAudits({client_id})),
    //   dispatch(fetchClientTaxonomies({client_id})),
    //   dispatch(fetchClientParameters({client_id}))
    // ]);

    const store = getState();
    const userId = store.session.userId;

    setDefaultUserClient(userId, client_id);

    dispatch({
      type: SET_CURRENT_CLIENT_ID,
      clientId: client_id
    });
  }
};
