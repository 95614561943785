import React from "react";
import Flatpickr from "react-flatpickr";
import {Button, Input, ListGroup, ListGroupItem} from "reactstrap";
import update from "immutability-helper";
import {getT, tFunction} from "../tools/Translation";

const days = {
  0: tFunction('common.monday'),
  1: tFunction('common.tuesday'),
  2: tFunction('common.wednesday'),
  3: tFunction('common.thursday'),
  4: tFunction('common.friday'),
  5: tFunction('common.saturday'),
  6: tFunction('common.sunday')
};

function decodeWeekTableData(data){
  let ret = [[],[],[],[],[],[],[]];

  data.forEach(time => {
    ret[parseInt(time.day)].push({
      start: hoursToDate(time.start),
      finish: hoursToDate(time.finish)
    });
  });

  ret.forEach(day => {
    if (!day.length)
      day.push({
        start: null,
        finish: null
      })
  });

  return ret;
}

function encodeWeekTable(data){
  let ret = [];

  data.forEach((day, dayIdx) => {
    day.forEach(time => {
      if (time.start !== null && time.finish !== null){
        const finish = time.start.getTime() < time.finish.getTime() ? time.finish : time.start;
        const start = time.start.getTime() < time.finish.getTime() ? time.start : time.finish;

        ret.push({
          day: dayIdx,
          start: getHoursString(start),
          finish: getHoursString(finish)
        })
      }
    })
  });

  return ret;
}

function getHoursString(date){
  let minutes = date.getMinutes().toString();
  let hours = date.getHours().toString();

  if (minutes.length === 1)
    minutes = '0' + minutes;

  return `${hours}:${minutes}`;
}

function hoursToDate(date){
  const [hours, minutes] = date.split(':');
  let newDate = new Date();
  newDate.setHours(parseInt(hours));
  newDate.setMinutes(parseInt(minutes));

  return newDate;
}

class WeekTimetable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: []
    }
  }

  // componentDidUpdate(prevProps){
  //   if (this.props.value !== prevProps.value){
  //     this.setState({value: this.props.value});
  //   }
  // };

  componentDidMount(){
    const {value} = this.props;

    this.setState({value: decodeWeekTableData(value)});
  };

  onChange = (value) => {
    this.setState({value: value});
    if (!!this.props.onChange){
      this.props.onChange(encodeWeekTable(value));
    }
  };

  addTime = (dayIndex) => {
    const {value} = this.state;
    const newValue = update(value, {
      [dayIndex]: {$push: [{start:null, finish: null}]}
    });

    this.onChange(newValue);
  };

  removeTime = (dayIndex, timeIndex) => {
    const {value} = this.state;
    const newValue = update(value, {
      [dayIndex]: {$splice: [[timeIndex , 1]]}
    });

    this.onChange(newValue);
  };

  handleChange = ({dayIndex, timeIndex, name, date}) => {
    const {value} = this.state;
    const newValue = update(value, {
      [dayIndex]: {
        [timeIndex]: {
          [name]: {$set: date[0]}
        }
      }
    });

    this.onChange(newValue);
  };

  handleOpenInput = ({dayIndex, timeIndex, name}) => {
    const {value} = this.state;

    if (!value[dayIndex][timeIndex][name]) {
      let newDate = new Date();
      newDate.setHours(12);
      newDate.setMinutes(0);
      const newValue = update(value, {
        [dayIndex]: {
          [timeIndex]: {
            [name]: {$set: newDate}
          }
        }
      });
      this.onChange(newValue);
    }
  };

  resetValues = (dayIndex, timeIndex) => {
    const {value} = this.state;

    const newValue = update(value, {
      [dayIndex]: {
        [timeIndex]: {
          start: {$set: null},
          finish: {$set: null}
        }
      }
    });
    this.onChange(newValue);
  };

  render(){
    const {value} = this.state;

    return (
      <ListGroup className={'list-group-bordered weekTimetable'}>
        {value.map((v, dayIdx) => {
          const dayName = days[dayIdx];
          return (
            <ListGroupItem key={dayIdx}>
              <div class="dayName">
                {dayName} :
              </div>
              <div>
                {v.map(({start, finish}, idx) => {
                  const isLast = idx === v.length - 1;

                  return (
                    <div class="d-flex timeRange" key={idx}>
                      <Flatpickr
                        className="form-control form-control-sm"
                        value={start}
                        placeholder={t('common.start')}
                        onOpen={() => this.handleOpenInput({
                          dayIndex: dayIdx,
                          timeIndex: idx,
                          name: "start",
                        })}
                        onChange={(date) => this.handleChange({
                          dayIndex: dayIdx,
                          timeIndex: idx,
                          name: "start",
                          date: date
                        })}
                        name="start"
                        options={{
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: "H:i",
                          time_24hr: true
                        }}/>
                      <span class="mr-2 ml-2">-</span>
                      <Flatpickr
                        className="form-control form-control-sm"
                        placeholder={t('common.start')}
                        name="finish"
                        value={finish}
                        onOpen={() => this.handleOpenInput({
                          dayIndex: dayIdx,
                          timeIndex: idx,
                          name: "finish",
                        })}
                        onChange={(date) => this.handleChange({
                          dayIndex: dayIdx,
                          timeIndex: idx,
                          name: "finish",
                          date: date
                        })}
                        options={{
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: "H:i",
                          time_24hr: true
                        }}/>
                      {(start !== null || finish !== null) &&
                      <Button
                        type="button"
                        onClick={() => this.resetValues(dayIdx, idx)}
                        title={t('common.reinit')}
                        size="xs"
                        color="light"
                        className="ml-1">
                        <i class="fa fa-times"/>
                      </Button>}
                      {idx !== 0 &&
                      <Button
                        type="button"
                        onClick={() => this.removeTime(dayIdx, idx)}
                        title={t('common.add_time_slot')}
                        size="xs"
                        color="light"
                        className="ml-1">
                        <i class="fa fa-trash"/>
                      </Button>}
                      {isLast &&
                      <Button
                        type="button"
                        onClick={() => this.addTime(dayIdx)}
                        title={t('common.add_time_slot')}
                        size="xs"
                        color="light"
                        className="ml-1">
                        <i class="fa fa-plus"/>
                      </Button>}
                    </div>
                  )
                })}
              </div>
            </ListGroupItem>
          )
        })}
      </ListGroup>
    )
  }
}

export {WeekTimetable};
export default WeekTimetable;