import {fk, attr, Model, many} from 'redux-orm';
import {_Firm, _Client, _Prestataire} from "../constants/orm";

export class Firm extends Model {
  static parse(data){
    if (!data.clients)
      data.clients = [];
    else
      data.clients = data.clients.map(i => i.id);
    if (!data.prestataires)
      data.prestataires = [];
    else
      data.prestataires = data.prestataires.map(i => i.id);
    return data;
  }

  static get fields(){
    return {
      id: attr(),
      label: attr(),
      path: attr(),
      created: attr(),
      modified: attr(),
      freeze: attr(),
      clients: many(_Client, 'firms'),
      prestataires: many(_Prestataire, 'firms')
    }
  }
}

Firm.modelName = _Firm;