import React from 'react';
import {connect} from 'react-redux';
import {REMOVE_ALERT} from "../../actions/ui";


const mapStateToProps = state => {
    return {alerts: state.ui.alerts};
};

const mapDispatchToProps = (dispatch) => {
    return {
        removeAlert: alertIndex => dispatch({type: REMOVE_ALERT, payload: alertIndex}),
    }
};

class ConnectedAlerts extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            opacities: []
        };

        this.intervals = [];

        this.removeAlertAnimation = this.removeAlertAnimation.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.alerts.length > 0) {
            let opacities = this.state.opacities.slice();
            opacities[arr_end(nextProps.alerts).id] = 1;
            this.setState({opacities: opacities});
            if(arr_end(nextProps.alerts).autoRemove === undefined || arr_end(nextProps.alerts).autoRemove === true) setTimeout(() => this.removeAlertAnimation(opacities.length - 1), 2000);
            window.scrollTo(0, 0);
        }
        this.props = nextProps;
    }

    removeAlertAnimation(id){
        this.intervals[id] = setInterval(() => {
            if(this.state.opacities[id] < 0){
                this.props.removeAlert(id);
                clearInterval(this.intervals[id]);
            }
            else{
                let opacities = this.state.opacities.slice();
                opacities[id] -= 0.1;
                this.setState({opacities: opacities});
            }
        }, 100);
    }

    removeAlert(id){
        clearInterval(this.intervals[id]);
        this.props.removeAlert(id);
    }


    render(){
        const alerts = this.props.alerts.map((alert, index) => {
            return(
                <div key={alert.id} className={'alert fade show ' + alert.class + (alert.icon !== '' ? ' has-icon' : '')} style={{marginTop: '10px', opacity: this.state.opacities[alert.id]}}>
                    <button onClick={() => this.props.removeAlert(alert.id)} className='close' type='button' data-dismiss='alert'>x</button>
                    {(alert.icon !== '' ? <div className='alert-icon'><span className={'oi ' + alert.icon}></span></div> : '')}
                    {(alert.title !== '' ? <h4 className='alert-heading'>{alert.title}</h4> : '')}
                    {alert.text}
                </div>
            )
        });

        return (
            <div className='row justify-content-md-center'>
                <div className='col-6'>
                    {alerts}
                </div>
            </div>
        )
    }
}

const Alerts = connect(mapStateToProps, mapDispatchToProps)(ConnectedAlerts);

export default Alerts;