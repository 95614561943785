import {attr, fk, Model} from 'redux-orm';
import {_Report, _ZoneExploitation, _User} from "../constants/orm";

export class Visit extends Model {
  static parse(data){
    delete data.user;

    if (!!data.passage)
      data.passage = data.passage * 1000;

    return data;
  }

  static get fields(){
    return {
      id:  attr(),
      report_id: fk(_Report, 'visits'),
      zone_exploitation_id: fk(_ZoneExploitation, 'visits'),
      passage: attr(),
      status: attr(),
      user_id: fk(_User, 'visits'),
      comment: attr(),
      created: attr(),
      modified: attr(),
      nb_total_questions: attr(),
      nb_responses: attr()
    }
  }
}

Visit.modelName = 'Visit';