import orm from "../orm";
import * as _Redux from "../redux";
import {_Client, _User, _Prestataire, _Audit, _Site, _ZoneExploitation, _Theme, _ThemeQuestion, _Response, _Questionnaire, _RapportsProcessing} from "../constants/orm";

const emptyDBState = orm.getEmptyState();

export const dbReducer = _Redux.createReducer(Object.assign({}, emptyDBState), {
  HYDRATE_ENTITIES: hydrateEntities,
  GENERIC_HYDRATE_ENTITIES: genericHydrateEntities,
  UPDATE_ENTITIES: updateEntities,
  DELETE_ENTITY: deleteEntity,
  FORCE_UPDATE_ENTITIES: forceUpdateEntities,
  CLEAR_DB: clear,
  BASIC_UPDATE_ENTITIES: basicUpdateEntities
});

function forceUpdateEntities(dbState, action){
  const session = orm.session(dbState);
  let ModelClass = {};

  action.payload.forEach(function(model){
    ModelClass = session[model.type];
    forceSync(model.entities, ModelClass, session);
  });

  return session.state;
}

function forceSync(datas, model, orm){
  if (datas) {
    datas.forEach((data) => {
      if (!data)
        return;
      if(model.idExists(data.id))
        model.withId(data.id).update(model.parse(data, orm));
      else
        model.create(model.parse(data, orm));
    });
  }
}

function hydrateEntities(dbState, action){
  const session = orm.session(dbState);
  let ModelClass = {};

  action.payload.forEach(function(model){
    ModelClass = session[model.type];
    sync(model.entities, ModelClass, session);
  });

  return session.state;
}

function genericHydrateEntities(dbState, {payload}){
  const session = orm.session(dbState);
  const modelToNotUpdate = [_Client, _User, _Prestataire, _Audit, _Site, _ZoneExploitation, _Theme, _ThemeQuestion, _Response, _Questionnaire];

  payload.forEach(({modelType, object}) => {
    const Model = session[modelType];

    if(Model.idExists(object.id) && !modelToNotUpdate.includes(Model.modelName))
      Model.withId(object.id).update(Model.parse(object, orm));
    else if(!Model.idExists(object.id)) Model.create(Model.parse(object, orm));
  });

  return session.state;
}

function basicUpdateEntities(dbState, action){
  const session = orm.session(dbState);
  let ModelClass = {};

  action.payload.forEach(function(model){
    ModelClass = session[model.type];
    model.entities.forEach(data => {
      ModelClass.withId(data.id).update(data);
    });
  });

  return session.state;
}

function updateEntities(dbState, action){
  const session = orm.session(dbState);
  let ModelClass = {};

  action.payload.forEach(function(model){
    ModelClass = session[model.type];
    model.entities.forEach(data => {
      ModelClass.withId(data.id).update(ModelClass.parse(data, session));
    });
  });

  return session.state;
}

function deleteEntity(dbState, {payload: {type, id}}){
  const session = orm.session(dbState);

  session[type].withId(id).delete();
  return session.state;
}

/**
 * generic function that take object from the server
 * and update them in the store to be up to date with
 * the data base server
 * When receiving datas from server always use this function
 * to avoid cache unsync problem
 * @param datas => should be properly formated
 * @param model
 */
function sync(datas, model, orm){
  if (datas) {
    datas.forEach((data) => {
      if (!data)
        return;
      const modelToNotUpdate = [_Client, _Prestataire, _Audit, _Site, _Theme, _ThemeQuestion, _Response, _Questionnaire, _RapportsProcessing];
      if(model.idExists(data.id) && !modelToNotUpdate.includes(model.modelName)){
        model.withId(data.id).update(model.parse(data, orm));
      }
      else if(!model.idExists(data.id)) model.create(model.parse(data, orm));
    });
  }
}

function clear(){
  return emptyDBState;
}
