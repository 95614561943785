import React from "react";
import {ocmRed, ocmOrange,  ocmGreen, ocmBlack} from "../../../constants/ui";
import {Red, Orange, Green, Black} from "./utils";

export const EvolText = ({value, style, nullSign = '=', ...other}) => {
  const val = (value !== null && value !== undefined) ? Math.round(value) : null;
  const color = val === null ? ocmBlack : val < 0 ? Red : val === 0 ? Orange : ocmGreen;
  const text = val === null ? '' : val === 0 ? nullSign : val > 0 ? `+${val}` : val;

  return (
    <span
      {...other}
      style={{
        color: color,
        ...style
      }}>
      {text}
    </span>
  )
};

export const I2CText = ({value, style, passageLow, passageHigh, ...other}) => {
  const val = (value !== null && value !== undefined) ? Math.round(value) : null;
  const low = !!passageLow ? passageLow : 80;
  const high = !!passageHigh ? passageHigh : 100;
  const color = val === null ? ocmBlack : val < low ? Red : val < high ? Orange : ocmGreen;
  const text = val !== null ? `${val}%` : 'N/C';

  return (
    <span
      {...other}
      style={{
        color: color,
        ...style
      }}>
      {text}
    </span>
  )
};
