import createSelector from "../../tools/createSelector";

export const createReportsObjectSelector = () => {
  return createSelector(
    (orm) => {
      const report = orm.Report.all().toRefArray().reduce((acc, curr) => {
        acc[curr.id] = curr;

        return acc;
      }, {});

      return !!report ? report : null;
    }
  )
};

export const createReportByIdSelector = () => {
  return createSelector(
    (orm, {id}) => {
      const report = orm.Report.withId(id);

      return !!report ? report.ref : null;
    }
  )
};

export const createReportByClientIdSelector = () => createSelector(
  (orm, {clientId}) => {
  const reports = orm.Report.all().toRefArray().filter(r => !!orm.Audit.withId(r.audit_id) && orm.Audit.withId(r.audit_id).ref.client_id === clientId);

  return !!reports.length ? reports : [];
});

export const createReportByClientIdObjectSelector = () => createSelector(
  (orm, {clientId}) => {
    const reports = orm.Report.all().toRefArray().filter(r =>
      !!orm.Audit.withId(r.audit_id) && orm.Audit.withId(r.audit_id).ref.client_id === clientId
    ).reduce((acc, curr) => {
      acc[curr.id] = curr;

      return acc;
    }, {});

    return !!reports ? reports : {};
  });