import React from "react";
import {
  Form,
  FormGroup,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  FormFeedback
} from "reactstrap";
import {setVisitsDateChange} from "../../actions/modals";
import {connect} from "react-redux";
import {LoadingButton} from "../common/LoadingButton";
import Flatpickr from "react-flatpickr";
import {French} from "flatpickr/dist/l10n/fr";
import {FormValidator} from "../../tools/formValidator/FormValidator";
import {updateVisit} from "../../actions/orm/Visit";
import {tFunction} from "../tools/Translation";

const localFv = new FormValidator([
  {
    field: 'date',
    method: 'isPresent',
    validWhen: true,
    message: tFunction('modals.date_required')
  }
]);

@connect(({modals: {visitsDateChange: {visitIds}}}) => ({
  visitIds
}))
class VisitsDateChange extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      closing: false,
      loading: false,
      date: '',
      validation: localFv.valid()
    }
  }

  handleChange = (date) => {
    this.setState({date: new Date(date).getTime()});
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const {date} = this.state;
    const {visitIds, dispatch} = this.props;

    const validation = localFv.validate({
      date
    });

    if (!validation.isValid){
      this.setState({validation});
      return;
    }

    this.setState({loading: true});
    await Promise.all(visitIds.map(visitId => dispatch(updateVisit({
      id: visitId,
      passage: date
    }))));
    this.setState({loading: false});
    this.closing();
  };

  close = () => {
    this.props.dispatch(setVisitsDateChange({
      open: false
    }));
  };

  closing = () => {
    this.setState({closing: true});
  };

  render(){
    const {closing, loading, date, validation} = this.state;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.closing}>
          {t('modals.modify_date')}
        </ModalHeader>
        <Form onSubmit={this.handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label>
                {t('modals.choose_date_:')}
              </Label>
              <Flatpickr
                value={date}
                onChange={this.handleChange}
                name={"date"}
                className={`form-control ${validation.date.isInvalid ? 'is-invalid': ''}`}
                placeholder={t('modals.choose_date')}
                options={{
                  dateFormat: "d-m-Y",
                  locale: French
                }}/>
              <FormFeedback>
                {validation.date.message}
              </FormFeedback>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              type='button'
              disabled={loading}
              onClick={this.closing}>
              {t('common.close')}
            </Button>
            <LoadingButton
              loading={loading}
              disabled={loading}
              color="success">
              {t('common.valid')}
            </LoadingButton>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export {VisitsDateChange};
export default VisitsDateChange;
