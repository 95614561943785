import {fk, attr, Model} from 'redux-orm';
import {_Client, _Prestataire, _Parameter} from "../constants/orm";

export class Parameter extends Model{
    static parse(data){
        const {cible_id, model, ...parameter} = data;
        switch (model) {
            case ('clients'):
                parameter.client_id = cible_id;
                break;
            case ('prestataires'):
                parameter.prestataire_id = cible_id;
                break;
        }
        return parameter;
    }

    static get fields() {
        return {
            id: attr(),
            client_id: fk(_Client, 'parameters'),
            prestataire_id:fk(_Prestataire, 'parameters'),
            label: attr()
        }
    }
}

Parameter.modelName = _Parameter;
