import React from "react";
import {connect} from "react-redux";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label,
  Form
} from "reactstrap";
import {setModifyPrestataireClients} from "../../actions/modals";
import {LoadingButton} from "../common/LoadingButton";
import createSelector from "../../tools/createSelector";
import {Select2Wrapper} from "../common/Select2Wrapper";
import {updateClient} from "../../actions/orm/Client";

const clientsSelector = createSelector(
  (orm, {prestataireId}) => {
    const prestataire = orm.Prestataire.withId(prestataireId);
    const clients = orm.Client.all().filter(i => (!i.parent_id)).toModelArray().map(i => ({
      ...i.ref,
      prestataires: i.prestataires.toRefArray()
    }));
    return {
      prestataire: {
        ...prestataire.ref,
        clients: prestataire.clients.toRefArray()
      },
      clients,
      prestataireClients: prestataire.clients.toRefArray()
    }
  }
);

@connect(
  ({modals: {modifyPrestataireClients: {prestataireId}}}) => ({
    prestataireId
  })
)
class ModifyPrestataireClients extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {
      closing: false,
      loading: false,
      selectedClients: [],
      clients: [],
      clientsSelectData: [],
      prestataire: null
    };

    const {clients, prestataireClients, prestataire} = clientsSelector({prestataireId: this.props.prestataireId});

    this.state.selectedClients = prestataireClients.map(i => i.id);
    this.state.clients = clients;
    this.state.clientsSelectData = clients.map(i => ({
      id: i.id,
      text: i.label
    }));
    this.state.prestataire = prestataire;
  }

  onChange = (e) => {
    this.setState({[e.target.name] : e.target.value})
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const {dispatch, prestataireId} = this.props;
    const {clients, selectedClients, prestataire} = this.state;

    const oldClients = prestataire.clients.map(i => i.id);
    let addedClientsCalls = [];
    let removedClientsCalls = [];

    this.setState({loading: true});
    //checking for added clients
    selectedClients.map(id => {
      if (oldClients.indexOf(id) === -1){
        const client = clients.find(i => i.id === id);
        addedClientsCalls.push(dispatch(updateClient({
          id,
          prestataireIds: [...client.prestataires.map(i => i.id), prestataireId]
        })));
      }
    });
    //checking for removed clients
    oldClients.map(id => {
      if (selectedClients.indexOf(id) === -1){
        const client = clients.find(i => i.id === id);
        let prestataires = client.prestataires.map(i => i.id);

        prestataires.splice(prestataires.indexOf(prestataireId), 1);
        removedClientsCalls.push(dispatch(updateClient({
          id,
          prestataireIds: prestataires
        })));
      }
    });

    await Promise.all([...addedClientsCalls, ...removedClientsCalls]);
    this.setState({loading: false});
    this.closing();
  };

  close = () => {
    this.props.dispatch(setModifyPrestataireClients({
      open: false
    }));
  };

  closing = () => {
    this.setState({closing: true});
  };

  render(){
    const {closing, loading, clientsSelectData, selectedClients} = this.state;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.closing}>{t('common.modify_clients')}</ModalHeader>
        <Form onSubmit={this.onSubmit}>
          <ModalBody>
            <FormGroup>
              <Label>
                {t('common.clients')}
              </Label>
              <Select2Wrapper
                multiple
                name="selectedClients"
                data={clientsSelectData}
                value={selectedClients}
                options={{
                  placeholder: t('common.clients')
                }}
                onChange={this.onChange}/>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary"
                    type="button"
                    disabled={loading}
                    onClick={this.closing}>
              {t('common.close')}
            </Button>
            <LoadingButton color="success"
                           loading={loading}
                           disabled={loading}
                           type="submit">
              {t('common.valid')}
            </LoadingButton>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export {ModifyPrestataireClients};
export default ModifyPrestataireClients;
