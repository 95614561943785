import {fk, attr, Model, many} from 'redux-orm';
import {_TypeZoneExploitation} from '../constants/orm';

export class TypeZoneExploitation extends Model{
    static get fields(){
        return{
            id: attr(),
            label: attr(),
            created: attr(),
            modified: attr()
        }
    }
}

TypeZoneExploitation.modelName = _TypeZoneExploitation;

export default TypeZoneExploitation;