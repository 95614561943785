import {fk, many, attr, Model} from 'redux-orm';
import {_ProgressModel, _Client, _Theme, _ThemeQuestion, _Action, _ModelAction} from "../constants/orm";

export class ProgressModel extends Model{
    static parse(data){
        const actions = data.type_actions.map(a => a.id);

        delete data._matchingData;
        delete data._joinData;
        delete data.type_actions;
        return {...data, actions: actions, themes: data.themes.map(theme => theme.id), themes_questions: data.themes_questions.map(theme_question => theme_question.id)};
    }

    static get fields() {
        return {
            id: attr(),
            client_id: fk(_Client, 'progressModels'),
            themes: many(_Theme, 'progressModels'),
            themes_questions: many(_ThemeQuestion, 'progressModels'),
            label: attr(),
            level: attr(),
            created: attr(),
            modified: attr(),
            actions: many(_ModelAction, 'progressModels')
        }
    }
}

ProgressModel.modelName = _ProgressModel;