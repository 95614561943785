import {fk, attr, Model} from 'redux-orm';
import {_User, _Message, _ProgressZoneExploitation} from "../constants/orm";

export class Message extends Model {
  static parse(data){
    return data;
  }
  static get fields(){
    return {
      id: attr(),
      status: attr(),
      content: attr(),
      created: attr(),
      user_id: fk(_User, 'messages'),
      progre_zone_exploitation_id: fk(_ProgressZoneExploitation, 'messages')
    }
  }
}

Message.modelName = _Message;
export default Message;
