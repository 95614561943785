import api from "../../tools/api";
import {hydrateEntities} from "../orm";
import {_ProgressPlan, _ProgressZoneExploitation} from "../../constants/orm";

export const fetchProgressPlansWithFilters = (filters) => {
  return async dispatch => {
    return await api.progress.getWithFilters(filters);
  }
};

export const fetchProgressPlan = ({id}) => {
  return async dispatch => {
    const progressPlan = await api.progress.get({id});

    dispatch(hydrateEntities({
      payload: [
        {type: _ProgressPlan, entities: [progressPlan]}
      ]
    }))
  }
};

export const addZoneExploitationInProgressPlan = ({progress_id, zone_exploitation_id, user_id}) => {
  return async dispatch => {
    const progres_ze =  await api.progress.addZoneExploitation({progress_id, zone_exploitation_id, user_id});

    dispatch(hydrateEntities({
      payload: [
        {type: _ProgressZoneExploitation, entities: [progres_ze]}
      ]
    }))
  }
};