import React from "react";
import {setAddModifyQuestion, setChooseQuestion} from "../../actions/modals";
import {connect} from "react-redux";
import {
  Badge,
  Button,
  Form, FormFeedback,
  FormGroup, FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import LoadingButton from "../common/LoadingButton";
import {EditorState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {convertToHTML, convertFromHTML} from "draft-convert";
import {WritableImageCarousel} from "../common/WritableImageCarousel";
import {WritableFileList} from "../common/WritableFileList";
import {FormValidator} from "../../tools/formValidator/FormValidator";
import CreatableSelect from 'react-select/creatable';
import {fetchGenericThemes} from "../../actions/orm/Theme";
import {startPending, stopPending} from "../../actions/ui";
import {createGenericQuestionsSelector} from "../selectors/question";
import {createThemeTreeByClientIdSelector} from "../selectors/theme";
import {hasPermission} from "../../constants/modules";
import {Adm_The_Gen} from "../../constants/modules";
import {tFunction} from "../tools/Translation";

const localFv = new FormValidator([
  {
    field: 'title',
    method: 'isEmpty',
    validWhen: false,
    message: tFunction('modals.title_informed')
  },
  {
    field: 'label',
    method: 'isEmpty',
    validWhen: false,
    message: tFunction('modals.label_required')
  },
  {
    field: 'ranging',
    method: 'isArrayEmpty',
    validWhen: false,
    message: tFunction('admin.audit_view.ranging_require')
  }
]);

const genericQuestionsSelector = createGenericQuestionsSelector();
const themeTreeSelector = createThemeTreeByClientIdSelector();
const rangingDefaultValues = [{label: '0', value: 0}, {label: '25', value: 25}, {label: '50', value: 50}, {label: '75', value: 75}, {label: '100', value: 100}];

@connect(({modals: {addModifyQuestion: {current, isGeneric, resolve, reject}}}) => ({
  current,
  isGeneric : !!isGeneric,
  resolve,
  reject
}))
class AddModifyQuestion extends React.PureComponent {
  constructor(props) {
    super(props);

    const {current} = this.props;

    this.state = {
      loading: false,
      closing: false,
      editorState: EditorState.createEmpty(),
      labelEditorState: EditorState.createEmpty(),
      validation: localFv.valid(),
      label: '',
      generic: null,
      title: '',
      faq: '',
      type: 'free',
      objectif: '',
      ranging: rangingDefaultValues,
      photo: false,
      answer: false,
      file: false,
      sample_comment: '',
      sample_files: [],
      sample_photos: [],
      sample_value: '',
      view: 'normal',
      genericQuestionsOptions: {}
    };

    if (!!current){
      this.state = {
        ...this.state,
        label: !!current.label ? current.label : '',
        generic: !!current.generic ? current.generic : null,
        title: !!current.title ? current.title : '',
        faq: !!current.faq ? current.faq : '',
        objectif: !!current.objectif ? current.objectif : '',
        type: !!current.type ? current.type : 'free',
        ranging: !!current.ranging && !!current.ranging.length ? current.ranging.map(r => ({label: r, value: r})) : [],
        photo: !!current.photo ? current.photo : false,
        answer: !!current.answer ? current.answer : false,
        file: !!current.file ? current.file : false,
        sample_comment: !!current.sample_comment ? current.sample_comment : '',
        sample_files: !!current.sample_files ? current.sample_files : [],
        sample_photos: !!current.sample_photos ? current.sample_photos : [],
        sample_value: !!current.sample_value ? current.sample_value : ''
      };
      if (!!current.faq)
        this.state.editorState = EditorState.createWithContent(convertFromHTML(current.faq));
      if (!!current.label)
        this.state.labelEditorState = EditorState.createWithContent(convertFromHTML(current.label));
    }
  }

  inputTitle = React.createRef();

  componentDidMount() {
    this.inputTitle.focus();
  }

  setView = (view) => {
    this.setState({view});
  };

  setGenericLinkageView = async () => {
    this.props.dispatch(startPending());
    await this.props.dispatch(fetchGenericThemes());
    const genericQuestions = genericQuestionsSelector();
    const questionOptions = genericQuestions.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {});

    this.setState({
      view: 'linkage',
      genericQuestionsOptions: questionOptions
    });
    this.props.dispatch(stopPending());
  };

  chooseGenericQuestion = async () => {
    const genericThemesTree = themeTreeSelector({clientId: null}, true);

    try {
      const question = await this.props.dispatch(setChooseQuestion({
        open: true,
        tree: genericThemesTree
      }));

      this.setState({generic: question.id});
    } catch (e) {}
  };

  handleLabelEditorChange = (editorState) => {
    const label = convertToHTML(editorState.getCurrentContent());
    let cuttedLabel = label.replace(/<p>/gi, '');
    cuttedLabel = cuttedLabel.replace(/<\/p>/gi, '');

    this.setState({
      labelEditorState: editorState,
      label: cuttedLabel
    })
  };

  handleEditorStateChange = (editorState) => {
    const faq = convertToHTML(editorState.getCurrentContent());

    this.setState({
      editorState,
      faq: faq
    });
  };

  handleChange = ({target}) => {
    const {name, value} = target;

    this.setState({[name]: value});
  };

  handleCheckboxChange = ({target}) => {
    const {name, checked} = target;

    this.setState({[name]: checked});
  };

  checkRanging = (f) => {
    try {
      const validation = localFv.validate(f);

      this.setState({validation});
      return validation.isValid;
    } catch (e) {}
  };

  initRanging = async () => {
    try {
      this.setState({ranging: rangingDefaultValues.sort((a,b) => a.value - b.value)});
      this.checkRanging({'ranging':rangingDefaultValues});
    } catch (e) {}
  };

  onSubmit = (e) => {
    e.preventDefault();

    const {
      label,
      generic,
      title,
      faq,
      objectif,
      type,
      ranging,
      photo,
      answer,
      file,
      sample_comment,
      sample_files,
      sample_photos,
      sample_value
    } = this.state;

    var rules = {title,label};

    if(type === 'select')
      rules.ranging = ranging;

    const validation = localFv.validate(rules);
   
    if (!validation.isValid){
      this.setState({validation});
      return;
    }

    this.props.resolve({
      label,
      generic,
      title,
      faq,
      objectif: objectif === '' ? null : parseInt(objectif),
      type,
      ranging: ranging.map(r => parseInt(r.value)),
      photo,
      answer,
      file,
      sample_comment,
      sample_files,
      sample_photos,
      sample_value
    });
    this.closing();
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setAddModifyQuestion({
      open: false
    }));
  };

  reject = () => {
    this.props.reject();
    this.closing();
  };

  handleRangeValueChange = (newValue) => {
    this.setState({ranging: newValue.sort((a,b) => a.value - b.value)});
    this.checkRanging({'ranging' : newValue});
  };

  isChoiceValidOption = (inputValue, selectValue, selectOptions) => {
    let number = parseInt(inputValue);

    return !isNaN(number) && number >= 0 && number <= 100 && !selectValue.find(v => parseInt(v.value) === number);
  };

  render(){
    const {validation, loading, closing, view, generic, label, title, genericQuestionsOptions, objectif, type, ranging, answer, photo, file, sample_comment, sample_files, sample_photos, sample_value} = this.state;
    const {isGeneric} = this.props;

    const genericQuestion = genericQuestionsOptions[generic];
    const genericPerm = hasPermission(Adm_The_Gen);

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.reject}>
          {t('modals.edit_question')}
        </ModalHeader>
        <Form onSubmit={this.onSubmit}>
          <ModalBody>
            <FormGroup>
              <Nav tabs>
                <NavItem>
                  <NavLink active={view === 'normal'}
                           onClick={() => this.setView('normal')}>
                    {t('common.general')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink active={view === 'faq'}
                           onClick={() => this.setView('faq')}>
                    {t('common.faq')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink active={view === 'example'}
                           onClick={() => this.setView('example')}>
                    {t('modals.response_example')}
                  </NavLink>
                </NavItem>
                {!isGeneric && genericPerm &&
                <NavItem>
                  <NavLink active={view === 'linkage'}
                           onClick={this.setGenericLinkageView}>
                    {t('theme_question_view.generic_bank')}
                  </NavLink>
                </NavItem>}
              </Nav>
            </FormGroup>
            {view === 'normal' &&
            <>
              <FormGroup>
                <Label>
                  {t('common.title')}
                </Label>
                <Input
                  invalid={validation.title.isInvalid}
                  innerRef={(input) => (this.inputTitle = input)}
                  name="title"
                  maxLength="250"
                  value={title}
                  onChange={this.handleChange}
                  placeholder={t('common.title')}/>
                <FormFeedback>
                  {validation.title.message}
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>
                  {t('common.label')}
                </Label>
                <div>
                  <Editor editorState={this.state.labelEditorState}
                          onEditorStateChange={this.handleLabelEditorChange}
                          handleBeforeInput={input => {
                            if (this.state.label.length > 900)
                              return 'handled';
                          }}
                          handlePastedText={pastedText => {
                            if (this.state.label.length + pastedText.length > 900)
                              return 'handled';
                          }}
                          toolbar={{
                            options: ['inline', 'list', 'blockType'],
                            inline: {
                              inDropdown: false,
                              options: ['bold', 'italic', 'underline', 'strikethrough']
                            },
                            blockType: {
                              inDropdown: true,
                              options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6']
                            },
                            list: {
                              inDropdown: false,
                              options: ['unordered', 'ordered']
                            }
                          }}/>
                </div>
                {validation.label.isInvalid &&
                <FormText color="danger">
                  {validation.label.message}
                </FormText>}
              </FormGroup>
              <FormGroup>
                <Label>
                  {t('admin.audit_view.goal')} <span class="text-muted">({t('admin.audit_view.leave_empty')})</span>
                </Label>
                <Input
                  min={0}
                  max={100}
                  placeholder={t('admin.audit_view.goal')}
                  name="objectif"
                  value={objectif}
                  onChange={this.handleChange}
                  type="number"/>
              </FormGroup>
              <FormGroup>
                <Label>
                  {t('admin.audit_view.required_field')}
                </Label>
                <div>
                  <label class="custom-control custom-control-inline custom-checkbox">
                    <input name="answer"
                           type="checkbox"
                           className="custom-control-input"
                           onChange={this.handleCheckboxChange}
                           checked={answer}/>
                    <div className="custom-control-label">
                      {t('common.response')}
                    </div>
                  </label>
                  <label className="custom-control custom-control-inline custom-checkbox">
                    <input name="photo"
                           type="checkbox"
                           className="custom-control-input"
                           onChange={this.handleCheckboxChange}
                           checked={photo}/>
                    <div className="custom-control-label">
                      {t('common.photo')}
                    </div>
                  </label>
                  <label className="custom-control custom-control-inline custom-checkbox">
                    <input name="file"
                           type="checkbox"
                           className="custom-control-input"
                           onChange={this.handleCheckboxChange}
                           checked={file}/>
                    <div className="custom-control-label">
                      {t('common.file')}
                    </div>
                  </label>
                </div>
              </FormGroup>
              <FormGroup>
                <Label>
                  {t('admin.audit_view.response_type')}
                </Label>
                <div>
                  <label className="custom-control custom-control-inline custom-radio">
                    <input name="type"
                           type="radio"
                           className="custom-control-input"
                           value={'free'}
                           onChange={this.handleChange}
                           checked={type === 'free'}/>
                    <div className="custom-control-label">
                      {t('common.free')}
                    </div>
                  </label>
                  <label className="custom-control custom-control-inline custom-radio">
                    <input name="type"
                           type="radio"
                           className="custom-control-input"
                           value={'bool'}
                           onChange={this.handleChange}
                           checked={type === 'bool'}/>
                    <div className="custom-control-label">
                      {t('common.yes')}/{t('common.no')}
                    </div>
                  </label>
                  <label className="custom-control custom-control-inline custom-radio">
                    <input name="type"
                           type="radio"
                           className="custom-control-input"
                           onChange={this.handleChange}
                           value={'select'}
                           checked={type === 'select'}/>
                    <div className="custom-control-label">
                      {t('admin.audit_view.multiple_choice')}
                    </div>
                  </label>
                </div>
              </FormGroup>
              {type === 'select' &&
              <FormGroup>
                <Label>
                  {t('admin.audit_view.choice')} <span class="text-muted">({t('admin.audit_view.options_response_0_100')})</span>
                </Label>
                <CreatableSelect
                  isMulti
                  isValidNewOption={this.isChoiceValidOption}
                  onChange={this.handleRangeValueChange}
                  value={ranging}
                  noOptionsMessage={() => t('modals.write_value')}
                  placeholder={t('modals.add_options')}
                  formatCreateLabel={(inputValue) => `${t('common.add')} ${inputValue}`}
                />
                {validation.ranging.isInvalid &&
                  <FormText color="danger">
                  {validation.ranging.message}
                  </FormText>}
              </FormGroup>}
            </>}
            {view === 'faq' &&
            <>
              <FormGroup>
                <Label>
                  {t('common.faq')}
                </Label>
                <div>
                  <Editor editorState={this.state.editorState}
                          onEditorStateChange={this.handleEditorStateChange}
                          toolbar={{
                            options: ['inline', 'list', 'blockType'],
                            inline: {
                              inDropdown: false,
                              options: ['bold', 'italic', 'underline', 'strikethrough']
                            },
                            blockType: {
                              inDropdown: true,
                              options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6']
                            },
                            list: {
                              inDropdown: false,
                              options: ['unordered', 'ordered']
                            }
                          }}/>
                </div>
              </FormGroup>
            </>}
            {view === 'example' &&
            <>
              <FormGroup>
                <Label>
                  {t('common.comment')}
                </Label>
                <Input
                  type="textarea"
                  name="sample_comment"
                  value={sample_comment}
                  onChange={this.handleChange}
                  placeholder={t('common.comment')}/>
              </FormGroup>
              <FormGroup>
                <Label>
                  {t('common.value')}
                </Label>
                <Input
                  type="number"
                  name="sample_value"
                  value={sample_value}
                  onChange={this.handleChange}
                  placeholder={t('common.value')}/>
              </FormGroup>
              <FormGroup>
                <Label>
                  {t('common.photos')}
                </Label>
                <div>
                  <WritableImageCarousel
                    size={100}
                    value={sample_photos}
                    name="sample_photos"
                    onChange={this.handleChange}/>
                </div>
              </FormGroup>
              <FormGroup>
                <Label>
                  {t('common.files')}
                </Label>
                <div>
                  <WritableFileList
                    value={sample_files}
                    onChange={this.handleChange}
                    name="sample_files"/>
                </div>
              </FormGroup>
            </>}
            {!isGeneric && genericPerm && view === 'linkage' &&
            <>
              <Label class="font-weight-bold">
                {t('modals.generic_question_associate')}
              </Label>
              <FormGroup>
                <div class="pr-1 mb-2">
                  {!genericQuestion ?
                    <span class="text-muted">{t('modals.no_question_associate')}</span> :
                    <span>
                     <Badge color="primary" title={t('common.question')} class="badge-subtle">
                       {t('common.question_badge')}
                      </Badge>
                      &nbsp;
                      {genericQuestion.title}
                      </span>}
                  <i class="fa fa-edit link ml-1"
                     title={t('modals.select_question')}
                     onClick={this.chooseGenericQuestion}/>
                </div>
              </FormGroup>
            </>}
          </ModalBody>
          <ModalFooter>
            {(type === 'select' && !this.state.ranging.length) &&
            <Button
              color="secondary"
              type="button"
              className="mr-auto"
              onClick={this.initRanging}>
              {t('admin.audit_view.init_choice')}
            </Button>
            }
            <Button
              color="secondary"
              type="button"
              onClick={this.reject}>
              {t('common.close')}
            </Button>
            <LoadingButton
              loading={loading}
              disabled={loading}
              color="success">
              {t('common.valid')}
            </LoadingButton>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export {AddModifyQuestion};
export default AddModifyQuestion;
