import api from "../../tools/api";
import {_Visit} from "../../constants/orm";
import {hydrateEntities, updateEntities} from "../orm";

export const validateVisit = ({report_id, zone_exploitations_id}) => {
  return async dispatch => {
    const visit = await api.visit.validate({
      report_id,
      zone_exploitations_id
    });

    dispatch(updateEntities({
      payload: [
        {type: _Visit, entities: [visit]}
      ]
    }));

    return visit;
  }
};

export const addVisit = ({report_id, zone_exploitation_id, passage, status, user_id, comment}) => {
  return async dispatch => {
    const visit = await api.visit.add({
      report_id,
      zone_exploitation_id,
      passage,
      status,
      user_id,
      comment
    });

    dispatch(hydrateEntities({
      payload: [
        {type: _Visit, entities: [visit]}
      ]
    }));

    return visit;
  }
};

export const updateVisit = ({id, passage, status, user_id, comment}) => {
  return async dispatch => {
    const visit = await api.visit.update({
      id,
      passage,
      status,
      user_id,
      comment
    });

    dispatch(updateEntities({
      payload: [
        {type: _Visit, entities: [visit]}
      ]
    }));

    return visit;
  }
};
