import React from "react";
import LoadingButton from "../common/LoadingButton";
import {createBrandSelectorById} from "../selectors/brand";
import {connect} from "react-redux";
import {
  FormGroup,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import {setRemoveBrand} from "../../actions/modals";
import {removeBrand} from "../../actions/orm/Brand";

const brandSelector = createBrandSelectorById();

@connect(({modals: {removeBrand: {brandId}}}) => ({
  brandId
}))
class RemoveBrand extends React.PureComponent {
  constructor(props) {
    super(props);
    const {brandId} = this.props;

    this.state = {
      closing: false,
      loading: false,
      brand: brandSelector({id: brandId})
    }
  }

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setRemoveBrand({
      open: false
    }))
  };

  handleSubmit = async () => {
    const {brand} = this.state;

    this.setState({loading: true});
    await this.props.dispatch(removeBrand({
      id: brand.id
    }));
    this.setState({loading: false});
    this.closing();
  };

  render(){
    const {brand, closing, loading} = this.state;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.closing}>
          {t('modals.removing_brand')} {brand.label}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            {t('modals.remove_brand_question')} {brand.label} ?
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            type='button'
            disabled={loading}
            onClick={this.closing}>
            {t('common.cancel')}
          </Button>
          <LoadingButton
            loading={loading}
            disabled={loading}
            onClick={this.handleSubmit}
            color="danger">
            {t('common.remove')}
          </LoadingButton>
        </ModalFooter>
      </Modal>
    )
  }
}

export {RemoveBrand};
export default RemoveBrand;
