import React from 'react';

const NoPermissions = () => (
    <React.Fragment>
        <main className='app-main bordered-scrollbar'>
            <div class="wrapper">
                <div id="notfound-state" class="empty-state">
                    <div class="empty-state-container">
                        <div class="state-figure">
                            <img class="img-fluid" src="/img/error-page.svg" alt="" style={{maxWidth: '300px'}}/>
                        </div>
                        <h3 class="state-header">{t('common.oops')}</h3>
                        <p class="state-description lead text-muted">{t('common.no_modules_contact_admin')}</p>
                    </div>
                </div>
            </div>
        </main>
    </React.Fragment>
);

export default NoPermissions;