import {SET_CURRENT_CLIENT_ID, SET_SESSION_USER, RECEIVE_USER_RIGHTS, RECEIVE_USER_CLIENTS, RECEIVE_USER_RIGHTS_HIERARCHY,
  CLEAR_SESSION, START_INIT, END_INIT, SWITCH_CLIENT} from "../actions/session";
import {START_PENDING, STOP_PENDING} from "../actions/ui";
import * as _Redux from "../redux";
import {fetchSessionUser} from "../actions/orm/User";
import {getFilteredModulesTree} from "../constants/modules";
import {getDefaultUserClient, setDefaultUserClient} from "../tools/localStorageHelpers";
import update from "immutability-helper";

const sessionStateDEFAULT = {
  isInit: false,
  currentClientId: null,
  userId: null,
  rights:null,
  hierarchicRights: [],
  clientsId:[],
  websocketJWT: null,
  forepaasJWT: null
};

export const sessionReducer = _Redux.createReducer(Object.assign({}, sessionStateDEFAULT), {
  [CLEAR_SESSION]: clearSession,
  [START_INIT]: startInit,
  [END_INIT]: endInit,
  [SET_CURRENT_CLIENT_ID]: setCurrentClientId,
  [SET_SESSION_USER]: setSessionUser
});

function setCurrentClientId(state, {clientId}){
  return update(state, {
    currentClientId: {$set: clientId}
  })
}

function setSessionUser(state, {user}){
  const {roots, modules, default_client_id, id, jwt, jwt_forepaas} = user;
  const clientIds = roots.map(c => c.id);
  const modulesTree = getFilteredModulesTree(modules);
  const rights = modules.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});

  const storageClientId = getDefaultUserClient(id);
  const defaultClientId = clientIds.find(id => id === storageClientId);

  return update(state, {
    userId: {$set: id},
    rights: {$set: rights},
    websocketJWT: {$set: jwt},
    forepaasJWT: {$set: jwt_forepaas},
    clientsId: {$set: clientIds},
    currentClientId: {$set: !!defaultClientId ? defaultClientId : !!default_client_id ? default_client_id : clientIds[0]},
    hierarchicRights: {$set: modulesTree}
  });
}

/**
 * fetch all the session data on connection or redirection
 * from OCM
 * @returns {function(*=): Promise<AxiosResponse<any>>}
 */
export function fetchSession(){
  return async dispatch => {
    try {
      dispatch({type: START_PENDING});

      await dispatch(fetchSessionUser());
      dispatch({type: STOP_PENDING});
      dispatch({type: END_INIT});
    } catch(error){
      console.log(error);
      dispatch({type: STOP_PENDING});
      dispatch({type: CLEAR_SESSION, payload: error});
    }
  };
}

/**
 * redirect to OCM for connection
 * @param sessionState
 * @param action
 * @returns {*}
 */
function clearSession(sessionState, action){
  if(_ENV.hasOwnProperty('LOGOUT_URL')) window.location.replace(_ENV.LOGOUT_URL);
  return {...sessionStateDEFAULT};
}

function startInit(sessionState, action){
  if(sessionState.isInit) return _Redux.updateObject(sessionState, {isInit: false});

  return sessionState;
}

function endInit(sessionState, action){
  if(!sessionState.isInit) return _Redux.updateObject(sessionState, {isInit: true});

  return sessionState;
}
