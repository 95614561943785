import React from "react";
import {api} from "../../tools/api";

class ImportImageDiv extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  fileInputRef = React.createRef();

  onLogoChange = (e) => {
    const {onChange, name} = this.props;
    const file = e.target.files[0];
    const extension = file.name.split(".");
    let defaultName = "";
    extension.map((n, i) => {
      if (i !== extension.length - 1)
        defaultName += i === 0 ? n : `.${n}`;
    });

    const reader = new FileReader();
    reader.onload = async (e) => {
      const response = await api.file.upload({
        data: `extension:${extension[extension.length - 1]};${e.target.result}`
      });

      if (!!onChange)
        onChange({
          target: {
            name: name,
            value: response.path
          }
        });
    };
    reader.readAsDataURL(file);
  };

  render(){
    const {src, name, onChange, ...otherProps} = this.props;

    return (
      <React.Fragment>
        {!!src ?
          <img src={src}
               title={t('common.import_image')}
               style={{
                 width:48,
                 height: 48,
                 borderRadius:'.3rem',
                 cursor: 'pointer'
               }}
               onClick={() => this.fileInputRef.current.click()}
               {...otherProps}/> :
          <div className="tile tile-lg cursor-pointer"
               title={t('common.import_image')}
               onClick={() => this.fileInputRef.current.click()}
               {...otherProps}>
            <span className="fa fa-upload"/>
          </div>}
        <input
          hidden
          ref={this.fileInputRef}
          type="file"
          name="path"
          accept="image/*"
          onChange={this.onLogoChange}
          className="custom-file-input"/>
      </React.Fragment>
    )
  }
}

export {ImportImageDiv};
export default ImportImageDiv;
