import {fk, attr, many, Model} from 'redux-orm';
import {
  _Taxonomy,
  _Brand,
  _ZoneExploitation,
  _Prestataire,
  _Site,
  _User,
  _TypeZoneExploitation,
  _Responsable
} from "../constants/orm";

export class ZoneExploitation extends Model{
  static parse(data, orm){
    delete data._joinData;

    if (!!data.prestataires)
      data.prestataires = data.prestataires.map(p => p.id);
    if (!!data.taxonomies)
      data.taxonomies = data.taxonomies.map(t => t.id);
    if (!data.schedules)
      data.schedules = [];

    data.responsable_ids = data.responsables !== undefined ? data.responsables.map(responsable => responsable.user_id) : [];
    data.main_responsable_id = data.responsables !== undefined && !!data.responsables.length ? !!data.responsables.find(r => !!r.main) ? data.responsables.find(r => !!r.main).id : null : null;

    return data;
  }

  static get fields(){
    return {
      id: attr(),
      label: attr(),
      site_id: fk(_Site, 'zone_exploitations'),
      prestataires: many(_Prestataire, 'zoneExploitations'),
      taxonomies: many(_Taxonomy, 'zoneExploitations'),
      type_zone_exploitation_id: fk(_TypeZoneExploitation),
      brand_id: fk(_Brand, 'zoneExploitations'),
      comment: attr(),
      created: attr(),
      schedules: attr(),
      zone_characteristics: attr(),
      modified: attr(),
      user_id: fk(_User, 'zones_exploitation'),
      scope: attr(),
      responsable_ids: fk(_User, 'zoneExploitations'),
      main_responsable_id: fk(_User, 'zoneExploitationsMainResponsable')
    }
  }
}

ZoneExploitation.modelName = _ZoneExploitation;

export default ZoneExploitation;
