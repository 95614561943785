import createSelector from "../../tools/createSelector";

export const createBrandSelectorById = () => {
  return createSelector(
    (orm, {id}) => {
      const brand = orm.Brand.withId(id);

      return !!brand ? brand.ref : null;
    }
  )
};

export const createAllBrandsSelector = () => {
  return createSelector(
    (orm) => {
      return orm.Brand.all().toRefArray();
    }
  )
};
