import * as _Redux from "../redux";
import update from "immutability-helper";

export const reportingReducer = _Redux.createReducer(
  {
    filters: {
      clients: {
        loading: true,
        fappId: "agg_ocm2_clients_level_1",
        labelData: 'ocm2_clients_label',
        labelScale: 'ocm2_clients_id',
        labelTableName: 'ocm2_clients',
        options: [],
        value: [],
        parents: [],
        isMulti: false,
        isClearable: false,
        defaultSelection: {
          state: true,
          value: 'first'
        },
      },
      prestataires: {
        loading: true,
        fappId:"agg_ocm2_prestataires_level_1",
        labelData: 'ocm2_prestataires_label',
        labelScale: 'ocm2_prestataires_id',
        labelTableName: 'ocm2_prestataires',
        options: [],
        value: [],
        parents: [
          "clients"
        ],
        isMulti: true,
        isClearable: true,
        defaultSelection: {
          state: false,
          value: 'first'
        }
      },
      zones_exploitation: {
        loading: true,
        fappId:"ocm2_zone_exploitations_id",
        labelData: 'ocm2_zone_exploitations_label',
        labelScale: 'ocm2_zone_exploitations_id',
        labelTableName: 'ocm2_zone_exploitations',
        options: [],
        value: [],
        parents: [
          "clients",
          "prestataires",
          "analyses",
          "vagues"
        ],
        isMulti: true,
        isClearable: true,
        defaultSelection: {
          state: false,
          value: 'first'
        }
      },
      analyses: {
        loading: true,
        fappId:"ocm2_analyses_id",
        labelData: 'ocm2_analyses_label',
        labelScale: 'ocm2_analyses_id',
        labelTableName: 'ocm2_analyses',
        options: [],
        value: [],
        parents: [
          "clients"
        ],
        isMulti: false,
        isClearable: false,
        defaultSelection: {
          state: true,
          value: 'first'
        }
      },
      vagues: {
        loading: true,
        fappId:"agg_ocm2_analyses_vague",
        options: [],
        value: [],
        parents: [
          "analyses"
        ],
        isMulti: false,
        isClearable: false,
        defaultSelection: {
          state: true,
          value: 'last'
        }
      },
      themesTree: {
        loading: true,
        options: [],
        value: {
          agg_ocm2_themes_level_1: [],
          agg_ocm2_themes_level_2: [],
          ocm2_questions_id: []
        },
        parents: [
          "clients",
          "prestataires",
          "analyses",
          "vagues",
          "zones_exploitation"
        ],
        isTree: true,
        isMulti: false,
        isClearable: false,
        defaultSelection: {
          state: true,
          value: 'last'
        }
      }
    }
  },
  {
    ['SET_LOADING']: setLoading,
    ['UPDATE_OPTIONS']: updateOptions,
    ['UPDATE_VALUE']: updateValue,
    ['UPDATE_TREE_OPTIONS']: updateTreeOptions
  });

function updateValue(state, {filterId, value}){
  let newState = update(state, {
    filters: {
      [filterId]: {
        value: {$set: value}
      }
    }
  });

  Object.keys(state.filters).forEach(key => {
    const f = state.filters[key];

    if (f.parents.includes(filterId))
      newState = update(newState, {
        filters: {
          [key]: {
            loading: {$set: true}
          }
        }
      })
  });

  return newState;
}

function updateOptions(state, {filterId, options}){
  const filter = state.filters[filterId];
  let newValues = filter.value.filter(v => !!options.find(i => i.value === v.value));

  const valueChanged = newValues.length !== filter.value.length;

  if (!!filter.defaultSelection.state && !newValues.length && !!options.length){
    if (filter.defaultSelection.value === 'first')
      newValues.push(options[0]);
    else if (filter.defaultSelection.value === 'last')
      newValues.push(options[options.length - 1]);
  }

  let newState = update(state, {
    filters: {
      [filterId]: {
        value: {$set: newValues},
        options: {$set: options},
        loading: {$set: false}
      }
    }
  });

  if (valueChanged){
    Object.keys(state.filters).forEach(key => {
      const f = state.filters[key];

      if (f.parents.includes(filterId))
        newState = update(newState, {
          filters: {
            [key]: {
              loading: {$set: true}
            }
          }
        })
    });
  }
  return newState;
}

function updateTreeOptions(state, {filterId, options}){
  const filter = state.filters[filterId];

//  let newValues = filter.value.filter(v => !!options.find(i => (i.value === v.value && i.type === v.type)));

  let newValues = {};
  const horizontalTree = flatTree(options);

  Object.keys(filter.value).forEach(key => {
    newValues[key] = horizontalTree.filter(i => (i.fappId === key && !!i.checked)).map(i => i.id)
  });
//  const valueChanged = newValues.length !== filter.value.length;

  let newState = update(state, {
    filters: {
      [filterId]: {
        value: {$set: newValues},
        options: {$set: options},
        loading: {$set: false}
      }
    }
  });

//  if (valueChanged){
  Object.keys(state.filters).forEach(key => {
    const f = state.filters[key];

    if (f.parents.includes(filterId))
      newState = update(newState, {
        filters: {
          [key]: {
            loading: {$set: true}
          }
        }
      })
  });
//  }

  return newState;
}

function setLoading(state, {filterId}){
  return update(state, {
    filters: {
      [filterId]: {
        loading: {$set: true}
      }
    }
  })
}
