import api from "../../tools/api";
import {_ModelAction, _ProgressModel, _Theme, _ThemeQuestion} from "../../constants/orm";
import {deleteEntity, HYDRATE_ENTITIES, hydrateEntities} from "../orm";
import {addModelAction, removeModelAction, updateModelAction} from "./ModelAction";

export const fetchProgressModels = ({clients = [], themes = [], themes_questions = []}) => {
  return async dispatch => {
    const models = await api.progressModel.get({
      clients,
      themes_questions,
      themes
    });

    let modelActions = [];
    let theme_questions = [];

    models.forEach(item => {
      modelActions = modelActions.concat(item.type_actions);
      themes = themes.concat(item.themes);
      theme_questions = theme_questions.concat(item.themes_questions);
    });

    dispatch({
      type: HYDRATE_ENTITIES,
      payload: [
        {type: _ModelAction, entities: modelActions},
        {type: _ProgressModel, entities: models},
        {type: _Theme, entities: themes},
        {type: _ThemeQuestion, entities: theme_questions}
      ]
    });

    return models;
  }
};

export const addProgressModel = ({client_id, label, actions, level, themes, themes_questions}) => {
  return dispatch => {
    return api.progressModel.add({
      client_id,
      label,
      actions,
      level,
      themes_questions,
      themes
    }).then(model => {
      dispatch(hydrateEntities({
        payload: [
          {type: _ModelAction, entities: model.type_actions},
          {type: _ProgressModel, entities: [model]}
        ]
      }));
      return model;
    })
  }
};

export const deleteProgressModel = ({id}) => {
  return dispatch => {
    return api.progressModel.remove({id}).then(resp => {
      dispatch(deleteEntity({
        type: _ProgressModel,
        id: id
      }));
      return resp;
    })
  }
};

export const updateProgressModel = ({id, removedActions, newActions, updateActions, ...params}) => {
  return async dispatch => {
    const [r, c, u, model] = await Promise.all([
      Promise.all(removedActions.map(a => {
        return dispatch(removeModelAction({
          id: a.id
        }))
      })),
      Promise.all(newActions.map(a => {
        return dispatch(addModelAction({
          modelId: id,
          label: a,
          date: moment().format('x')
        }))
      })),
      Promise.all(updateActions.map(a => {
        return dispatch(updateModelAction({
          id: a.id,
          label: a.label
        }))
      })),
      api.progressModel.update({
        id: id,
        ...params
      })
    ]);
    dispatch(hydrateEntities({
      payload: [
        {type: _ProgressModel, entities: [model]}
      ]
    }))
  }
};
