import * as _Redux from "../redux";
import update from "immutability-helper";

import {SET_MODAL} from "../actions/modals";

import {modals} from "../components/modals/modalsDescriber";

let defaultState = {};

Object.keys(modals).map(modalName => {
  defaultState[modalName] = {
    open:false,
    resolve: null,
    reject: null,
    ...modals[modalName].state};
});

export const ocmModalsReducer = _Redux.createReducer({...defaultState}, {
  [SET_MODAL]: setModal
});

function setModal(state, {modalName, ...modalState}){
  let newState = state;
  const mState = newState[modalName];

  Object.keys(mState).map(key => {
    newState = update(newState, {
      [modalName]: {
        [key]: {$set: modalState[key]}
      }
    })
  });

  return newState;
}
