import React from "react";
import {ListGroup, ListGroupItem, Label} from "reactstrap";
import {Select2Wrapper} from "./Select2Wrapper";

class ResponsablesTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render(){
    const {responsablesChoices, selectedResponsables, mainResponsables, onChange, onChangeMain, prestataires} = this.props;

    return (
      <ListGroup className={'list-group-bordered weekTimetable'}>
        {prestataires.map(prestataire => {
          const prestataireName = prestataire.label;
          return (
            <ListGroupItem key={prestataire.id}>
              <div className="dayName lineHeight34">
                {prestataireName} :
              </div>
              <div className="w-100">
                <div className={`d-flex flex-column timeRange ${!!Object.keys(responsablesChoices).length && !responsablesChoices[prestataire.id].length && 'lineHeight34'}`}>
                  {!!Object.keys(responsablesChoices).length && !!responsablesChoices[prestataire.id].length &&
                  <Select2Wrapper
                    multiple
                    name="selectedResponsables"
                    data={responsablesChoices[prestataire.id]}
                    value={selectedResponsables[prestataire.id]}
                    options={{placeholder: t('common.responsable')}}
                    onChange={e => onChange(e, prestataire.id)}/>}
                  {!!Object.keys(responsablesChoices).length && !responsablesChoices[prestataire.id].length &&
                  <React.Fragment>{t('common.no_parametric_user')}</React.Fragment>}
                  {!!selectedResponsables[prestataire.id] && selectedResponsables[prestataire.id].length > 1 &&
                  <div className="mt-2">
                    <Label>Responsable principal :</Label>
                    <Select2Wrapper
                      name="mainResponsables"
                      data={responsablesChoices[prestataire.id].filter(r => selectedResponsables[prestataire.id].includes(r.id))}
                      value={mainResponsables[prestataire.id]}
                      options={{placeholder: t('common.responsable')}}
                      onChange={e => onChangeMain(e, prestataire.id)}/>
                  </div>}
                </div>
              </div>
            </ListGroupItem>
          )
        })}
      </ListGroup>
    )
  }
}

export {ResponsablesTable};
export default ResponsablesTable;