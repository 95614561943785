import {fk, attr, Model} from 'redux-orm';
import {_Client, _Taxonomy} from "../constants/orm";

export class Taxonomy extends Model {
  static parse(data){

    return data;
  }

  static get fields(){
    return {
      id: attr(),
      parent_id: fk(_Taxonomy, 'children'),
      client_id: fk(_Client, 'taxonomies'),
      tax_id: fk(_Taxonomy, 'modelTaxonomy'),
      label: attr(),
      freeze: attr(),
      created: attr(),
      modified: attr()
    }
  }
}

Taxonomy.modelName = _Taxonomy;