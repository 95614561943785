import React from "react";
import {Card, CardBody, CardHeader, Col, Row, Alert} from "reactstrap";
import {connect} from "react-redux";
import {ProgressPlanNumber} from "./ProgressPlanNumber";
import {ProgressPlanAdvancement} from "./ProgressPlanAdvancement";
import {ProgressPlanDelay} from "./ProgressPlanDelay";
import {ProgressPlanAdvancementTable} from "./ProgressPlanAdvancementTable";
import shallowequal from "shallowequal";
import {createItemI2cFetch} from "../../../tools/Filters/updateFunctions";
import {withFilters, FiltersTracker} from "@neqo34/filters";
import {createClientWithParametersByIdSelector} from "../../../selectors/clients";
import {fetchAllParameters} from "../../../../actions/orm/Parameter";

// @withFilters(({zones_exploitation: {options, value, loading}, analyses, vagues}) => ({
//   zoneIds: value.map(i => i.value),
//   zoneOptions: options.map(i => i.value),
//   zonesLoading: loading,
//   vaguesLoading: vagues.loading,
//   analyseIds: analyses.value.map(i => i.value),
//   vagues: vagues.value.map(i => i.value)
// }))

const clientSelector = createClientWithParametersByIdSelector();

@connect(({session: {currentClientId}}) => ({
  clientId: currentClientId,
  client: clientSelector({id: currentClientId})
}))
class Content extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      pps: [],
      vagueInfo: null,
      zones_exploitation: [],
      loading: true
    }
  }

  updateProgressPlans = async () => {
    const {zoneIds, zoneOptions, clientId, analyseIds, vagues, clients} = this.props;

    const zones = !!zoneIds.length ? zoneIds : ((!!zoneOptions && !!zoneOptions.length) ? zoneOptions : [-1]);


    let ppsValues = await this.vaguePdpDataFetch({
      zones_exploitation: zones,
      analyses: analyseIds,
      vagues: vagues
    });
    let zonesValues = await this.zonesPdpDataFetch({
      zones_exploitation: zones,
      analyses: analyseIds,
      vagues: vagues
    });

    this.setState({
      zones_exploitation: zonesValues,
      vagueInfo: !!ppsValues.length ? ppsValues[0] : null,
      loading: false
    });
  };

  async componentDidUpdate(prevProps){
    if (!shallowequal(this.props.zoneIds, prevProps.zoneIds) ||
      this.props.analyseIds[0] !== prevProps.analyseIds[0] ||
      this.props.vagues[0] !== prevProps.vagues[0]){
      this.updateProgressPlans();
    }
  }

  async componentDidMount(){
    if (!this.props.zonesLoading)
      this.updateProgressPlans();
    await this.props.dispatch(fetchAllParameters())
  }

  vaguePdpDataFetch = createItemI2cFetch({
    params: {
      progressPlanStateDistribution: true,
      latePdpNb: true
    },
    by: 'progressPlanDataByVague'
  });

  zonesPdpDataFetch = createItemI2cFetch({
    params: {
      progressPlanStateDistribution: true
    },
    by: 'progressPlanDataByZoneExploitation',
    withLabels: true,
    labelModelName: 'ZoneExploitation'
  });

  render(){
    const {pps, loading, zones_exploitation, vagueInfo} = this.state;
    const {passageLow, passageHigh, analyseIds, vagues, client} = this.props;
    if (loading)
      return t('reporting.loading');
    if (!vagueInfo)
      return (
        <Alert color="warning">
          {t('pp.empty_pps')}.
        </Alert>
      );
    return (
      <div>
        <Row class="mb-4">
          <Col sm={4}>
            <Card class="m-0" style={{height: '100%'}}>
              <CardHeader>
                Taux d'avancement des plans de progrès
              </CardHeader>
              <CardBody>
                <ProgressPlanAdvancement client={client} vague={vagueInfo} passageLow={passageLow} passageHigh={passageHigh}/>
              </CardBody>
            </Card>
          </Col>
          <Col sm={4}>
            <Card class="m-0" style={{height: '100%'}}>
              <CardHeader>
                Nombre de plans de progrès
              </CardHeader>
              <CardBody>
                <ProgressPlanNumber vague={vagueInfo}/>
              </CardBody>
            </Card>
          </Col>
          <Col sm={4}>
            <Card class="m-0" style={{height: '100%'}}>
              <CardHeader>
                Respect des délais
              </CardHeader>
              <CardBody>
                <ProgressPlanDelay vague={vagueInfo}/>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card class="m-0">
              <CardHeader>
                Taux d'avancement des plans de progrès par sélection
              </CardHeader>
              <CardBody>
                <ProgressPlanAdvancementTable
                  client={client}
                  vagues={vagues}
                  analyses={analyseIds}
                  data={zones_exploitation}
                  passageLow={passageLow}
                  passageHigh={passageHigh}/>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const SyntheticProgressPlanView = (props) => {
  return (
    <FiltersTracker filters={["analyses", "vagues", "zones_exploitation"]}>
      {(filters) => {
        if (!filters)
          return null;
        const {zones_exploitation, vagues, analyses} = filters;

        return <Content
          vagues={vagues}
          analyseIds={analyses}
          zoneIds={zones_exploitation}
          {...props}/>
      }}
    </FiltersTracker>
  )
};

export {SyntheticProgressPlanView};
export default SyntheticProgressPlanView;
