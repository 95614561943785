export const START_PENDING = "START_PENDING";
export const STOP_PENDING = "STOP_PENDING";
export const ADD_ALERT = "ADD_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const HAS_SIDEBAR = 'HAS_SIDEBAR';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const TOGGLE_SIDEMENU= 'TOGGLE_SIDEMENU';
export const CLEAR_UI = 'CLEAR_UI';

export const startPending = () => {
  return {
    type: START_PENDING
  }
};

export const stopPending = () => {
  return {
    type: STOP_PENDING
  }
};

export const addAlert = ({text, class : classNames, icon, title, autoRemove}) => {
  return {
    type: ADD_ALERT,
    payload: {
      text,
      class : classNames,
      icon,
      title,
      autoRemove
    }
  }
};

export const setSideMenu = (state) => {
  return {
    type: TOGGLE_SIDEMENU,
    payload: state
  }
};
