import React from "react";
import Select2 from "react-select2-wrapper";

class Select2Wrapper extends React.PureComponent {
  onChange = (e) => {
    const {multiple} = this.props;

    if (!!this.props.onChange)
      this.props.onChange({
        target: {
          name: e.target.name,
          value: !!multiple ? $(e.target).val().map(i => parseInt(i)) : parseInt($(e.target).val())
        }
      });
  };

  /*
  * Sort data passed to the Select2
  * Default sort on the label A->Z (asc)
  * Can be disable with a prop on the Select2Wrapper (sort={disabled:true})
  */
  compare(key = 'text', order = 'asc'){
    return function innerSort(element, nextElement) {
      // The sort is cancel if the object doesn't have the property
      if (!element.hasOwnProperty(key) || !nextElement.hasOwnProperty(key)) {
        return 0;
      }
  
      const strElement= (typeof element[key] === 'string')
        ? element[key].toUpperCase() : element[key];
      const strNextElement = (typeof nextElement[key] === 'string')
        ? nextElement[key].toUpperCase() : nextElement[key];
  
      let comparison = 0;
      if (strElement > strNextElement) {
        comparison = 1;
      } else if (strElement < strNextElement) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }

  render(){
    const {sort,onChange, ...other} = this.props;
    var key, order, disabled, dataField;
    if(sort){
      dataField = sort.key;
      order = sort.order;
      // we authorize to disable sort
      disabled = sort.disabled;
    }
    const data = (disabled ? this.props.data: this.props.data.sort(this.compare(key,order)));

    return <Select2 onChange={this.onChange} data={data} {...other}/>
  }
}

export {Select2Wrapper};
export default Select2Wrapper;