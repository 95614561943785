import {api} from "../../tools/api";
import {_Firm, _User} from "../../constants/orm";
import {deleteEntity, updateEntities, hydrateEntities} from "../orm";
import {actionCallsTracker} from "../actionsTracker";

export const fetchAllFirms = () => {
  return async dispatch => {
    if (actionCallsTracker.isSaved('firms', 'client', 'all'))
      return;

    const firms = await api.firms.getAll();

    dispatch(hydrateEntities({
      payload: [
        {type: _Firm, entities: firms}
      ]
    }));

    actionCallsTracker.save('firms', 'client', 'all');
    return firms;
  }
};


export const fetchFirmUsers = ({firm_id}) => {
  return async dispatch => {
    if (actionCallsTracker.isSaved('users', 'firm', firm_id))
      return;
    const users = await api.firms.getUsers({firm_id});

    dispatch(hydrateEntities({
      payload: [
        {type: _User, entities: users}
      ]
    }));

    actionCallsTracker.save('users', 'firm', firm_id);
    return users;
  }
};

export const fetchFirmUsersResponsables = ({firm_id}) => {
  return async dispatch => {
    if (actionCallsTracker.isSaved('users', 'firm', firm_id))
      return;
    const users = await api.firms.getUsersResponsables({firm_id});

    dispatch(hydrateEntities({
      payload: [
        {type: _User, entities: users}
      ]
    }));

    actionCallsTracker.save('users', 'firm', firm_id);

    return users;
  }
};

export const addFirm = ({label, path}) => {
  return async dispatch => {
    const firm = await api.firms.add({
      label,
      path
    });

    dispatch(hydrateEntities({
      payload: [
        {type: _Firm, entities: [firm]}
      ]
    }));

    return firm;
  }
};

export const updateFirm = ({id, label, path, clients, prestataires}) => {
  return async dispatch => {
    const firm = await api.firms.update({
      id,
      label,
      path,
      clients,
      prestataires
    });

    dispatch(updateEntities({
      payload: [
        {type: _Firm, entities: [firm]}
      ]
    }));

    return firm;
  }
};

export const removeFirm = ({id}) => {
  return async dispatch => {
    await api.firms.remove({id});

    dispatch(deleteEntity({
      type: _Firm,
      id
    }))
  }
};