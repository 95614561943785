import React from "react";
import {SingleValueI2CDoughnut} from "../SingleValueI2CDoughnut";
import {EvolText} from "../TextRenderers";

class ProgressPlanAdvancement extends React.PureComponent {
  render(){
    const {client, vague, passageLow, passageHigh} = this.props;

    return (
      <div className="d-flex flex-grow-1 w-100">
        <div className="flex-grow-1 d-flex align-items-center justify-content-center">
          <div className="flex-grow-1 d-flex align-items-center justify-content-center">
            <SingleValueI2CDoughnut
              passageLow={passageLow}
              passageHigh={passageHigh}
              value={vague.progression}
              size={120}/>
          </div>
            {!!client.i2cE_enable &&
          <div className="text-center d-flex flex-column align-items-center">
            <span className="text-muted">{t('common.i2c_estimated', {i2c: Labels.i2c})}</span>
            <SingleValueI2CDoughnut
              passageLow={passageLow}
              passageHigh={passageHigh}
              value={vague.valueEstimated}
              fontSize={15}
              size={60}/>
          </div>}
          {!!client.i2cE_enable &&
            <div className="flex-grow-1 d-flex align-items-center justify-content-center">
              <h2 className="m-0"><EvolText value={vague.evolEstimated}/></h2>
            </div>}
        </div>
      </div>
    )
  }
}

export default ProgressPlanAdvancement;
export {ProgressPlanAdvancement};
