import {fk, attr, Model} from 'redux-orm';
import {_Audit} from "../constants/orm";

export class QuestionnaireSection extends Model {
  static parse(data){
    return data;
  }

  static get fields(){
    return {
      id: attr(),
      ranking: attr(),
      label: attr(),
      audit_id: fk(_Audit, 'sections')
    }
  }
}

QuestionnaireSection.modelName = 'QuestionnaireSection';