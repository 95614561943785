import {fk, attr, Model} from 'redux-orm';
import {_ProgressPlan, _ProgressZoneExploitation, _ZoneExploitation, _User, _Responsable} from "../constants/orm";

export class ProgressZoneExploitation extends Model{
    static parse(data){
        return data;
    }

    static get fields(){
        return {
            id: attr(),
            progre_id: fk(_ProgressPlan, 'progressZoneExploitation'),
            zone_exploitation_id: fk(_ZoneExploitation),
            progression: attr(),
            created: attr(),
            modified: attr(),
            responsable: fk(_Responsable),
            user_id: fk(_User)
        }
    }
}

ProgressZoneExploitation.modelName = _ProgressZoneExploitation;
