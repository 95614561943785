import React from "react";
import {
  Form,
  FormGroup,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
} from "reactstrap";
import {connect} from "react-redux";
import {setChangeUsersProfile} from "../../actions/modals";
import {updateUser} from "../../actions/orm/User";
import LoadingButton from "../common/LoadingButton";
import {createAvailableProfilesByOrganisationSelector} from "../selectors/profile";
import {Select2Wrapper} from "../common/Select2Wrapper";

const profilesSelector = createAvailableProfilesByOrganisationSelector();

@connect(
  ({modals: {changeUsersProfile: {userIds, targetOrganisationType, targetOrganisationId}}}) => ({
    userIds,
    targetOrganisationId,
    targetOrganisationType
  })
)
class ChangeUsersProfile extends React.PureComponent {
  constructor(props) {
    super(props);

    const {targetOrganisationId, targetOrganisationType} = this.props;

    this.state = {
      loading: false,
      closing: false,
      profiles: profilesSelector({
        type: targetOrganisationType,
        id: targetOrganisationId
      }),
      profile_id: ''
    }
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const {profile_id} = this.state;
    const {userIds, dispatch} = this.props;

    if (!profile_id)
      return;

    this.setState({loading: true});
    await Promise.all(userIds.map(id => dispatch(updateUser({
      id,
      profil_id: profile_id
    }))));
    this.setState({loading: false});
    this.closing();
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setChangeUsersProfile({
      open: false
    }));
  };

  render(){
    const {closing, loading, profile_id, profiles} = this.state;

    return (
      <Modal isOpen={!closing}
             onClosed={this.close}>
        <ModalHeader toggle={this.closing}>
          {t('modals.change_profile_users')}
        </ModalHeader>
        <Form onSubmit={this.handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label>
                {t('modals.choose_profile')}
              </Label>
              <Select2Wrapper
                options={{
                  placeholder: t('modals.choose_profile')
                }}
                name="profile_id"
                value={profile_id}
                data={profiles.map(p => ({id: p.id, text: p.label}))}
                onChange={this.handleChange}/>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              type='button'
              disabled={loading}
              onClick={this.closing}>
              {t('common.close')}
            </Button>
            <LoadingButton
              loading={loading}
              disabled={loading || !profile_id}
              color="success">
              {t('common.valid')}
            </LoadingButton>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export {ChangeUsersProfile};
export default ChangeUsersProfile;
