import createSelector from "../../tools/createSelector";
import store from "../../store";

export const createAllPrestatairesSelector = () => createSelector(
  (orm) => {
    return orm.Prestataire.all().toRefArray();
  }
);

export const getPrestataireById = createSelector(
  (orm, {id}) => {
    const prestataire = orm.Prestataire.withId(id);

    return !!prestataire ? prestataire.ref : null;
  }
);

export const createRootPrestatairesSelector = () => createSelector(
  (orm) => {
    return orm.Prestataire.all().toRefArray().filter(i => i.parent_id === null);
  }
);

export const createPrestataireSelectorById = () => {
  return createSelector(
    (orm, {id}) => {
      const prestataire = orm.Prestataire.withId(id);

      return !!prestataire ? prestataire.ref : null;
    }
  )
};
/*
* Get all prestataires for all clients passed in parameters
*/
export const allClientsPrestataires = () => createSelector(
  (orm, {clientIds}) => {
    
    const {userId} = store.getState().session;
    const user = orm.User.withId(userId).ref;
    var prestataires;
    Array.from(clientIds).forEach(clientId => {
      var client =  orm.Client.withId(clientId);
      if(prestataires)
        prestataires = prestataires.concat(client.prestataires.toRefArray());
      else
        prestataires  = client.prestataires.toRefArray();    
    });

    if (!!user.prestataire_id)
      return prestataires.filter(u => u.id === user.prestataire_id);

    return prestataires;
  });

export const allClientPrestataires = createSelector(
  (orm, {clientId}) => {
    const client = orm.Client.withId(clientId);
    return client.prestataires.toRefArray();
  }
);

export const createPrestataireTreeSelector = () => {
  return createSelector(
    (orm, {pId}) => {
      const presta = orm.Prestataire.withId(pId);
      function setupTree(items){
        return items.map(item => {
          return {
            ...item.ref,
            toggled: true,
            type: 'prestataire',
            children: setupTree(item.ChildPrestataires.toModelArray())
          };
        });
      }
      return setupTree([presta]);
    }
  )
};
