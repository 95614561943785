import React from 'react';
import {StateBtnGroup} from "../../tools/stateBtnGroup";
import Flatpickr from 'react-flatpickr';
import {Select} from "../../tools/select";
import {connect} from "react-redux";
import {setChooseOrganisationsItems, setConstatSet} from "../../../actions/modals";
import {French} from "flatpickr/dist/l10n/fr";
import {cDate} from "../../../tools/date";
import {SummaryPerimetre} from "./SummaryPerimetre";
import {SummaryInCharges} from "./SummaryInCharges";
import {zoneExploitationById} from "../../../ormSelectors/zoneExploitation";
import {clientById} from "../../../ormSelectors/client";

const _FormValidations = Object.freeze({
  label: 0,
  constat: 1,
  type_progre_id: 2,
  level: 3,
  start_date: 4,
  end_date: 5,
  action: 6,
  preconisation: 7
});

@connect(
  ({session: {currentClientId, userId}}, ownProps) => ({
    userId,
    zes: !!ownProps.zeIds ? ownProps.zeIds.map(id => zoneExploitationById(id).ref) : [],
    currentClient: clientById(currentClientId)
  }),
  dispatch => ({
    openConstat: (data) => {
      dispatch(setConstatSet(data))
    },
    dispatch
  })
)
export class Parameters extends React.Component{
  constructor(props){
    super(props);

    const {model} = this.props;
    let formValidation = {};
    const initFormValidation = {value: '', class: '', validationOnChange: false};

    for (let key in _FormValidations) {
      let overWriteAttributes = {};

      if (this.props[key] !== undefined && key !== 'type_progre_id')
        overWriteAttributes = {value: this.props[key], class: 'is-valid'};
      else if (key === 'type_progre_id')
        overWriteAttributes = {value: model !== null ? this.props['model'].id : -1, class: 'is-valid'};
      else if (key === 'level')
        overWriteAttributes = {value: model !== null ? model.level.toString() : 1, class: 'is-valid'};
      if (key === 'label' || key === 'constat' || key === 'preconisation')
        overWriteAttributes = {...overWriteAttributes, validationOnChange: true};
      if (key === 'preconisation' && !!model && !!model.ref)
        overWriteAttributes = {
          value: model !== null && !!model.actions ? model.actions.toRefArray().reduce((acc, curr) =>
            `${acc}${acc !== '' ? '\n' : ''}${curr.label}`, '') : '',
          class: 'is-valid',
          validationOnChange: true
        };
      else if (key === 'preconisation' && !!model)
        overWriteAttributes = {
          value: model !== null && !!model.actions ? model.actions.reduce((acc, curr) =>
            `${acc}${acc !== '' ? '\n' : ''}${curr.label}`, '') : '',
          class: 'is-valid',
          validationOnChange: true
        };

      formValidation[key] = Object.assign({}, {...initFormValidation, ...overWriteAttributes});
    }
    formValidation['action'] = Object.assign({}, initFormValidation);

    this.state = {
      actionModes: [{id: 1, text: t('common.list')}, {id: 2, text: t('common.tab')}],
      actionModeSelected: 1,
      zonesSelected: this.props.zes.map(z => z.id).slice(0, 4),
      navSelected: 0,
      isInit: false,
      formValidation: formValidation,
      actions: this.props.actions !== undefined ? this.props.actions.slice() : [],
    };
  }

  componentDidMount(){
    this.componentDidUpdate();
  }

  componentDidUpdate(){
    if(!this.state.isInit) this.setState({isInit: true});
  }

  handleFormChange = (element, event) => {
    let state = Object.assign({}, this.state);
    if(element === 'start_date' || element === 'end_date') state.formValidation[element].value = event.length > 0 ? event[0] : '';
    else state.formValidation[element].value = event.target.value;

    if(state.formValidation[element].validationOnChange && state.formValidation[element].value !== ''){
      state.formValidation[element].class = 'is-valid';
    }
    else state.formValidation[element].class = '';

    this.setState(state);
  };

  handleLabel = (e, {id}) => {
    const actions = this.state.actions.slice();

    actions.find(a => id === a.id).label = e.target.value;
    this.setState({actions});
  };

  handleSubmit = () => {
    let problem = false;

    if (this.state.formValidation['label'].value === '') {
      problem = true;
      let state = Object.assign({}, this.state);
      state.formValidation['label'].class = 'is-invalid';
      this.setState(state);
    }
    if (this.state.formValidation['constat'].value === '') {
      problem = true;
      let state = Object.assign({}, this.state);
      state.formValidation['constat'].class = 'is-invalid';
      this.setState(state);
    }
    if(!problem){
      let datas = {};

      for(let key in this.state.formValidation){
        if(this.state.formValidation[key].value !== '' && key !== 'action' && key !== 'equals'){
          if(key === 'type_progre_id' && this.state.formValidation[key].value === -10){
            datas.newProgress = this.props.models[getIndexById(-10, this.props.models)];
          } else if (key === 'type_progre_id' && this.state.formValidation[key].value !== -1 || key !== 'type_progre_id')
            datas[key] = this.state.formValidation[key].value;
        }
        else if (key === 'preconisation' && this.state.formValidation[key].value === '')
          datas[key] = this.state.formValidation[key].value;
      }

      datas.actions = [];
      this.state.actions.map(a => {
        const propsAction = this.props.actions.find(d => a.id === d.id);
        datas.actions.push({
          id: !!a.progres_zone_exploitation_id ? a.id : null,
          label: a.label,
          date: !!a.id ? a.modified : Number((a.modified / 1000).toFixed(0)),
        })
      });

      if(datas.start_date !== undefined && datas.start_date instanceof Date)
        datas.start_date = cDate.datePickerToSubmit(datas.start_date, 'd-m-Y');
      if(datas.end_date !== undefined && datas.end_date instanceof Date)
        datas.end_date = cDate.datePickerToSubmit(datas.end_date, 'd-m-Y');

      this.props.onSubmit(datas);
    }
  };

  chooseOrganisationsItems = async () => {
    const {dispatch, currentClient, zes} = this.props;
    const {zonesSelected} = this.state;

    const defaultValues = {
      sites: [],
      clients: [],
      zonesShown: zes.map(z => z.id),
      zone_exploitations: zonesSelected
    };

    try {
      const result = await dispatch(setChooseOrganisationsItems({
        open: true,
        clientId: currentClient.id,
        defaultValues
      }));
      const zonesSelected = result.zone_exploitations.filter(zId => !!zes.find(zone => zone.id === zId));

      this.setState({zonesSelected});
    } catch (e) {}
  };

  handleConstatAdd = (constat) => {
    this.handleFormChange('constat', {
      target: {
        value: `${this.state.formValidation['constat'].value}${this.state.formValidation['constat'].value !== '' ? '\n' : ''}${constat}`
      }
    });
  };

  render(){
    let models = (this.props.models !== undefined ? this.props.models.map((model, index) =>
      ({value: model.id, label: model.label})
    ) : []);
    models.push({value: -1, label: '(...)'});

    const modelValue = this.state.formValidation['type_progre_id'].value.toString();

    const statusButton = this.props.handleStatus !== undefined ? (
      <div className='row justify-content-end'>
        <button onClick={this.props.handleStatus} className='btn btn-success'>
          {this.props.defaultState === 3 ? t('common.open') : t('pp.finished_pp')}
        </button>
      </div>
    ) : '';

    return(
      <div hidden={this.props.hidden}>
        <div className='row'>
          <div className='col-4'>
            <SummaryPerimetre
              vague={this.props.vague}
              zeIds={this.props.zeIds}
              themes={this.props.themes}
              reportId={this.props.reportId}
              analyseId={this.props.analyseId}
              editProgresZones={this.props.editProgresZones}
              progressPlanId={!!this.props.progressPlan ? this.props.progressPlan.id : null}/>
            <SummaryInCharges
              inCharges={this.props.inCharges}/>
          </div>
          <div className='col-8'>
            <section className='card card-fluid'>
              <div className='card-body'>
                <h4 className='card-title'>{t('common.parameters')}</h4>
                <div className='row'>
                  <div className='col-8'>
                    <div className='form-group'>
                      <label className='form-control-label'>{t('common.label')}</label>
                      <input className={'form-control ' + this.state.formValidation['label'].class}
                             value={this.state.formValidation['label'].value}
                             onChange={(e) => this.handleFormChange('label', e)}
                             placeholder={t('pp.pre_filled_perimeter')} type='text'/>
                      <div className='invalid-feedback'>
                        <i className='fa fa-exclamation-circle fa-fw'/>
                        {t('pp.write_text_to_add')}
                      </div>
                    </div>
                    <div className='form-group'>
                      <label className='form control-label'>{t('common.constat')}
                        <button
                          className='btn btn-secondary'
                          style={{marginLeft: '5px'}}
                          onClick={() => this.props.openConstat({
                            open: true,
                            themes: this.props.themes,
                            vagueId: this.props.vague,
                            onSelect: this.handleConstatAdd,
                            analyseId: this.props.analyseId,
                            zoneExploitationsId: this.props.zeIds
                          })}>
                        <span className='oi oi-zoom-in'/></button>
                      </label>
                      <textarea className={'form-control '  + this.state.formValidation['constat'].class}
                                value={this.state.formValidation['constat'].value}
                                onChange={(e) => this.handleFormChange('constat', e)}
                                placeholder={t('pp.click_zoom')} rows='5'/>
                    </div>
                    <div className='form-group'>
                      <label className='form control-label'>{t('common.actions_preconisations')}</label>
                      <textarea className={'form-control '  + this.state.formValidation['preconisation'].class}
                                value={this.state.formValidation['preconisation'].value}
                                onChange={(e) => this.handleFormChange('preconisation', e)}
                                rows='3'/>
                    </div>
                  </div>
                  <div className='col-4'>
                    <div className='form-group'>
                      <Select label={t('common.model')}
                              defaultValue={modelValue}
                              onOptionsChangeValue={modelValue}
                              onValue={modelValue}
                              externalInit={this.state.isInit}
                              handleChange={(e) => this.handleFormChange('type_progre_id', e)}
                              id='model_param'
                              options={models}/>
                    </div>
                    <div className='form-group'>
                      <Select label={t('common.priority')}
                              defaultValue={this.state.formValidation['level'].value}
                              externalInit={this.state.isInit}
                              handleChange={(e) => this.handleFormChange('level', e)}
                              id='priority_param'
                              onValue={this.state.formValidation['level'].value}
                              options={[{value: '1', label: '1'}, {value: '2', label: '2'}, {value: '3', label: '3'}]}/>
                    </div>
                    <div className='form-group'>
                      <label className='form-control-label'>{t('pp.starting_date')}</label>
                      <Flatpickr className='input-group input-group-alt flatpickr'
                                 value={this.state.formValidation['start_date'].value}
                                 onChange={(e) => this.handleFormChange('start_date', e)}
                                 options={{wrap: true,
                                   dateFormat:"d-m-Y",
                                   locale:French}}>
                        <input className='form-control' data-input type='text'/>
                        <div className='input-group-append'>
                          <button className='btn btn-secondary' type='button' data-toggle>
                            <i className='fa fa-calendar'/>
                          </button>
                          <button className='btn btn-secondary' type='button' data-clear>
                            <i className='fa fa-times'/>
                          </button>
                        </div>
                      </Flatpickr>
                    </div>
                    <div className='form-group'>
                      <label className='form-control-label'>{t('pp.ending_date')}</label>
                      <Flatpickr className='input-group input-group-alt flatpickr'
                                 value={this.state.formValidation['end_date'].value}
                                 onChange={(e) => this.handleFormChange('end_date', e)}
                                 options={{wrap: true,
                                   dateFormat:"d-m-Y",
                                   locale:French}}>
                        <input className='form-control' data-input type='text'/>
                        <div className='input-group-append'>
                          <button className='btn btn-secondary' type='button' data-toggle>
                            <i className='fa fa-calendar'/>
                          </button>
                          <button className='btn btn-secondary' type='button' data-clear>
                            <i className='fa fa-times'/>
                          </button>
                        </div>
                      </Flatpickr>
                    </div>
                  </div>
                </div>
                <div className='row justify-content-center'>
                  <StateBtnGroup
                    isState={true}
                    buttons={[{class: 'btn-secondary', label: t('pp.draft')}, {class: 'btn-secondary', label: t('common.in_progress')},
                      {class: 'btn-secondary', label: t('pp.late')}, {class: 'btn-secondary', label: t('common.archived')}]}
                    activeClass={'btn-primary'}
                    defaultActive={(this.props.defaultState !== undefined ? this.props.defaultState : 0)}/>
                </div>
                {statusButton}
              </div>
            </section>
            <div className='col text-right'>
              <button onClick={() => this.handleSubmit()} className={this.props.btnSubmitClass} type='button'>
                {this.props.btnSubmitText}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
