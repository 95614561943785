import React from "react";
import {ocmRed, ocmOrange, ocmGreen} from "../../constants/ui";

const ColoredProgress = ({value, max = 100, children, className, passageLow = 70, passageHigh = 100, style, colorLow = ocmRed, colorMiddle = ocmOrange, colorHigh = ocmGreen, striped, ...other}) => {
  const percent = Math.round((!!value ? value : 0) / max * 100);

  return (
    <div className={`progress ${!!className ? className : ''}`}
         style={style}
         {...other}>
      <div class="progress-bar"
           class={`progress-bar ${!!striped ? 'progress-bar-striped' : ''}`}
           style={{
             width: `${percent}%`,
             backgroundColor: percent < passageLow ? colorLow : percent < passageHigh ? colorMiddle : colorHigh
           }}>
        {children}
      </div>
    </div>
  )
};

export {ColoredProgress};
export default ColoredProgress;
