import api from "../../tools/api";
import {_Message, _ProgressPlan, _ProgressZoneExploitation} from "../../constants/orm";
import {hydrateEntities} from "../orm";

export const postNotificationMessage = ({notification_id, content}) =>{
  return dispatch => {
    return api.notifications.postMessage({
      notification_id,
      content
    }).then(message => {
      dispatch(hydrateEntities({
        payload: [{type: _Message, entities: [message]}]
      }));
      return message;
    })
  }
};

export const fetchNotificationMessages = ({notification_id}) => {
  return dispatch => {
    return api.notifications.getMessages({
      notification_id
    }).then(messages => {
      dispatch(hydrateEntities({
        payload: [{type: _Message, entities: messages}]
      }));
      return messages;
    });
  }
};

export const readMessage = ({message_id}) => {
  return dispatch => {
    return api.messages.read2({
      message_id
    }).then(message => {
      dispatch(hydrateEntities({
        payload: [{type: _Message, entities: [message]}]
      }))
    });
  }
};

export const fetchMessages = () => {
  return dispatch => {
    return api.messages.fetchMessages().then(messages => {
      const progressZonesExploitation = messages.map(m => m.progres_zone_exploitation);

      dispatch(hydrateEntities({
        payload: [
          {type: _ProgressZoneExploitation, entities: progressZonesExploitation},
          {type: _Message, entities: messages}
        ]
      }));

      return messages;
    });
  }
};

export const fetchMessagesOfProgressZone = ({progres_zone_exploitation_id}) => {
  return dispatch => {
    return api.messages.fetchOfProgressZone({
      progres_zone_exploitation_id
    }).then(messages => {
      dispatch(hydrateEntities({
        payload: [{type: _Message, entities: messages}]
      }));

      return messages;
    });
  }
};

export const postMessage = ({progres_zone_exploitation_id, content}) =>{
  return dispatch => {
    return api.messages.postMessage({
      progres_zone_exploitation_id,
      content
    }).then(message => {
      dispatch(hydrateEntities({
        payload: [{type: _Message, entities: [message]}]
      }));
      return message;
    })
  }
};
