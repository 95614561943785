import React from "react";
import Responsive from "react-responsive-decorator";

const HOC = Comp => {
  class HOC extends React.PureComponent {
    state = {
      isMobile : false,
      isMobileMini : false
    };

    componentDidMount() {
      this.props.media({maxWidth : 576}, () => {
        this.setState({isMobile : true})
      });
    }

    render() {
      return <Comp {...this.props} {...this.state}/>
    }
  }
  return Responsive(HOC)
};

export default HOC