import {fk, attr, many, Model} from 'redux-orm';
import {_Client, _Analyse, _User} from "../constants/orm";

export class Analyse extends Model{
  static parse(data){
    delete data._matchingData;
    delete data._joinData;
    return {
      ...data,
      passage_low: !!data.passage_low ? data.passage_low : 70,
      passage_high: !!data.passage_high ? data.passage_high : 90
    };
  }

  static selectorFunction(){

  }

  static get fields() {
    return {
      id: attr(),
      client_id: fk(_Client, 'analyses'),
      label: attr(),
      passage_low: attr(),
      passage_high: attr(),
      users: many(_User, 'analyses'),
      sources: attr(),
      vagues: attr()
    };
  }
}

Analyse.modelName = _Analyse;
