import React from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import {defaultPaginationFactory} from "../../../constants/ui";
import {DebounceSearchInput} from "../../common/DebounceSearchInput";
import {userById} from "../../../ormSelectors/user";
import {zoneExploitationById} from "../../../ormSelectors/zoneExploitation";
import {connect} from "react-redux";
import {clientById} from "../../../ormSelectors/client";

@connect((state) => ({
  client: clientById(state.session.currentClientId),
}))
export class SummaryInCharges extends React.PureComponent{
  constructor(props){
    super(props);

    this.columns = [
      {
        dataField: 'label',
        text: t('common.name'),
        sort: true,
      },
      {
        dataField: 'zesCount',
        headerAlign: 'center',
        align: 'center',
        text: t('common.nb') + props.client.label_zone_exploitation,
        sort: true,
      }
    ];
  }

  expandRow = {
    renderer: row => {
      const zeRows = row.zone_exploitations.map((ze, index) => (
        <React.Fragment key={index}>
          {ze.label}
          {index !== (row.zone_exploitations.length-1)  &&
          <span> | </span>
          }
        </React.Fragment>
      ));

      return(
        <span class="font-italic">
          {zeRows}
          </span>
      )
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      return isAnyExpands ? <i className="fa fa-caret-down cursor-pointer"/> : <i class="fa fa-caret-right cursor-pointer"/>;
    },
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
      if (!expandable)
        return null;
      return expanded ? <i className="fa fa-caret-down cursor-pointer"/> : <i class="fa fa-caret-right cursor-pointer"/>;
    }
  };

  formatDatas = () => {
    let datas = [];

    if(!!this.props.inCharges){
      this.props.inCharges.forEach(r => {
        if(!!r.user_id){
          const index = getIndexById(r.user_id, datas);
          if(index !== null){
            datas[index].zesCount ++;
            datas[index].zone_exploitations.push(zoneExploitationById(r.id))
          } else {
            datas.push({zesCount: 1, ...userById(r.user_id).ref});
            arr_end(datas).label = arr_end(datas).firstname + ' ' + arr_end(datas).lastname;
            arr_end(datas).zone_exploitations = [zoneExploitationById(r.id)];
          }
        }
      });
    }

    return datas;
  };

  render(){
    const datas = this.formatDatas();
    return(
      <section className='card card-fluid'>
        <div className='card-body'>
          <h4 className='card-title'>
            {t('pp.resume_responsables')}
          </h4>
          <ToolkitProvider
            search
            keyField={'id'}
            columns={this.columns}
            bootstrap4={true}
            data={datas}>
            {props => (
              <div>
                <DebounceSearchInput onChange={(e) => props.searchProps.onSearch(e.target.value)}/>
                <BootstrapTable
                  expandRow={this.expandRow}
                  bordered={false}
                  classes={'bulk-selection'}
                  noDataIndication={() => t('common.no_data')}
                  pagination={defaultPaginationFactory()}
                  {...props.baseProps}/>
              </div>
            )}
          </ToolkitProvider>
        </div>
      </section>
    );
  }
}
