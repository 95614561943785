import React from "react";
import { Doughnut } from 'react-chartjs-2';
import {Red, Green, Orange, Black} from "./utils";

class SingleValueI2CDoughnut extends React.PureComponent {
  render(){
    const {value, size, fontSize, passageLow, passageHigh} = this.props;
    const low = passageLow !== undefined ? passageLow : 80;
    const high = passageHigh !== undefined ? passageHigh : 100;
    const color = value === null ? Black : value < low ? Red : value < high ? Orange : Green;
    const middleText = value !== null ? `${value.toFixed(0)}%` : 'N/C';
    const trueValue = !!value ? value : 0;

    const data = {
      labels: ['', ''],
      datasets: [
        {
          data: [trueValue, 100 - trueValue],
          backgroundColor: [
            color,
            "#AAAAAA"
          ],
          hoverBackgroundColor: [
            color,
            "#AAAAAA"
          ],
          hoverBorderColor: [
            "#ffffff",
            "#ffffff"
          ]
        }
      ]
    };
    const options = {
      responsive: true,
      legend: {
        display: false
      },
      cutoutPercentage: 60,
      tooltips: {
        filter: function (item, data) {
          let label = data.labels[item.index];
          if (label) return item;
        }
      }
    };

    return (
      <div class="position-relative" style={{width: size, height: size}}>
        <Doughnut data={data} height={size} width={size} options={options}/>
        <h2 style={{
          position: 'absolute',
          left: '50%',
          color: color,
          fontSize: fontSize,
          top: '50%',
          margin: 0,
          transform: 'translate3d(-50%, -50%, 0)'
        }}>
          {middleText}
        </h2>
      </div>
    )
  }
}

export {SingleValueI2CDoughnut};
export default SingleValueI2CDoughnut;
