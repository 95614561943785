import React from "react";

export const handleSortBySelected = (options, selected, tableRef) => {
  tableRef.sortContext.state.sortOrder = undefined;
  tableRef.sortContext.state.sortColumn = undefined;
  options.sort((a,b) => !!(selected.includes(b.id)) - !!(selected.includes(a.id)));
};

export const handleSortByLabel = (options, tableRef) => {
  tableRef.sortContext.state.sortOrder = undefined;
  tableRef.sortContext.state.sortColumn = undefined;
  options.sort((a,b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
};

export const handleSelectAllData = (setSelected, tableRef) => {
  const keyField = tableRef.selectionContext.props.keyField;
  const selected = [...tableRef.selectionContext.state.selected];
  const displayedItems = tableRef.paginationContext.props.data.map(i => i[keyField]);

  displayedItems.forEach(id => {
    if (selected.indexOf(id) === -1)
      selected.push(id);
  });

  setSelected(selected);
};

export const handleUnselectAllData = (setSelected, tableRef) => {
  const keyField = tableRef.selectionContext.props.keyField;
  const selected = [...tableRef.selectionContext.state.selected];
  const displayedItems = tableRef.paginationContext.props.data.map(i => i[keyField]);

  displayedItems.forEach(id => {
    const index = selected.indexOf(id);

    if (index !== -1)
      selected.splice(index, 1);
  });

  setSelected(selected);
};

export const handleSelect = (setSelected, tableRef) => {
  setTimeout(() => {
    const selected = [...tableRef.selectionContext.state.selected];

    setSelected(selected);
  }, 1);
};

export const expandRendererProps = {
  expandHeaderColumnRenderer: ({ isAnyExpands }) => {
    return isAnyExpands ? <i className="fa fa-caret-down cursor-pointer"/> : <i class="fa fa-caret-right cursor-pointer"/>;
  },
  expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
    if (!expandable)
      return null;
    return expanded ? <i className="fa fa-caret-down cursor-pointer"/> : <i class="fa fa-caret-right cursor-pointer"/>;
  }
};
