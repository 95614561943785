import createSelector from "../tools/createSelector";
import {getOrm} from "../redux";
import {questionnairesByAudit} from "./questionnaire";

const inQuestionnaires = (themeQuestion, questionnaires) => getIndexBy('themes_question_id', themeQuestion.id, questionnaires) !== null;

export const themeQuestionsByAudit = createSelector(
  (orm, audit) => {

    if(audit !== null){
      const questionnaires = questionnairesByAudit(audit).toRefArray();
      return orm.ThemeQuestion.all().filter(themeQuestion => inQuestionnaires(themeQuestion, questionnaires));
    }

    return null;
  }
);

export const themeQuestionsByIds = createSelector(
  (orm, themeQuestionIds) => {
    if(themeQuestionIds !== null)
      return themeQuestionIds.map(id => themeQuestionById(id));
    return null;
  }
);

export const themeQuestionById = (themeQuestionById) => {
  if(themeQuestionById !== null)
    if(getOrm().ThemeQuestion.idExists(themeQuestionById))
      return getOrm().ThemeQuestion.withId(themeQuestionById);
  return null;
};

export const themeQuestionByQuestionnaireId = createSelector(
  (orm, questionnaire_id) => {
    if (!!questionnaire_id) {
      return orm.ThemeQuestion.withId(orm.Questionnaire.withId(questionnaire_id).ref.themes_question_id).ref;
    }
  }
);
