import update from "immutability-helper";

export const setDefaultUserClient = (userId, clientId) => {
  try {
    let usersClient = JSON.parse(localStorage.getItem('userDefaultClients')) || {};

    usersClient = update(usersClient, {
      [userId]: {$set: clientId}
    });

    localStorage.setItem('userDefaultClients', JSON.stringify(usersClient));
  } catch (e) {}
};

export const getDefaultUserClient = (userId) => {
  try {
    const usersClient = JSON.parse(localStorage.getItem('userDefaultClients')) || {};

    return usersClient[userId];
  } catch (e) {
    return null;
  }
};
