import createSelector from "../tools/createSelector";
import {getOrm} from "../redux";
import store from "../store";

export const userSelectorById = (userId) =>
  getOrm().User.idExists(userId) ? getOrm().User.withId(userId).ref : null;

export const sessionUserSelector = createSelector((orm) => {
    const {userId} = store.getState().session;
    return orm.User.idExists(userId) ? orm.User.withId(userId).ref : null;
});

export const userById = (userId) => {
  if(getOrm().User.idExists(userId))
    return getOrm().User.withId(userId);
  return null;
};

export const usersByAudits = createSelector(
  (orm, audits) => {
    let users = [];
    if(!!audits){
      audits.forEach(a => {
        a.users.toModelArray().forEach(u => {
          if(getIndexById(u.id, users) === null){
            users.push(u); 
          }
        });
      });
      return users;
    } 
    return null;
  }
);
