import createSelector from "../tools/createSelector";
import {themeQuestionsByAudit} from "./themeQuestion";
import {themesByParent} from "./theme";
import {getOrm} from "../redux";

const inThemesQuestions = (questionnaire, themesQuestions) => getIndexById(questionnaire.themes_question_id, themesQuestions) !== null;
const hasAudit = (questionnaire, audit) => questionnaire.audit_id === audit.id;

export const questionnairesByThemesandAudit = createSelector(
  (orm, {themes, audit}) => {
    if(themes.length > 0 && audit !== null){
      let themeQuestions =  themeQuestionsByAudit(audit).toRefArray();

      themeQuestions = sortThemeQuestions(themes, themeQuestions);

      return orm.Questionnaire.all().filter(questionnaire => inThemesQuestions(questionnaire, themeQuestions)).toModelArray();
    }

    return [];

    function sortThemeQuestions(themes, themeQuestions){
      let sorted = [];

      themes.forEach(theme => {
        const length = sorted.length;

        themeQuestions.forEach((themeQuestion, index) => {
          if(themeQuestion.theme_id === theme.id)
            sorted.push(themeQuestions[index]);
        });

        if(sorted.length === length)
          sorted = sorted.concat(sortThemeQuestions(themesByParent(theme).toRefArray(), themeQuestions));
      });

      return sorted;
    }
  }
);

export const questionnairesByThemeQuestionsandAudit = createSelector(
  (orm, {themeQuestions, audit}) => {
    if(themeQuestions !== null && audit !== null)
      return orm.Questionnaire.all().filter(questionnaire => inThemesQuestions(questionnaire, themeQuestions) && hasAudit(questionnaire, audit)).toModelArray();
    return [];
  }
);

export const questionnairesByAudit = createSelector(
    (orm, audit) => {
        if(audit !== null) return orm.Questionnaire.all().filter(questionnaire => hasAudit(questionnaire, audit));
        return null;
    }
);

export const questionnairesByAuditId = (auditId) => {
  return getOrm().Questionnaire.all().filter(q => q.audit_id === auditId).toModelArray();
};

export const questionnaireById = createSelector(
  (orm, questionnaire_id) => {
    return orm.Questionnaire.withId(questionnaire_id).ref;
  });