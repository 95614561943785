import React from "react";
import {connect} from "react-redux";
import {PhotoPlaceholder} from "../common/PhotoPlaceholder";
import {setModal, setUploadFile} from "../../actions/modals";
import {Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader, Label, Input} from "reactstrap";

@connect(({ocmModals: {modifyProgressPlanAction: {action, resolve, reject}}}) => ({
  action: action,
  resolve,
  reject
}))
class ModifyProgressPlanAction extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      closing: false,
      warning: false,
      allSelected: false,
      action: this.props.action
    }
  }

  handleLabel = (e) => {
    // const action = this.state.action.slice();

    this.state.action.label = e.target.value;
    // this.setState({action});
  };

  addFile = async ({type}) => {
    const action = {...this.state.action};

    try {
      const file = await this.props.dispatch(setUploadFile({
        open: true,
        defaultType: type
      }));

      if (file.type === 1)
        action.files = [...action.files, {...file, type: 'files'}];
      else
        action.photos = [...action.photos, {...file, type: 'photos'}];
      this.setState({action});
    } catch (e) {}
  };

  removeFile = (type, index) => {
    const action = {...this.state.action};

    if (type === 1)
      action.files = action.files.filter((item, idx) => idx !== index);
    else
      action.photos = action.photos.filter((item, idx) => idx !== index);
    this.setState({action});
  };

  changeFile = async (type, index) => {
    const action = {...this.state.action};
    const file = type === 1 ? action.files[index] : action.photos[index];

    const newFile = await this.props.dispatch(setUploadFile({
      open: true,
      defaultPath: file.path,
      defaultName: file.filename,
      defaultType: type
    }));

    if (type === 1)
      action.files = action.files.filter((f, idx) => newFile.type === type || idx !== index).map((file,idx) => {
        if (idx === index && newFile.type === type)
          return newFile;
        return file;
      });
    else
      action.photos = action.photos.filter((f, idx) => newFile.type === type || idx !== index).map((file,idx) => {
        if (idx === index && newFile.type === type)
          return newFile;
        return file;
      });

    if (newFile.type !== type) {
      if (newFile.type === 1)
        action.files = [...action.files, {...newFile, type: 'files'}];
      else
        action.photos = [...action.photos, {...newFile, type: 'photos'}];
    }

    this.setState({action});
  };

  resolve = () => {
    const {action} = this.state;

    if (action.label === '')
      this.setState({warning: true});
    else {
      this.props.resolve({action});
      this.closing();
    }
  };

  reject = () => {
    this.props.reject();
    this.closing();
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setModal('modifyProgressPlanAction', false));
  };

  render(){
    const {closing, action} = this.state;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.reject}>
          {t('modals.modify_action')}
        </ModalHeader>
        <ModalBody>
          <div>
              <Card key={action.id} class={`response-card`}>
                <CardBody>
                  <div>
                    <Label>{t('common.label')} : </Label>
                    <Input
                      defaultValue={action.label}
                      placeholder={t('common.label')}
                      onChange={e => this.handleLabel(e)}
                      invalid={this.state.warning && action.label === ''}/>
                  </div>
                  <br/>
                  <div className="mb-1">
                    <Button size="sm" onClick={() => this.addFile({type: 1})}>
                      {t('modals.add_document')}
                    </Button>
                    &nbsp;
                    <Button size="sm" onClick={() => this.addFile({type: 2})}>
                      {t('modals.add_photo')}
                    </Button>
                  </div>
                  {!!action.files.length &&
                  <div>
                    <div>
                      {t('common.files')} :
                    </div>
                    <div className="d-flex flex-column">
                      {action.files.map(({filename, path}, index) => (
                        <div key={index}>
                          <a href={`${path}`} target="_blank">
                            {filename}
                          </a>
                          &nbsp;
                          <Button size="sm" color="link" title={t('common.modify')} onClick={() => this.changeFile(1, index)}>
                            <i className="fa fa-edit"/>
                          </Button>
                          &nbsp;
                          <Button size="sm" color="link" title={t('common.remove')} onClick={() => this.removeFile(1, index)}>
                            <i className="fa fa-times"/>
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>}
                  {!!action.photos.length &&
                  <div className="mb-1">
                    <div>
                      {t('common.photos')} :
                    </div>
                    <div className="d-flex">
                      {action.photos.map((p, index) =>
                        <PhotoPlaceholder key={index} src={p.path} onRemove={() => this.removeFile(2, index)}/>
                      )}
                    </div>
                  </div>}
                </CardBody>
              </Card>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={this.reject}>
            {t('common.close')}
          </Button>
          <Button
            onClick={this.resolve}
            color="success">
            {t('common.apply')}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export {ModifyProgressPlanAction};
export default ModifyProgressPlanAction;
