import React from 'react';
import {connect} from 'react-redux';
import * as spinLoader from '../../../css/spinLoader.css';

const mapStateToProps = state => {
  return {isPending: state.ui.isPending};
};

export class ConnectedSpinLoader extends React.Component{
  render(){
    const isRender = (this.props.isPending ? {display: 'block'} : {display: 'none'});

    return (
      <div className={'lds-overlay'} style={isRender}>
        <div className="lds-text lds-css ng-scope">
          <div style={{width:"100%", height:"100%"}} className="lds-eclipse">
            <div></div>
          </div>
        </div>
      </div>
    );
  }
}

const SpinLoader = connect(mapStateToProps)(ConnectedSpinLoader);

export default SpinLoader;
