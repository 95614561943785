import createSelector from "../../tools/createSelector";
import {fetchUser} from "../../actions/orm/User";

export const createResponsablesSelector = () => {
  return createSelector(
    (orm) => {
      const responsables = orm.Responsable.all().toRefArray();

      return !!responsables ? responsables : null;
    }
  )
};

export const createResponsablesByPrestataireIdSelector = () => {
  return createSelector(
    (orm, {prestataire_id}) => {
      const responsables = orm.Responsable.all().toRefArray().filter(r => r.prestataire_id === prestataire_id);

      return !!responsables ? responsables : null;
    }
  )
};

export const createProspectiveResponsablesByZoneIdSelector = () => {
  return createSelector(
    (orm, {zoneId, dispatch}) => {
      const zone = orm.ZoneExploitation.withId(zoneId);
      const site = orm.Site.withId(zone.ref.site_id);
      const clients = [orm.Client.withId(site.ref.client_id)];

      function getAllClientsParent(client) {
        if (client.parent_id) {
          const parent = orm.Client.withId(client.parent_id);
          clients.push(parent);
          getAllClientsParent(parent.ref);
        }
        return true;
      }

      getAllClientsParent(clients[0].ref);

      const responsables = [];
      const zoneResponsable = zone.responsable.toRefArray().find(r => !!r.main);
      const zoneResponsableUserId = !!zoneResponsable ? zoneResponsable.user_id : null;
      const siteResponsable = site.responsable.toRefArray().find(r =>
        r.prestataire_id === zone.prestataires.toRefArray()[0].id && !!r.main);

      if (!!zoneResponsableUserId)
        responsables.push(orm.User.withId(zoneResponsableUserId).ref);
      if (!!siteResponsable && !responsables.find(r => r.id === siteResponsable.user_id))
        responsables.push(orm.User.withId(siteResponsable.user_id).ref);
      clients.map(async client => {
        const clientResponsable = client.responsable.toRefArray().find(r =>
          r.prestataire_id === zone.prestataires.toRefArray()[0].id && !!r.main);

        if (!!clientResponsable && !responsables.find(r => r.id === clientResponsable.user_id))
          responsables.push(orm.User.withId(clientResponsable.user_id).ref);
      });

      return responsables;
    }
  )
};