import React from "react";
import {connect} from "react-redux";
import {setWriteEditorText} from "../../actions/modals";
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Editor} from 'react-draft-wysiwyg';
import {EditorState} from 'draft-js';
import {convertToHTML, convertFromHTML} from "draft-convert";

@connect(({modals: {writeEditorText: {defaultValue, labelText, headerText, resolve, reject}}}) => ({
  defaultValue,
  labelText,
  headerText,
  resolve,
  reject
}))
class WriteEditorText extends React.PureComponent {
  constructor(props) {
    super(props);
    const {defaultValue} = this.props;

    this.state = {
      closing: false,
      textEditorState: EditorState.createEmpty()
    }

    if (!!defaultValue)
      this.state.textEditorState = EditorState.createWithContent(convertFromHTML(defaultValue));
  }

  inputText = React.createRef();

  componentDidMount() {
    this.inputText.current.focusEditor();
  }

  handleLabelEditorChange = (editorState) => {
    this.setState({
      textEditorState: editorState,
    })
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.resolve(this.state.textEditorState);
    this.closing();
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setWriteEditorText({
      open: false
    }));
  };

  reject = () => {
    this.props.reject();
    this.closing();
  };

  render(){
    const {closing, textEditorState} = this.state;
    const {headerText, labelText} = this.props;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.reject}>
          {headerText}
        </ModalHeader>
        <Form onSubmit={this.handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label>
                {labelText} :
              </Label>
              <Editor 
                    placeholder={labelText}
                    editorState={textEditorState}
                    onEditorStateChange={this.handleLabelEditorChange}
                    ref={this.inputText}
                    toolbar={{
                      options: ['inline', 'list', 'blockType'],
                      inline: {
                        inDropdown: false,
                        options: ['bold', 'italic', 'underline', 'strikethrough']
                      },
                      blockType: {
                        inDropdown: true,
                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6']
                      },
                      list: {
                        inDropdown: false,
                        options: ['unordered', 'ordered']
                      }
                    }}/>

            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              type='button'
              onClick={this.reject}>
              {t('common.close')}
            </Button>
            <Button
              color="success">
              {t('common.valid')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export {WriteEditorText};
export default WriteEditorText;
