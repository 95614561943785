class WebsocketClient {
  constructor(serverUrl) {
    this.serverUrl = serverUrl;
    this.socket = null;
    this.lastListenerId = 0;
    this.listeners = [];
    this.keepAliveInterval = null;
  }

  keepAlive = () => {
    if (this.socket.readyState === undefined || this.socket.readyState > 1) {
      try {
        this.socket = new WebSocket(this.connectionUrl);
        this.socket.onmessage = this.onMessage;
      } catch (e) {}
    }
  };

  init = (token) => {
    try {
      this.connectionUrl = `${this.serverUrl}/ws?token=${token}`;

      this.socket = new WebSocket(this.connectionUrl);
      this.socket.onmessage = this.onMessage;
      this.keepAliveInterval = setInterval(this.keepAlive, 5000);
    } catch (e) {}
  };

  close = () => {
    clearInterval(this.keepAliveInterval);
    this.socket.close();
    this.socket = null;
  };

  on = (type, callback) => {
    this.listeners.push({
      id: ++this.lastListenerId,
      type,
      callback
    });

    return this.lastListenerId;
  };

  removeListener = (id) => {
    this.listeners = this.listeners.filter(l => l.id !== id);
  };

  onMessage = ({data: messageData}) => {
    const message = JSON.parse(messageData);
    const {type: messageType, data} = message;

    this.listeners.forEach(({type, callback}) => {
      if (type === messageType)
        callback(data);
    });
  };
}

export {WebsocketClient};
export default WebsocketClient;
