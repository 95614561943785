import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {setChooseQuestionnaireItems} from "../../actions/modals";
import {connect} from "react-redux";
import {createThemeTreeByClientIdSelector} from "../selectors/theme";
import {ThemesHierarchyTree} from "../tools/ThemesHierarchyTree";
import {ChooseQuestionnaireItemsTree} from "../tools/ChooseQuestionnaireItemsTree";

const themesTreeSelector = createThemeTreeByClientIdSelector();

@connect(
  ({modals: {chooseQuestionnaireItems: {clientId, resolve, reject, defaultValues, lockedValues, recursiveTree}}}) => ({
    clientId,
    defaultValues,
    lockedValues,
    recursiveTree,
    resolve,
    reject
  })
)
class ChooseQuestionnaireItems extends React.PureComponent {
  constructor(props) {
    super(props);

    const {defaultValues, lockedValues} = this.props;
    let tree = themesTreeSelector({
      clientId: this.props.clientId,
      sortByRank: true
    }, true);

    const horizontal = flatTree(tree);

    const {themes, themes_questions} = defaultValues;

    if (!!themes)
      themes.forEach(id => {
        const item = horizontal.find(i => (i.nodeModel === 'theme' && i.id === id));
        if (!!item)
          item.checked = true;
      });
    if (!!themes_questions)
      themes_questions.forEach(id => {
        const item = horizontal.find(i => (i.nodeModel === 'themeQuestion' && i.id === id));
        if (!!item)
          item.checked = true;
      });

    if (!!lockedValues){
      const {themes, themes_questions} = lockedValues;

      if (!!themes)
        themes.forEach(id => {
          const item = horizontal.find(i => (i.nodeModel === 'theme' && i.id === id));
          if (!!item)
            item.readOnly = true;
        });
      if (!!themes_questions)
        themes_questions.forEach(id => {
          const item = horizontal.find(i => (i.nodeModel === 'themeQuestion' && i.id === id));
          if (!!item)
            item.readOnly = true;
        });
    }

    this.state = {
      closing: false,
      tree: tree
    };
  }

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setChooseQuestionnaireItems({
      open: false
    }));
  };

  resolve = () => {
    const {tree} = this.state;
    const horizontal = flatTree(tree);
    const checkedItems = Object.values(horizontal).filter(i => !!i.checked);

    this.props.resolve({
      themes_questions: checkedItems.filter(i => i.nodeModel === 'themeQuestion'),
      themes: checkedItems.filter(i => i.nodeModel === 'theme')
    });
    this.closing();
  };

  reject = () => {
    this.props.reject();
    this.closing();
  };

  render(){
    const {recursiveTree} = this.props;
    const {closing, tree} = this.state;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.reject}>{t('modals.select_elements')}</ModalHeader>
        <ModalBody>
          {!!recursiveTree ?
            <ChooseQuestionnaireItemsTree
              name={'ChooseQtModalTree'}
              data={tree}/> :
            <ThemesHierarchyTree
              name={'ChooseQtModalTree'}
              data={tree}/>}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.reject}>{t('common.close')}</Button>
          <Button color="success" onClick={this.resolve}>{t('common.valid')}</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ChooseQuestionnaireItems;
