import React from "react";
import {setReportZonesExplotationOverview} from "../../actions/modals";
import {
  FormGroup,
  Modal,
  Button,
  ModalBody,
  ModalHeader
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import {defaultPaginationFactory} from "../../constants/ui";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import {DebounceSearchInput} from "../common/DebounceSearchInput";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {getRoute, modules} from "../../constants/modules";
import {createClientSelectorById} from "../selectors/clients";
import createSelector from "../../tools/createSelector";

const visitsViewPath = getRoute(modules.Aud_Vis.id);
const visitsSelector = createSelector(
  (orm, {visitIds}) => {
    return visitIds.map(id => {
      const visit = orm.Visit.withId(id);

      return {
        ...visit.ref,
        zone_exploitation: {
          ...visit.zone_exploitation_id.ref,
          prestataires: visit.zone_exploitation_id.prestataires.toRefArray(),
        },
        user: !!visit.user_id ? visit.user_id.ref : null,
      };
    });
  }
);

const clientSelector = createClientSelectorById();

@withRouter
@connect(({session : {currentClientId}, modals: {reportZonesExploitationOverview: {reportId, visitIds, opener}}}) => ({
  reportId,
  visitIds,
  opener,
  client: clientSelector({id: currentClientId})
}))
class ReportZonesExploitationOverview extends React.PureComponent {
  constructor(props) {
    super(props);

    const {visitIds} = this.props;

    this.state = {
      closing: false,
      visits: visitsSelector({visitIds})
    }
  }

  redirectToVisitQuestionnaireEdit = (visitId) => {
    const {history, opener} = this.props;

    this.closing();
    history.push({
      pathname: `${visitsViewPath}/${visitId}`,
      state: !opener ? null : {
        from: opener.pathname,
        name: opener.name
      }
    });
  };

  columns = [
    {
      dataField: 'zone_exploitation.label',
      text: this.props.client.zoneExploitationLabel,
      sort: true
    },
    {
      dataField: 'user.firstname',
      text: t('common.auditor'),
      sort: true,
      formatter: (cell, row) => {
        if (!row.user)
          return t('audit_edit_view.not_defined');
        return `${row.user.firstname} ${row.user.lastname}`
      }
    },
    {
      dataField: 'passage',
      text: t('audit_edit_view.visit_date'),
      sort: true,
      formatter: (cell, row) => {
        if (!row.passage)
          return t('audit_edit_view.not_defined');
        return moment(row.passage).format('L');
      }
    },
    {
      dataField: 'zone_exploitation.prestataires',
      text: t('common.prestataire'),
      formatter: (cell, row) => {
        if (!row.zone_exploitation.prestataires.length)
          return t('common.no_prestataires');
        return row.zone_exploitation.prestataires.map(p => p.label).join(', ');
      }
    },
    {
      dataField: '',
      text: '',
      isDummyField: true,
      headerStyle: {
        width: 190
      },
      formatter: (cell, row) => {
        return (
          <div class="d-flex">
            <Button
              onClick={() => this.redirectToVisitQuestionnaireEdit(row.id)}
              title={t('audit_edit_view.edit_questionnaire')}
              size="sm"
              class="overflow-hidden text-overflow-ellipsis"
              color="secondary">
              {t('modals.visits_progress.access_visit')}
              {/*<span className="oi oi-pencil"/>*/}
            </Button>
          </div>
        )
      }
    }
  ];

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setReportZonesExplotationOverview({
      open: false
    }));
  };

  render(){
    const {closing, visits} = this.state;

    return (
      <Modal size="lg" isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.closing}>
          {t('modals.visits_progress.title')}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <ToolkitProvider
              search
              bootstrap4={true}
              columns={this.columns}
              data={visits}
              keyField={'id'}>
              {props => (
                <div>
                  <DebounceSearchInput
                    className="mb-2"
                    onChange={(e) => props.searchProps.onSearch(e.target.value)}/>
                  <BootstrapTable
                    noDataIndication={() => t('common.no_data')}
                    bordered={false}
                    pagination={defaultPaginationFactory()}
                    {...props.baseProps}/>
                </div>
              )}
            </ToolkitProvider>
          </FormGroup>
        </ModalBody>
      </Modal>
    )
  }
}

export {ReportZonesExploitationOverview};
export default ReportZonesExploitationOverview;
