import React from "react";
import {connect} from "react-redux";
import {
  Form,
  FormGroup,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback
} from "reactstrap";
import {FormValidator} from "../../tools/formValidator/FormValidator";
import {setSetupModifiedPrestataireObject} from "../../actions/modals";
import {ImportFileIconPlaceholder} from "../common/ImagePlaceholders";
import {tFunction} from "../tools/Translation";

const localFv = new FormValidator([
  {
    field: 'label',
    method: 'isEmpty',
    validWhen: false,
    message: tFunction('modals.label_specified')
  }
]);

@connect(({modals: {setupModifiedPrestataireObject : {resolve, reject, current}}}) => ({
  resolve,
  reject,
  current
}))
class SetupModifiedPrestataireObject extends React.PureComponent {
  constructor(props) {
    super(props);
    const {current} = this.props;

    this.state = {
      closing: false,
      label: current.label,
      adresse: current.adresse,
      code_postal: current.code_postal,
      ville: current.ville,
      pays: current.pays,
      phone: current.phone,
      path: current.path,
      validation: localFv.valid()
    }
  }

  fileInputRef = React.createRef();

  inputLabel = React.createRef();

  componentDidMount() {
    this.inputLabel.focus();
  }

  onChange = (e) => {
    this.setState({[e.target.name] : e.target.value});
  };
  onLogoChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.setState({path: e.target.result});
    };
    reader.readAsDataURL(file);
  };
  resolve = (e) => {
    e.preventDefault();
    const {label, adresse, code_postal, ville, pays, phone, path} = this.state;

    const validations = localFv.validate({label});
    if (!validations.isValid){
      this.setState({validation: validations});
      return;
    }
    this.props.resolve({
      label,
      adresse,
      code_postal,
      ville,
      pays,
      phone,
      path
    });
    this.closing();
  };
  reject = () => {
    this.props.reject();
    this.closing();
  };
  closing = () => {
    this.setState({closing: true});
  };
  close = () => {
    this.props.dispatch(setSetupModifiedPrestataireObject({
      open: false
    }));
  };
  render(){
    const {
      validation,
      closing,
      label,
      adresse,
      code_postal,
      ville,
      pays,
      phone,
      path
    } = this.state;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.reject}>
          {t('modals.modify_presta')}
        </ModalHeader>
        <Form onSubmit={this.resolve}>
          <ModalBody>
            <FormGroup className="text-center">
              <ImportFileIconPlaceholder
                onClick={() => this.fileInputRef.current.click()}
                src={path}/>
              <input
                hidden
                ref={this.fileInputRef}
                type="file"
                name="path"
                accept="image/*"
                onChange={this.onLogoChange}
                className="custom-file-input"/>
            </FormGroup>
            <FormGroup>
              <Label>
                {t('common.label')}
              </Label>
              <Input
                name="label"
                innerRef={(input) => (this.inputLabel = input)}
                invalid={validation.label.isInvalid}
                placeholder={t('common.label')}
                onChange={this.onChange}
                value={label}/>
              <FormFeedback>
                {validation.label.message}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label>
                {t('common.address')}
              </Label>
              <Input
                onChange={this.onChange}
                value={adresse}
                name="adresse"
                placeholder={t('common.address')}/>
            </FormGroup>
            <FormGroup>
              <Label>
                {t('modals.postal')}
              </Label>
              <Input
                onChange={this.onChange}
                value={code_postal}
                name="code_postal"
                placeholder={t('modals.postal')}/>
            </FormGroup>
            <FormGroup>
              <Label>
                {t('modals.town')}
              </Label>
              <Input
                onChange={this.onChange}
                value={ville}
                name="ville"
                placeholder={t('modals.town')}/>
            </FormGroup>
            <FormGroup>
              <Label>
                {t('modals.country')}
              </Label>
              <Input
                onChange={this.onChange}
                value={pays}
                name="pays"
                placeholder={t('modals.country')}/>
            </FormGroup>
            <FormGroup>
              <Label>
                {t('modals.phone')}
              </Label>
              <Input
                onChange={this.onChange}
                value={phone}
                name="phone"
                placeholder={t('modals.phone')}/>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" type='button' onClick={this.reject}>{t('common.close')}</Button>
            <Button color="success">{t('common.valid')}</Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export {SetupModifiedPrestataireObject};
export default SetupModifiedPrestataireObject;
