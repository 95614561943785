import React from "react";
import {EvolTable} from "./EvolTable";
import {Col, DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown} from "reactstrap";
import {Green,Red} from "../utils";
import {createItemI2cFetch} from "../../../tools/Filters/updateFunctions";
import {FRequest} from "@neqo34/filters";

const params = {
  lastVagueDiff: true,
  lastVagueValue: true,
  order: {'value': 'desc'}
};

const updateFuncs = {
  'zoneExploitation': createItemI2cFetch({
    withLabels: true,
    params:params,
    level: [1],
    labelModelName: 'ZoneExploitation',
    by: 'zoneExploitation'
  }),
  'theme': createItemI2cFetch({
    withLabels: true,
    params:params,
    level: [1],
    labelModelName: 'Theme',
    by: 'questionnaireItem'
  }),
  'subTheme': createItemI2cFetch({
    withLabels: true,
    params:params,
    level: {not_in: [0,1, -1]},
    labelModelName: 'Theme',
    by: 'questionnaireItem'
  }),
  'question': createItemI2cFetch({
    withLabels: true,
    params:params,
    level: [0],
    labelModelName: 'ThemeQuestion',
    by: 'questionnaireItem'
  }),
  'prestataire': createItemI2cFetch({
    withLabels: true,
    params:params,
    level: [1],
    labelModelName: 'Prestataire',
    by: 'prestataire'
  })
};

class TopFlopTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      scale: "zoneExploitation"
    }
  }

  changeTarget = ({target: {value: scale}}) => {
    this.setState({
      scale
    });
  };

  render(){
    const {scale, query} = this.state;
    const {passageLow, passageHigh} = this.props;

    return (
      <section className="card card-fluid m-0 flex-grow-1 d-flex flex-column">
        <header className="card-header d-flex align-items-center">
          <span class="flex-grow-1">TOPS/FLOPS</span>
          <Input
            type="select"
            name="scale"
            onChange={this.changeTarget}
            style={{width:150}}
            bsSize="sm">
            <option value={"zoneExploitation"}>
              {t('common.sites')}
            </option>
            <option value={"theme"}>
              {t('common.thematics')}
            </option>
            <option value={"subTheme"}>
              {t('common.subthematics')}
            </option>
            <option value={"question"}>
              {t('common.questions')}
            </option>
            <option value={"prestataire"}>
              {t('common.prestataires')}
            </option>
          </Input>
        </header>
        <div className="card-body d-flex flex-column">
          <FRequest
            dynamicParameters={[
              "clients",
              "analyses",
              "vagues",
              "zones_exploitation",
              "prestataires"
            ]}
            updateFunc={updateFuncs[scale]}>
            {(result, loading, error, filters) => {
              if (loading && !result)
                return <div>{t('reporting.loading')}</div>;

              if (!result || !result.length)
                return <div class="text-center">{t('reporting.no_data')}</div>;

              let datas = result.map(r => {
                return {
                  id: r.id,
                  type: scale === 'zoneExploitation' ? 'zone_exploitation' : undefined,
                  label: r.label,
                  currentValue: r.value,
                  evol: r.lastVagueDiff,
                  hideEvol: r.firstVague
                }
              });
              datas = _.sortBy(datas, 'currentValue').reverse();

              const filtered = datas.filter(i => i.currentValue !== null);

              let tops = filtered.slice(0, filtered.length / 2).slice(0, 5);
              let flops = filtered.slice(filtered.length / 2, filtered.length);
              if (flops.length > 5)
                flops = flops.slice(flops.length - 5, flops.length);
              
              if(filtered.length <= 10)
              {
                tops = datas.filter(i => i.currentValue >= 85);
                flops = datas.filter(i => i.currentValue < 85);
              }

              flops = flops.sort((a, b) => a.currentValue - b.currentValue);

              return (
                <div className="d-flex">
                  <div className="d-flex flex-column"
                       style={{
                         borderRight: '1px solid #e2e2e4'
                       }}>
                    <h4 className="text-center" style={{color: Green}}>
                      TOPS
                    </h4>
                    <EvolTable
                      datas={tops}
                      passageLow={passageLow}
                      passageHigh={passageHigh}
                      disablePagination={true}
                      defaultSort={{dataField:'currentValue', order:'desc'}}
                      filters= {filters}/>
                  </div>
                  <div className="d-flex flex-column">
                    <h4 className="text-center" style={{color: Red}}>
                      FLOPS
                    </h4>
                    <EvolTable
                      disablePagination={true}
                      defaultSort={{dataField:'currentValue', order:'asc'}}
                      passageLow={passageLow}
                      passageHigh={passageHigh}
                      datas={flops}
                      filters= {filters}/>
                  </div>
                </div>
              )
            }}
          </FRequest>
        </div>
      </section>
    )
  }
}

export {TopFlopTable};
export default TopFlopTable;
