import React from "react";
import {connect} from "react-redux";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Input} from "reactstrap";
import {auditsByClientId} from "../../ormSelectors/audit";
import {setEditCoefficient} from "../../actions/modals";
import {getClientAnalysesSelector} from "../../ormSelectors/analyse";

@connect(
  ({session: {currentClientId}, modals: {editCoefficient: {datas, resolve, reject}}}) => ({
    datas,
    audits: auditsByClientId(currentClientId),
    analyses: getClientAnalysesSelector({client_id: currentClientId}),
    resolve,
    reject
  })
)
class EditCoefficient extends React.PureComponent {
  state = {
    closing: false,
    datas: []
  };

  componentDidMount(){
    const {datas} = this.props;
    let newDatas = [];

    if (!!datas.audits)
      datas.audits.map(d => {
        const audit = this.props.audits.find(a => a.id === d);
        const coefficient = !!datas.coefficients.find(c => c.audit_id === d) ?
          datas.coefficients.find(c => c.audit_id === d).coefficient : 1;
        newDatas.push({
          label: audit.label,
          model: 'Audit',
          coeff: coefficient,
          id: d,
          questionnaire_id: datas.questionnairesId[audit.id]
        })
      });
    if (!!datas.analyses)
      datas.analyses.map(d => {
        const analyse = this.props.analyses.find(a => a.id === d);
        const coefficient = !!datas.coefficients.find(c => c.analyse_id === d) ?
          datas.coefficients.find(c => c.analyse_id === d).coefficient : 1;
        newDatas.push({
          label: analyse.label,
          model: 'Analyse',
          coeff: coefficient,
          id: d,
          questionnaire_id: datas.questionnairesId[datas.audits[0]]
        })
      });
    this.setState({datas: newDatas});
  }

  changeCoefficient = (e, {id}) => {
    const datas = this.state.datas.slice();

    datas.find(d => d.id === id).coeff = e.target.value;
    this.setState({datas: datas});
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setEditCoefficient({
      open: false
    }));
  };

  resolve = () => {
    const datas = this.state.datas.slice();

    datas.map(d => {
      if (d.coeff < 0)
        d.coeff = 0;
    });
    this.props.resolve({datas});
    this.closing();
  };

  reject = () => {
    this.props.reject();
    this.closing();
  };

  render(){
    const {closing, datas} = this.state;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.reject}>{t('modals.manage_ponderation_coefficient')}</ModalHeader>
        <ModalBody>
          <h5>{this.props.datas.text}</h5>
          {datas.map(d => (
            <div key={d.id} className='w-100 mb-3'>
              <span>{d.label}</span>
              <Input value={d.coeff}
                     style={{maxWidth:155, float: 'right'}}
                     bsSize="sm"
                     min={0}
                     name='coefficient'
                     max={100}
                     type="number"
                     placeholder={t('common.coefficient')}
                     onChange={e => this.changeCoefficient(e,{id: d.id})}/>
            </div>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.reject}>{t('common.close')}</Button>
          <Button color="success" onClick={this.resolve}>{t('common.valid')}</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export {EditCoefficient};
