import React from "react";
import {FormValidator} from "../../tools/formValidator/FormValidator";
import {connect} from "react-redux";
import {
  Form,
  FormGroup,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback, Row, Col, Nav, NavItem, NavLink
} from "reactstrap";
import {setAddModifyUser} from "../../actions/modals";
import {addUser, updateUser} from "../../actions/orm/User";
import LoadingButton from "../common/LoadingButton";
import createSelector from "../../tools/createSelector";
import {createClientSelectorById} from "../selectors/clients";
import {
  allClientPrestataires,
  createPrestataireSelectorById,
  createPrestataireTreeSelector
} from "../selectors/prestataires";
import {ImportFileIconPlaceholder} from "../common/ImagePlaceholders";
import {fetchClientOrganisation} from "../../actions/orm/Client";
import {organisationsByClientId2} from "../selectors/organisations";
import update from "immutability-helper";
import {GenericOrganisationCheckableTree} from "../tools/GenericOrganisationCheckableTree";
import {tFunction} from "../tools/Translation";
import _ from "lodash";

const localFv = new FormValidator([
  {
    field: 'firstname',
    method: 'isEmpty',
    validWhen: false,
    message: tFunction('admin.profile_settings.firstname_require')
  },
  {
    field: 'lastname',
    method: 'isEmpty',
    validWhen: false,
    message: tFunction('admin.profile_settings.lastname_require')
  },
  {
    field: 'email',
    method: 'isEmpty',
    validWhen: false,
    message: tFunction('admin.profile_settings.email_require')
  },
  {
    field: 'email',
    method: 'isEmail',
    validWhen: true,
    message: tFunction('admin.profile_settings.not_email')
  },
  {
    field: 'profil_id',
    method: 'isEmpty',
    validWhen: false,
    message: tFunction('modals.select_profile_require')
  },
  {
    field: 'phone',
    method: 'isMobilePhoneNotMandatory',
    validWhen: true,
    message: tFunction('modals.phone_number_format')
  }
]);

const userSelector = createSelector(
  (orm, {id}) => {
    const user = orm.User.withId(id);

    return {
      ...user.ref,
      profil: user.profil_id.ref,
      clients: !!user.clients ? user.clients.toRefArray().map(i => i.id) : [],
      prestataires: !!user.prestataires ? user.prestataires.toRefArray().map(i => i.id) : [],
      sites: !!user.scope_sites ? user.scope_sites.toRefArray().map(i => i.id) : [],
      zonesExploitation: !!user.scope_zones_exploitation ? user.scope_zones_exploitation.toRefArray().map(i => i.id) : []
    }
  }
);

const profilesSelector = createSelector(
  (orm, {type, id}) => {
    const item = type === 'client' ?
      orm.Client.withId(id) :
      type === 'prestataire' ?
        orm.Prestataire.withId(id) :
        orm.Firm.withId(id);
    const profiles = orm.Profile.all().filter(i => i.model === 'generic').toRefArray();

    return profiles.concat(item.profiles.toRefArray());
  }
);

const firmSelector = createSelector(
  (orm, {id}) => {
    const firm = orm.Firm.withId(id);

    return !firm ? null : {
      ...firm.ref,
      clients: firm.clients.toRefArray(),
      prestataires: firm.prestataires.toRefArray()
    }
  }
);

const prestataireSelector = createPrestataireSelectorById();
const clientSelector = createClientSelectorById();
const getPrestataireTree = createPrestataireTreeSelector();

@connect(
  ({session: {userId}, modals: {addModifyUser: {targetType, targetId, currentUserId}}}) => ({
    targetId,
    targetType,
    currentUserId,
    profiles: profilesSelector({type: targetType, id: targetId}),
    me: userSelector({id: userId})
  })
)
class AddModifyUser extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {
      loading: false,
      closing: false,
      user: null,
      email: '',
      active: true,
      firstname: '',
      lastname: '',
      path: '',
      profil_id: '',
      validation: localFv.valid(),
      organisationScopesIndex: 0,
      organisationScopes: [],
      fetchingOrganisation: false,
      mode: 'details',
      default_client_id: null,
      language: 'fr_FR',
      jobtitle: '',
      phone: ''
    };

    const {currentUserId, targetType, targetId} = this.props;

    if (targetType === 'client'){
      const client = {
        ...clientSelector({id: targetId}),
        type: 'client'
      };

      this.state.organisationScopes.push({
        item: client,
        fetched: true,
        tree: organisationsByClientId2({clientId: targetId}, true)
      });

      const prestataires = allClientPrestataires({clientId: targetId}, true);
      prestataires.forEach(p => {
        if (this.props.me.prestataires.find(id => p.id === id)) {
          const prestataire = {
            ...prestataireSelector({id: p.id}),
            type: 'prestataire'
          };
          this.state.organisationScopes.push({
            item: prestataire,
            fetched: false,
            tree: getPrestataireTree({pId: prestataire.id}, true)
          });
        }
      });
    }
    else if (targetType === 'prestataire'){
      this.state.organisationScopes.push({
        item: prestataireSelector({id: targetId}),
        tree: getPrestataireTree({pId: targetId}, true)
      });
    }
    else if (targetType === 'firm'){
      const firm = firmSelector({id: targetId});
      firm.clients.forEach(c => {
        const client = {
          ...clientSelector({id: c.id}),
          type: 'client'
        };

        this.state.organisationScopes.push({
          item: client,
          fetched: false,
          tree: organisationsByClientId2({clientId: client.id}, true)
        });
      });
      firm.prestataires.forEach(p => {
        const prestataire = {
          ...prestataireSelector({id: p.id}),
          type: 'prestataire'
        };

        this.state.organisationScopes.push({
          item: prestataire,
          fetched: false,
          tree: getPrestataireTree({pId: prestataire.id}, true)
        });
      });
    }

    if (!!currentUserId){
      const user = userSelector({id: currentUserId});
      this.state = {
        ...this.state,
        user,
        email: user.email,
        active: user.active,
        firstname: user.firstname,
        lastname: user.lastname,
        path: user.path,
        profil_id: user.profil.id,
        default_client_id: user.default_client_id,
        language: user.locale,
        jobtitle: user.jobtitle,
        phone: user.phone
      };

      this.state.organisationScopes.forEach(({tree}) => {
        let horizontal = flatTree(tree);

        if (horizontal[0].type === 'client')
          horizontal.forEach(item => {
            if (user.clients.includes(item.id) && item.type === 'client')
              item.checked = true;
            if (user.sites.includes(item.id) && item.type === 'site')
              item.checked = true;
            if (user.zonesExploitation.includes(item.id) && item.type === 'zone_exploitations')
              item.checked = true;
          });
        if (horizontal)
          if (horizontal[0].type === 'prestataire')
            horizontal.forEach(item => {
              if (user.prestataires.includes(item.id))
                item.checked = true;
            });
      });
    }
  }

  fileInputRef = React.createRef();

  inputFirstname = React.createRef();

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };
  setMode = (mode) => {
    this.setState({mode});
  };

  fetchOrganisationScope = async (index) => {
    const {organisationScopes} = this.state;
    const scope = organisationScopes[index];

    if (!!scope && scope.item.type === 'client' && !scope.fetched){
      let clients = [];
      let sites = [];
      let ze = [];

      if (!!this.state.user) {
        clients = this.state.user.clients;
        sites = this.state.user.sites;
        ze = this.state.user.zonesExploitation
      }

      this.setState({fetchingOrganisation: true});
      await this.props.dispatch(fetchClientOrganisation({
        client_id: scope.item.id
      }));

      let newTree = organisationsByClientId2({clientId: scope.item.id}, true);
      let newTreeHorizontal = flatTree(newTree);
      newTreeHorizontal.forEach(client => {
        if ((client.type === 'client' && clients.includes(client.id)) ||
          (client.type === 'zone_exploitations' && ze.includes(client.id)) ||
          (client.type === 'site' && sites.includes(client.id)))
          client.checked = true;
      });
      this.setState({organisationScopes: update(organisationScopes, {
          [index]: {
            tree: {$set: newTree},
            fetched: {$set: true}
          }
        }),
        fetchingOrganisation: false});
    }
  };

  handleSelectedOrganisationScopeIndex = async (e) => {
    const index = parseInt(e.target.value);

    this.fetchOrganisationScope(index);
    this.setState({organisationScopesIndex: index});
  };

  handleLogoChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.setState({path: e.target.result});
    };
    reader.readAsDataURL(file);
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const {user, email, firstname, lastname, phone, path, profil_id, active, organisationScopes, language, jobtitle} = this.state;
    const {targetType, targetId} = this.props;

    const validation = localFv.validate({
      email,
      firstname,
      lastname,
      profil_id: typeof profil_id === 'number' ? profil_id.toString() : profil_id,
      phone
    });

    if (!validation.isValid){
      this.setState({validation});
      return;
    }

    this.setState({loading: true});

    let prestataires = [];
    let clients = [];
    let zonesExploitations = [];
    let sites = [];
    let remove_clients = [];
    let remove_zonesExploitations = [];
    let remove_sites = [];
    let remove_prestataires = [];

    if (!!user){
      prestataires = prestataires.concat(user.prestataires);
      clients = clients.concat(user.clients);
      zonesExploitations = zonesExploitations.concat(user.zonesExploitation);
      sites = sites.concat(user.sites);
    }

    organisationScopes.forEach(({tree}) => {
      const horizontal = flatTree(tree);

      if (tree[0].type === 'client'){
        horizontal.filter(i => i.type === 'client').forEach(client => {
          const isPresent = clients.includes(client.id);

          if (!!client.checked && !isPresent)
            clients.push(client.id);
          else if (!client.checked && isPresent) {
            clients = clients.filter(c => c !== client.id);
            remove_clients.push(client.id);
          }
        });
        horizontal.filter(i => i.type === 'site').forEach(site => {
          const isPresent = sites.includes(site.id);

          if (!!site.checked && !isPresent)
            sites.push(site.id);
          else if (!site.checked && isPresent) {
            sites = sites.filter(c => c !== site.id);
            remove_sites.push(site.id);
          }
        });
        horizontal.filter(i => i.type === 'zone_exploitations').forEach(zone => {
          const isPresent = zonesExploitations.includes(zone.id);

          if (!!zone.checked && !isPresent){
            zonesExploitations.push(zone.id);
          }
          else if (!zone.checked && isPresent) {
            zonesExploitations = zonesExploitations.filter(z => z !== zone.id);
            remove_zonesExploitations.push(zone.id);
          }
        });
      }else {
        horizontal.forEach(p => {
          const isPresent = prestataires.includes(p.id);

          if (!!p.checked && !isPresent)
            prestataires.push(p.id);
          else if (!p.checked && isPresent) {
            prestataires = prestataires.filter(i => i !== p.id);
            remove_prestataires.push(p.id);
          }
        })
      }
    });

    let finalUser = null;

    if (!user)
      try {
        finalUser = await this.props.dispatch(addUser({
          email,
          firstname,
          lastname,
          phone,
          path,
          active,
          profil_id,
          client_id: targetType === 'client' ? targetId : undefined,
          prestataire_id: targetType === 'prestataire' ? targetId : undefined,
          firm_id: targetType === 'firm' ? targetId : undefined,
          prestataires,
          clients,
          scope_sites: sites,
          scope_zones_exploitation: zonesExploitations,
          locale: language,
          jobtitle
        }));
      } catch (e) {
        if (e.response.status === 409) {
          this.setState(update(this.state, {
            validation: {
              email: {
                isInvalid: {$set: true},
                message: {$set: t('modals.email_existing')}
              }
            },
            loading: {$set: false}
          }));
          return;
        }
      }
    else
      finalUser = await this.props.dispatch(updateUser({
        id: user.id,
        email,
        firstname,
        lastname,
        phone,
        path,
        active,
        profil_id,
        client_id: user.client_id,
        prestataire_id: user.prestataire_id,
        prestataires,
        clients,
        scope_sites: sites,
        scope_zones_exploitation: zonesExploitations,
        remove_clients,
        remove_sites,
        remove_zonesExploitations,
        remove_prestataires,
        default_client_id: this.state.default_client_id,
        locale: language,
        jobtitle
      }));
      
    this.setState({loading: false});
    this.closing();
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setAddModifyUser({
      open: false
    }));
  };

  componentDidMount(){
    const {organisationScopesIndex} = this.state;
    const {targetType} = this.props;
    this.inputFirstname.focus();

    if (targetType === 'firm')
      this.fetchOrganisationScope(organisationScopesIndex);
  }

  render(){
    const {profiles, targetType} = this.props;
    const {
      validation,
      closing,
      loading,
      organisationScopes,
      organisationScopesIndex,
      fetchingOrganisation,
      user,
      firstname,
      phone,
      email,
      lastname,
      path,
      profil_id,
      active,
      mode,
      default_client_id,
      language,
      jobtitle
    } = this.state;
    const organisationScopesSorted = _.sortBy(organisationScopes, ({item}) => item.label);
    const editMode = !!user;
    const clients = organisationScopes.filter(scope => scope.item.type === 'client' && scope.item.parent_id === null).map(scope => scope.item);

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.closing}>
          {editMode ?
            `${t('modals.edit_user')} ${user.firstname} ${user.lastname}` : t('modals.add_user')}
        </ModalHeader>
        <Form onSubmit={this.handleSubmit}>
          <ModalBody>
            <FormGroup className="text-center">
              <ImportFileIconPlaceholder
                onClick={() => this.fileInputRef.current.click()}
                src={path}/>
              <input
                hidden
                ref={this.fileInputRef}
                type="file"
                name="path"
                accept="image/*"
                onChange={this.handleLogoChange}
                className="custom-file-input"/>
            </FormGroup>
            <FormGroup>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className="cursor-pointer"
                    onClick={this.setMode.bind(null, 'details')}
                    active={mode === 'details'}>
                    {t('common.details')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="cursor-pointer"
                    onClick={this.setMode.bind(null, 'perimetre')}
                    active={mode === 'perimetre'}>
                    {t('common.organizational_perimeter')}
                  </NavLink>
                </NavItem>
              </Nav>
            </FormGroup>
            {mode === 'details' &&
            <React.Fragment>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label>
                      {t('common.firstname')}
                    </Label>
                    <Input name="firstname"
                           innerRef={(input) => (this.inputFirstname = input)}
                           invalid={validation.firstname.isInvalid}
                           onChange={this.handleChange}
                           value={firstname}
                           placeholder={t('common.firstname')}/>
                    <FormFeedback>
                      {validation.firstname.message}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>
                      {t('common.lastname')}
                    </Label>
                    <Input name="lastname"
                           invalid={validation.lastname.isInvalid}
                           onChange={this.handleChange}
                           value={lastname}
                           placeholder={t('common.lastname')}/>
                    <FormFeedback>
                      {validation.lastname.message}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label>
                  {t('common.email')}
                </Label>
                <Input name="email"
                       invalid={validation.email.isInvalid}
                       type="text"
                       onChange={this.handleChange}
                       value={email}
                       placeholder={t('common.email')}/>
                <FormFeedback>
                  {validation.email.message}
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>
                  {t('admin.users.phone')}
                </Label>
                <Input name="phone"
                       invalid={validation.phone.isInvalid}
                       type="text"
                       onChange={this.handleChange}
                       value={phone}
                       placeholder={t('admin.users.phone')}/>
                <FormFeedback>
                  {validation.phone.message}
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>
                  {t('common.jobtitle')}
                </Label>
                <Input name="jobtitle"
                       type="text"
                       onChange={this.handleChange}
                       value={jobtitle}
                       placeholder={t('common.jobtitle')}/>
              </FormGroup>
              <FormGroup>
                <Label>
                  {t('common.profile')}
                </Label>
                <Input
                  id="addModifyUserProfil"
                  name="profil_id"
                  invalid={validation.profil_id.isInvalid}
                  onChange={this.handleChange}
                  value={profil_id}
                  type="select">
                  <option value="">{t('modals.select_profile')}</option>
                  {profiles.map(item => (
                    <option
                      value={item.id}
                      key={item.id}>
                      {item.label}
                    </option>
                  ))}
                </Input>
                <FormFeedback>
                  {validation.profil_id.message}
                </FormFeedback>
              </FormGroup>
              {editMode &&
              <FormGroup>
                <Label for="addModifyUserActive">
                  {t('modals.activation')}
                </Label>
                <Input
                  id="addModifyUserActive"
                  type="select"
                  name="active"
                  value={active}
                  onChange={this.handleChange}>
                  <option value={false}>{t('common.no')}</option>
                  <option value={true}>{t('common.yes')}</option>
                </Input>
              </FormGroup>}
              {clients.length > 1 && targetType === 'firm' &&
              <FormGroup>
                <Label for="addModifyUserActive">
                  {t('modals.default_client')}
                </Label>
                <Input
                  id="addModifyUserDefaultClient"
                  type="select"
                  name="default_client_id"
                  value={default_client_id}
                  onChange={this.handleChange}>
                  {clients.map(item => (
                    <option
                      value={item.id}
                      key={item.id}>
                      {item.label}
                    </option>
                  ))}
                </Input>
              </FormGroup>}
              <FormGroup>
                <Label for="addModifyUserActive">
                  {t('language.language')}
                </Label>
                <Input
                  id="addModifyUserLanguage"
                  type="select"
                  name="language"
                  value={language}
                  onChange={this.handleChange}>
                  <option value='fr_FR'>
                    {t('language.french')}
                  </option>
                  <option value='en_US'>
                    {t('language.english')}
                  </option>
                  <option value='es_ES'>
                    {t('language.spanish')}
                  </option>
                </Input>
              </FormGroup>
            </React.Fragment>}
            {mode === 'perimetre' &&
            <React.Fragment>
              {targetType === 'prestataire' &&
              <FormGroup>
                <Label>
                  {t('modals.organisational_perimeter')}
                </Label>
                <GenericOrganisationCheckableTree
                  style={{
                    maxHeight: 300,
                    overflow: 'auto'
                  }}
                  data={organisationScopes[0].tree}
                  name="addmodtree"/>
              </FormGroup>}
              {targetType !== 'prestataire' && !!organisationScopes.length &&
              <FormGroup>
                <Label>
                  {t('common.organizational_perimeter')}
                </Label>
                <Input
                  onChange={this.handleSelectedOrganisationScopeIndex}
                  className="mb-2"
                  value={organisationScopesIndex}
                  name="organisationScopesIndex"
                  type="select">
                  {organisationScopesSorted.map(({item}, idx) => {
                    return (
                      <option value={idx} key={idx}>
                        {`${item.label} (${item.type})`}
                      </option>
                    )
                  })}
                </Input>
                {fetchingOrganisation &&
                <div className="mb-1">{t('modals.getting_data')}</div>}
                <GenericOrganisationCheckableTree
                  style={{
                    maxHeight: 300,
                    overflow: 'auto'
                  }}
                  data={organisationScopesSorted[organisationScopesIndex].tree}
                  name="addmodtree"/>
              </FormGroup>}
            </React.Fragment>}
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              type='button'
              disabled={loading}
              onClick={this.closing}>
              {t('common.close')}
            </Button>
            <LoadingButton
              loading={loading}
              disabled={loading}
              color="success">
              {t('common.valid')}
            </LoadingButton>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export {AddModifyUser};
export default AddModifyUser;
