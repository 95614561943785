import React from "react";
import {ImageDiv} from "./ImageDiv";
import {store} from "../../store";
import {setViewImage} from "../../actions/modals";
import {Button} from "reactstrap";

const PhotoPlaceholder = ({onRemove, onEdit, className, src, ...otherProps}) => {
  return (
    <ImageDiv
      onClick={() => store.dispatch(setViewImage({
        open: true,
        url: src
      }))}
      src={src}
      className={`photo ${!!className ? className: ''}`}
      {...otherProps}>
      {!!onEdit &&
      <Button
        title={t('common.edit_photo')}
        onClick={(e) => {e.stopPropagation();onEdit()}}
        className="remove-button edit btn-link btn-xs">
        <i className="fa fa-edit"/>
      </Button>}
      {!!onRemove &&
      <Button
        title={t('common.remove_photo')}
        onClick={(e) => {e.stopPropagation();onRemove()}}
        className="remove-button remove btn-link btn-xs">
        <i className="fa fa-trash"/>
      </Button>}
    </ImageDiv>
  )
};

export {PhotoPlaceholder};
export default PhotoPlaceholder;