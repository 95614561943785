import {fk, many, attr, Model} from 'redux-orm';
import {_Audit, _Client, _Prestataire, _User, _ZoneExploitation} from "../constants/orm";

export class Audit extends Model{
  static parse(data){

    if (!!data.prestataires)
      data.prestataires = data.prestataires.map(p => p.id);
    if (!!data.users) {
      data.user_rights = data.users;
      data.users = data.users.map(u => u.user_id);
    }
    if (!!data.zone_exploitations)
      data.zone_exploitations = data.zone_exploitations.map(z => z.id);

    return data;
  }

  static get fields(){
    return {
      id: attr(),
      label: attr(),
      freeze: attr(),
      client_id: fk(_Client, 'audits'),
      clients: many(_Client),
      prestataires: many(_Prestataire, 'audits'),
      zone_exploitations: many(_ZoneExploitation, 'audits'),
      type: attr(),
      numerical: attr(),
      users: many(_User, 'audits'),
      user_rights: attr(),
      label_i2c: attr(),
      label_zone_exploitation: attr(),
      color_down: attr(),
      color_middle: attr(),
      color_up: attr(),
      passage_low: attr(),
      passage_high: attr(),
      created: attr(),
      modified: attr(),
      scope: attr()
    }
  }
}

Audit.modelName = _Audit;
