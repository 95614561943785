import React from "react";
import {Row, Col, Label} from "reactstrap";
import {Bar, Radar} from "react-chartjs-2";
import {TopFlopTable} from "./SyntheticView/TopFlopTable";
import {ThemesI2CTable} from "./SyntheticView/ThemesI2CTable";
import {GlobalI2CSection} from "./SyntheticView/GlobalI2CSection";
import {Red,Orange,Green} from "./utils";
import "chartjs-plugin-labels";
import {createItemI2cFetch} from "../../tools/Filters/updateFunctions";
import {FRequest} from "@neqo34/filters";

class SyntheticView extends React.PureComponent {
  updateFunc = createItemI2cFetch({
    params: {
      lastVagueValue: true,
      lastVagueDiff: true
    },
    level: [1],
    by: 'zoneExploitation'
  });

  render(){
    const {passageLow, passageHigh} = this.props;

    return (
      <div>
        <Row>
          <Col sm={6}>
            <GlobalI2CSection/>
          </Col>
          <Col sm={6}>
            <section className="card card-fluid m-0 flex-grow-1 d-flex flex-column">
              <header className="card-header">
                {t('reporting.i2c_tranche', {I2C: Labels.i2c})}
              </header>
              <div className="card-body d-flex flex-column" style={{height: 200}}>
                <FRequest
                  dynamicParameters={[
                    "clients",
                    "analyses",
                    "vagues",
                    "zones_exploitation",
                    "prestataires"
                  ]}
                  updateFunc={this.updateFunc}>
                  {(result, loading, error) => {
                    if (loading && !result)
                      return <div>{t('reporting.loading')}</div>;
                    
                    if (!result || !result.length)
                      return <div class="text-center">{t('reporting.no_data')}</div>;
                      
                    const datas = result.map(r => {
                      return {
                        id: r.id,
                        label: r.id,
                        currentValue: r.value,
                        evol: r.lastVagueDiff
                      }
                    });

                    const min = datas.filter(v => !!v.currentValue && v.currentValue < passageLow);
                    const middle = datas.filter(v => !!v.currentValue && v.currentValue >= passageLow && v.currentValue < passageHigh);
                    const max = datas.filter(v => !!v.currentValue && v.currentValue >= passageHigh);

                    const data = {
                      labels: [`${Labels.i2c} >= ${passageHigh}%`, `${passageLow}% < ${Labels.i2c} < ${passageHigh}%`, `${Labels.i2c} < ${passageLow}%`],
                      datasets: [{
                        label: Labels.ze,
                        data: [max.length, middle.length, min.length],
                        backgroundColor: [Green, Orange, Red],
                        borderColor: [Green, Orange, Red],
                        borderWidth: 1
                      }],
                    };

                    const options = {
                      maintainAspectRatio: false,
                      legend: {
                        display: false
                      },
                      scales: {
                        xAxes: [{
                          gridLines: {
                            display:false
                          }
                        }],
                        yAxes: [{
                          gridLines: {
                            display:true
                          },
                          ticks: {
                            beginAtZero: true,
                            precision: 0
                          },
                          minBarLength: 3
                        }]
                      }
                    };

                    return (
                      <div class="d-flex">
                        <Bar data={data} options={options}/>
                      </div>
                    )
                  }}
                </FRequest>
              </div>
            </section>
          </Col>
        </Row>
        <Row class="mt-4">
          <Col>
            <TopFlopTable
              passageLow={passageLow}
              passageHigh={passageHigh}/>
          </Col>
        </Row>
        <Row class="mt-4">
          <Col>
            <ThemesI2CTable
              passageLow={passageLow}
              passageHigh={passageHigh}/>
          </Col>
        </Row>
      </div>
    )
  }
}

export {SyntheticView};
export default SyntheticView;
