import React from "react";
import {Input, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import {DebounceInput} from "react-debounce-input";

export const DebounceSearchInput = ({value, onChange, delay = 300, minLength = 0, className, groupProps, ...other}) => {
  return (
    <InputGroup className={className} {...groupProps}>
      <InputGroupAddon addonType="prepend">
        <InputGroupText>
          <span className="oi oi-magnifying-glass"/>
        </InputGroupText>
      </InputGroupAddon>
      <DebounceInput
        element={Input}
        minLength={minLength}
        debounceTimeout={delay}
        type="text"
        name="search"
        value={value}
        onChange={onChange}
        className="form-control"
        aria-label={t('common.search')}
        placeholder={t('common.search')}
        {...other}/>
    </InputGroup>
  )
};

export default DebounceSearchInput;