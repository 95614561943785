import React from "react";
import {
  FormGroup,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label
} from "reactstrap";
import {setDateRangePicker} from "../../actions/modals";
import {connect} from "react-redux";
import {RangeDateFlatpickrInput} from "../common/RangeDateFlatpickrInput";

@connect(({modals: {dateRangePicker: {resolve, reject}}}) => ({
  resolve,
  reject
}))
class DateRangePicker extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      closing: false,
      value: ['', '']
    }
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  resolve = () => {
    const {value} = this.state;

    this.props.resolve(value);
    this.closing();
  };

  close = () => {
    this.props.dispatch(setDateRangePicker({
      open: false
    }));
  };

  closing = () => {
    this.setState({closing: true});
  };

  reject = () => {
    this.props.reject();
    this.closing();
  };

  render(){
    const {closing, value} = this.state;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.reject}>
          {t('visits_view.choose_period')}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>
              {t('modals.choose_period')}
            </Label>
            <RangeDateFlatpickrInput
              value={value}
              onChange={this.handleChange}
              name="value"/>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            type='button'
            onClick={this.reject}>
            {t('common.close')}
          </Button>
          <Button
            disabled={!value[0] || !value[1]}
            onClick={this.resolve}
            color="success">
            {t('common.valid')}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export {DateRangePicker};
export default DateRangePicker;
