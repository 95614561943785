import createSelector from "../../tools/createSelector";

export const allRootClients = createSelector(
  (orm) => {
    return orm.Client.all().toRefArray().filter(i => i.parent_id === null);
  }
);

export const createAllClientObjectSelector = () => createSelector(
  (orm) => {
    return orm.Client.all().toRefArray().reduce((acc, curr) => {
      acc[curr.id] = curr;

      return acc;
    }, {});
  }
);

export const createRootClientsSelector = () => createSelector(
  (orm) => {
    return orm.Client.all().toRefArray().filter(i => i.parent_id === null);
  }
);

export const createClientSelectorById = () => createSelector(
  (orm, {id}) => {
    const client = orm.Client.withId(id);

    return !!client ? client.ref : null;
  }
);

export const createClientWithParametersByIdSelector = () => createSelector(
  (orm, {id}) => {
    const client = orm.Client.withId(id);
    let retClient = {...client.ref};

    const parameters = orm.Parameter.all().toRefArray()
    if(parameters && parameters.length > 0){
      parameters.filter( p => p.client_id === id).forEach(p => {
        retClient[p.label] = true;
      });
    }

    return retClient;
  }
);

export const createClientTreeSelector = () => {
  return createSelector(
    (orm, {clientId}) => {
      const client = orm.Client.withId(clientId);

      function setupTree(tree){
        return tree.map(item => {
          return {
            ...item.ref,
            toggled: true,
            type: 'client',
            children: setupTree(item.ChildClients.toModelArray())
          }
        });
      }
      return setupTree([client]);
    }
  )
};
