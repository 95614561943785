import {createSelector} from "../../tools/createSelector";

export const getZoneRootClientModel = (zoneModel) => {
  const site = zoneModel.site_id;
  let client = site.client_id;

  while (!!client.parent_id)
    client = client.parent_id;
  return client;
};

export const createAllZonesExploitationSelector = () => {
  return createSelector(
    (orm) => {
      return orm.ZoneExploitation.all().toRefArray();
    }
  )
};

export const createAllZonesExploitationObjectSelector = () => {
  return createSelector(
    (orm) => {
      return orm.ZoneExploitation.all().toRefArray().reduce((acc, curr) => {
        acc[curr.id] = curr;

        return acc;
      }, {});
    }
  )
};

export const createZoneExploitationByIdsSelector = () => createSelector(
  (orm, {zeIds}) => {
    if (!!zeIds){
      return zeIds.map(zId => {
        const zone = orm.ZoneExploitation.withId(zId);

        return !!zone ? zone.ref : null
      }).filter(z => !!z);
    }
    return [];
  }
);

export const getZoneExploitationRootClient = (zoneModel) => {
  let client = zoneModel.site_id.client_id;

  while (!!client.parent_id)
    client = client.parent_id;

  return client;
};
