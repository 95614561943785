import createSelector from "../../tools/createSelector";

export const createGenericQuestionsSelector = () => createSelector(
  (orm) => {
    return orm.Question.all().filter(q => !q.client_id).toRefArray();
  }
);

export const createQuestionsByClientIdSelector = () => createSelector(
  (orm, {clientId}) => {
    const client = orm.Client.withId(clientId);

    return client.clientQuestions.toRefArray();
  }
);
