import React from "react";
import {connect} from "react-redux";
import {setWriteText} from "../../actions/modals";
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

@connect(({modals: {writeText: {defaultValue, labelText, headerText, inputType, resolve, reject}}}) => ({
  defaultValue,
  labelText,
  headerText,
  inputType,
  resolve,
  reject
}))
class WriteText extends React.PureComponent {
  constructor(props) {
    super(props);
    const {defaultValue} = this.props;

    this.state = {
      closing: false,
      text: !!defaultValue ? defaultValue : ''
    }
  }

  inputComment = React.createRef();

  componentDidMount() {
    this.inputComment.focus();
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.resolve(this.state.text);
    this.closing();
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setWriteText({
      open: false
    }));
  };

  reject = () => {
    this.props.reject();
    this.closing();
  };

  render(){
    const {closing, text} = this.state;
    const {headerText, labelText, inputType} = this.props;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.reject}>
          {headerText}
        </ModalHeader>
        <Form onSubmit={this.handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label>
                {labelText} :
              </Label>
              <Input name="text"
                     type={!!inputType ? inputType : 'textarea'}
                     innerRef={(input) => (this.inputComment = input)}
                     onChange={this.handleChange}
                     placeholder={labelText}
                     value={text}/>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              type='button'
              onClick={this.reject}>
              {t('common.close')}
            </Button>
            <Button
              color="success">
              {t('common.valid')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export {WriteText};
export default WriteText;
