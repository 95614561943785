import {fk, attr, Model} from 'redux-orm';
import {_ModelAction, _ProgressModel} from "../constants/orm";

export class ModelAction extends Model{
    static parse(data){
        delete data._matchingData;
        delete data._joinData;

        Object.defineProperty(data, 'model_id',
            Object.getOwnPropertyDescriptor(data, 'type_progre_id'));
        delete data['type_progre_id'];

        return data;
    }

    static get fields() {
        return {
            id: attr(),
            label: attr(),
            model_id: fk(_ProgressModel, 'modelActions'),
            created: attr(),
            modified: attr()
        }
    }
}

ModelAction.modelName = _ModelAction;