import React from "react";
import {connect} from "react-redux";
import {setModal} from "../../actions/modals";
import {Modal, Button, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import {tFunction} from "../tools/Translation";
import {RowSelectionHeaderItem, RowSelectionItem} from "../tools/DatatableComponents";

const columns = [
  {
    dataField: 'text',
    text: tFunction('common.right'),
    sort: false
  }
];

@connect(({ocmModals: {editAuditUserRights: {user, resolve, reject}}}) => ({
  user,
  resolve,
  reject
}))
class EditAuditUserRights extends React.PureComponent {
  state = {
    closing: false,
    selected: []
  };

  componentDidMount() {
    const auditRights = [];

    !!this.props.user.create_report ? auditRights.push(1) : null;
    !!this.props.user.validate_rapport ? auditRights.push(2) : null;
    !!this.props.user.edit_validate_rapport ? auditRights.push(3) : null;

    this.setState({selected: auditRights});
  }

  handleSelectAll = () => {
    setTimeout(() => {
      const selected = [...this.tableRef.selectionContext.state.selected];

      this.setState({selected: selected});
    }, 1);
  };

  handleSelect = () => {
    setTimeout(() => {
      const selected = [...this.tableRef.selectionContext.state.selected];

      this.setState({selected: selected});
    }, 1);
  };

  resolve = () => {
    this.props.resolve({rights: this.state.selected});
    this.closing();
  };

  reject = () => {
    this.props.reject();
    this.closing();
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setModal('editAuditUserRights', false));
  };

  render(){
    const {user} = this.props;
    const {closing, selected} = this.state;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.reject}>
          {t('modals.rights_edition_of')} {`${user.firstname} ${user.lastname}`}
        </ModalHeader>
        <ModalBody>
          <ToolkitProvider
            keyField={'id'}
            columns={columns}
            bootstrap4={true}
            data={[
              {id: 1, text: t('admin.audit_view.add_vague')},
              {id: 2, text: t('common.visit_validation')},
              {id: 3, text: t('admin.audit_view.validated_visit_edition')}]}>
            {props => (
              <div>
                <BootstrapTable
                  bordered={false}
                  ref={ref => this.tableRef = ref}
                  classes={'bulk-selection'}
                  rowStyle={{cursor: 'pointer'}}
                  selectRow={{
                    onSelect: this.handleSelect,
                    onSelectAll: this.handleSelectAll,
                    selected: selected,
                    mode: 'checkbox',
                    clickToSelect: true,

                    selectionHeaderRenderer: (props) => {
                      return (
                        <RowSelectionHeaderItem
                          options={[
                            [
                              {
                                label: t('common.select_all'),
                                onClick: () => this.setState({selected: [1, 2, 3]})
                              },
                              {
                                label: t('common.unselect_all'),
                                onClick: () => this.setState({selected: []})
                              }
                            ],
                          ]}
                          {...props}/>
                      )
                    },
                    selectionRenderer: (props) => <RowSelectionItem {...props}/>
                  }}
                  {...props.baseProps}/>
              </div>
            )}
          </ToolkitProvider>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={this.reject}>
            {t('common.close')}
          </Button>
          <Button
            onClick={this.resolve}
            color="success">
            {t('common.confirm')}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export {EditAuditUserRights};
export default EditAuditUserRights;
