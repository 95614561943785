import React from "react";
import {ImportImageDiv} from "../common/ImportImageDiv";
import {createFirmSelectorById} from "../selectors/firm";
import {FormValidator} from "../../tools/formValidator/FormValidator";
import {connect} from "react-redux";
import {setAddModifyFirm} from "../../actions/modals";
import {FormFeedback, Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {addFirm, updateFirm} from "../../actions/orm/Firms";
import LoadingButton from "../common/LoadingButton";
import {tFunction} from "../tools/Translation";

const localFv = new FormValidator([
  {
    field: 'label',
    method: 'isEmpty',
    validWhen: false,
    message: tFunction('modals.label_specified')
  }
]);

const firmSelector = createFirmSelectorById();

@connect(({modals: {addModifyFirm: {currentId}}}) => ({
  currentId
}))
class AddModifyFirm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      closing: false,
      loading: false,
      validation: localFv.valid(),
      label: '',
      path: ''
    };

    const {currentId} = this.props;

    if (!!currentId){
      const firm = firmSelector({id: currentId});

      this.state = {
        ...this.state,
        firm,
        label: firm.label,
        path: firm.path
      }
    }
  }

  inputLabel = React.createRef();

  componentDidMount() {
    this.inputLabel.focus();
  }

  handleChange = (e) => {
    this.setState({[e.target.name] : e.target.value});
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const {label, path, firm} = this.state;
    const {currentId} = this.props;

    const validation = localFv.validate({
      label
    });

    if (!validation.isValid){
      this.setState({validation});
      return;
    }

    this.setState({loading: true});

    if (!!firm)
      await this.props.dispatch(updateFirm({
        id: firm.id,
        label,
        path
      }));
    else
      await this.props.dispatch(addFirm({
        label,
        path
      }));

    this.setState({loading: false});
    this.closing();
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setAddModifyFirm({
      open: false
    }));
  };

  render(){
    const {closing, loading, validation, label, path, firm} = this.state;
    const editMode = !!firm;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.closing}>
          {editMode ?
          `${t('common.modify')} ${firm.label}` : `${t('admin.enterprise.add_firm')}`}
        </ModalHeader>
        <Form onSubmit={this.handleSubmit}>
          <ModalBody>
            <FormGroup className="text-center">
              <ImportImageDiv
                name="path"
                src={path}
                onChange={this.handleChange}/>
            </FormGroup>
            <FormGroup>
              <Label>
                {t('common.label')}
              </Label>
              <Input
                invalid={validation.label.isInvalid}
                innerRef={(input) => (this.inputLabel = input)}
                value={label}
                onChange={this.handleChange}
                placeholder={t('common.label')}
                name="label"/>
              <FormFeedback>
                {validation.label.message}
              </FormFeedback>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              type='button'
              disabled={loading}
              onClick={this.closing}>
              {t('common.close')}
            </Button>
            <LoadingButton
              loading={loading}
              disabled={loading}
              color="success">
              {t('common.valid')}
            </LoadingButton>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export {AddModifyFirm};
export default AddModifyFirm;
