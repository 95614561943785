import {fk, attr, many, Model} from 'redux-orm';
import {_User, _Firm, _Client, _Prestataire, _Profile, _ZoneExploitation, _Site} from "../constants/orm";

export class User extends Model{
  static parse(data){
    delete data.type;

    if (!!data.clients)
      data.clients = data.clients.map(i => i.id);
    if (!!data.prestataires)
      data.prestataires = data.prestataires.map(i => i.id);
    if (!!data.zone_exploitations){
      data.scope_zones_exploitation = data.zone_exploitations.map(i => i.id);
      delete data.zone_exploitations;
    }
    if (!!data.sites){
      data.scope_sites = data.sites.map(i => i.id);
      delete data.sites;
    }
    return data;
  }

  static get fields(){
    return {
      id: attr(),
      email: attr(),
      firstname: attr(),
      lastname: attr(),
      phone: attr(),
      function: attr(),
      password: attr(),
      path: attr(),
      token: attr(),
      active: attr(),
      level: attr(),
      created: attr(),
      modified: attr(),
      client_id: fk(_Client, 'users'),
      default_client_id: fk(_Client, 'defaultUsers'),
      profil_id: fk(_Profile, 'users'),
      prestataire_id: fk(_Prestataire, 'users'),
      clients: many(_Client, 'extraUsers'),
      scope_zones_exploitation: many(_ZoneExploitation, 'extraUsers'),
      scope_sites: many(_Site, 'extraUsers'),
      prestataires: many(_Prestataire, 'extraUsers'),
      firm_id: fk(_Firm, 'users'),
      locale: attr(),
      jobtitle: attr()
    };
  }
}

User.modelName = _User;
