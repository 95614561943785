import React from "react";
import {Row, Col} from "reactstrap";
import {Historic} from "./SyntheticView/Historic"
import {I2CEvolution} from "./SyntheticView/I2CEvolution";
import {ThemesI2CTable} from "./SyntheticView/ThemesI2CTable";

class SyntheticView extends React.PureComponent {
  render(){
    const {passageLow, passageHigh} = this.props;

    return (
      <div>
        <Row>
          <Col>
            <Historic passageLow={passageLow} passageHigh={passageHigh}/>
          </Col>
          <Col>
            <ThemesI2CTable passageLow={passageLow} passageHigh={passageHigh}/>
          </Col>
        </Row>
        <Row class="mt-4">
          <Col>
            <I2CEvolution passageLow={passageLow} passageHigh={passageHigh}/>
          </Col>
        </Row>
      </div>
    )
  }
}

export {SyntheticView};
export default SyntheticView;
