import React from "react";
import {Bar, Radar} from "react-chartjs-2";
import {EvolTable} from "./EvolTable";
import {Input} from "reactstrap";
import {ocmDarkBlue, ocmBlue} from "../../../../constants/ui";
import {createItemI2cFetch} from "../../../tools/Filters/updateFunctions";
import Color from "color";
import {FRequest} from "@neqo34/filters";
import {createThemeRankingByIdSelector} from "../../../selectors/theme";

const themeRankingByIdSelector = createThemeRankingByIdSelector();

const updateFunc = createItemI2cFetch({
  withLabels: true,
  params:{
    lastVagueDiff: true,
    lastVagueValue: true
  },
  level: [1],
  labelModelName: 'Theme',
  by: 'questionnaireItem'
});

class ThemesI2CTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      displayType: 'table'
    }
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  render(){
    const {passageLow, passageHigh} = this.props;
    const {displayType} = this.state;

    return (
      <section className="card card-fluid m-0 flex-grow-1 d-flex flex-column">
        <header className="card-header d-flex align-items-center">
          <span class="flex-grow-1">{t('reporting.thematic_results')}</span>
          <Input
            bsSize="sm"
            type="select"
            onChange={this.handleChange}
            style={{width: 150}}
            name="displayType">
            <option value="table">{t('common.array')}</option>
            <option value="radar">{t('reporting.graphic')}</option>
          </Input>
        </header>
        <div className="card-body d-flex flex-column">
          <FRequest
            dynamicParameters={[
              "clients",
              "analyses",
              "vagues",
              "zones_exploitation",
              "prestataires"
            ]}
            updateFunc={updateFunc}>
            {(result, loading, error) => {
              if (loading && !result)
                return <div>{t('reporting.loading')}</div>;

              if (!result || !result.length)
                return <div class="text-center">{t('reporting.no_data')}</div>;

              if (displayType === 'radar'){
                const datas = result.map(r => {
                  return {
                    id: r.agg_ocm2_themes_level_1,
                    label: r.label,
                    lastValue: r.lastVagueValue,
                    currentValue: r.value,
                    evol: r.lastVagueDiff
                  }
                });

                let radarData = {
                  labels: [],
                  datasets: [{
                    data: [],
                    label: t('reporting.current_vague'),
                    backgroundColor: Color(ocmDarkBlue).alpha(.5).rgb().toString()
                  }, {
                    data: [],
                    label: t('reporting.previous_vague'),
                    backgroundColor: Color(ocmBlue).alpha(.5).rgb().toString()
                  }]
                };

                datas.forEach(d => {
                  radarData.labels.push(d.label);
                  radarData.datasets[0].data.push(d.currentValue || 0);
                  if (!!d.lastValue)
                    radarData.datasets[1].data.push(d.lastValue || 0);
                });

                const options = {
                  tooltips: {
                    callbacks: {
                      label: function (item, data){
                        return `${data.datasets[item.datasetIndex].label} : ${data.datasets[item.datasetIndex].data[item.index].toFixed(0)}%`;
                      }
                    }
                  },
                  maintainAspectRatio: false,
                  scale: {
                    ticks: {
                      suggestedMax: 100,
                      beginAtZero: true
                    }
                  }
                };

                return (
                  <Radar
                    width={400}
                    height={400}
                    data={radarData}
                    options={options}/>
                )
              } else {
                const datas = result.map(r => {
                  return {
                    id: r.id,
                    label: r.label,
                    currentValue: r.value,
                    evol: r.lastVagueDiff,
                    hideEvol: r.firstVague,
                    ranking: themeRankingByIdSelector({id: r.id})
                  }
                }).sort((a, b) => a.ranking - b.ranking);

                return (
                  <EvolTable
                    defaultSort={{dataField:'id', order:'asc'}}
                    datas={datas}
                    search={true}
                    progressBar={true}
                    passageLow={passageLow}
                    passageHigh={passageHigh}/>
                )
              }
            }}
          </FRequest>
        </div>
      </section>
    )
  }
}

export {ThemesI2CTable};
export default ThemesI2CTable;
