import {attr, fk, Model} from 'redux-orm';
import {_Questionnaire, _Audit, _ThemeQuestion, _QuestionnaireSection} from "../constants/orm";

export class Questionnaire extends Model{
  static parse(data){
    return data;
  }

  static get fields(){
    return {
      id: attr(),
      audit_id: fk(_Audit, 'questionnaires'),
      themes_question_id: fk(_ThemeQuestion, 'questionnaires'),
      title: attr(),
      label: attr(),
      faq: attr(),
      freeze: attr(),
      progre: attr(),
      ranking: attr(),
      type: attr(),
      ranging: attr(),
      objectif: attr(),
      tooltip: attr(),
      photo: attr(),
      answer: attr(),
      file: attr(),
      section_id: fk(_QuestionnaireSection, 'questionnaires')
    }
  }
}

Questionnaire.modelName = _Questionnaire;
