import React from "react";
import {DebounceInput} from "react-debounce-input";
import {InputGroup} from "reactstrap";
import {InputGroupAddon} from "reactstrap";
import {InputGroupText} from "reactstrap";
import {Input} from "reactstrap";

export const TableSearchInput = ({onChange, ...otherProps}) => {
  return (
    <InputGroup {...otherProps}>
      <InputGroupAddon addonType="prepend">
        <InputGroupText>
          <span className="oi oi-magnifying-glass"/>
        </InputGroupText>
      </InputGroupAddon>
      <DebounceInput
        minLength={0}
        element={Input}
        debounceTimeout={300}
        type="text"
        name="search"
        onChange={onChange}
        className="form-control"
        aria-label={t('common.search')}
        placeholder={t('common.search')}/>
    </InputGroup>
  )
};

export const CustomControlCheckbox = ({onChange, readOnly, disabled, className, checked, indeterminate, label, disableWrapContent, children}) => {
  return (
    <label className={`custom-control custom-checkbox ${className || ''}`}>
      <input type="checkbox"
             className={`custom-control-input ${!!indeterminate ? 'indeterminate' : ''}`}
             readOnly={readOnly}
             disabled={disabled}
             onChange={onChange}
             checked={checked}/>
      <div className={`custom-control-label ${!!disableWrapContent ? 'text-nowrap' : ''}`}>
        {!!children ? children : label}
      </div>
    </label>
  )
};
