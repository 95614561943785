import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {fetchOnSwitchClient} from '../../reducers/session';
import {logout} from "../../actions/session";
import {clientsSelector, userSelector} from './selectors';
import Notifications from './notifications';
import Messageries from './messages';
import {TOGGLE_SIDEMENU, startPending, stopPending} from "../../actions/ui";
import Responsive from "../../HOC/Responsive";
import PerfectScrollbar from 'react-perfect-scrollbar';
import {DebounceSearchInput} from "../common/DebounceSearchInput";
import {withRouter} from "react-router";
import {setCurrentClientId} from "../../actions/orm/Client";
import {updateUser} from "../../actions/orm/User";
import {userById} from "../../ormSelectors/user";
import ReactCountryFlag from "react-country-flag";
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';

@connect((state) => ({
  userId: state.session.userId,
  locale: !!userById(state.session.userId) ? userById(state.session.userId).locale : 'fr_FR',
  clients: clientsSelector(state),
  currentClientId: state.session.currentClientId,
  user: userSelector(state),
  showSideMenu: state.ui.showSideMenu
}), (dispatch) => {
  return {
    logOut: () => dispatch(logout()),
    switchClient: (clientId) => {
      dispatch(setCurrentClientId({client_id: clientId}));
    },
    toggleSideMenu: () => dispatch({type: TOGGLE_SIDEMENU, payload: true}),
    dispatch: dispatch
  }
})
@withRouter
@Responsive
class Header extends React.PureComponent{
  constructor(props) {
    super(props);
    this.state = {
      filterValue: '',
      locale: {
        label: this.props.locale === 'fr_FR' ? t('language.french') :
          this.props.locale === 'en_US' ? t('language.english') : t('language.spanish'),
        value: this.props.locale,
        code: this.props.locale === 'fr_FR' ? 'fr' : this.props.locale === 'en_US' ? 'en' : 'es'
      },
      locales: [
        {label: t('language.french'), value: 'fr_FR', code: 'fr'},
        {label: t('language.spanish'), value: 'es_ES', code: 'es'},
        // {label: t('language.english'), value: 'en_US', code: 'en'}
      ]
    }
  }

  changeLanguage = async (e) => {
    if (this.props.locale !== e.value) {
      await this.props.dispatch(updateUser({
        id: this.props.userId,
        locale: e.value
      }));
      i18n.changeLanguage(e.value);
      this.setState({locale: e});
      window.location.reload();
    }
  };

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  logout = async () => {
    await this.props.dispatch(logout());
  };

  handleToPdf = async () => {
    this.props.dispatch(startPending());
    try{
      const canvas = await html2canvas(document.querySelector("main.app-main"), {logging: false});
      const imgData = canvas.toDataURL('image/png');

      const pdf = new jsPDF({
        orientation: 'landscape',
        format: 'a2'
      });
      pdf.addImage(imgData, 'PNG', 0, 0);
      this.props.dispatch(stopPending());
      pdf.save(`${moment().format("DD_MM_YY")}${window.location.pathname}` + ".pdf");
    } catch(e){
      this.props.dispatch(stopPending());
      console.log(e);
    }
  };

  render(){
    const {filterValue} = this.state;
    const clients = this.props.clients
      .filter(client => {
        return client.label.toLowerCase().includes(filterValue.toLowerCase())
      })
      .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
      .map((client, index) => {
        const currentClientId = (this.props.currentClientId !== undefined ? this.props.currentClientId : 0);

        return (
          <a tabIndex="1"
             key={client.id}
             className={`dropdown-item align-items-center ${currentClientId === client.id ? 'active' : ''}`}
             onClick={() => this.props.switchClient(client.id)}>
            <div className="user-avatar d-flex" style={{backgroundColor: 'inherit', borderRadius: 5}}>
              {!!client.path ?
                <img src={'/upload/' + client.path}
                     style={{borderRadius: 5}}
                     alt=""/> :
                <i className="fa fa-sitemap"/>}
            </div>
            <div className="dropdown-item-body">
              <p className="text">
                {client.label}
              </p>
            </div>
          </a>
        )
      });

    const locales = this.state.locales.map((locale, index) => (
      <a tabIndex="1"
         key={locale.value}
         className={`dropdown-item align-items-center ${this.props.locale === locale.value ? 'active' : ''} d-inline-flex`}
         onClick={() => this.changeLanguage(locale)}>
        <div className="user-avatar d-flex mr-2" style={{backgroundColor: 'inherit', borderRadius: 5}}>
          <ReactCountryFlag code={locale.code} svg/>
        </div>
        <div className="dropdown-item-body">
          <p className="text">
            {locale.label}
          </p>
        </div>
      </a>
    ));

    const currentLogoPath = (this.props.clients[this.props.currentClientId] !== undefined ? this.props.clients[this.props.currentClientId].path : null);
    const currentClientLogo = (currentLogoPath != null ? <img src={'/upload/' + currentLogoPath} style={{height: '32px', marginLeft: '10px', marginRight: '10px'}}/> : '');
    const currentClientLabel = (this.props.clients[this.props.currentClientId] !== undefined ? this.props.clients[this.props.currentClientId].label : '');
    const {isMobile} = this.props;

    return (
      <header className={'app-header app-header-dark'}>
        <div className={'top-bar'}>
          <div className={"top-bar-brand"}>
            <Link to='/'>
              <img src='/img/logo-ocm.png' alt='ocmLogo' style={{height: 50}}/>
            </Link>
          </div>
          <div className="top-bar-list w-100">
            <div className={"top-bar-item px-2 d-md-none d-lg-none d-xl-none"}>
              <button onClick={this.props.toggleSideMenu} className={"hamburger hamburger-squeeze" + (this.props.showSideMenu ? ' has-active' : '')} type="button" data-toggle="aside"
                      aria-label="Menu" aria-controls="navigation">
                                <span className="hamburger-box">
                                    <span className="hamburger-inner"/>
                                </span>
              </button>
            </div>
            <div className="top-bar-item text-overflow-ellipsis">
              {currentClientLogo} {currentClientLabel}
            </div>
            <div className="top-bar-item top-bar-item-right px-0 d-flex flex-shrink-0">
              <ul className="header-nav nav">
                <li className="nav-item dropdown header-nav-dropdown">
                  <a onClick={this.handleToPdf} className="nav-link" tabIndex="1">
                    <span className="oi oi-print"/>
                  </a>
                </li>
                <li className="nav-item dropdown header-nav-dropdown">
                  <a className="nav-link" tabIndex="1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <ReactCountryFlag code={this.state.locale.code} svg/>
                  </a>
                  {!isMobile &&
                  <div className="dropdown-arrow"/>}
                  <div role="menu" className="dropdown-menu dropdown-menu-right p-0"
                       style={{width: isMobile ? '100%' : '', left: 'auto'}}>
                    {locales}
                  </div>
                </li>
                <Notifications isMobile={isMobile}/>
                <Messageries isMobile={isMobile}/>
                <li className="nav-item dropdown header-nav-dropdown">
                  <a className="nav-link" tabIndex="1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span className="oi oi-grid-three-up"/>
                  </a>
                  {!isMobile &&
                  <div className="dropdown-arrow"/>}
                  <div role="menu" className="dropdown-menu dropdown-menu-rich dropdown-menu-right"
                       style={{width: isMobile ? '100%' : '', left: 'auto'}}>
                    <h6 className="dropdown-header stop-propagation">
                      <DebounceSearchInput
                        bsSize="sm"
                        value={filterValue}
                        onChange={this.handleChange}
                        name="filterValue"/>
                    </h6>
                    <PerfectScrollbar className='dropdown-scroll has-scrollable'>
                      {clients}
                    </PerfectScrollbar>
                    <div className="dropdown-footer"/>
                  </div>
                </li>
              </ul>
              <div className="dropdown">
                <button className="btn-account d-flex" type="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                                    <span className="user-avatar">
                                      {!!this.props.user.path &&
                                      <img src={'/upload/' + this.props.user.path} alt=""/>}
                                    </span>
                  <span className="account-summary pr-lg-4 d-none d-lg-block">
                                        <span className="account-name">{(this.props.user.firstname !== undefined ? this.props.user.firstname : '') + ' ' + (this.props.user.lastname !== undefined ? this.props.user.lastname : '')}</span>
                                        <span className="account-description"/>
                                    </span>
                </button>
                <div className="dropdown-arrow dropdown-arrow-left"/>
                <div className="dropdown-menu">
                  <NavLink to={'/me'} class="dropdown-item">
                    <span className="dropdown-icon oi oi-person"/>
                    {t('common.parameters')}
                  </NavLink>
                  <a className="dropdown-item" tabIndex="1" onClick={this.logout} style={{cursor:'pointer'}}>
                    <span className="dropdown-icon oi oi-account-logout"/>
                    {t('common.logout')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default Header;
export {Header};
