import {fk, attr, Model} from 'redux-orm';
import {_ProgressPlan, _ProgressSite, _Site} from "../constants/orm";

export class ProgressSite extends Model{
    static parse(data){
        const {responsable, ...item} = data;
        return item;
    }

    static get fields(){
        return {
            id: attr(),
            progre_id: fk(_ProgressPlan, 'progressSite'),
            site_id: fk(_Site),
            progression: attr(),
            created: attr(),
            modified: attr()
        }
    }
}

ProgressSite.modelName = _ProgressSite;
