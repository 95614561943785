import api from "../../tools/api";
import orm from "../../orm";
import {_Analyse, _Brand} from "../../constants/orm";
import {deleteEntity, fetchClientReports, hydrateEntities, updateEntities} from "../orm";
import {
  setEditCoefficient,
  setEditCoefficientInTree,
  setRemoveAnalyse,
  setEditAnalyseVagues, setGenericConfirmation
} from "../modals";
import {getLabel} from "../../tools/helperFunctions";
import {actionCallsTracker} from "../actionsTracker";

export const fetchClientAnalyses = ({client_id}) => {
  return async (dispatch, getState) => {
    if (actionCallsTracker.isSaved('analyses', 'client', client_id))
      return;

    const [analyses] = await Promise.all([
      api.analyse.get({client_id}),
      dispatch(fetchClientReports({client_id}))
    ]);
    const reports = Object.keys(getState().db.Report.itemsById).map(r => getState().db.Report.itemsById[r]);

    analyses.map(a => {
      getLabel(a, reports, analyses)
    });

    dispatch(hydrateEntities({
      payload: [
        {type: _Analyse, entities: analyses}
      ]
    }));

    actionCallsTracker.save('analyses', 'client', client_id);
    return analyses;
  }
};

export const addAnalyse = ({label, client_id, users, sources, vagues, passage_low, passage_high}) => {
  return async (dispatch, getState) => {
    let analyse = null;

    async function createAnalyse() {
      analyse = await api.analyse.add({
        label,
        client_id,
        users,
        sources,
        vagues,
        passage_low,
        passage_high
      });

      await dispatch(hydrateEntities({
        payload: [
          {type: _Analyse, entities: [analyse]}
        ]
      }));

      const analyses = Object.keys(getState().db.Analyse.itemsById).map(r => getState().db.Analyse.itemsById[r]);
      const reports = Object.keys(getState().db.Report.itemsById).map(r => getState().db.Report.itemsById[r]);

      getLabel(analyse, reports, analyses, dispatch);
    }

    if (!vagues.length) {
      await dispatch(setGenericConfirmation({
        open: true,
        title: t('admin.analyse.analyse_creation'),
        body: t('admin.analyse.analyse_without_vague_question')
      })).then(async res => {
        return await createAnalyse();
      }).catch(e => {analyse = null; return e;});
    }
    else
      await createAnalyse();
    return analyse;
  }
};

export const editAnalyse = ({id, label, client_id, users, sources, vagues, remove, passage_low, passage_high}) => {
  return async (dispatch, getState) => {
    let r = 1;
    async function modifyAnalyse() {
      const analyse = await api.analyse.update({
        id,
        label,
        client_id,
        users,
        sources,
        vagues,
        passage_low,
        passage_high
      });

      await dispatch(updateEntities({
        payload: [
          {type: _Analyse, entities: [analyse]}
        ]
      }));

      const analyses = Object.keys(getState().db.Analyse.itemsById).map(r => getState().db.Analyse.itemsById[r]);
      const reports = Object.keys(getState().db.Report.itemsById).map(r => getState().db.Report.itemsById[r]);

      getLabel(analyse, reports, analyses, dispatch);
    }

    if (!vagues.length && !remove) {
      await dispatch(setGenericConfirmation({
        open: true,
        title: 'Modification d\'analyse',
        body: 'Êtes-vous sûr de vouloir enregistrer une analyse sans vague ?'
      })).then(async r => {
        return await modifyAnalyse();
      }).catch(e => {r = 0; return e;});
    }
    else
      await modifyAnalyse();
    return r;
  }
};

export const removeAnalyse = ({id}) => {
  return async (dispatch, getState) => {
    const session = orm.session(getState().db);
    const r = await api.analyse.remove({id});
    const analysesId = r.analyses;
    let yes = !!r.analyses;

    if (!!yes) {
      return dispatch(setRemoveAnalyse({
        open: true,
        analyses: analysesId.map(aId => session.Analyse.withId(aId).ref),
        analyseSelected: session.Analyse.withId(id).ref
      })).then(async r => {
        await Promise.all(analysesId.map(async a_id => {
          const analyse = session.Analyse.withId(a_id).ref;
          const sources = analyse.sources.filter(s => s.model !== 'Analyse' || Number(s.id) !== id);
          await dispatch(editAnalyse({id: analyse.id, sources: sources, vagues: [], remove: true}));
        }));

        await api.analyse.remove({id});
        await dispatch(deleteEntity({
          type: _Analyse,
          id
        }));
        return 1;
      }).catch(() => 0);
    }
    else
      dispatch(deleteEntity({
        type: _Analyse,
        id
      }));
    return 1;
  }
};

export const editCoefficient = ({datas}) => {
  return async (dispatch, getState) => {
    let response = 'rejected';

    await dispatch(setEditCoefficient({
      open: true,
      datas: datas
    })).then(r =>
      response = r.datas
    ).catch(e => e);

    return response;
  }
};

export const editCoefficientInTree = ({data}) => {
  return async (dispatch, getState) => {
    let response = 'rejected';

    await dispatch(setEditCoefficientInTree({
      open: true,
      data: data
    })).then(r =>
      response = r.data
    ).catch(e => e);

    return response;
  }
};

export const editAnalyseVagues = ({datas, analyse, currentVague}) => {
  return async (dispatch, getState) => {
    let response = {
      datas: null,
      currentVague: 0
    };

    await dispatch(setEditAnalyseVagues({
      open: true,
      datas: datas,
      analyse: analyse,
      currentVague: currentVague
    })).then(r =>
      response = {datas: r.datas, currentVague: r.currentVague}
    ).catch(e => response.currentVague = e.currentVague);

    return response;
  }
};
