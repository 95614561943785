import React from "react";
import {connect} from "react-redux";
import {LoadingButton} from "../common/LoadingButton";
import {setRemoveAnalyse} from "../../actions/modals";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Form} from "reactstrap";

@connect(({modals: {removeAnalyse: {analyses, analyseSelected, resolve, reject}}}) => ({
  resolve,
  reject,
  analyses,
  analyseSelected
}))
class RemoveAnalyse extends React.PureComponent {
  state = {
    closing: false,
    loading: false
  };

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({loading: true});

    this.props.resolve({yes: true});

    this.setState({loading: false});
    this.closing();
  };

  reject = () => {
    this.props.reject();
    this.closing();
  };

  close = () => {
    this.props.dispatch(setRemoveAnalyse({
      open: false
    }));
  };

  closing = () => {
    this.setState({closing: true});
  };

  render(){
    const {loading, closing} = this.state;
    const {analyses, analyseSelected} = this.props;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.closing}>{t('modals.remove_analyse_question')} {analyseSelected.label} ?</ModalHeader>
        <Form onSubmit={this.onSubmit}>
          <ModalBody>
            {t('modals.analyse_used_in')} <ul>{analyses.map((a, i) => <li key={i}>{a.label}</li>)}</ul>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary"
                    type="button"
                    disabled={loading}
                    onClick={this.reject}>
              {t('common.no')}
            </Button>
            <LoadingButton color="danger"
                           loading={loading}
                           disabled={loading}
                           type="submit">
              {t('common.yes')}
            </LoadingButton>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export {RemoveAnalyse};
