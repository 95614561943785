import React from "react";
import {Button} from "reactstrap";

export const LoadingButton = ({loading, children, ...props}) => {
  return (
    <Button {...props}>
      {!!loading &&
      <React.Fragment>
        <i className="fa fa-circle-notch fa-spin"/>
        {' '}
      </React.Fragment>}
      {children}
    </Button>
  )
};

export default LoadingButton;