import React from "react";
import {Col, Row} from "reactstrap";
import {_progressPlanState} from "../../../../constants/types";

class ProgressPlanDelay extends React.PureComponent {
  render(){
    const {vague: {progressPlanStateDistribution, latePdpNb}} = this.props;

    const notFinishedNb = progressPlanStateDistribution[_progressPlanState.new] + progressPlanStateDistribution[_progressPlanState.started];
    const delayedNb = latePdpNb;

    return (
      <div class="d-flex flex-column h-100">
        <div class="d-flex">
          <Row class={`w-100 ${!delayedNb ? 'justify-content-center' : ''}`}>
            <Col sm={6} class="d-flex align-items-center justify-content-center">
              <h6 class="m-0 text-center">
                Nombre de plans de progrès non finalisés
              </h6>
            </Col>
            {!!delayedNb &&
            <Col sm={6} class="d-flex align-items-center justify-content-center">
              <h6 class="m-0 text-center text-red">
                <u>
                  Dont hors délais
                </u>
              </h6>
            </Col>}
          </Row>
        </div>
        <div class="d-flex flex-grow-1">
          <Row class={`w-100 ${!delayedNb ? 'justify-content-center' : ''}`}>
            <Col sm={6} class="justify-content-center align-items-center d-flex">
              <h1>
                {notFinishedNb}
              </h1>
            </Col>
            {!!delayedNb &&
            <Col sm={6} class="justify-content-center align-items-center d-flex">
              <h1 class="text-red">
                {delayedNb}
              </h1>
            </Col>}
          </Row>
        </div>
      </div>
    )
  }
}

export {ProgressPlanDelay};
export default ProgressPlanDelay;
