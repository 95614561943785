import createSelector from "../../tools/createSelector";

export const createVisitByReportIdSelector = () => createSelector(
  (orm, {id}) => orm.Visit.all().toRefArray().filter(v => v.report_id === id)
);

export const createVisitByIdSelector = () => createSelector(
  (orm, {id}) => {
    const visit = orm.Visit.withId(id);

    return !!visit ? visit.ref : null;
  }
);

export const createVisitModelByIdSelector = () => createSelector(
  (orm, {id}) => {
    const visit = orm.Visit.withId(id);

    return !!visit ? visit : null;
  }
);

export const createVisitByResponseIdSelector = () => createSelector(
  (orm, {id}) => {
    const r = orm.Response.withId(id).ref;
    const visit = orm.Visit.all().toRefArray().find(v => v.zone_exploitation_id === r.zone_exploitation_id && v.report_id === r.report_id);

    return !!visit ? visit : null;
  }
);

export const createResponseByVisitIdSelector = () => createSelector(
  (orm, {id}) => {
    const v = orm.Visit.withId(id).ref;
    const response = orm.Response.all().filter(r => r.zone_exploitation_id === v.zone_exploitation_id && r.report_id === v.report_id);

    return !!response ? response.toRefArray() : null;
  }
);