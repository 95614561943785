import React from "react";
import _ from "lodash";
import {EvolTable} from "./SyntheticView/EvolTable";
import {Badge, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {I2CText} from "./TextRenderers";
import paginationFactory from "react-bootstrap-table2-paginator";
import {createItemI2cFetch} from "../../tools/Filters/updateFunctions";
import {CheckableTree, utils} from "@neqo34/neqo-tree";
import {TreeFilter, FRequest, withFilters} from "@neqo34/filters";
import {CustomControlCheckbox} from "@neqo34/ui";
import {QuestionBadge, SubThemeBadge, ThemeBadge} from "../../common/TreeItemsBadges";

const pagination = paginationFactory({
  paginationSize: 200,
  sizePerPageList: [
    {text: '200', value: 200},
    {text: '500', value: 500},
    {text: '1000', value: 1000},
  ]
});

const getChecked = (tree) => {
  let itemList = [];

  function getCheckedItems(tree, path = ''){
    tree.forEach(item => {
      if (!!item.checked){
        itemList.push(`${path}${item.label}`);
        return;
      }

      if (!!item.children && item.children.length)
        getCheckedItems(item.children, `${path}${item.label} => `)
    });
  }

  getCheckedItems(tree);

  return itemList;
};

@withFilters(({themesTree: {value, options}}) => ({
  value,
  options
}))
class PerimetreReminder extends React.PureComponent {
  render(){
    const {options} = this.props;
    const itemList = getChecked(options);

    return (
      <div>
        {itemList.map((item,idx) => (
          <div key={idx}>{item}</div>
        ))}
      </div>
    )
  }
}

class ZonesExploitationView extends React.PureComponent {
  vagueI2cUpdateFunc = createItemI2cFetch({
    params: {},
    by: 'vague'
  });

  zonesI2cUpdateFunc = createItemI2cFetch({
    params: {
      lastVagueValue: true,
      lastVagueDiff: true,
      order: {'value': 'desc'}
    },
    by: 'zoneExploitation',
    withLabels: true,
    labelModelName: 'ZoneExploitation'
  });

  handleThemesTreeCheck = (node, tree, handleTreeChange) => {
    const {type, level} = node;

    node.checked = !node.checked;
    const horizontalTree = flatTree(tree);

    if (!!node.checked){
      if (type === 'theme')
        horizontalTree.forEach(n => {
          if (!!n.checked && (n.type !== type || n.level !== level))
            n.checked = !n.checked;
        });
      else
        horizontalTree.forEach(n => {
          if (!!n.checked && n.type !== type)
            n.checked = !n.checked;
        });
    }

    utils.indeterminateTreeSetup(tree);
    handleTreeChange(tree);
  };

  themeTreeDecorators = {
    HeaderContent: ({node, listeners}) => {
      return (
        <CustomControlCheckbox
          className="d-inline-block"
          checked={!!node.checked}
          indeterminate={node.indeterminate}
          onChange={listeners.handleCheck}>
          {node.nodeModel === 'themeQuestion' ?
            <QuestionBadge/> :
            !!node.parent_id ?
              <SubThemeBadge/> :
              <ThemeBadge/>}
          {/*<Badge color="primary" title={t('common.theme')} class="badge-subtle">*/}
          {/*{node.nodeModel === 'themeQuestion' ? t('common.question_badge') : t('common.theme_badge')}*/}
          {/*</Badge>*/}
          &nbsp;
          {node.label}
        </CustomControlCheckbox>
      )
    }
  };

  render(){
    const {passageLow, passageHigh} = this.props;

    return (
      <div>
        <section className="card card-fluid m-0 flex-grow-1 d-flex flex-column">
          <header className="card-header">
            {t('reporting.global_results')}
          </header>
          <div className="card-body d-flex flex-column">
            <div class="mb-2">
              <UncontrolledDropdown direction="down" size="sm">
                <DropdownToggle caret>
                  {t('reporting.choose_perimeter')}
                </DropdownToggle>
                <DropdownMenu class="p-2" style={{maxHeight: 250, overflow: 'auto'}}>
                  <TreeFilter id="themesTree">
                    {({value, options}, handleChange, handleCheck, handleTreeChange) => (
                      <CheckableTree
                        data={options}
                        decorators={this.themeTreeDecorators}
                        indeterminateCheck={true}
                        customCheck={(node) => this.handleThemesTreeCheck(node, options, handleTreeChange)}
                        searchDisabled={true}
                      />
                    )}
                  </TreeFilter>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            <div>
              <PerimetreReminder/>
            </div>
            <div class="mb-2">
              <FRequest
                dynamicParameters={[
                  "clients",
                  "analyses",
                  "vagues",
                  "zones_exploitation",
                  "prestataires",
                  "themesTree"
                ]}
                updateFunc={this.vagueI2cUpdateFunc}>
                {(result, loading, error) => {
                  if (loading && !result)
                    return <div>{t('reporting.loading')}</div>;

                  if (!result || !result.length)
                    return <div class="text-center">{t('reporting.no_data')}</div>;

                  let value = null;

                  if (!!result && !!result.length)
                    value = result[0].value;

                  return (
                    <strong>
                      {t('reporting.global_i2c_selected_perimeter', {I2C: Labels.i2c})} : <I2CText passageLow={passageLow} passageHigh={passageHigh} value={value}/>
                    </strong>
                  )
                }}
              </FRequest>
            </div>
            <div>
              <FRequest
                dynamicParameters={[
                  "clients",
                  "analyses",
                  "vagues",
                  "zones_exploitation",
                  "prestataires",
                  "themesTree"
                ]}
                updateFunc={this.zonesI2cUpdateFunc}>
                {(result, loading, error, filters) => {
                  if (loading && !result)
                    return <div>{t('reporting.loading')}</div>;

                  if (!result || !result.length)
                    return <div class="text-center">{t('reporting.no_data')}</div>;

                  const datas = _.orderBy(result.map(r => {
                    return {
                      id: r.id,
                      type: 'zone_exploitation',
                      label: r.label,
                      currentValue: r.value,
                      evol: r.lastVagueDiff,
                      hideEvol: r.firstVague
                    }
                  }), ['label'], 'asc');
                  return (
                    <EvolTable
                      passageLow={passageLow}
                      passageHigh={passageHigh}
                      datas={datas}
                      tableProps={{
                        pagination: pagination
                      }}
                      progressBar={true}
                      search={true}
                      filters={filters}
                      />
                  )
                }}
              </FRequest>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export {ZonesExploitationView};
export default ZonesExploitationView;
