import React from 'react';


export class Select extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isInit: false,
            onOptionsChangeValue: false,
        };

        this.init = this.init.bind(this);
    }


    UNSAFE_componentWillReceiveProps(nextProps){
        if (!this.state.isInit && nextProps.externalInit) this.init();
        if(this.props.onOptionsChangeValue !== undefined && !this.isOptionsEquals(this.props.options, nextProps.options)){
            if(this.props.onOptionsChangeValue !== undefined) this.setState({onOptionsChangeValue: nextProps.onOptionsChangeValue});
        }
        if(this.props.onReset !== undefined && nextProps.onReset) $('#' + this.props.id).val('').trigger('change');
        if(this.props.onValue !== undefined){
            if(Array.isArray(nextProps.onValue) && !nextProps.onValue.equals(this.props.onValue) ||
                !Array.isArray(nextProps.onValue) && nextProps.onValue !== this.props.onValue) $('#' + this.props.id).val(nextProps.onValue).trigger('change');
        }
        this.props = nextProps;
    }

    isOptionsEquals(options1, options2){
        if(options1.length !== options2.length) return false;

        let find = false;
        options1.every(function(option1){
            find = false;
            options2.forEach(function(option2){
                if(option1.value === option2.value && option1.label === option2.label){
                    find = true;
                    return
                }
            });
            return find;
        });

        return find;
    }

    componentDidMount() {
        if (this.props.externalInit === undefined) this.init();
    }

    componentDidUpdate(){
        if(this.state.onOptionsChangeValue !== false){
            $('#' + this.props.id).val(this.state.onOptionsChangeValue).trigger('change');
            this.setState({onOptionsChangeValue: false});
        }
    }

    init() {
        $('#' + this.props.id).select2({
            placeholder: this.props.placeholder
        });

        if(this.props.defaultValue !== undefined && this.props.defaultValue !== null) $('#' + this.props.id).val(this.props.defaultValue).trigger('change');

        $('#' + this.props.id).on('select2:select select2:unselect', (e) => {
            try{
                this.props.handleChange(e, $('#' + this.props.id).val());
            } catch(err){
                console.log(err);
                let error;
                if(this.props.handleChange === undefined) error = '<b>INTERNAL ERROR : Select\'s parent component must bind a prop handleChange to proper work</b>';
                else error = '<b>' + err + '</b>';

                document.getElementById('root').innerHTML = error;
            }
        });

        this.setState({isInit: true});
    }

    render() {
        const options = this.props.options.map((option) => {
            return <option key={option.value} value={option.value}>{option.label}</option>
        });

        const multiple = (this.props.multiple ? 'multiple' : '');

        return (
            <div>
                <label className='form-control-label'>{this.props.label}</label>
                <select multiple={multiple} id={this.props.id} className='custom-select'>
                    {options}
                </select>
            </div>
        )
    }
}