import React from "react";
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {setModal} from "../../actions/modals";
import {connect} from "react-redux";

@connect(({ocmModals: {testModal: {text}}}) => ({
  text
}))
class TestModal extends React.PureComponent {
  constructor(props) {
    super(props);
    const {text} = this.props;

    this.state = {
      closing: false,
      text: !!text ? text : ''
    }
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.resolve(this.state.text);
    this.closing();
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setModal('testModal', false));
  };

  reject = () => {
//    this.props.reject();
    this.closing();
  };

  render(){
    const {closing, text} = this.state;

    return (
      <Modal isOpen={!closing} onClosed={this.close} toggle={this.reject}>
        <ModalHeader toggle={this.reject}>
          TestModal
        </ModalHeader>
        <Form onSubmit={this.handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label>
                TestModal:
              </Label>
              <Input name="text"
                     type="textarea"
                     onChange={this.handleChange}
                     placeholder={'TestModal'}
                     value={text}/>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              type='button'
              onClick={this.reject}>
              {t('common.close')}
            </Button>
            <Button
              color="primary">
              {t('common.done')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export {TestModal};
export default TestModal;