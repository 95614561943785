import createSelector from "../../tools/createSelector";

export const createAuditByIdSelector = () => {
  return createSelector(
    (orm, {id}) => {
      const audit = orm.Audit.withId(id);

      return !!audit ? audit.ref : null;
    }
  )
};

export const createAuditsFullByClientIdSelector = () => createSelector(
  (orm, {clientId}) => {
    const client = orm.Client.withId(clientId);
    return client.audits.toRefArray();
  }
);

export const createAuditsByClientIdSelector = () => createSelector(
  (orm, {clientId}) => {
    const client = orm.Client.withId(clientId);
    return client.audits.toRefArray().filter(a => !!a.scope);
  }
);

export const createAuditsByClientIdsSelector = () => createSelector(
  (orm, {clientIds}) => {
    const clients = clientIds.map(id => {
      return orm.Client.withId(id);
    });
    return clients.map(c => {
      return c.audits.toRefArray().filter(a => !!a.scope)
    }).flat();
  }
);

export const createAuditsModelByClientIdsSelector = () => createSelector(
  (orm, {clientIds}) => {
    const clients = clientIds.map(id => {
      return orm.Client.withId(id);
    });
    return clients.map(c => {
      return c.audits.toModelArray().filter(a => !!a.scope)
    }).flat();
  }
);

export const createAuditUsersSelector = () => createSelector(
  (orm, {auditId}) => {
    return orm.Audit.withId(auditId).users.toRefArray();
  }
);

export const createAuditsIdByReportsIdSelector = () => createSelector(
  (orm, {reportsId}) => {
    const audits = [];

    reportsId.map(rId => {
      const report = orm.Report.withId(rId).ref;

      if (!audits.includes(report.audit_id))
        audits.push(report.audit_id);
    });

    return audits;
  }
);

export const createAuditByReportIdSelector = () => createSelector(
  (orm, {reportId}) => {
    const report = orm.Report.withId(reportId);

    return !!report ? {
      ...report.audit_id.ref,
      zone_exploitation_ids: report.audit_id.zone_exploitations.toRefArray().map(z => z.id)
    } : null;
  }
);