import React from "react";

export const UserIconPlaceholder = ({size, src, style,className, ...other}) => {
  if (!src)
    return (
      <i class={`fa fa-user-o ${!!className ? className : ''}`} style={{fontSize: size, ...style}} {...other}/>
    );
  return (
    <img style={{width: size, height: size, borderRadius: 5, ...style}}
         className={className}
         src={`/upload/${src}`} {...other}/>
  )
};

export const BrandIconPlaceholder = ({size, src, style,className, ...other}) => {
  if (!src)
    return (
      <i class={`fa fa-store ${!!className ? className : ''}`} style={{fontSize: size, ...style}} {...other}/>
    );
  return (
    <img style={{width: size, height: size, borderRadius: 5, ...style}}
         className={className}
         src={`/upload/${src}`} {...other}/>
  )
};

export const OrganisationIconPlaceholder = ({size, src, style, className, ...other}) => {
  if (!src)
    return (
      <i class={`fa fa-sitemap ${!!className ? className : ''}`} style={{fontSize: size, ...style}} {...other}/>
    );
  return (
    <img style={{width: size, height: size, borderRadius: 5, ...style}}
         className={className}
         src={`/upload/${src}`} {...other}/>
  )
};

export const FirmIconPlaceholder = ({size, src, style, className, ...other}) => {
  if (!src)
    return (
      <i class={`fa fa-sitemap ${!!className ? className : ''}`} style={{fontSize: size, ...style}} {...other}/>
    );
  return (
    <img style={{width: size, height: size, borderRadius: 5, ...style}}
         className={className}
         src={src} {...other}/>
  )
};

export const ImportFileIconPlaceholder = ({src, onClick, ...otherProps}) => {
  if (!src)
    return (
      <div className="tile tile-lg cursor-pointer"
           title={t('common.import_image')}
           onClick={onClick}
           {...otherProps}>
        <span className="fa fa-upload"/>
      </div>
    );
  return (
    <img src={src.startsWith('data') ? src : `/upload/${src}`}
         onError={e => { e.currentTarget.src = "/img/no-image-available.png"; e.currentTarget.className = "noimage"; }}
         title={t('common.import_image')}
         style={{
           width:48,
           height: 48,
           borderRadius:'.3rem',
           cursor: 'pointer'
         }}
         onClick={onClick}
         {...otherProps}/>
  )
};