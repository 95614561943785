import React from "react";
import {setZoneExploitationsSurveyResponse} from "../../actions/modals";
import {connect} from "react-redux";
import {
  FormGroup,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  Nav,
  NavItem,
  NavLink,
  CustomInput
} from "reactstrap";
import {Tooltip} from "react-tippy";
import {ImageDiv} from "../common/ImageDiv";
import {store} from "../../store";
import {setViewImage} from "../../actions/modals";
import {FileInput} from "@neqo34/ui";
import {api} from "../../tools/api";
import update from "immutability-helper";
import createSelector from "../../tools/createSelector";
import {LoadingButton} from "../common/LoadingButton";
import {sendResponse} from "../../actions/orm/Response";
import {addAlert} from "../../actions/ui";
import {isQuestionResponseValid} from "../../tools/helperFunctions";
import {tFunction} from "../tools/Translation";

const isResponseValid = (question, response) => {
  if (!response)
    return false;
  return isQuestionResponseValid({
    answer: question.answer,
    photo: question.photo,
    file: question.file,
    value: response.value,
    valid: response.valid,
    photos: response.photos,
    files: response.files
  });
};

const PhotoPlaceholder = ({onRemove, className, src, ...otherProps}) => {
  return (
    <ImageDiv
      onClick={() => store.dispatch(setViewImage({
        open: true,
        url: src
      }))}
      src={src}
      className={`photo ${!!className ? className: ''}`}
      {...otherProps}>
      {!!onRemove &&
      <Button
        onClick={(e) => {e.stopPropagation();onRemove()}}
        className="remove-button btn-light btn-xs">
        <i className="fa fa-trash"/>
      </Button>}
    </ImageDiv>
  )
};

const historyResponseFormatter = ({value, type}) => {
  if (!value)
    return tFunction('modals.no_response');
  if (type === 'bool')
    return value === 100 ? tFunction('common.yes') : tFunction('common.no');
  else
    return `${value}%`;
};

const HistorySection = ({question, handleChange, historyResponses, historyResponseIndex}) => {
  const response = historyResponses[historyResponseIndex];

  return (
    <React.Fragment>
      <FormGroup>
        <Input
          type="select"
          value={historyResponseIndex}
          onChange={handleChange}
          name="historyResponseIndex">
          {historyResponses.map((r, idx) => {
            return (
              <option value={idx}>
                {t('common.vague')} {r.report.vague}
              </option>
            )
          })}
        </Input>
      </FormGroup>
      <FormGroup>
        <strong>
          {t('common.photos')} :
        </strong>
        {(!!response.photos && !!response.photos.length) ?
          <div className="photo-carousel">
            {response.photos.map((p, idx) => {
              return (
                <PhotoPlaceholder
                  key={idx}
                  src={p.path}/>
              )
            })}
          </div>:
          <div>{tFunction('modals.no_photos')}</div>}
      </FormGroup>
      <FormGroup>
        <strong>
          {tFunction('common.response')} :
        </strong>
        <div>
          {historyResponseFormatter({
            value: response.value,
            type: question.type
          })}
        </div>
      </FormGroup>
      <FormGroup>
        <strong>
          {tFunction('common.comment')} :
        </strong>
        <div>
          {(!!response.comment && !!response.comment.length) ?
            response.comment : tFunction('modals.no_comment')}
        </div>
      </FormGroup>
      <FormGroup>
        <strong>
          {tFunction('common.files')} :
        </strong>
        <div>
          {(!!response.files && response.files.length) ?
            <ul style={{margin: 0}}>
              {response.files.map((f, idx) => {
                return (
                  <li key={idx}>
                    <a href={f.path} target="_blank">
                      {f.filename}
                    </a>
                  </li>
                )
              })}
            </ul> : tFunction('modals.no_files_associated')}
        </div>
      </FormGroup>
    </React.Fragment>
  )
};

const ExampleSection = ({question: {question : {sample_photos, sample_files, sample_comment, sample_value}}}) => {
  return (
    <React.Fragment>
      <FormGroup>
        <strong>
          {tFunction('common.photos')} :
        </strong>
        {(!!sample_photos && !!sample_photos.length) ?
          <div className="photo-carousel">
            {sample_photos.map((p, idx) => {
              return (
                <PhotoPlaceholder
                  key={idx}
                  src={p.path}/>
              )
            })}
          </div>:
          <div>{tFunction('modals.no_example')}</div>}
      </FormGroup>
      <FormGroup>
        <strong>
          {tFunction('common.response')} :
        </strong>
        <div>
          {!!sample_value ? sample_value : tFunction('modals.no_example')}
        </div>
      </FormGroup>
      <FormGroup>
        <strong>
          {tFunction('common.comment')} :
        </strong>
        <div>
          {!!sample_comment ? sample_comment : tFunction('modals.no_example')}
        </div>
      </FormGroup>
      <FormGroup>
        <strong>
          {tFunction('common.files')} :
        </strong>
        <div>
          {(!!sample_files && sample_files.length) ?
            <ul style={{margin: 0}}>
              {sample_files.map((f, idx) => {
                return (
                  <li key={idx}>
                    <a href={f.path} target="_blank">
                      {f.filename}
                    </a>
                  </li>
                )
              })}
            </ul> : tFunction('modals.no_example')}
        </div>
      </FormGroup>
    </React.Fragment>
  )
};

const TypeInput = ({type, value, onChange, values}) => {
  if (type === 'bool')
    return (
      <Input
        onChange={onChange}
        value={value}
        name="value"
        type="select">
        <option value="">{t('modals.no_response')}</option>
        <option value={100}>{t('common.yes')}</option>
        <option value={0}>{t('common.no')}</option>
      </Input>
    );
  else if (type === 'select')
    return (
      <Input
        onChange={onChange}
        value={value}
        name="value"
        type="select">
        <option value="">{t('modals.no_response')}</option>
        {values.map(v => {
          return (
            <option value={v} key={v}>{v} %</option>
          )
        })}
      </Input>
    );
  else if (type === 'free')
    return (
      <Input
        type="number"
        placeholder={t('modals.your_response')}
        onChange={onChange}
        value={value}
        name="value"/>
    )
};

class CurrentSection extends React.PureComponent {
  render(){
    const {
      value,
      values,
      comment,
      files,
      photos,
      uploadPhoto,
      handleChange,
      handleValueChange,
      handleValidChange,
      removePhoto,
      uploadFile,
      removeFile,
      question,
      valid
    } = this.props;
    const {photo, file, answer} = question;

    return (
      <React.Fragment>
        <FormGroup>
          <strong>
            {t('common.photos')}
            {!!photo &&
            <span style={{fontSize:20}} className="text-red">*</span>}
            &nbsp;
            :
          </strong>
          <div class="photo-carousel">
            <FileInput
              inputProps={{
                onChange: uploadPhoto,
                name: 'path',
                accept: "image/*"
              }}
              title={t('modals.add_photo')}
              className='photo add-button d-flex align-items-center justify-content-center'>
              <i className="fa fa-plus-circle"/>
            </FileInput>
            {photos.map((p, idx) => {
              return (
                <PhotoPlaceholder
                  key={idx}
                  onRemove={() => removePhoto(idx)}
                  src={p.path}/>
              )
            })}
          </div>
          {photo &&
          <div
            className={`d-block ${!!photos.length ? 'valid-feedback' : 'invalid-feedback'}`}>
            <i className="fa fa-exclamation-circle fa-fw"/>
            {t('modals.photos_required')}
          </div>}
        </FormGroup>
        <FormGroup>
          <strong>
            {t('common.response')}
            {!!answer &&
            <span style={{fontSize: 20}} className="text-red">*</span>}
            &nbsp;
            :
          </strong>
          <TypeInput
            type={question.type}
            values={values}
            value={value}
            onChange={handleValueChange}/>
          {!answer &&
          <div className='d-block valid-feedback'>
            <i className="fa fa-exclamation-circle fa-fw"/>
            {t('modals.response_not_required')}
          </div>}
          {!!answer &&
          <div className={`d-block ${!value ? 'invalid-feedback' : 'valid-feedback'}`}>
            <i className="fa fa-exclamation-circle fa-fw"/>
            {t('modals.response_required')}
          </div>}
          {!answer &&
          <div class="mt-1">
            <CustomInput
              id={`question${question.id}`}
              name="valid"
              label={t('modals.not_answer')}
              checked={valid}
              onChange={handleValidChange}
              type="checkbox"/>
          </div>}
        </FormGroup>
        <FormGroup>
          <strong>
            {t('common.comment')} :
          </strong>
          <Input
            type="textarea"
            value={comment}
            onChange={handleChange}
            placeholder={t('modals.your_comment')}
            name="comment"/>
        </FormGroup>
        <FormGroup className="m-0">
          <strong>
            {t('modals.files_associated')}
            {!!file &&
            <span style={{fontSize:20}} className="text-red">*</span>}
            &nbsp;
            :
          </strong>
          <div>
            {!!files.length &&
            <ul style={{margin: 0}}>
              {files.map((f, idx) => {
                return (
                  <li key={idx}>
                    <a href={f.path} target="_blank">
                      {f.filename}
                    </a>
                    <Button
                      color="light"
                      size="xs"
                      onClick={(e) => {e.stopPropagation();removeFile(idx)}}
                      className="ml-1">
                      <i className="fa fa-times"/>
                    </Button>
                  </li>
                )
              })}
            </ul>}
            <FileInput
              inputProps={{
                onChange: uploadFile,
                name: 'path'
              }}
              size="sm"
              color="link"
              as={Button}>
              {t('common.add_file')}
            </FileInput>
          </div>
          {file &&
          <div
            className={`d-block ${!!files.length ? 'valid-feedback' : 'invalid-feedback'}`}>
            <i className="fa fa-exclamation-circle fa-fw"/>
            {t('modals.files_required')}
          </div>}
        </FormGroup>
      </React.Fragment>
    )
  }
}

const questionnaireSelector = createSelector(
  (orm, {id}) => {
    const question = orm.Questionnaire.withId(id);
    const questionRef = question.ref;

    const progressPlans = question
      .themes_question_id
      .progressPlans
      .toModelArray()
      .map(p => {
        return {
          ...p.ref,
          actions: p.actions.toRefArray(),
          report: p.report_id.ref
        }
      }).sort((a,b) => a.report.vague - b.report.vague);

    return {
      ...questionRef,
      lastProgressPlan: !!progressPlans.length ? progressPlans[0] : null,
      question: question.themes_question_id.question_id.ref
    };
  }
);

const responseSelector = createSelector(
  (orm, {questionnaire_id, zone_exploitation_id, report_id}) => {
    const response = orm.Response.get({
      questionnaire_id,
      zone_exploitation_id,
      report_id
    });

    return !!response ? response.ref : null;
  }
);

const historyResponsesSelector = createSelector(
  (orm, {questionnaire_id, zone_exploitation_id, report_id}) => {
    const report = orm.Report.withId(report_id);
    let responses = orm.Response.all().filter(r => {
      return (r.questionnaire_id === questionnaire_id && r.zone_exploitation_id === zone_exploitation_id && r.report_id !== report.id)
    }).toModelArray();

    responses = responses.filter(r => {
      return r.report_id.ref.audit_id === report.ref.audit_id
    });

    responses = responses.map(r => {
      return {
        ...r.ref,
        report: r.report_id.ref
      }
    });

    responses.sort((a,b) => a.report.ranking - b.report.ranking);

    return responses;
  }
);

const ProgressPlanIndicator = ({progressPlan}) => {
  if (!progressPlan)
    return (
      <Tooltip
        animation="scale"
        arrow={true}
        duration={100}
        unmountHTMLWhenHide={true}
        html={
          <div>
            {t('modals.no_pp')}
          </div>
        }
        position="bottom">
        <i className="fa fa-minus-circle text-red indicator"/>
      </Tooltip>
    );
  else
    return (
      <Tooltip
        animation="scale"
        arrow={true}
        duration={100}
        unmountHTMLWhenHide={true}
        html={
          <div class="text-left">
                <span class="text-center">
                 {t('modals.pp_exists')}
                </span>
            <br/>
            <br/>
            {progressPlan.label}
            <br/>
            <ul>
              {progressPlan.actions.map(a => {
                return (
                  <li key={a.id}>
                    {a.label}
                  </li>
                )
              })}
            </ul>
          </div>
        }
        position="bottom">
        <i className="fa fa-info-circle text-success indicator"/>
      </Tooltip>
    )
};

@connect(
  ({modals: {zoneExploitationsSurveyResponse : {questionnaire_id, zone_exploitation_id, report_id, questionnaireIdsList}}}) => ({
    questionnaire_id,
    zone_exploitation_id,
    report_id,
    questionnaireIdsList
  })
)
class ZoneExploitationsSurveyResponse extends React.PureComponent {
  constructor(props) {
    super(props);
    const {questionnaire_id, zone_exploitation_id, report_id, questionnaireIdsList} = this.props;

    const index = questionnaireIdsList.indexOf(questionnaire_id);
    const question = questionnaireSelector({id: questionnaire_id});

    this.state = {
      closing: false,
      loading: false,
      index: index,
      historyResponses: historyResponsesSelector({
        questionnaire_id,
        zone_exploitation_id,
        report_id
      }),
      values: !!question.ranging ? question.ranging : [],
      historyResponseIndex: 0,
      questionnaire_id: questionnaire_id,
      question: question,
      view: 'current',
      photos: [],
      files: [],
      value: '',
      valid: false,
      comment: ''
    };

    const response = responseSelector({questionnaire_id, zone_exploitation_id, report_id});

    if (!!response){
      this.state = {
        ...this.state,
        photos: response.photos,
        files: response.files,
        valid: response.valid,
        value: !!response.value ? response.value: '',
        comment: response.comment
      }
    }

    this.modalRef = null;
  }

  nextInvalidQuestion = () => {
    const {index} = this.state;
    const {questionnaireIdsList, zone_exploitation_id, report_id} = this.props;

    let newIndex = index;
    let questionnaire_id = null;
    let question = null;
    let response = null;

    while (++newIndex < questionnaireIdsList.length){
      questionnaire_id = questionnaireIdsList[newIndex];
      question = questionnaireSelector({id: questionnaire_id});
      response = responseSelector({questionnaire_id, zone_exploitation_id, report_id});
      if (!isResponseValid(question, response))
        break;
    }

    if (newIndex >= questionnaireIdsList.length){
      //revérifier depuis le 0 si toutes les questions sont valides
      newIndex = -1;
      while (++newIndex < questionnaireIdsList.length){
        questionnaire_id = questionnaireIdsList[newIndex];
        question = questionnaireSelector({id: questionnaire_id});
        response = responseSelector({questionnaire_id, zone_exploitation_id, report_id});
        if (!isResponseValid(question, response))
          break;
      }
      if (newIndex >= questionnaireIdsList.length){
        this.closing();
        this.props.dispatch(addAlert({
          text: t('modals.all_questions_have_response'),
          class: "alert-success",
          icon: "oi-check",
          title: ''
        }));
        return;
      }
    }

    this.setState({
      view:'current',
      questionnaire_id,
      question,
      index: newIndex,
      historyResponses: historyResponsesSelector({
        questionnaire_id,
        zone_exploitation_id,
        report_id
      }),
      historyResponseIndex: 0,
      values: !!question.ranging ? question.ranging : [],
      photos: !!response ? response.photos : [],
      files: !!response ? response.files : [],
      value: (!!response && !!response.value) ? response.value : '',
      comment: !!response ? response.comment : ''
    });
    this.modalRef.scrollTo(0,0);
  };

  changeQuestionIndex = (adder) => {
    const {index} = this.state;
    const {questionnaireIdsList, zone_exploitation_id, report_id} = this.props;
    const newIndex = index + adder;

    if (newIndex < 0 || newIndex >= questionnaireIdsList.length)
      return;

    const questionnaire_id = questionnaireIdsList[newIndex];
    const question = questionnaireSelector({id: questionnaire_id});
    const response = responseSelector({questionnaire_id, zone_exploitation_id, report_id});

    this.setState({
      view:'current',
      questionnaire_id,
      question,
      index: newIndex,
      historyResponses: historyResponsesSelector({
        questionnaire_id,
        zone_exploitation_id,
        report_id
      }),
      historyResponseIndex: 0,
      values: !!question.ranging ? question.ranging : [],
      photos: !!response ? response.photos : [],
      files: !!response ? response.files : [],
      value: (!!response && !!response.value) ? response.value : '',
      comment: !!response ? response.comment : ''
    });
    this.modalRef.scrollTo(0,0);
  };

  sendResponse = async () => {
    const {question, questionnaire_id, photos, files, value, comment,valid, index} = this.state;
    const {zone_exploitation_id, report_id, questionnaireIdsList} = this.props;

    this.setState({loading: true});
    await this.props.dispatch(sendResponse({
      questionnaire_id,
      zone_exploitation_id,
      report_id,
      photos,
      files,
      value : !!value ? parseInt(value) : null,
      valid,
      comment
    }));

    this.setState({loading: false});
    this.nextInvalidQuestion();
  };

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  handleValidChange = (e) => {
    this.setState({
      valid: e.target.checked,
      value: ''
    });
  };

  handleValueChange = (e) => {
    this.setState({
      value: e.target.value,
      valid: false
    });
  };

  removePhotos = (index) => {
    this.setState({photos: update(this.state.photos, {
        $splice: [[index, 1]]
      })});
  };

  uploadPhoto = (e) => {
    const file = e.target.files[0];
    const extension = file.name.split(".");
    let defaultName = "";
    extension.map((n, i) => {
      if (i !== extension.length - 1)
        defaultName += i === 0 ? n : `.${n}`;
    });
    const reader = new FileReader();
    reader.onload = async (e) => {
      const response = await api.file.upload({data: `extension:${extension[extension.length - 1]};${e.target.result}`});

      this.setState({
        photos: [
          ...this.state.photos,
          {
            path:response.path,
            filename: defaultName
          }
        ]});
    };
    reader.readAsDataURL(file);
  };

  removeFile = (index) => {
    this.setState({files: update(this.state.files, {
        $splice: [[index, 1]]
      })});
  };

  uploadFile = (e) => {
    const file = e.target.files[0];
    const extension = file.name.split(".");
    let defaultName = "";
    extension.map((n, i) => {
      if (i !== extension.length - 1)
        defaultName += i === 0 ? n : `.${n}`;
    });
    const reader = new FileReader();
    reader.onload = async (e) => {
      const response = await api.file.upload({data: `extension:${extension[extension.length - 1]};${e.target.result}`});

      this.setState({files: [
          ...this.state.files,
          {
            filename: defaultName,
            path: response.path
          }
        ]});
    };
    reader.readAsDataURL(file);
  };

  setView = (viewName) => {
    this.setState({view: viewName});
  };

  closing = () =>  {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setZoneExploitationsSurveyResponse({
      open: false
    }));
  };

  render(){
    const {closing, view, question, loading, index, historyResponses, historyResponseIndex} = this.state;
    const {questionnaireIdsList} = this.props;
    const {question:{faq: basicFaq}} = question;
    const {faq, lastProgressPlan} = question;
    const exampleExists = ((!!question.question.sample_photos && !!question.question.sample_photos.length) ||
      (!!question.question.sample_files && !!question.question.sample_files.length) ||
      !!question.question.sample_value ||
      !!question.question.sample_comment);

    return (
      <Modal
        id="SurveyResponseModal"
        isOpen={!closing}
        innerRef={ref => this.modalRef = ref}
        onClosed={this.close}>
        <ModalHeader toggle={this.closing}>
          <ProgressPlanIndicator progressPlan={lastProgressPlan}/>
          <span class="d-flex align-items-center">
            <i
              onClick={() => this.changeQuestionIndex(-1)}
              className="fa fa-angle-left mr-1 navigation-button"/>
            <span class="survey-steps">
              {index + 1}/{questionnaireIdsList.length}
            </span>
            <i
              onClick={() => this.changeQuestionIndex(1)}
              className="fa fa-angle-right ml-1 navigation-button"/>
          </span>
          <div className="indicator"/>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <span dangerouslySetInnerHTML={{__html: question.question.label}}>
            </span>
            &nbsp;
            {(!!faq || !!basicFaq) &&
            <Tooltip
              animation="scale"
              arrow={true}
              duration={100}
              unmountHTMLWhenHide={true}
              html={<div class="text-left" dangerouslySetInnerHTML={{__html: !!faq ? faq : basicFaq}}/>}
              position="top">
              <i className="fa fa-info-circle mr-1"/>
            </Tooltip>}
          </FormGroup>
          <FormGroup>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className="cursor-pointer"
                  onClick={() => this.setView('current')}
                  active={view === 'current'}>
                  {t('common.in_progress')}
                </NavLink>
              </NavItem>
              {!!historyResponses.length  &&
              <NavItem>
                <NavLink
                  className="cursor-pointer"
                  onClick={() => this.setView('history')}
                  active={view === 'history'}>
                  {t('modals.historic')}
                </NavLink>
              </NavItem>}
              {exampleExists &&
              <NavItem>
                <NavLink
                  className="cursor-pointer"
                  onClick={() => this.setView('example')}
                  active={view === 'example'}>
                  {t('modals.example')}
                </NavLink>
              </NavItem>}
            </Nav>
          </FormGroup>
          {view === 'current' &&
          <CurrentSection
            question={question}
            files={this.state.files}
            photos={this.state.photos}
            value={this.state.value}
            values={this.state.values}
            comment={this.state.comment}
            valid={this.state.valid}
            handleChange={this.handleChange}
            handleValueChange={this.handleValueChange}
            handleValidChange={this.handleValidChange}
            removePhoto={this.removePhotos}
            removeFile={this.removeFile}
            uploadFile={this.uploadFile}
            uploadPhoto={this.uploadPhoto}/>}
          {(view === 'example' && exampleExists) &&
          <ExampleSection question={question}/>}
          {view === 'history' &&
          <HistorySection
            question={question}
            handleChange={this.handleChange}
            historyResponseIndex={historyResponseIndex}
            historyResponses={historyResponses}/>}
        </ModalBody>
        {view === 'current' &&
        <ModalFooter>
          <LoadingButton
            color="success"
            loading={loading}
            disabled={loading || (!this.state.valid && !this.state.value)}
            onClick={this.sendResponse}>
            {t('modals.next_question')}
          </LoadingButton>
        </ModalFooter>}
      </Modal>
    )
  }
}

export {ZoneExploitationsSurveyResponse};
export default ZoneExploitationsSurveyResponse;
