import createSelector from "../tools/createSelector";
import {getOrm} from "../redux";

export const themesByParent = createSelector(
  (orm, theme) => {
    if(theme !== null) return orm.Theme.all().filter(t => t.parent_id === theme.id);
    return null;
  }
);

export const themeById = (themeId) => {
  if (getOrm().Theme.idExists(themeId))
    return getOrm().Theme.withId(themeId);
  return null;
};

export const themesByIds = createSelector(
  (orm, themeIds) => {
    if(themeIds !== null)
      return themeIds.map(id => themeById(id));
    return null;
  }
);

export const getThemeLevel = createSelector(
  (orm, {id}) => {
    let theme = orm.Theme.withId(id);
    let lvl = 0;

    while (!!theme.parent_id){
      lvl++;
      theme = theme.parent_id;
    }

    return lvl + 1;
  }
);
