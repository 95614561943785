import {fk, attr, Model} from 'redux-orm';
import {_Notification, _User, _ProgressPlan,_ProgressClient, _ZoneExploitation, _Client} from "../constants/orm";

export class Notification extends Model {

  static parse(data){

    return data;
  }

  static get fields(){
    return {
      id: attr(),
      cible: attr(),
      model: attr(),
      title: attr(),
      client_id: fk(_Client),
      progres_site_id: fk(_ProgressPlan),
      progress_client_id: fk(_ProgressClient),
      progres_zone_exploitation_id: fk(_ZoneExploitation),
      message: attr(),
      user_id: fk(_User, 'sender'),
      user2_id: fk(_User, 'receiver'),
      created: attr(),
      read: attr(),
      type: attr(),
      object_id: attr(),
      module_label: attr()
    }
  }
}

Notification.modelName = _Notification;

export default Notification;
