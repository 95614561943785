import createSelector from "../../tools/createSelector";

export const createMessagesSelector = () => {
  return createSelector(
    (orm, {user_id}) => {
      const messages = orm.Message.all().toModelArray().filter(message => {
        const author_id = message.progre_zone_exploitation_id.progre_id ?
          message.progre_zone_exploitation_id.progre_id.ref.author_id :
          message.progre_zone_exploitation_id.progre.author_id;

        return ((message.progre_zone_exploitation_id.ref.user_id === user_id || author_id === user_id)
          && message.ref.user_id !== user_id)
      }).map(message => {
        const progre = message.progre_zone_exploitation_id.progre_id ?
          message.progre_zone_exploitation_id.progre_id.ref :
          message.progre_zone_exploitation_id.progre;

        return ({
          ...message.ref,
          sender: message.progre_zone_exploitation_id.ref.user_id === user_id ?
            progre.author_id : message.progre_zone_exploitation_id.ref.user_id,
          title: progre.label,
          progreId: progre.id,
          zoneExploitationId: message.progre_zone_exploitation_id.ref.zone_exploitation_id,
        })
      });

      return !!messages ? messages : null;
    }
  )
};