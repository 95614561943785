import React, {lazy, Suspense} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as _Modules from '../constants/modules';
import A04 from './static/404';
import NoScope from './static/NoScope';
import NoPermissions from './static/NoPermissions';
import Alerts from './default/alerts';
import {TOGGLE_SIDEBAR, TOGGLE_SIDEMENU} from "../actions/ui";
import {getRoute} from "../constants/modules";
import {Rep_MonSit} from "../constants/modules";
import testComponent from "./testComponent";
import {resolvePermission} from "../constants/modules";
import {ConnectedSpinLoader} from './default/spinLoader';


import Edit from './bank/ProgressPlanView/monitoring/edit';
import Browse from "./bank/ProgressPlanView/monitoring/browse";
// import Edit from './progressPlan/monitoring/edit';
// import Browse from "./progressPlan/monitoring/browse";
//const Create = lazy(() => import('./progressPlan/creation/main'));
const Create = lazy(() => import('./bank/ProgressPlanView/creation/main'));
const Model = lazy(() => import('./bank/ProgressPlanView/model'));
const MonitoringSingle = lazy(() => import('./bank/ProgressPlanView/monitoring/single/main'));
const ProfileSettings = lazy(() => import('./bank/ProfileSettingsView'));
const Organisations = lazy(() => import('./bank/OrganisationsView/index'));
const Brands = lazy(() => import('./bank/BrandsView'));
const EnterpriseView = lazy(() => import('./bank/EnterprisesView'));
// const Activities = lazy(() => import('./default/activities'));
const UserProfiles = lazy(() => import('./bank/ProfilRightsView/index'));
const UsersView = lazy(() => import('./bank/UsersView/index'));
const TaxonomiesView = lazy(() => import('./bank/TaxonomiesView/index'));
const CalendarView = lazy(() => import('./bank/CalendarView/index'));
const AuditEditView = lazy(() => import('./bank/AuditEditView/index'));
const SurveyQuestionsFillin = lazy(() => import('./bank/VisitsView'));
const ThemesQuestionsVIew = lazy(() => import('./bank/ThemesQuestionsView/index'));
const AuditsView = lazy(() => import('./bank/AuditsView/index'));
const AnalysesView = lazy(() => import('./bank/AnalysesView/index'));

import Reporting from "./bank/Reporting/index";
import MonoReporting from "./bank/MonoReporting/index";

const mapStateToProps = state => {
  return {
    clientsId: state.session.clientsId,
    sessionRights: state.session.rights,
    isInit: state.session.isInit,
    hasSidebar: state.ui.hasSidebar,
    showSidebar: state.ui.showSidebar,
    showSideMenu: state.ui.showSideMenu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSidebar: () => dispatch({type: TOGGLE_SIDEBAR, payload: false}),
    toggleSideMenu: () => dispatch({type: TOGGLE_SIDEMENU, payload: false}),
  }
};

class Main extends React.PureComponent{
  constructor(props){
    super(props);
    window.scrollTo(0, 0);

    //fetch the session on each redirection to be sure connection is not timed out
    // this.props.history.listen((location, action) => {
    //   //reset scrollbar
    //   window.scrollTo(0, 0);
    // });
  }

  render(){
    const {clientsId, sessionRights} = this.props;
    const rights = Object.values(sessionRights);

    let hasSidebarClass = '';
    let routes = '';
    if(this.props.hasSidebar)
      hasSidebarClass += ' has-sidebar has-sidebar-expand-xl';
    if(this.props.showSidebar)
      hasSidebarClass += ' has-sidebar-open';

    switch(true){
      case clientsId.length === 0:
        routes = <Route component={NoScope}/>;
        break;

      case rights.length === 0:
        routes = <Route component={NoPermissions}/>;
        break;

      default:

        routes = [
          <Route key={0} exact path='/' component={Landing}/>,

          resolvePermission(_Modules.ProPla_Cre, sessionRights) ?
            <Route key={1} exact path={_Modules.getRoute(_Modules.ProPla_Cre)} component={Create}/> : null,

          resolvePermission(_Modules.ProPla_Mon_Edi, sessionRights) ?
            <Route key={2} exact path={_Modules.getRoute(_Modules.ProPla_Mon_Edi) + _Modules.modules.ProPla_Mon_Sin.routeOptions} component={Edit}/> : null,

          resolvePermission(_Modules.ProPla_Mon, sessionRights) ?
            <Route key={3} exact path={_Modules.getRoute(_Modules.ProPla_Mon) + _Modules.modules.ProPla_Mon.routeOptions} component={Browse}/> : null,

          resolvePermission(_Modules.ProPla_Mon_Sin, sessionRights) ?
            <Route key={4} path={`${_Modules.getRoute(_Modules.ProPla_Mon_Sin)}${_Modules.modules.ProPla_Mon_Sin.routeOptions}`} component={MonitoringSingle}/> : null,

          resolvePermission(_Modules.ProPla_Mod, sessionRights) ?
            <Route key={5} exact path={_Modules.getRoute(_Modules.ProPla_Mod) + _Modules.modules.ProPla_Mod.routeOptions} component={Model}/> : null,

          resolvePermission('Adm_Org', sessionRights) ?
            <Route key={6} path={_Modules.getRoute('Adm_Org') + _Modules.modules.Adm_Org.routeOptions} component={Organisations}/> : null,

          resolvePermission('Adm_Ent', sessionRights) ?
            <Route key={7} path={_Modules.getRoute('Adm_Ent') + _Modules.modules.Adm_Ent.routeOptions} component={EnterpriseView}/> : null,

          resolvePermission('Adm_ProUse', sessionRights) ?
            <Route key={8} path={_Modules.getRoute('Adm_ProUse') + _Modules.modules.Adm_ProUse.routeOptions} component={UserProfiles}/> : null,

          resolvePermission('Adm_Use', sessionRights) ?
            <Route key={9} path={_Modules.getRoute('Adm_Use') + _Modules.modules.Adm_Use.routeOptions} component={UsersView}/> : null,

          resolvePermission('Adm_Tax', sessionRights) ?
            <Route key={10} path={_Modules.getRoute('Adm_Tax') + _Modules.modules.Adm_Tax.routeOptions} component={TaxonomiesView}/>  : null,

          resolvePermission('Adm_Bra', sessionRights) ?
            <Route key={11} path={_Modules.getRoute('Adm_Bra') + _Modules.modules.Adm_Bra.routeOptions} component={Brands}/> : null,

          resolvePermission('Aud_Sui', sessionRights) ?
            <Route key={12} path={_Modules.getRoute('Aud_Sui') + _Modules.modules.Aud_Sui.routeOptions} component={CalendarView}/> : null,

          resolvePermission('Aud_Vis', sessionRights) ?
            <Route key={13} path={_Modules.getRoute('Aud_Vis') + _Modules.modules.Aud_Vis.routeOptions} component={SurveyQuestionsFillin}/> : null,

          resolvePermission('Aud', sessionRights) ?
            <Route key={14} path={'/audits/:id'} component={AuditEditView}/> : null,

          resolvePermission('Adm_The', sessionRights) ?
            <Route key={15} path={'/admin/themes/:model?/:id?'} component={ThemesQuestionsVIew}/> : null,

          resolvePermission('Adm_Aud', sessionRights) ?
            <Route key={16} path={'/admin/audits/:id?'} component={AuditsView}/> : null,

          resolvePermission('Adm_Ana', sessionRights) ?
            <Route key={17} path={_Modules.getRoute('Adm_Ana') + _Modules.modules.Adm_Ana.routeOptions} component={AnalysesView}/> : null,

          resolvePermission('Rep_MulSit', sessionRights) ?
            <Route key={18} path={_Modules.getRoute('Rep_MulSit') + _Modules.modules.Rep_MulSit.routeOptions} component={Reporting}/> : null,

          resolvePermission('Rep_MonSit', sessionRights) ?
            <Route key={19} path={_Modules.getRoute('Rep_MonSit') + _Modules.modules.Rep_MonSit.routeOptions} component={MonoReporting}/> : null,

          <Route key={20} component={A04}/>];
        break;
    }

    return (
      <React.Fragment>
        <main className='app-main bordered-scrollbar'>
          <Alerts />
          <div className='wrapper'>
            <div className={'page' + hasSidebarClass}>
              <div onClick={this.handleBackdropClick} className={'sidebar-backdrop'}/>
              <Route path="/test" component={testComponent}/>
              <Suspense fallback={<ConnectedSpinLoader isPending={true}/>}>
                <Switch>
                  {/* <Route exact path={'/activities'} component={Activities}/> */}

                  <Route path={'/me'} component={ProfileSettings}/>

                  {routes}
                </Switch>
              </Suspense>
            </div>
          </div>
        </main>
        <div onClick={this.props.toggleSideMenu} className={'aside-backdrop ' + (this.props.showSideMenu ? 'show' : '')}/>
        {/*<Footer />*/}
      </React.Fragment>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));

@connect(({session: {rights}}) => ({
  rights
}))
class Landing extends React.Component {
  render(){
    const {rights} = this.props;

    switch (true){
      case resolvePermission(Rep_MonSit, rights):
        return <Redirect to={getRoute(Rep_MonSit)} />;
      case resolvePermission(Rep_MonSit, rights):
        return <Redirect to={getRoute(Rep_MonSit)} />;
    }

    const rightsArray = Object.keys(rights);

    if (!rightsArray.length)
      return <Redirect to={'/me/account'}/>;

    return <Redirect to={getRoute(rightsArray[0])}/>;
  }
}
