import React from "react";
import Flatpickr from "react-flatpickr";
import {French} from "flatpickr/dist/l10n/fr";

class RangeDateFlatpickrInput extends React.PureComponent {
  handleChange = ([startDate, endDate]) => {
    const {onChange, name} = this.props;

    if (!!startDate && !!endDate){
      const start = startDate.getTime();
      const end = endDate.getTime();

      if (!!onChange)
        onChange({target: {name, value: [start, end]}});
    } else if (!startDate && !endDate){
      if (!!onChange)
        onChange({target: {name, value: ['', '']}});
    }
  };

  render(){
    const {value, onChange, className, ...otherProps} = this.props;

    return (
      <Flatpickr
        value={value}
        onChange={this.handleChange}
        className={`form-control ${className || ''}`}
        placeholder={t('visits_view.choose_period')}
        options={{
          mode: "range",
          dateFormat: "d-m-Y",
          locale: French
        }}
        {...otherProps}/>
    )
  }
}

export {RangeDateFlatpickrInput};
export default RangeDateFlatpickrInput;
