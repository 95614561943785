import store from '../store';
import pathToRegexp from 'path-to-regexp';
import {getT, tFunction} from "../components/tools/Translation";

//export const Home = 'Home';
export const Act = 'Act';
export const ProPla = 'ProPla';
export const ProPla_Cre = 'ProPla_Cre';
export const ProPla_Mon_Edi = 'ProPla_Mon_Edi';
export const ProPla_Mon = 'ProPla_Mon';
export const ProPla_Mon_Sin = 'ProPla_Mon_Sin';
export const ProPla_Mon_Sin_Rea = 'ProPla_Mon_Sin_Rea';
export const ProPla_Mon_Sin_Wri = 'ProPla_Mon_Sin_Wri';
export const ProPla_Mod = 'ProPla_Mod';
export const Ban = 'Ban';
export const Ban_Que = 'Ban_Que';
export const Ban_The = 'Ban_The';
export const Adm = 'Adm';
export const Adm_Ent = 'Adm_Ent';
export const Adm_Org = 'Adm_Org';
export const Adm_Org_Cli = 'Adm_Org_Cli';
export const Adm_Org_CliPre = 'Adm_Org_CliPre';
export const Adm_Org_Pre = 'Adm_Org_Pre';
export const Adm_Use = 'Adm_Use';
export const Adm_ProUse = 'Adm_ProUse';
export const Adm_Tax = 'Adm_Tax';
export const Adm_Bra = 'Adm_Bra';
export const Adm_The = 'Adm_The';
export const Adm_The_Cli = 'Adm_The_Cli';
export const Adm_The_Gen = 'Adm_The_Gen';
export const Adm_Ana = "Adm_Ana";
export const Aud = 'Aud';
export const Aud_Sui = "Aud_Sui";
export const Aud_Vis = "Aud_Vis";
export const Adm_Aud = "Adm_Aud";
export const Rep = "Rep";
export const Rep_MulSit = "Rep_MulSit";
export const Rep_MonSit = "Rep_MonSit";

//Immutable module hierarchy
export const modules = Object.freeze({ //if isView = false && route = null => header on sidebar, isView = true accessible element on sidebar
                                       //Home: {id: Home, name: 'Home', route: '/', routeOptions: '', isView: true, icon: 'oi-home'},
  Adm: {id: Adm, tradName: 'sidebar.administration', name: tFunction('sidebar.administration'), route: '/admin', routeOptions: '', isView: false, icon: 'oi oi-cog'},
  Adm_Ent: {id: Adm_Ent, tradName: 'common.clientPrestataire', name: tFunction('common.clientPrestataire'), route: '/entreprise', routeOptions: '', isView: true, icon: ''},
  Adm_Org: {id: Adm_Org, tradName: 'common.organisations', name: tFunction('common.organisations'), route: '/organisation', routeOptions: '', isView: true, icon: ''},
  Adm_Use: {id: Adm_Use, tradName: 'common.users', name: tFunction('common.users'), route: '/users', routeOptions: '', isView: true, icon: ''},
  Adm_ProUse: {id: Adm_ProUse, tradName: 'sidebar.users_profiles', name: tFunction('sidebar.users_profiles'), route: '/users-profils', routeOptions: '', isView: true, icon: ''},
  Adm_Bra: {id: Adm_Bra, tradName: 'common.brand', name: tFunction('common.brand'), route: '/brands', routeOptions: '', isView: true, icon: ''},
  Adm_Tax: {id: Adm_Tax, tradName: 'common.taxonomies', name: tFunction('common.taxonomies'), route: '/taxonomies', routeOptions: '', isView: true, icon: ''},
  Adm_The: {id: Adm_The, tradName: 'sidebar.question_bank', name: tFunction('sidebar.question_bank'), route: '/themes', routeOptions: '', isView: true, icon: ''},
  Adm_The_Cli: {id: Adm_The_Cli, tradName: 'sidebar.client_themes', name: tFunction('sidebar.client_themes'), route: '', routeOptions:'', isView: false, icon: ''},
  Adm_The_Gen: {id: Adm_The_Gen, tradName: 'sidebar.generic_themes', name: tFunction('sidebar.generic_themes'), route: '', routeOptions:'', isView: false, icon: ''},
  Adm_Aud: {id: Adm_Aud, tradName: 'sidebar.audits', name: tFunction('sidebar.audits'), route: '/audits', routeOptions: '/:id?', isView: true, icon: ''},
  Adm_Org_Cli: {id: Adm_Org_Cli, tradName: 'common.client', name: tFunction('common.client'), route: '/client', routeOptions: '/:id', isView: false, icon: ''},
  Adm_Org_Pre: {id: Adm_Org_Pre, tradName: 'common.prestataire', name: tFunction('common.prestataire'), route: '/prestataire', routeOptions: '/:id', isView: false, icon: ''},
  Adm_Org_CliPre: {id: Adm_Org_CliPre, tradName: 'sidebar.prestataires_liaisons', name: tFunction('sidebar.prestataires_liaisons'), route: '', routeOptions: '', isView: false, icon: ''},
  Adm_Ana: {id: Adm_Ana, tradName: 'sidebar.analyses', name: tFunction('sidebar.analyses'), route: '/analyses', routeOptions: '', isView: true, icon: ''},
  Aud: {id: Aud, tradName: 'sidebar.audits', name: tFunction('sidebar.audits'), route: '/audits', routeOptions: '', isView: false, icon:'oi oi-calendar'},
  Aud_Vis: {id: Aud_Vis, tradName: 'sidebar.my_rapports', name: tFunction('sidebar.my_rapports'), route: '/visits', routeOptions: '', isView: true, icon: ''},
  Aud_Sui: {id: Aud_Sui, tradName: 'sidebar.browse', name: tFunction('sidebar.browse'), route: '/follow', routeOptions: '', isView: true, icon: ''},
  Rep: {id: Rep, tradName: 'common.Reporting', name: tFunction('common.Reporting'), route: '/reporting', routeOptions: '', isView: false, icon:'oi oi-bar-chart'},
  Rep_MonSit: {id: Rep_MonSit, tradName: 'sidebar.mono_sites', name: tFunction('sidebar.mono_sites'), route: '/mono', routeOptions: '/:section?', isView: true, icon:''},
  Rep_MulSit: {id: Rep_MulSit, tradName: 'sidebar.multi_sites', name: tFunction('sidebar.multi_sites'), route: '/multi', routeOptions: '/:section?', isView: true, icon:''},
  ProPla: {id: ProPla, tradName: 'sidebar.progress_plan', name: tFunction('sidebar.progress_plan'), route: '/progress-plan', routeOptions: '', isView: false, icon: 'oi oi-clock'},
  ProPla_Cre: {id: ProPla_Cre, tradName: 'sidebar.creation', name: tFunction('sidebar.creation'), route: '/create', routeOptions: '', isView: true, icon: ''},
  ProPla_Mon: {id: ProPla_Mon, tradName: 'common.browse', name: tFunction('common.browse'), route:'/monitoring', routeOptions: '', isView: true, icon:''},
  ProPla_Mon_Sin: {id: ProPla_Mon_Sin, tradName: 'sidebar.detailed_browse', name: tFunction('sidebar.detailed_browse'), route: '/single', routeOptions: '/:id/:userId?', isView: false, icon: ''},
  ProPla_Mon_Sin_Rea: {id: ProPla_Mon_Sin_Rea, tradName: 'sidebar.reading', name: tFunction('sidebar.reading'), route: '/single', routeOptions: '/:id/:userId?', isView: false, icon: ''},
  ProPla_Mon_Sin_Wri: {id: ProPla_Mon_Sin_Wri, tradName: 'sidebar.writing', name: tFunction('sidebar.writing'), route: '/single', routeOptions: '/:id/:userId?', isView: false, icon: ''},
  ProPla_Mon_Edi: {id: ProPla_Mon_Edi, tradName: 'sidebar.edition', name: tFunction('sidebar.edition'), route: '/edit', routeOptions: '/:id', isView: false, icon: ''},
  ProPla_Mod: {id: ProPla_Mod, tradName: 'sidebar.models', name: tFunction('sidebar.models'), route: '/model', routeOptions: '', isView: true, icon: ''},
});

export function getByRoute(route){
  for(let moduleId in modules){
    let match = pathToRegexp(getRoute(moduleId) + modules[moduleId].routeOptions);
    if(match.test(route)) return modules[moduleId];
  }
}

export function getRoute(moduleID){
  let parents = getParents(modules[moduleID]);
  parents.push(modules[moduleID]);

  return parents.map((parent) => parent.route).reduce((accumulator, currentURL) => accumulator + currentURL);
}

export function getParents(module){
  const split = module.id.split('_');
  let i = 0;
  let parents = [];

  for(i = 0; i < split.length - 1; i++){
    parents.push(modules[split.slice(0,i+1).reduce((accumulator, module) => {
      return accumulator + '_' + module;
    })]);
  }

  return parents;
}

export function getDirectParent(module){
  const split = module.id.split('_');

  if(split.length > 1) return modules[split.slice(0, split.length - 1).reduce((accumulator, module) => {
    return accumulator + '_' + module;
  })];

  return null;
}

export function getDirectChilds(module){
  const split = (module === null ? null : module.id.split('_'));
  let childs = [];

  for(let child in modules){
    let childSplit = child.split('_');

    if(module !== null && childSplit.length === split.length + 1 && childSplit.slice(0, childSplit.length - 1).equals(split)) childs.push(modules[child]);
    else if(module === null && childSplit.length === 1) childs.push(modules[child]);
  }

  return childs;
}

export function getRoots(){
  let roots = [];

  for(let module in modules){
    if(module.split('_').length == 1) roots.push(modules[module]);
  }

  return roots;
}

function makeTree(modules, idCount = 0){
  let ret = {tree: [], idCount: idCount};

  if(modules.length > 0){
    modules.forEach(m => {
      let newElem = {
        label: m.id,
        id: ret.idCount,
      };
      ret.idCount ++;

      let ret2 = makeTree(getDirectChilds(m), ret.idCount);
      ret.idCount = ret2.idCount;
      ret2.tree.length > 0 ? newElem.children = ret2.tree : newElem.leaf = true;

      ret.tree.push(newElem);
    });
  }

  return ret;
}

export function getModulesTree(){
  return makeTree(getRoots()).tree;
}

export const getFilteredModulesTree = (modules) => {
  const tree = getModulesTree();

  function filterTree(nodes){
    nodes.forEach(node => {
      if (!!node.children && node.children.length){
        node.children = node.children.filter(n => {
          return (!n.leaf || modules.includes(n.label))
        });
        filterTree(node.children);
        node.children = node.children.filter(n => {
          return (!!n.leaf || !!n.children.length)
        });
      }
    });
  }

  filterTree(tree);
  return tree;
};

export const hasPermission = (id) => {
  const {session: {rights}} = store.getState();
  return resolvePermission(id, rights);
};

export const resolvePermission = (id, rights) => {
  const requiredRights = flatTree(makeTree([modules[id]]).tree);
  return requiredRights.some(r => rights.hasOwnProperty(r.label));
};
