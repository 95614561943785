import {fk, attr, Model} from 'redux-orm';
import {_Prestataire} from "../constants/orm";

export class Prestataire extends Model{
    static parse(data){
        delete data._matchingData;
        delete data._joinData;
        delete data.type;
        return data;
    }

    static get fields() {
        return{
            id: attr(),
            parent_id: fk(_Prestataire, 'ChildPrestataires'),
            label: attr(),
            created: attr(),
            modified: attr(),
            path: attr(),
            adresse: attr(),
            code_postal: attr(),
            ville: attr(),
            scope: attr(),
            pays: attr(),
            phone: attr()
        }
    }
}

Prestataire.modelName = _Prestataire;