import {fetchSession} from "../reducers/session";
import store from '../store';

export class Axios{
    static filtersToQuery(filters){
        let query = (Object.keys(filters).length === 0 && filters.constructor === Object) ? '' : '?';

        for(let key in filters){
            if(filters.hasOwnProperty(key)){
                filters[key].forEach(function(id){
                    query += key + '[]=' + id + '&';
                });
            }
        }

        return query.slice(0, -1);
    }

    static handleError(error){
        switch(error.status){
            case 401:
                store.dispatch(fetchSession()); //redirect if the connexion has expired
                break;
        }
        alert(error.statusText);
    }
}