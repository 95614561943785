import {fk, attr, Model} from 'redux-orm';
import {_Audit, _Client, _Complement} from "../constants/orm";

export class Complement extends Model {
  static parse(data){
    if (data.context === 'audits')
      data.audit_id = data.target;
    else if (data.context === 'clients')
      data.client_id = data.target;
    return data;
  }

  static get fields(){
    return {
      id: attr(),
      ranking: attr(),
      coefficient: attr(),
      model: attr(),
      cible: attr(),
      audit_id: fk(_Audit, 'complements'),
      client_id: fk(_Client, 'complements')
    }
  }
}

Complement.modelName = _Complement;
export default Complement;