import React from "react";
import {connect} from "react-redux";
import {fetchSessionUser} from "./actions/orm/User";
import ModalsContainer from "./components/modals/modalsContainer";
import {OcmModalsContainer} from "./components/modals/ocmModalsContainer";
import SpinLoader from "./components/default/spinLoader";
import {Route, withRouter} from "react-router";
import {reflect} from "./tools/helperFunctions";
import {Switch} from "react-router-dom";
import {LoginView} from "./Login";
import {MainView} from "./MainView";
import {ProtectedRoute} from "./tools/ProtectedRoute";

@withRouter
@connect(({session: {userId}}) => ({
  userId
}))
class Base extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    }
  }

  async componentDidMount(){
    await reflect(this.props.dispatch(fetchSessionUser()));

    this.setState({loading: false});
  }

  render(){
    const {loading} = this.state;
    const {userId} = this.props;

    if (loading)
      return null;
    return (
      <div className="app">
        <ModalsContainer/>
        <OcmModalsContainer/>
        <SpinLoader/>
        <Switch>
          <Route path={'/login' } component={LoginView}/>
          <ProtectedRoute path={'/'} loggedIn={!!userId} component={MainView}/>
        </Switch>
      </div>
    )
  }
}

export {Base};
export default Base;
