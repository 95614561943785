import createSelector from "../tools/createSelector";

export const progressModelByClient = createSelector(
  (orm, client) => {
    if(client !== null)
     return orm.ProgressModel.all().filter(p => p.client_id === client.id).toModelArray();

    return null;
  }
);

export const progressModelsByIds = createSelector(
  (orm, modelIds) => {
    if(modelIds !== null)
      return orm.ProgressModel.all().filter(m => modelIds.includes(m.id)).toModelArray();

    return null;
  }
);

export const progressModelsByThemes = createSelector(
  (orm, themes) => {
    if(themes !== null) {
      let models = orm.ProgressModel.all().toModelArray().filter(p => {
        let find = false;
        p.themes.toModelArray().some(t => {
          if(getIndexById(t.id, themes) !== null)
            find = true;
          return find;
        });

        return find;
      });

      return models;
    }
    return null;
  }
);

export const progressModelsByThemeQuestions = createSelector(
  (orm, themeQuestions) => {
    if(themeQuestions !== null) {
      let models = orm.ProgressModel.all().toModelArray().filter(p => {
        let find = false;
        p.themes_questions.toModelArray().some(tQ => {
          if(getIndexById(tQ.id, themeQuestions) !== null)
            find = true;
          return find;
        });

        return find;
      });

      return models;
    }
    return null;
  }
);