import {Badge} from "reactstrap";
import React from "react";

export const ThemeBadge = () => {
  return (
    <Badge title={t('common.theme')} class="badge-theme">
      {t('common.theme_badge')}
    </Badge>
  )
};

export const SubThemeBadge = () => {
  return (
    <Badge title={t('common.subTheme')} class="badge-sub-theme">
      {t('common.subTheme_badge')}
    </Badge>
  )
};

export const QuestionBadge = () => {
  return (
    <Badge color="primary" title={t('common.question')} class="badge-question badge-subtle">
      {t('common.question_badge')}
    </Badge>
  )
};

export const ClientBadge = () => {
  return (
    <Badge color="primary" title={t('common.client')} class="badge-client">
      {t('common.client_badge')}
    </Badge>
  )
};

export const SubClientBadge = () => {
  return (
    <Badge color="primary" title={t('common.subClient')} class="badge-sub-client">
      {t('common.subClient_badge')}
    </Badge>
  )
};

export const SiteBadge = () => {
  return (
    <Badge color="primary" title={t('common.site')} class="badge-site badge-subtle">
      {t('common.site_badge')}
    </Badge>
  )
};

export const ZoneExploitationsBadge = () => {
  return (
    <Badge color="primary" title={t('common.exploitation_zone')} class="badge-zone-exploitations badge-subtle">
      {t('common.zone_exploitations_badge')}
    </Badge>
  )
};

export const PrestataireBadge = () => {
  return (
    <Badge color="primary" title={t('common.presta')} class="badge-prestataire">
      {t('common.prestataire_badge')}
    </Badge>
  )
};

export const SubPrestataireBadge = () => {
  return (
    <Badge color="primary" title={t('common.subPrestataire')} class="badge-sub-prestataire">
      {t('common.subPrestataire_badge')}
    </Badge>
  )
};
