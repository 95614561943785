import createSelector from '../../tools/createSelector';
import {responsesByQuestionnairesandZesandReport} from "../../ormSelectors/response";
import {questionnairesByThemeQuestionsandAudit, questionnairesByThemesandAudit} from "../../ormSelectors/questionnaire";

export const responseCommentsSelector = createSelector(
  (orm, {reportId, zesId, themes}) => {
    let report = orm.Report.withId(reportId);
    let zes = orm.ZoneExploitation.all().filter(ze => zesId.includes(ze.id)).toModelArray();

    let questionnaires = [];

    if(themes.type === 'questions')
      questionnaires = questionnairesByThemeQuestionsandAudit({themeQuestions: themes.objects, audit: report.audit_id});
    else
      questionnaires = questionnairesByThemesandAudit({themes: themes.objects, audit: report.audit_id});

    if(questionnaires !== null) {
      const responses = responsesByQuestionnairesandZesandReport({
        report: report,
        zoneExploitations: zes,
        questionnaires: questionnaires
      });

      if(zes.length > 0) {
        let hierarchy = [];

        questionnaires.forEach(questionnaire => {
          hierarchy.push(Object.assign({}, questionnaire.themes_question_id.question_id.ref));
          arr_end(hierarchy).type = 'question';
          arr_end(hierarchy).checked = false;
          arr_end(hierarchy).children = [];

          zes.forEach(ze => {
            let localComments = [];

            responses.forEach(response => {
              if (response.questionnaire_id.id === questionnaire.id
                && response.zone_exploitation_id.id === ze.id && response.comment !== '') {
                localComments.push({...response.ref});
                arr_end(localComments).type = 'comment';
                arr_end(localComments).checked = false;
              }
            });

            if (localComments.length > 0) {
              arr_end(hierarchy).children.push(Object.assign({}, ze.ref));
              arr_end(arr_end(hierarchy).children).type = 'zoneExploitation';
              arr_end(arr_end(hierarchy).children).children = localComments.slice();
              arr_end(arr_end(hierarchy).children).checked = false;
            }
          });

          if (arr_end(hierarchy).children.length === 0)
            hierarchy.pop();
        });

        return hierarchy;
      }
    }

    return [];
  }
);

export const responseCommentsByReportsSelector = () => createSelector(
  (orm, {reportIds, zesId, themes, toggled}, idx) => {
    let reports = orm.Report.all().filter(r => reportIds.includes(r.id)).toModelArray();
    let zes = orm.ZoneExploitation.all().filter(ze => zesId.includes(ze.id)).toModelArray();
    const index = idx;

    let audits = {};
    let questionnaires = [];

    reports.map(report => {
      if (!audits[report.ref.audit_id])
        audits[report.ref.audit_id] = report.audit_id;
      if (themes.type === 'questions')
        questionnaires = questionnaires.concat(questionnairesByThemeQuestionsandAudit({
          themeQuestions: themes.objects,
          audit: report.audit_id
        }));
      else
        questionnaires = questionnaires.concat(questionnairesByThemesandAudit({
          themes: themes.objects,
          audit: report.audit_id
        }))
    });

    if(!!questionnaires.length) {
      const qIds = [];

      questionnaires = questionnaires.filter(q => {
        if (!qIds.includes(q.id)) {
          qIds.push(q.id);
          return true;
        }
        return false;
      });

      let responses = [];

      reports.map(report => {
        responses = responses.concat(responsesByQuestionnairesandZesandReport({
          report: report,
          zoneExploitations: zes,
          questionnaires: questionnaires
        }))
      });

      if(zes.length > 0) {
        let hierarchy = [];

        Object.keys(audits).forEach(auditId => {
          hierarchy.push(Object.assign({}, audits[auditId].ref));
          arr_end(hierarchy).type = 'audit';
          arr_end(hierarchy).checked = false;
          arr_end(hierarchy).children = [];
          arr_end(hierarchy).toggled = !!toggled.find(t => t.type === 'audit' && t.id === Number(auditId));

          questionnaires.filter(q => q.ref.audit_id === Number(auditId)).forEach(questionnaire => {
            arr_end(hierarchy).children.push(Object.assign({}, questionnaire.themes_question_id.question_id.ref));
            arr_end(arr_end(hierarchy).children).type = 'question';
            arr_end(arr_end(hierarchy).children).checked = false;
            arr_end(arr_end(hierarchy).children).children = [];
            arr_end(arr_end(hierarchy).children).parent = arr_end(hierarchy);
            arr_end(arr_end(hierarchy).children).toggled = !!toggled.find(t =>
              t.type === 'question' && t.id === questionnaire.themes_question_id.question_id.ref.id &&
              t.parent.id === Number(auditId)
            );

            zes.forEach(ze => {
              let localComments = [];
              arr_end(arr_end(hierarchy).children).children.push(Object.assign({}, ze.ref));
              arr_end(arr_end(arr_end(hierarchy).children).children).checked = false;
              arr_end(arr_end(arr_end(hierarchy).children).children).type = 'zoneExploitation';
              arr_end(arr_end(arr_end(hierarchy).children).children).parent = arr_end(arr_end(hierarchy).children);
              arr_end(arr_end(arr_end(hierarchy).children).children).toggled = !!toggled.find(t =>
                t.type === 'zoneExploitation' && t.id === ze.id &&
                t.parent.id === arr_end(arr_end(hierarchy).children).id &&
                t.parent.parent.id === Number(auditId)
              );

              responses.forEach(response => {
                if (response.questionnaire_id.id === questionnaire.id
                  && response.zone_exploitation_id.id === ze.id && response.comment !== '') {
                  localComments.push({...response.ref});
                  arr_end(localComments).type = 'comment';
                  arr_end(localComments).checked = false;
                }
                else if (response.questionnaire_id.id === questionnaire.id
                  && response.zone_exploitation_id.id === ze.id && response.comment === '') {
                  localComments.push({...response.ref});
                  arr_end(localComments).comment = 'Pas de commentaire';
                  arr_end(localComments).type = 'comment';
                  arr_end(localComments).checked = false;
                }
              });

              if (!responses.find(r =>
                r.zone_exploitation_id.id === ze.id &&
                r.questionnaire_id.id === questionnaire.id &&
                r.questionnaire_id.ref.audit_id === Number(auditId)))
                localComments.push({
                  id: 0,
                  checked: false,
                  type: 'comment',
                  zone_exploitation_id: ze,
                  comment: 'Pas de réponse',
                  questionnaire_id: questionnaire
                });

              arr_end(arr_end(arr_end(hierarchy).children).children).children = localComments.slice();

              // if (localComments.length > 0) {
              //   arr_end(arr_end(hierarchy).children).children.push(Object.assign({}, ze.ref));
              //   arr_end(arr_end(arr_end(hierarchy).children).children).type = 'zoneExploitation';
              //   arr_end(arr_end(arr_end(hierarchy).children).children).children = localComments.slice();
              //   arr_end(arr_end(arr_end(hierarchy).children).children).checked = false;
              //   arr_end(arr_end(arr_end(hierarchy).children).children).parent = arr_end(arr_end(hierarchy).children);
              //   arr_end(arr_end(arr_end(hierarchy).children).children).toggled = !!toggled.find(t => t.type === 'zoneExploitation' && t.id === ze.id);
              // }
            });

            if (arr_end(hierarchy).children.length === 0)
              hierarchy.pop();
          });
        });

        return hierarchy;
      }
    }

    return [];
  }
);