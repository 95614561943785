import {
  clientSelector,
  prestataireSelector,
  taxonomyTreeSelector,
  zonesExploitationSelector,
  analysesSelector,
  analyseVaguesSelector,
  clientQuestionnaireTreeSelector
} from "../../tools/Filters/filterItemsSelectors";

export const filtersState = {
  clients: {
    loading: false,
    type: 'clients',
    options: [],
    value: [{value: 18}],
    parents: [],
    selectorFunc: clientSelector,
    isMulti: false,
    isClearable: false,
    defaultSelection: {
      state: true,
      value: 'value',
      id: 18
    }
  },
  analyses: {
    loading: true,
    type: 'analyses',
    options: [],
    value: [],
    parents: [
      "clients"
    ],
    selectorFunc: analysesSelector,
    isMulti: false,
    isClearable: false,
    defaultSelection: {
      state: true,
      value: 'first'
    }
  },
  vagues: {
    loading: true,
    type:"vagues",
    options: [],
    value: [],
    parents: [
      "analyses"
    ],
    selectorFunc: analyseVaguesSelector,
    isMulti: false,
    isClearable: false,
    defaultSelection: {
      state: true,
      value: 'first'
    }
  },
  prestataires: {
    loading: true,
    type: 'prestataires',
    options: [],
    value: [],
    parents: [
      "clients"
    ],
    selectorFunc: prestataireSelector,
    isMulti: true,
    isClearable: true,
    defaultSelection: {
      state: false,
      value: 'first'
    }
  },
  taxonomiesTree: {
    loading: true,
    endpoint: '/qb/taxonomies_tree',
    options: [],
    recursiveCheck: true,
    value: {
      taxonomy: []
    },
    type: {
      taxonomy: 'taxonomies'
    },
    selectorFunc: taxonomyTreeSelector,
    parents: [
      "clients"
    ],
    isTree: true,
    isMulti: false,
    isClearable: false,
    defaultSelection: {
      state: false,
      value: 'all'
    }
  },
  zones_exploitation: {
    loading: true,
    type: 'zones_exploitation',
    options: [],
    value: [],
    parents: [
      "clients",
      "prestataires",
      "taxonomiesTree",
      "analyses"
    ],
    processOptionsIfEmptyValue: true,
    selectorFunc: zonesExploitationSelector,
    isMulti: true,
    isClearable: true,
    defaultSelection: {
      state: false,
      value: 'first'
    }
  },
  themesTree: {
    loading: true,
    options: [],
    value: {
      question: [],
      theme: []
    },
    type: {
      question: 'questions',
      theme: 'themes'
    },
    parents: [
      "clients"
    ],
    selectorFunc: clientQuestionnaireTreeSelector,
    isTree: true,
    isMulti: false,
    isClearable: false,
    defaultSelection: {
      state: true,
      value: 'last'
    }
  }
};
