import createSelector from "../../tools/createSelector";
import store from "../../store";

export const createUserSelectorById = () => {
  return createSelector(
    (orm, {userId}) => {
      const user = orm.User.withId(userId);

      return !!user ? user.ref : null;
    }
  )
};

export const createUsersObjectSelector = () => {
  return createSelector(
    (orm) => {
      return orm.User.all().toRefArray().reduce((acc, curr) => {
        acc[curr.id] = curr;

        return acc;
      }, {});
    }
  )
};

export const createUsersSelector = () => {
  return createSelector(
    (orm) => {
      return orm.User.all().toRefArray();
    }
  )
};

export const createUsersAuditorsSelectorByAuditId = () => {
  return createSelector(
    (orm, {clientIds}) => {

      const {userId} = store.getState().session;
      const user = orm.User.withId(userId).ref;
      let auditsUsers = [];

      clientIds.map(client_id => {
        orm.Audit.all().toModelArray().filter(a => a.ref.client_id === client_id).map(a => {
          a.users.toModelArray().map(u => {
            if (!auditsUsers.find(au => au.id === u.ref.id))
              auditsUsers.push({
                ...u.ref,
                enterprise: !!u.ref.firm_id ? u.ref.firm_id : !!u.ref.prestataire_id ? u.ref.prestataire_id : u.ref.client_id
              });
          });
        });
      });

      if (!!user.prestataire_id)
        return auditsUsers.filter(u => u.prestataire_id === user.prestataire_id);

      return auditsUsers;
    }
  )
};

export const createSessionEnterpriseUsersSelector = () => {
  return createSelector(
    (orm) => {
      const {userId} = store.getState().session;
      const user = orm.User.withId(userId).ref;
      let users = [];

      if (!!user.client_id)
        users = orm.User.all().filter(u => u.client_id === user.client_id).toRefArray();
      else if (!!user.firm_id)
        users = orm.User.all().filter(u => u.firm_id === user.firm_id).toRefArray();

      return users;
    }
  )
};


export const createSessionUserSelector = () => {
  return createSelector((orm) => {
    const {userId} = store.getState().session;

    return orm.User.withId(userId).ref;
  })
};