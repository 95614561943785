import createSelector from "../../tools/createSelector";

export const createAnalysesSelector = () => {
  return createSelector(
    (orm) => {
      const analyses = orm.Analyse.all().toRefArray();

      return !!analyses ? analyses : null;
    }
  )
};

export const createAnalysesObjectSelector = () => {
  return createSelector(
    (orm) => {
      const analysesArray = orm.Analyse.all().toModelArray().map(a => ({
        ...a.ref,
        user_ids: a.users.toRefArray().map(u => u.id)
      }));
      const analyses = analysesArray.reduce((acc, curr) => {
        acc[curr.id] = curr;

        return acc;
      }, {});

      return !!analyses ? analyses : null;
    }
  )
};

export const createAnalyseByIdSelector = () => {
  return createSelector(
    (orm, {id}) => {
      const analyse = orm.Analyse.withId(id);

      return !!analyse ? analyse.ref : null;
    }
  )
};
