import {fk, attr, Model, many} from 'redux-orm';
import {_Site, _Client, _User, _Responsable} from "../constants/orm";

export class Site extends Model{
  static parse(data){
    delete data.zone_exploitations;

    let ids = {};
    if(data.responsables !== undefined)
    {
      data.responsables.map(responsable => {
        if (!ids[responsable.prestataire_id])
          ids[responsable.prestataire_id] = [responsable.user_id];
        else
          ids[responsable.prestataire_id].push(responsable.user_id);
      });
    }
    data.responsable_ids = ids;
    data.responsables = (data.responsables !== undefined ? data.responsables.map(r => r.user_id) : []);
    data.main_responsables = (data.responsables !== undefined && !!data.responsables.length ? data.responsables.filter(r => !!r.main).map(r => r.id) : []);

    return data;
  }

  static get fields(){
    return{
      id: attr(),
      label: attr(),
      client_id: fk(_Client, 'sites'),
      adresse: attr(),
      latitude: attr(),
      longitude: attr(),
      created: attr(),
      modified: attr(),
      user_id: fk(_User, 'sites'),
      scope: attr(),
      responsables: many(_Responsable, 'sites'),
      responsable_ids: attr(),
      main_responsables: many(_Responsable, 'sitesMainResponsables')
    }
  }
}

Site.modelName = _Site;

export default Site;