import React from "react";
import { connect } from "react-redux";
import {
  Form,
  FormGroup,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback, Nav, NavItem, NavLink, ListGroupItem, ListGroup, InputGroup, InputGroupAddon
} from "reactstrap";
import { FormValidator } from "../../tools/formValidator/FormValidator";
import { setAddModifyClient } from "../../actions/modals";
import { addClient, updateClient } from "../../actions/orm/Client";
import { clientParameters } from "../../constants/parameters";
import LoadingButton from "../common/LoadingButton";
import { addParameter, removeParameter } from "../../actions/orm/Parameter";
import createSelector from "../../tools/createSelector";
import { ImportFileIconPlaceholder } from "../common/ImagePlaceholders";
import { tFunction } from "../tools/Translation";
import { setGenericConfirmation, setWriteText } from "../../actions/modals";

const localFv = new FormValidator([
  {
    field: 'label',
    method: 'isEmpty',
    validWhen: false,
    message: tFunction('modals.label_specified')
  }
]);

const clientParametersSelector = createSelector(
  (orm, { clientId }) => {
    const client = orm.Client.withId(clientId);
    return client.parameters.toRefArray();
  }
);

const clientSelector = createSelector(
  (orm, { clientId }) => {
    return orm.Client.withId(clientId).ref;
  }
);

@connect(({ modals: { addModifyClient: { currentClientId } } }) => ({
  currentClientId
}))
class AddModifyClient extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      closing: false,
      label: '',
      adresse: '',
      code_postal: '',
      ville: '',
      pays: '',
      phone: '',
      path: null,
      label_i2c: '',
      label_zone_exploitation: '',
      label_audit: '',
      validation: localFv.valid(),
      parameters: {},
      characteristics: [],
      displayTab: 'common',
      characteristicLabel: '',
      numerical: false
    };

    Object.keys(clientParameters).map(param => {
      this.state.parameters[param] = false
    });

    if (!!this.props.currentClientId) {
      const client = clientSelector({ clientId: this.props.currentClientId });
      const parameters = clientParametersSelector({ clientId: this.props.currentClientId }, true);

      this.state.label = client.label;
      this.state.adresse = client.adresse;
      this.state.code_postal = client.code_postal;
      this.state.ville = client.ville;
      this.state.pays = client.pays;
      this.state.phone = client.phone;
      this.state.path = client.path;
      this.state.label_i2c = !!client.label_i2c ? client.label_i2c : '';
      this.state.label_zone_exploitation = !!client.label_zone_exploitation ? client.label_zone_exploitation : '';
      this.state.label_audit = !!client.label_audit ? client.label_audit : '';
      this.state.characteristics = !!client.characteristics ? client.characteristics : [];
      this.state.numerical = !!client.numerical;
      parameters.map(item => {
        this.state.parameters[item.label] = true;
      });
    }
  }

  fileInputRef = React.createRef();
  inputLabel = React.createRef();

  addNewCharacteristic = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      characteristics: [...this.state.characteristics, { label: this.state.characteristicLabel, new: true }],
      characteristicLabel: ''
    })
  };

  setDisplayTab = (name) => {
    this.setState({ displayTab: name });
  };

  changeCharacteristicLabel = async (characteristicId, characteristicLabel) => {
    try {
      const newText = await this.props.dispatch(setWriteText({
        open: true,
        defaultValue: characteristicLabel,
        inputType: 'text',
        labelText: t('common.label'),
        headerText: t('modals.profil_edition')
      }));

      if (!!newText.length) {
        let c = this.state.characteristics.slice();
        c[characteristicId]['label'] = newText;
        this.setState({ characteristics: c });
      }
    } catch (e) { }
  };

  removeCharacteristic = async (index) => {
    const { characteristics } = this.state;
    const characteristic = characteristics[index];

    if (!characteristic.new) {
      await this.props.dispatch(setGenericConfirmation({
        open: true,
        title: t("admin.enterprise.Suppression d'une caractéristique"),
        body: (
          <span>
            {t('admin.enterprise.Voulez-vous vraiment supprimer la caractéristique')} <strong>{characteristic.label}</strong> ?
          </span>
        )
      }))
    }

    this.setState({
      characteristics: characteristics.filter((c, idx) => idx !== index)
    })
  };

  componentDidMount() {
    this.inputLabel.focus();
  }

  onParameterChange = (e) => {
    const newParameters = {
      ...this.state.parameters,
      [e.target.name]: e.target.checked
    };

    this.setState({ parameters: newParameters });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onLogoChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.setState({ path: e.target.result });
    };
    reader.readAsDataURL(file);
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const {
      label,
      adresse,
      code_postal,
      ville,
      pays,
      phone,
      path,
      label_i2c,
      label_zone_exploitation,
      label_audit,
      characteristics,
      numerical
    } = this.state;

    const validations = localFv.validate({ label });
    if (!validations.isValid) {
      this.setState({ validation: validations });
      return;
    }
    this.setState({ loading: true });
    let client = null;

    if (!this.props.currentClientId)
      client = await this.props.dispatch(addClient({
        label,
        adresse,
        code_postal,
        ville,
        pays,
        phone,
        path,
        label_i2c,
        label_zone_exploitation,
        label_audit,
        characteristics,
        numerical
      }));
    else
      client = await this.props.dispatch(updateClient({
        id: this.props.currentClientId,
        label,
        adresse,
        code_postal,
        ville,
        pays,
        phone,
        path,
        label_i2c,
        label_zone_exploitation,
        label_audit,
        characteristics,
        numerical
      }));
    await this.checkParameters(client);
    this.setState({ loading: false });
    this.closing();
  };

  checkParameters = async (client) => {
    const { dispatch } = this.props;
    const { parameters } = this.state;
    const currentParameters = clientParametersSelector({ clientId: client.id });

    const newParameters = [];
    const removedParameters = [];

    //check for new and removed parameters
    Object.keys(parameters).map(key => {
      const param = currentParameters.find(i => i.label === key);
      if (!!parameters[key]) {
        if (!param)
          newParameters.push(dispatch(addParameter({
            label: key,
            cible_id: client.id,
            model: 'clients'
          })));
      } else {
        if (!!param)
          removedParameters.push(dispatch(removeParameter({
            id: param.id
          })));
      }
    });
    return await Promise.all([...newParameters, ...removedParameters]);
  };

  closing = () => {
    this.setState({ closing: true });
  };

  close = () => {
    this.props.dispatch(setAddModifyClient({
      open: false
    }));
  };

  render() {
    const {
      validation,
      closing,
      loading,
      label,
      adresse,
      code_postal,
      ville,
      pays,
      phone,
      path,
      parameters,
      label_i2c,
      label_zone_exploitation,
      label_audit,
      displayTab,
      characteristics,
      characteristicLabel,
      numerical
    } = this.state;
    const { currentClientId } = this.props;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.closing}>
          {!!currentClientId ? t('common.modify') : t('common.create')} {t('modals.a_client')}
        </ModalHeader>
        <Form onSubmit={this.onSubmit}>
          <ModalBody>
            <FormGroup className="text-center">
              <ImportFileIconPlaceholder
                onClick={() => this.fileInputRef.current.click()}
                src={path} />
              <input
                hidden
                type="file"
                name="path"
                accept="image/*"
                ref={this.fileInputRef}
                onChange={this.onLogoChange}
                className="custom-file-input" />
            </FormGroup>
            <FormGroup>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    onClick={() => this.setDisplayTab('common')}
                    active={displayTab === 'common'}>
                    {t('common.general')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() => this.setDisplayTab('characteristics')}
                    active={displayTab === 'characteristics'}>
                    {t('common.characteristics')}
                  </NavLink>
                </NavItem>
              </Nav>
            </FormGroup>
            {displayTab === 'common' &&
              <React.Fragment>
                <FormGroup>
                  <Label>
                    {t('common.label')}
                  </Label>
                  <Input
                    name="label"
                    innerRef={(input) => (this.inputLabel = input)}
                    invalid={validation.label.isInvalid}
                    placeholder={t('common.label')}
                    onChange={this.onChange}
                    value={label} />
                  <FormFeedback>
                    {validation.label.message}
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label>
                    {t('common.address')}
                  </Label>
                  <Input
                    onChange={this.onChange}
                    value={adresse}
                    name="adresse"
                    placeholder={t('common.address')} />
                </FormGroup>
                <FormGroup>
                  <Label>
                    {t('modals.postal')}
                  </Label>
                  <Input
                    onChange={this.onChange}
                    value={code_postal}
                    name="code_postal"
                    placeholder={t('modals.postal')} />
                </FormGroup>
                <FormGroup>
                  <Label>
                    {t('modals.town')}
                  </Label>
                  <Input
                    onChange={this.onChange}
                    value={ville}
                    name="ville"
                    placeholder={t('modals.town')} />
                </FormGroup>
                <FormGroup>
                  <Label>
                    {t('modals.country')}
                  </Label>
                  <Input
                    onChange={this.onChange}
                    value={pays}
                    name="pays"
                    placeholder={t('modals.country')} />
                </FormGroup>
                <FormGroup>
                  <Label>
                    {t('modals.phone')}
                  </Label>
                  <Input
                    onChange={this.onChange}
                    value={phone}
                    name="phone"
                    placeholder={t('modals.phone')} />
                </FormGroup>
                <div className="log-divider">
                  <span className="bg-light">
                    {t('common.parameters')}
                  </span>
                </div>
                <FormGroup>
                  <Label>
                    {t('common.label')} "I2C"
                  </Label>
                  <Input
                    onChange={this.onChange}
                    value={label_i2c}
                    name="label_i2c"
                    placeholder={`${t('common.label')} I2C`} />
                </FormGroup>
                <FormGroup>
                  <Label>
                    {t('common.label')} "{t('common.exploitation_zone').toLowerCase()}"
                  </Label>
                  <Input
                    onChange={this.onChange}
                    value={label_zone_exploitation}
                    name="label_zone_exploitation"
                    placeholder={`${t('common.label')} "${t('common.exploitation_zone').toLowerCase()}"`} />
                </FormGroup>
                <FormGroup>
                  <Label>
                    {t('common.label')} "{t('common.audit').toLowerCase()}"
                  </Label>
                  <Input
                    onChange={this.onChange}
                    value={label_audit}
                    name="label_audit"
                    placeholder={`${t('common.label')} "${t('common.audit').toLowerCase()}"`} />
                </FormGroup>
                <FormGroup>
                  {Object.keys(clientParameters).map(key => {
                    return (
                      <div className="custom-control custom-checkbox" key={key}>
                        <input
                          type="checkbox"
                          checked={parameters[key]}
                          name={key}
                          onChange={this.onParameterChange}
                          className="custom-control-input"
                          id={key} />
                        <label className="custom-control-label"
                          htmlFor={key}>
                          {clientParameters[key]}
                        </label>
                      </div>
                    );
                  })}
                  <div className="custom-control custom-checkbox">
                    <input
                      id="numerical"
                      type="checkbox"
                      name="numerical"
                      checked={numerical}
                      className="custom-control-input"
                      onChange={() => this.onChange({ target: { name: 'numerical', value: !numerical } })} />
                    <label className="custom-control-label" htmlFor={'numerical'}>
                      {t('admin.audit_view.questions_numeration')}
                    </label>
                  </div>
                </FormGroup>
              </React.Fragment>}
            {displayTab === 'characteristics' &&
              <React.Fragment>
                <FormGroup>
                  <ListGroup className="list-group-bordered">
                    <div className="list-group-header">
                      {t('common.characteristics')}
                    </div>
                    {characteristics.map((ch, idx) => {
                      return (
                        <ListGroupItem key={idx}>
                          <div className="list-group-item-body">
                            {ch.label}
                          </div>
                          <div className="list-group-item-figure">
                            <button className="btn btn-sm btn-light"
                              type="button"
                              onClick={(e) => { e.stopPropagation(); this.changeCharacteristicLabel(idx, ch.label) }}>
                              <i className="fa fa-edit" />
                            </button>
                            <button className="btn btn-sm btn-light"
                              type="button"
                              onClick={() => this.removeCharacteristic(idx)}>
                              <i className="fa fa-times" />
                            </button>
                          </div>
                        </ListGroupItem>
                      )
                    })}
                  </ListGroup>
                </FormGroup>
                <FormGroup>
                  <Form>
                    <Label>{t('modals.Ajouter une caracteristique')}</Label>
                    <InputGroup>
                      <Input name="characteristicLabel"
                        required
                        value={characteristicLabel}
                        onChange={this.onChange}
                        placeholder={t('modals.Ajouter une caracteristique')} />
                      <InputGroupAddon addonType="append">
                        <Button color="primary" onClick={this.addNewCharacteristic}>{t('common.add')}</Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </Form>
                </FormGroup>
              </React.Fragment>}
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              type='button'
              disabled={loading}
              onClick={this.closing}>
              {t('common.close')}
            </Button>
            <LoadingButton
              loading={loading}
              disabled={loading}
              color="success">
              {t('common.valid')}
            </LoadingButton>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export { AddModifyClient };
export default AddModifyClient;
