import api from "../../tools/api";
import {_Profile} from "../../constants/orm";
import {hydrateEntities, updateEntities} from "../orm";
import {actionCallsTracker} from "../actionsTracker";

export const getAllProfiles = () => {
  return async dispatch => {
    if (actionCallsTracker.isSaved('profiles', 'client', 'all'))
      return;

    const profiles = await api.profile.getAll();

    dispatch(hydrateEntities({
      payload: [
        {type: _Profile, entities: profiles}
      ]
    }));

    actionCallsTracker.save('profiles', 'client', 'all');
    return profiles;
  }
};

export const getProfile = ({id}) => {
  return async dispatch => {
    const profile = await api.profile.get({id});

    dispatch(hydrateEntities({
      payload: [
        {type: _Profile, entities: [profile]}
      ]
    }));
    return profile;
  }
};

export const addProfile = ({model, cible, label, description, permissions}) => {
  return async dispatch => {
    const profile = await api.profile.add({
      model,
      cible,
      label,
      description,
      permissions
    });

    dispatch(hydrateEntities({
      payload: [
        {type: _Profile, entities: [profile]}
      ]
    }));
    return profile;
  }
};

export const updateProfile = ({id, model, cible, label, description, permissions}) => {
  return async dispatch => {
    const updatedProfile = await api.profile.update({
      id,
      model,
      cible,
      label,
      description,
      permissions
    });

    dispatch(updateEntities({
      payload: [
        {type: _Profile, entities: [updatedProfile]}
      ]
    }));

    return updatedProfile;
  }
};