import React  from "react";
import {connect} from "react-redux";
import {modals as modalsDescriber} from "./modalsDescriber";

@connect(({ocmModals}) => ({
  modals: ocmModals
}))
class OcmModalsContainer extends React.PureComponent {
  render(){
    const {modals} = this.props;

    return (
      <>
        {Object.entries(modals).map(([modalName, {open, ...otherProps}]) => {
          if (!open)
            return null;
          const modal = modalsDescriber[modalName];

          return <modal.component key={modalName}/>;
        })}
      </>
    )
  }
}

export {OcmModalsContainer};
export default OcmModalsContainer;