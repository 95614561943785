import React from 'react';
import {connect} from 'react-redux';
import {ocmGreen, ocmOrange, ocmRed} from "../../../../constants/ui";
import {uploadImport} from "../../../../reducers/progressPlan/monitoring";
import {getRoute, ProPla_Mon_Edi, ProPla_Mon_Sin} from "../../../../constants/modules";
import {ProgressPlanFilter} from "../../../tools/ProgressPlanFilter";
import ExtendedBrowseView from "./ExtendedBrowseView";
import {startPending, stopPending} from "../../../../actions/ui";
import {resolvePermission} from "../../../../constants/modules";
import createSelector from "../../../../tools/createSelector";
import {Link} from "react-router-dom";
import {fetchProgressModels} from "../../../../actions/orm/ProgressModel";
import {
  fetchClientAudits,
  fetchClientReports,
  fetchClientThemesTree,
  fetchClientOrganisation,
} from "../../../../actions/orm/Client";
import {fetchClientQuestionnaire} from "../../../../actions/orm/Questionnaire";
import {Button, Col, Row} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import {defaultPaginationFactory} from "../../../../constants/ui";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import {DebounceSearchInput} from "../../../common/DebounceSearchInput";
import {Tooltip} from 'react-tippy';
import {fetchProgressPlansWithFilters} from "../../../../actions/orm/ProgressPlan";
import {fetchClientAnalyses} from "../../../../actions/orm/Analyse";
import {createClientWithParametersByIdSelector} from "../../../selectors/clients";
import _ from "lodash";
import {fetchAllParameters} from "../../../../actions/orm/Parameter";
import waitPromiseBeforeMount from "../../../../HOC/waitPromiseBeforeMount";
import {setModal} from "../../../../actions/modals";
import {createItemI2cFetch} from "../../../tools/Filters/updateFunctions";
import {writeToExcel} from "../../../../tools/helperFunctions";
import {withRouter} from "react-router";
import qs from "query-string";
import {createNumericValueOfThemeQuestionSelector} from "../../../selectors/questionnaire";

const clientSelector = createClientWithParametersByIdSelector();
const themeQuestionSelector = createNumericValueOfThemeQuestionSelector();
const analysesObjectSelector = createSelector(
  (orm, {clientId}) => {
    const client = orm.Client.withId(clientId);
    const analyses = client.analyses.toRefArray();

    return analyses.reduce((acc, curr) => {
      acc[curr.id] = curr;

      return acc;
    }, {});
  }
);
const getThemeParents = createSelector(
  (orm, {id}) => {
    const theme = orm.Theme.withId(id);
    const themes = [theme.ref];

    function getParent(theme){
      if (!!theme.parent_id){
        let parent = theme.parent_id;
        themes.unshift(parent.ref);
        getParent(parent);
      }
    }

    getParent(theme);
    return themes.map(t => t.label).join('->');}
);

const getQuestionParents = createSelector(
  (orm, {id}) => {
    const question = orm.ThemeQuestion.withId(id);
    const theme = orm.Theme.withId(question.ref.theme_id);
    const items = [theme.ref, question.question_id.ref];

    function getParent(theme){
      if (!!theme.parent_id){
        const parent = theme.parent_id;
        items.unshift(parent.ref);
        getParent(parent);
      }
    }

    getParent(theme);
    return items.map(t => !!t.title ? t.title : t.label).join(' -> ');
  }
);

const defaultFilters = {
  clients: [],
  sites: [],
  zone_exploitations: [],
  level: [],
  status: [],
  themes: [],
  themes_questions: [],
  analyses: [],
  vague: [],
  models: [],
  prestataires: []
};

@waitPromiseBeforeMount(async ({session: {currentClientId}}, {}, dispatch) => {
  dispatch(startPending());

  await dispatch(fetchClientAnalyses({client_id: currentClientId}));

  dispatch(stopPending());
})
@withRouter
@connect((state) => ({
  clientId: state.session.currentClientId,
  client: clientSelector({id: state.session.currentClientId}),
  rights: state.session.rights,
  userId: state.session.userId,
  analyses: analysesObjectSelector({clientId: state.session.currentClientId})
}), dispatch => ({
  uploadImport: (file) => dispatch(uploadImport(file)),
  startPending: () => dispatch(startPending()),
  stopPending: () => dispatch(stopPending()),
  dispatch: dispatch
}))
export class Browse extends React.Component {
  constructor(props){
    super(props);

    const {location: {search}} = this.props;
    const {vagues, analyses, zones_exploitation} = qs.parse(search, {arrayFormat: 'bracket'});

    let analyseVagues = [];
    if(!!analyses && analyses.length === 1 && !!vagues && vagues.length === 1)
      analyseVagues.push(JSON.stringify({'vague': Number(vagues[0]), 'analyse_id': Number(analyses[0])}));

    this.state = {
      dataTableInit: false,
      redirectDatatable: '',
      extendedView: false,
      progressPlans: [],
      initialized: false,
      editPerm: resolvePermission(ProPla_Mon_Edi, this.props.rights),
      viewPerm: resolvePermission(ProPla_Mon_Sin, this.props.rights),
      i2cOG: 0,
      i2cEG: 0,
      zeIds: null,
      filters: {
        ...defaultFilters,
        clients: [],
        analyses: !!analyses ? (Array.isArray(analyses)) ? analyses.map(a => Number(a)) : [analyses].map(a => Number(a)) : [],
        vague: !!analyseVagues ? analyseVagues : [],
        zone_exploitations: !!zones_exploitation ? (Array.isArray(zones_exploitation)) ? zones_exploitation.map(a => Number(a)) : [zones_exploitation].map(a => Number(a)) : []
      }
    };
  }

  

  vaguePdpDataFetch = createItemI2cFetch({
    params: {
//      progressPlanStateDistribution: true,
//      latePdpNb: true
    },
    by: 'progressPlanDataByVague'
  });

  progressPlanDataFetch = createItemI2cFetch({
    by: 'progressPlanDataByProgressPlan',
    returnType: 'object'
  });

  resetFilters = () => {
    this.setState({
      filters: {
        ...defaultFilters,
        clients: []
      },
    }, this.filter);
  };

  handleFiltersChange = (filters, callback) => {
    this.setState({filters}, callback);
  };

  fetchProgressPlans = async () => {
    const {
      level,
      status,
      themes,
      themes_questions,
      clients,
      sites,
      zone_exploitations,
      prestataires,
      analyses,
      vague,
      models
    } = this.state.filters;
    const organisationsChoosed = !!clients.length || !!sites.length || !!zone_exploitations.length;
    
    const pps = await this.props.dispatch(fetchProgressPlansWithFilters({
      level,
      vague: vague.map(v => Number(JSON.parse(v).vague)),
      status: status,
      themes: themes.map(i => i.id),
      themes_questions: themes_questions.map(i => i.id),
      prestataires: prestataires,
      type_progres: models,
      analyses: analyses,
      clients: organisationsChoosed ? clients : [this.props.clientId],
      sites: sites,
      zone_exploitations: zone_exploitations
    }));

    await this.handleProgressPlansChange(pps, {zeIds: zone_exploitations, vagues: vague, analyses: analyses});
  };

  filter = async () => {
    this.props.dispatch(startPending());
    await this.fetchProgressPlans();
    this.props.dispatch(stopPending());
  };

  handleProgressPlansChange = async (pps, {zeIds, vagues, analyses}) => {
    let newZeIds = [];
    let progressPlans = pps.map(item => ({
      ...item,
      perimetre: item.perimetre.length > 0 ? item.perimetre.map(p => ({
        ...p,
        numericValue: !!p.themes || !this.props.client.numerical ?
          null : themeQuestionSelector({themeQuestionId: p.id}),
        path: !!p.themes ? getThemeParents({id: p.id}) : getQuestionParents({id: p.id})
      })) : [],
    }));
    const [progressPlanData, forepaasRequest] = await Promise.all([
      this.progressPlanDataFetch({
        progressPlanIds: pps.map(i => i.id),
        zones_exploitation: !!zeIds ? zeIds : []
      }),
      (analyses.length === 1 && vagues.length === 1) ? this.vaguePdpDataFetch({
        zones_exploitation: zeIds,
        analyses: analyses,
        vagues: vagues.map(v => Number(JSON.parse(v).vague))
      }) : [{}]
    ]);

    // const progressPlanData = await this.progressPlanDataFetch({
    //   progressPlanIds: pps.map(i => i.id),
    //   zones_exploitation: !!zeIds ? zeIds : []
    // });
    //
    // const forepaasRequest = await this.vaguePdpDataFetch({
    //   zones_exploitation: zone_exploitations,
    //   analyses: analyses,
    //   vagues: vague
    // });

    progressPlans.forEach(pp => {
      const {vague, analyse_id, zone_exploitations, perimetre} = pp;

      const analyses = this.props.analyses[analyse_id];
      const pdpInfo = progressPlanData[pp.id];
      const vagueLabel = analyses.vagues.find(v => v.vague === vague);

      zone_exploitations.forEach(ze => {
        newZeIds.push(ze.id);
      });

      pp.i2cO = !!pdpInfo ? pdpInfo.value : null;
      pp.i2cE = !!pdpInfo ? pdpInfo.valueEstimated : null;
      pp.progress = !!pdpInfo ? pdpInfo.progression : null;
      pp.vague = !!vagueLabel ? vagueLabel.label : null;
    });

    // const i2cOG = getAverage(progressPlans, 'i2cO');
    // const i2cEG = getAverage(progressPlans, 'i2cE');
    const vagueData = forepaasRequest[0];

    // this.setState({
    //   progressPlans: progressPlans,
    //   i2cOG: i2cOG,
    //   i2cEG: i2cEG,
    //   zeIds: newZeIds
    // });
    this.setState({
      progressPlans: progressPlans,
      i2cOG: !!vagueData ? vagueData.value : null,
      i2cEG: !!vagueData ? vagueData.valueEstimated : null,
      zeIds: _.uniq(newZeIds)
    });
  };

  toggleExtendedView = () => {
    this.setState({extendedView: !this.state.extendedView});
  };

  async componentDidMount(){
    const {dispatch, clientId, startPending, stopPending, analyses} = this.props;
    const {filters: {zone_exploitations, analyses: analysesFilter, vague}} = this.state;
    startPending();
    const [pps] = await Promise.all([
      dispatch(fetchProgressPlansWithFilters({
        clients:  [clientId],
        zone_exploitations: zone_exploitations,
        analyses: analysesFilter,
        vague: vague.map(v => Number(JSON.parse(v).vague))
      })),
      dispatch(fetchClientOrganisation({client_id: clientId})),
      dispatch(fetchProgressModels({clients: [clientId]})),
      dispatch(fetchClientReports({client_id: clientId})),
      dispatch(fetchClientAudits({client_id: clientId})),
      dispatch(fetchClientQuestionnaire({client_id: clientId})),
      dispatch(fetchClientThemesTree({client_id: clientId})),
      dispatch(fetchAllParameters())
    ]);
    stopPending();
    this.setState({initialized: true});

    const pps2 = pps.filter(pp => !!analyses[pp.analyse_id]);

    if (pps2.length !== pps.length) {
      const ppsError = pps.filter(pp => !analyses[pp.analyse_id]);

      this.props.dispatch(setModal('genericAlert', true, {
        data: {
          title: t('pp.pp_error'),
          body: <div>
            {t('pp.error_no_rights')}<br/>
            {t('pp.error_contact_admin')}<br/><br/>
            {t('pp.error_pp_list_:')}<br/>
            {ppsError.map(pp => <span>- {pp.label}<br/></span>)}
          </div>,
          button: {close: true}
        }}))
    }

    this.handleProgressPlansChange(pps2, {zeIds: zone_exploitations, vagues: vague, analyses: analysesFilter});
  }

  handleExport = () => {
    let url = ''; //make query with filters
    let win =  window.open('/api/progres?export&browser&clients[]=' + this.props.clientId, '_blank');
    win.focus();
  };

  handleImport = () => {
    $('#import_file').click();
  };

  handleImportUpload = (event) => {
    uploadImport(event.target.files[0]);
  };

  getColor = (value, passageLow, passageHigh) => {
    if (value === null)
      return {color: 'black', fontWeight: 'bold'};
    else if(value < passageLow)
      return {color: ocmRed, fontWeight: 'bold'};
    else if(value < passageHigh)
      return {color: ocmOrange, fontWeight: 'bold'};

    return {color: ocmGreen, fontWeight: 'bold'};
  };

  pdrGlobalNumber = () => {
    let pdrsId = [];
    this.state.progressPlans.map(plan => {
      plan.zone_exploitations.map(zone => {
        if (!pdrsId.find(id => {return id === zone.id}))
          pdrsId.push(zone.id);
      });
    });
    return pdrsId.length;
  };

  showPdrs = (passageLow, passageHigh, row, isDone) => {
    return (
      row.zone_exploitations.sort((a, b) => {
        if(a.label < b.label) { return -1; }
        if(a.label > b.label) { return 1; }
        return 0;
      }).filter((zone) => {
        if (isDone && zone._joinData.progression === 100)
          return zone;
        else if (!isDone && zone._joinData.progression < 100)
          return zone;
      }).map((zone, idx) => (
        <React.Fragment key={zone.id}>
          {idx !== 0 && ' | '}
          <span>{zone.label + ' '}</span>
          <span style={this.getColor(zone._joinData.progression, passageLow, passageHigh)}>{zone._joinData.progression + '%'}</span>
        </React.Fragment>
      )));
  };

  exportData = () => {
    const {client} = this.props;
    const {progressPlans} = this.state;

    writeToExcel({
      name: `${t('pp.pp_of')} ${client.label} ${moment().format('YYYY-MM-DD HH:mm:ss')}`,
      data: progressPlans,
      columns: [
        {
          text: t('pp.name_of_pp'),
          dataField: 'label'
        },
        {
          text: t('common.status'),
          formatter: (cell, row) => (
            row.status === 'en cours' ? t('common.in_progress') :
              row.status === 'en retard' ? t('pp.late') : t('common.archived')
          )
        },
        {
          text: `${Labels.i2c} ${t('common.got')}`,
          formatter: (col, row) => row.i2cO !== null ? `${Math.round(row.i2cO)}%` : 'NC'
        },
        {
          text: t('common.perimeter'),
          formatter: (col, row) => row.perimetre.map(p => p.path).join(', ')
        },
        {
          text: t('common.priority'),
          dataField: 'priority'
        },
        {
          text: `${Labels.i2c} ${t('common.estimated')}`,
          formatter: (col, row) => row.i2cE !== null ? `${Math.round(row.i2cE)}%` : 'NC',
          display: !!client.i2cE_enable
        },
        {
          text: t('common.progress'),
          formatter: (col, row) => row.progress !== null ? `${Math.ceil(row.progress)}%` : '0%'
        },
        {
          text: `${Labels.ze}`,
          formatter: (col, row) => row.zone_exploitations.map(z => z.label).join(', ')
        },
        {
          text: t('common.actions_preconisations'),
          formatter: (col, row) => row.preconisation
        },
        {
          text: t('pp.actions_done'),
          formatter: (col, row) => row.actions.map(z => z.label).join(', ')
        }
      ]
    })
  };

  render(){
    const {client, analyses} = this.props;
    const {extendedView, progressPlans, initialized, filtersCollapsed, filters} = this.state;
    const labelZoneExploitations = client.label_zone_exploitation;

    const passageLow = !analyses.length ? 70 : _.meanBy(analyses, 'passage_low');
    const passageHigh = !analyses.length ? 85 : _.meanBy(analyses, 'passage_high');

    let analysePassageLow = passageLow;
    let analysePassageHigh = passageHigh;

    if (filters.analyses  && filters.analyses.length === 1 && !!analyses[filters.analyses[0]]){
      analysePassageLow = analyses[filters.analyses[0]].passage_low;
      analysePassageHigh = analyses[filters.analyses[0]].passage_high;
    }

    let columns = [
      {
        dataField: 'perimetre',
        text: t('common.perimeter'),
        sort: true,
        formatter: (cell, row) => {
          const badge = <span class="badge badge-info">{row.perimetre.length > 0 ? (row.perimetre[0].themes ? t('common.theme_badge') : t('common.question_badge')) : t('pp.transverse')}</span>;
          const items = row.perimetre.length > 0 ? row.perimetre.map((p, idx) => {
            return (
              <React.Fragment key={idx}>
                {idx !== 0 && ', '}
                <Tooltip
                  animation="scale"
                  arrow={true}
                  size="small"
                  duration={100}
                  unmountHTMLWhenHide={true}
                  title={p.path}
                  position="top">
                <span>
                  <b>{!!p.numericValue ? `${p.numericValue} ` : ''}</b>{p.label}
                </span>
                </Tooltip>
              </React.Fragment>
            )
          }) : '';
          return (
            <span>
            {badge}
              &nbsp;
              {items}
          </span>
          )
        }
      },
      {
        dataField: 'label',
        text: t('common.label'),
        sort: true
      },
      {
        dataField: 'status',
        text: t('common.status'),
        sort: true,
        headerStyle: { width: '10%' },
        formatter: (cell, row) => (
          row.status === 'en cours' ? t('common.in_progress') :
          row.status === 'en retard' ? t('pp.late') : t('common.archived')
        )
      },
      {
        dataField: 'i2cO',
        text: `${!!this.props.client.label_i2c ? this.props.client.label_i2c : 'I2C'} ${t('common.got')}`,
        headerStyle: { width: '8%' },
        sort: true,
        formatter: (cell, row) => {
          const passageLow = !!analyses[row.analyse_id] ? analyses[row.analyse_id].passage_low : passageLow;
          const passageHigh = !!analyses[row.analyse_id] ? analyses[row.analyse_id].passage_high : passageHigh;

          const i2c = row.i2cO !== null ? Math.round(row.i2cO) : 'NC';
          const color = i2c < passageLow ? ocmRed : i2c < passageHigh ? ocmOrange : ocmGreen;

          return (
            <strong style={{color: i2c === 'NC' ? 'black' : color}}>{i2c + (i2c === 'NC' ? '' : '%')}</strong>
          )
        }
      },
      {
        dataField: 'priority',
        text: t('common.priority'),
        sort: true,
        headerStyle: { width: '8%' }
      },
      {
        dataField: 'zone_exploitations',
        text: `${t('pp.nb_of')}${labelZoneExploitations}`,
        sort: true,
        headerStyle: { width: '10%' },
        formatter: (cell, row) => {
          const number = row.zone_exploitations.length;

          return (
            <strong style={{color: 'black'}}>{number}</strong>
          )
        }
      },
      {
        dataField: 'vague',
        text: t('common.vague'),
        sort: true,
        headerStyle: { width: '8%' }
      },
      {
        dataField: 'id',
        text: t('common.creation_date'),
        sort: true,
        headerStyle: { width: '10%' },
        formatter: (cell, row) => {
          const date = new Date(row.created * 1000);

          return (
            <span>
              {`${date.getDate() > 9 ? '' : '0'}${date.getDate()}/${date.getMonth() > 8 ? '' : '0'}${date.getMonth() + 1}/${date.getFullYear()}`}
            </span>
          )
        }
      },
      {
        dataField: 'progress',
        text: t('common.progress'),
        sort: true,
        formatter: (cell, row) => {
          const analyse_id = row.analyse_id;
          const progress = row.progress !== null ? Math.ceil(row.progress) : '0';
          const {passage_high, passage_low} = !!analyses ? analyses[analyse_id] : {passage_high: 80, passage_low: 50};
          const color = progress < passage_low ? ocmRed : progress < passage_high ? ocmOrange : ocmGreen;

          return (
            <div className="progress">
              <div
                role='progressbar'
                style={{width: `${progress}%`, backgroundColor: color}}
                aria-valuemin='0'
                aria-valuemax='100'
                className={`progress-bar`}>
                {progress}%
              </div>
            </div>
          )
        }
      },
      {
        dataField: '',
        text: '',
        isDummyField: true,
        headerStyle: {
          width: 60
        },
        formatter: (cell, row) => {
          return (
            <div class="d-flex">
              {(this.state.viewPerm) &&
              <Link
                title={t('pp.modify_pp')}
                className={'btn btn-secondary btn-sm'}
                to={`${getRoute(ProPla_Mon_Sin)}/${row.id}`}>
                <span className="oi oi-pencil"/>
              </Link>}
            </div>
          )
        }
      }
    ];

    if (!!client.i2cE_enable)
      columns.splice(5, 0, {
        dataField: 'i2cE',
        text: `${!!this.props.client.label_i2c ? this.props.client.label_i2c : 'I2C'} ${t('common.estimated')}`,
        sort: true,
        headerStyle: { width: '8%' },
        formatter: (cell, row) => {
          const passageLow = !!analyses[row.analyse_id] ? analyses[row.analyse_id].passage_low : passageLow;
          const passageHigh = !!analyses[row.analyse_id] ? analyses[row.analyse_id].passage_high : passageHigh;

          const i2c = row.i2cE !== null ? Math.round(row.i2cE) : 'NC';
          const color = i2c < passageLow ? ocmRed : i2c < passageHigh ? ocmOrange : ocmGreen;

          return (
            <strong style={{color: i2c === 'NC' ? 'black' : color}}>{i2c + (i2c === 'NC' ? '' : '%')}</strong>
          )
        }
      });

    if (!initialized)
      return null;

    const tablesIndicators = (
      <Row class="mb-3">
        <Col sm={4}>
          <a className='metric metric-bordered align-items-center'>
            <h2 className='metric-label'>Nombre de {window.Labels.ze} concernés</h2>
            <p className='metric-value h3'>
              <span className='value'>{this.pdrGlobalNumber()}</span>
            </p>
          </a>
        </Col>
        <Col sm={4}>
          <a className='metric metric-bordered align-items-center'>
            <h2 className='metric-label'>{window.Labels.i2c} global obtenu</h2>
            <p className='metric-value h3'>
              <span className='value' style={this.getColor(this.state.i2cOG, analysePassageLow, analysePassageHigh)}>
                {this.state.i2cOG ? Number(this.state.i2cOG).toFixed(0) + '%' : 'NC'}
              </span>
            </p>
          </a>
        </Col>
        <Col sm={4}>
          <a className='metric metric-bordered align-items-center'>
            <h2 className='metric-label'>{window.Labels.i2c} global estimé</h2>
            <p className='metric-value h3'>
              <span className='value' style={this.getColor(this.state.i2cEG, analysePassageLow, analysePassageHigh)}>
                {this.state.i2cEG ? Number(this.state.i2cEG).toFixed(0) + '%' : 'NC'}
              </span>
            </p>
          </a>
        </Col>
      </Row>
    );

    return(
      <div className='page-inner browseProgressPlans'>
        <header className='page-title-bar'>
          <div className='d-md-flex align-items-md-start'>
            <h1 className='page-title mr-sm-auto'>{t('pp.follow')}</h1>
            <input id='import_file' onChange={this.handleImportUpload} style={{visibility: 'hidden'}} type='file'/>
            <div className='btn-toolbar'>
              <Button
                color="success"
                title="Exporter en excel"
                size="sm"
                onClick={this.exportData}>
                <i className="far fa-file-excel"/>
                &nbsp;
                {t('common.export')}
              </Button>
            </div>
          </div>
        </header>
        <div className=''>
          <div className='section-block'>
            <div id='accordion2' className='card-expansion'>
              {/*<Card class={"card-expansion-item"}>*/}
              {/*<CardHeader>*/}
              {/*<button*/}
              {/*class={`btn btn-reset d-flex justify-content-between w-100 ${filtersCollapsed ? 'collapsed' : ''}`}*/}
              {/*// data-toggle="collapse"*/}
              {/*//  data-target="#new_typePP"*/}
              {/*//  aria-expanded="false"*/}
              {/*onClick={() => this.setFiltersCollapsed(!filtersCollapsed)}*/}
              {/*aria-controls="new_typePP">*/}
              {/*<h4 className='card-title m-0'>{t('common.filters')}</h4>*/}
              {/*<span className="collapse-indicator">*/}
              {/*<i className="fa fa-fw fa-chevron-down"/>*/}
              {/*</span>*/}
              {/*</button>*/}
              {/*</CardHeader>*/}
              {/*<Collapse isOpen={!filtersCollapsed}>*/}
              {/*<CardBody>*/}
              <ProgressPlanFilter
                className={"card-expansion-item"}
                value={this.state.filters}
                clientId={this.props.clientId}
                resetFilters={this.resetFilters}
                filter={this.filter}
                onChange={this.handleFiltersChange}/>
              {/*</CardBody>*/}
              {/*</Collapse>*/}
              {/*</Card>*/}
              {/*<section className='card card-expansion-item'>*/}
              {/*<header className="card-header" id="headingOne">*/}
              {/*<button className="btn btn-reset d-flex justify-content-between w-100" data-toggle="collapse" data-target="#new_typePP" aria-expanded="false" aria-controls="new_typePP">*/}
              {/*<h4 className='card-title m-0'>{t('common.filters')}</h4>*/}
              {/*<span className="collapse-indicator">*/}
              {/*<i className="fa fa-fw fa-chevron-down"/>*/}
              {/*</span>*/}
              {/*</button>*/}
              {/*</header>*/}
              {/*<div id="new_typePP" className="collapse" aria-labelledby="headingOne" data-parent="#accordion2">*/}
              {/*<div className="card-body pt-0">*/}
              {/*<ProgressPlanFilter*/}
              {/*value={this.state.filters}*/}
              {/*clientId={this.props.clientId}*/}
              {/*resetFilters={this.resetFilters}*/}
              {/*filter={this.filter}*/}
              {/*onChange={this.handleFiltersChange}/>*/}
              {/*</div>*/}
              {/*</div>*/}
              {/*</section>*/}
            </div>
            <Button color="primary"
                    class="mb-3"
                    onClick={this.toggleExtendedView}>
              {t('common.change_view')}
            </Button>
            {(!!client.i2cE_enable && filters.analyses && filters.analyses.length === 1 && filters.vague  && filters.vague.length === 1) &&
            tablesIndicators}
            {!extendedView &&
            <section className='card card-fluid'>
              <div className='card-body'>
                <ToolkitProvider
                  keyField='id'
                  columns={columns}
                  bootstrap4={true}
                  data={progressPlans}
                  search>
                  {props => (
                    <div>
                      <DebounceSearchInput
                        onChange={(e) => props.searchProps.onSearch(e.target.value)}/>
                      <BootstrapTable
                        bordered={false}
                        defaultSorted={[{
                          dataField: 'label',
                          order: 'asc'
                        }]}
                        classes={'bulk-selection'}
                        noDataIndication={() => t('common.no_data')}
                        pagination={defaultPaginationFactory()}
                        expandRow={{
                          renderer: row => {
                            const passageLow = !!analyses[row.analyse_id] ? analyses[row.analyse_id].passage_low : passageLow;
                            const passageHigh = !!analyses[row.analyse_id] ? analyses[row.analyse_id].passage_high : passageHigh;

                            return (
                              <div>
                                {row.zone_exploitations.find(zone => (zone._joinData.progression < 100)) &&
                                <strong className="d-block">{t('common.in_progress')} :</strong>}
                                {this.showPdrs(passageLow, passageHigh, row, false)}
                                {row.zone_exploitations.find(zone => (zone._joinData.progression === 100)) &&
                                <strong className="d-block">{t('common.archived')} :</strong>}
                                {this.showPdrs(passageLow, passageHigh, row, true)}
                              </div>
                            )
                          },
                          showExpandColumn: true,
                          expandByColumnOnly: true,
                          expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                            return isAnyExpands ? <i className="fa fa-caret-down cursor-pointer"/> : <i class="fa fa-caret-right cursor-pointer"/>;
                          },
                          expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
                            if (!expandable)
                              return null;
                            return expanded ? <i className="fa fa-caret-down cursor-pointer"/> : <i class="fa fa-caret-right cursor-pointer"/>;
                          }
                        }}
                        {...props.baseProps}/>
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </section>}
            {extendedView &&
            <ExtendedBrowseView progressPlanIds={progressPlans.map(p => p.id)} zeIds={this.state.zeIds}/>}
          </div>
        </div>
      </div>
    )
  }
}

export default Browse;
