import React from "react";
import {connect} from "react-redux";
import {setConstatReadable} from "../../actions/modals";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

@connect(({modals : {constatReadable : {text, open}}}) => ({
  text,
  open
}),
dispatch => ({
  close: () => {
    dispatch(setConstatReadable({
      open: false
    }))
  }
}))
class ConstatReadable extends React.Component {
  state = {
    closing: false
  };
  close = () => {
    this.props.close();
  };
  closing = () => {
    this.setState({closing: true})
  };
  render(){
    const {text} = this.props;
    const {closing} = this.state;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.closing}>{t('common.constat')}</ModalHeader>
        <ModalBody>
          {text}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.closing}>{t('common.close')}</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ConstatReadable;
