import {ocmGreen, ocmRed, ocmOrange, ocmBlack} from "../constants/ui";
import {hydrateEntities, updateEntities} from "../actions/orm";
import {_Analyse} from "../constants/orm";
import XLSX from "xlsx";
import _ from "lodash";
import {createAuditByReportIdSelector} from "../components/selectors/audit";
import {createSessionUserSelector} from "../components/selectors/user";

export const getMinLevelCheckedItemsFromHorizontalTree = (horizontalTree) => {
  const checked = horizontalTree.filter(i => !!i.checked);
  let minLvl = 999;

  checked.forEach(item => {
    if (item.level < minLvl)
      minLvl = item.level
  });

  const filteredItems = checked.filter(i => i.level === minLvl);

  return {
    type: filteredItems[0].type,
    itemIds: filteredItems.map(item => item.id)
  }
};

export const setupLevelsInQuestionnaireTree = (tree, lvl = 0) => {
  tree.forEach(item => {
    item.level = lvl;
    if (!!item.ChildThemes && !!item.ChildThemes.length)
      setupLevelsInQuestionnaireTree(item.ChildThemes, lvl + 1);
    else if (!!item.questions && !!item.questions.length)
      setupLevelsInQuestionnaireTree(item.questions, lvl + 1);
  })
};

export const setupLevelsInOrganisationsTree = (tree, lvl = 0) => {
  tree.forEach(item => {
    item.level = lvl;
    if (!!item.ChildClients && !!item.ChildClients.length)
      setupLevelsInOrganisationsTree(item.ChildClients, lvl + 1);
    else if (!!item.sites && item.sites.length)
      setupLevelsInOrganisationsTree(item.sites, lvl + 1);
    else if (!!item.zone_exploitations && !!item.zone_exploitations.length)
      setupLevelsInOrganisationsTree(item.zone_exploitations, lvl + 1);
  });
};

export const setupLevelsInTree = (tree, lvl = 0) => {
  tree.forEach(item => {
    item.level = lvl;
    if (!!item.children && !!item.children.length)
      setupLevelsInTree(item.children, lvl + 1);
  })
};

export function reflect(promise){
  return promise.then(function(v){ return {data:v, error: false }},
    function(e){ return {data:e, error: true }});
}

export const isQuestionResponseValid = ({answer, photo, file, value, valid, photos, files}) => {
  if (answer && value === null)
    return false;
  if (value === null && !valid)
    return false;
  if (photo && !photos.length)
    return false;
  if (file && !files.length)
    return false;
  return true;
};

export const getI2cColor = (value, passageLow, passageHigh) => {
  if (value === null)
    return ocmBlack;
  const roundedValue = Math.round(value);

  return roundedValue < passageLow ? ocmRed : roundedValue < passageHigh ? ocmOrange : ocmGreen;
};

export function getLabel(source, reports, analyses, dispatch) {
  const analyse = analyses.find(a => a.id === source.id);

  analyse.vagues.map(v => {
    v.label = !v.label ? v.vague : v.label;
    v.end_date = 0;
    v.sources.map(s => {
      const report = s.model === "Report" ?
        reports.find(r => r.id === s.id) :
        analyses.find(aa => aa.id === s.source);

      if (s.model === "Report" && !!report) {
        s.label = report.label;

        if (v.end_date < report.end_date) {
          v.label = report.label;
          v.end_date = report.end_date;
        }
      }
      else if (s.model === "AnalyseReport" && !!report) {
        if (!s.label)
          getLabel(report, reports, analyses, dispatch);
        s.label = report.vagues.find(vv => vv.vague === s.id).label;

        if (!!v.label && !!isNaN(v.label)) {
          const month1 = v.label.slice(0, 2);
          const year1 = v.label.slice(3, 7);
          const month2 = s.label.slice(0, 2);
          const year2 = s.label.slice(3, 7);

          if (`${year1}-${month1}` < `${year2}-${month2}`)
            v.label = s.label;
        }
        else
          v.label = s.label;
      }
    });
  });

  if (!!dispatch)
    dispatch(updateEntities({
      payload: [
        {type: _Analyse, entities: [analyse]}
      ]
    }))
}

export const writeToExcel = ({name, columns, data}) => {
  let wb = XLSX.utils.book_new();

  wb.Props = {
    Title: name
  };
  // wb.SheetNames.push('Données');
  let work_sheet = [];

  //header row
  work_sheet.push(columns.filter(function(c) {
    if (c.display === false)
      return false;
    return true;
  }).map(c => c.text));

  data.forEach(d => {
    work_sheet.push(columns.filter(function(c) {
      if (c.display === false)
        return false;
      return true;
    }).map(c => {
      if (!!c.formatter)
        return c.formatter(null, d);
      return d[c.dataField];
    }));
  });

  var ws = XLSX.utils.aoa_to_sheet(work_sheet);
  
  ws['!cols'] = []
  columns.map( (c,k) => {
    if(c.hidden)
      ws['!cols'][Number(k)] = {hidden: c.hidden};
  });

  XLSX.utils.book_append_sheet(wb, ws, "Données");
  XLSX.writeFile(wb, `${name}.xlsx`);
};


export const writeToExcelVisits = ({name, columnsThemes, columnsQuestions, datas}) => {
  let wb = XLSX.utils.book_new();

  wb.Props = {
    Title: name
  };
  wb.SheetNames.push('Données');
  let work_sheet = [];

  //header row
  work_sheet.push(columnsThemes.map(c => c.text));

  // function columns(data) {
  //   if (data.type === 'theme') {
  //     work_sheet.push(columnsThemes.map(c => {
  //       if (!!c.formatter)
  //         return c.formatter(null, data);
  //       return data[c.dataField];
  //     }));
  //   }
  //   else {
  //     work_sheet.push(columnsQuestions.map(c => {
  //       if (!!c.formatter)
  //         return c.formatter(null, data);
  //       return data[c.dataField];
  //     }));
  //   }
  //   if (!!data.children && !!data.children.length) {
  //     if (data.children[0].type === 'question')
  //       work_sheet.push(columnsQuestions.map(c => c.text));
  //     data.children.forEach(d => {
  //       columns(d);
  //     });
  //   }
  // }

  work_sheet.push(columnsQuestions.map(c => c.text));

  datas.forEach(d => {
    work_sheet.push(columnsQuestions.map(c => {
      if (!!c.formatter)
        return c.formatter(null, d);
      return d[c.dataField];
    }));
  });

  wb.Sheets['Données'] = XLSX.utils.aoa_to_sheet(work_sheet);
  XLSX.writeFile(wb, `${name}.xlsx`);
};

export const getAllAuditsOfAnalyse = (analysesObject, analyseId) => {
  const analyse = analysesObject[analyseId];
  let audits = [];

  analyse.sources.map(source => {
    if (source.model === 'Audit')
      audits.push(source.id);
    else if (source.model === 'Analyse')
      audits.concat(getAllAuditsOfAnalyse(analysesObject, source.cible));
  });

  return _.uniq(audits.map(aId => Number(aId)));
};

const getMe = createSessionUserSelector();
const getAuditsIdSelector = createAuditByReportIdSelector();

export const getReportsAuditsOfAnalyseVague = (analysesObject, analyseId, vagueId, zeIds) => {
  const analyse = analysesObject[analyseId];
  const vague = analyse.vagues.find(vague => vague.vague === vagueId);
  let reports = [];
  let auditIds = [];
  const me = getMe();

  vague.sources.map(async source => {
    if (source.model === 'Report') {
      const audit = await getAuditsIdSelector({reportId: source.id});

      if (analyse.user_ids.includes(me.id) && audit.zone_exploitation_ids.some(el => zeIds.includes(el))) {
        reports.push(source.id);
        auditIds.push(audit.id);
      }
    }
    else if (source.model === 'AnalyseReport')
      reports.concat(getReportsAuditsOfAnalyseVague(analysesObject, source.source, source.id));
  });

  return {reports, auditIds};
};
