import createSelector from "../../tools/createSelector";

export const createTaxonomySelector = () => {
  return createSelector(
    (orm, {taxId}) => {
      const taxonomy = orm.Taxonomy.withId(taxId);

      return !!taxonomy ? taxonomy.ref : null
    }
  )
};

export const createClientRootTaxonomiesSelector = () => {
  return createSelector(
    (orm, {clientId}) => {
      const client = orm.Client.withId(clientId);

      const taxonomies = client.taxonomies.filter(i => !i.parent_id).toRefArray();

      return taxonomies;
    }
  )
};

export const createTaxonomyTreeSelector = () => {
  return createSelector(
    (orm, {taxId}) => {
      const taxonomy = orm.Taxonomy.withId(taxId);

      function setupTree(nodes){
        return nodes.map(node => {
          return {
            ...node.ref,
            children: setupTree(node.children.toModelArray())
          }
        });
      }

      return !!taxonomy ? setupTree([taxonomy]) : []
    }
  )
};

export const createSelectedTaxonomiesListSelector = () => {
  return createSelector(
    (orm, {clientId, selectedIds}) => {
      const rootTaxonomies = orm.Taxonomy.all().filter(i => (!i.parent_id && i.client_id === clientId)).toRefArray();
      let selectedTaxonomiesObj = {};

      function getParent(taxonomy){
        while (!!taxonomy.parent_id)
          taxonomy = taxonomy.parent_id;
        return taxonomy;
      }

      selectedIds.forEach(id => {
        const taxonomy = orm.Taxonomy.withId(id);
        const parent = getParent(taxonomy);
        selectedTaxonomiesObj[parent.id] = taxonomy.ref;
      });

      return rootTaxonomies.map(item => {
        return {
          root: item,
          leaf: selectedTaxonomiesObj[item.id]
        }
      })
    }
  )
};