export const HYDRATE_ENTITIES = 'HYDRATE_ENTITIES';
export const UPDATE_ENTITIES = 'UPDATE_ENTITIES';
export const DELETE_ENTITY = 'DELETE_ENTITY';
export const FORCE_UPDATE_ENTITIES = "FORCE_UPDATE_ENTITIES";
export const CLEAR_DB = "CLEAR_DB";
export const GENERIC_HYDRATE_ENTITIES = "GENERIC_HYDRATE_ENTITIES";
export const BASIC_UPDATE_ENTITIES = "BASIC_UPDATE_ENTITIES";

// export * from "./orm/User"; fix webpack prod compile (might be circular import, hard to tell)
export * from "./orm/ProgressModel";
export * from "./orm/ModelAction";
export * from "./orm/Message";
export * from "./orm/ProgressZone";
export * from "./orm/Client";
export * from "./orm/ProgressPlan";

export function createBasicCreateAction(model, apiCall){
  return (props) => {
    return async dispatch => {
      const item = await apiCall(props);

      dispatch(hydrateEntities({
        payload: [
          {type: model, entities: Array.isArray(item) ? item : [item]}
        ]
      }));

      return item;
    }
  }
}

export function createBasicUpdateAction(model, apiCall){
  return (props) => {
    return async dispatch => {
      const item = await apiCall(props);

      dispatch(updateEntities({
        payload: [
          {type: model, entities: Array.isArray(item) ? item : [item]}
        ]
      }));

      return item;
    }
  }
}

export function createBasicFetchAction(model, apiCall){
  return (props) => {
    return async dispatch => {
      const items = await apiCall(props);

      dispatch(hydrateEntities({
        payload: [
          {type: model, entities: items}
        ]
      }));

      return item;
    }
  }
}

export const basicUpdateEntitiees = ({payload}) => {
  return {
    type: BASIC_UPDATE_ENTITIES,
    payload
  }
};

export const forceUpdateEnitities = ({payload}) => {
  return {
    type: FORCE_UPDATE_ENTITIES,
    payload
  }
};

export const hydrateEntities = ({payload}) => {
  return {
    type: HYDRATE_ENTITIES,
    payload
  }
};

export const deleteEntity = ({type, id}) => {
  return {
    type: DELETE_ENTITY,
    payload: {
      type,
      id
    }
  }
};

export const updateEntities = ({payload}) => {
  return {
    type: UPDATE_ENTITIES,
    payload
  }
};

export const genericHydrateEntities = ({payload}) => {
  return {
    type: GENERIC_HYDRATE_ENTITIES,
    payload
  }
};
