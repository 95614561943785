import React from "react";

const ImageDiv = ({src, children, style, ...otherProps}) => {
  return (
    <div
      style={{
        backgroundImage: `url("${src}")`,
        ...style
      }}
      {...otherProps}>
      {children}
    </div>
  )
};

export {ImageDiv};
export default ImageDiv;