import {fk, attr, Model} from 'redux-orm';
import {_Analyse, _ZoneExploitation} from "../constants/orm";

export class RapportsProcessing extends Model {
  static parse(data){
    return data;
  }

  static get fields(){
    return {
      id: attr(),
      analyse_id : fk(_ZoneExploitation),
      zone_exploitation_id: fk(_ZoneExploitation, 'RapportsProcessing')
    }
  }
}

RapportsProcessing.modelName = 'RapportsProcessing';