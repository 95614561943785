import api from "../../tools/api";
import {_User, _Profile, _Client, _Prestataire} from "../../constants/orm";
import orm from "../../orm";
import {SET_SESSION_USER} from "../session";
import {deleteEntity, hydrateEntities, updateEntities} from "../orm";
import {RECEIVE_USER} from "../session";
import {setModal} from "../modals";

export const fetchSessionUser = () => {
  return async dispatch => {
    const user = await api.users.getMe();
    const {roots, prestataires} = user;

    i18n.changeLanguage(user.locale);
    dispatch(hydrateEntities({
      payload: [
        {type: _Profile, entities: [user.profil]},
        {type: _User, entities: [user]},
        {type: _Prestataire, entities: prestataires},
        {type: _Client, entities: roots}
      ]
    }));

    dispatch({
      type: SET_SESSION_USER,
      user
    });

    return user;
  }
};

export const fetchUser = ({id}) => {
  return async dispatch => {
    return api.users.get({
      id: id
    }).then(user => {
      dispatch(hydrateEntities({
        payload: [{type: _User, entities: [user]}]
      }));
      return user;
    });
  }
};

export const fetchUserIfNeeded = ({id}) => {
  return async (dispatch, getState) => {
    const session = orm.session(getState().db);

    if (!session.User.idExists(id))
      return dispatch(fetchUser({id}));
    return session.User.withId(id).ref;
  }
};

export const addUser = ({email, firstname, lastname, phone, path, active, level, client_id, prestataire_id, firm_id, profil_id, default_client_id, clients, scope_zones_exploitation, scope_sites, prestataires, locale, jobtitle}) => {
  return async dispatch => {
    const user = await api.users.add({
      email,
      firstname,
      lastname,
      phone,
      path,
      active,
      level,
      client_id,
      prestataire_id,
      firm_id,
      profil_id,
      default_client_id,
      clients,
      sites : !!scope_sites ? scope_sites : undefined,
      zones_exploitation: !!scope_zones_exploitation ? scope_zones_exploitation : undefined,
      prestataires,
      locale,
      jobtitle
    });

    dispatch(hydrateEntities({
      payload: [
        {type: _User, entities: [user]}
      ]
    }));
    return user;
  }
};

export const updateUser = ({id, email, firstname, lastname, phone, path, active, client_id, prestataire_id, profil_id, default_client_id, clients, scope_zones_exploitation, scope_sites, prestataires, locale, jobtitle, remove_clients, remove_sites, remove_zonesExploitations, remove_prestataires}) => {
  return async dispatch => {
    const user = await api.users.update({
      id,
      email,
      firstname,
      lastname,
      phone,
      path,
      active,
      client_id,
      prestataire_id,
      profil_id,
      default_client_id,
      clients,
      sites : !!scope_sites ? scope_sites : undefined,
      zones_exploitation: !!scope_zones_exploitation ? scope_zones_exploitation : undefined,
      prestataires,
      remove_clients,
      remove_sites,
      remove_zonesExploitations,
      remove_prestataires,
      locale,
      jobtitle
    });

    dispatch(updateEntities({
      payload: [
        {type: _User, entities: [user]}
      ]
    }));
    return user;
  }
};

export const updateUserClients = ({id, clients}) => {
  return async dispatch => {
    const user = await api.users.updateClients({
      id,
      clients
    });

    dispatch(updateEntities({
      payload: [
        {type: _User, entities: [user]}
      ]
    }));

    return user;
  }
};

export const updateUserPrestataires = ({id, prestataires}) => {
  return async dispatch => {
    const user = await api.users.updatePrestataires({
      id,
      prestataires
    });

    dispatch(updateEntities({
      payload: [
        {type: _User, entities: [user]}
      ]
    }));

    return user;
  }
};

export const deleteUser = ({id}) => {
  return async dispatch => {
    return dispatch(setModal('genericAlert', true, {
      data: {
        title: `Suppression d'utilisateur`,
        body: <div>Êtes-vous sûr de vouloir supprimer cet utilisateur ?</div>,
        button: {cancel: true, confirm: true}
      }})).then(r => {
        api.users.deleteUser({
          id
        }).then(res => {
          dispatch(deleteEntity({
            type: _User,
            id: id
          }));
          return res;
        }).catch(err => alert('Impossible de supprimer cet utilisateur.'));
    });
  }
};

export const fetchSessionEnterpriseUsers = () => {
  return async (dispatch, getState) => {
    const store = getState();
    const userId = store.session.userId;
    const session = orm.session(store.db);
    const user = session.User.withId(userId).ref;
    let users = [];

    if (!!user.client_id)
      users = await api.client.getUsers({client_id: user.client_id});
    else if (!!user.firm_id)
      users = await api.firms.getUsers({firm_id: user.firm_id});

    dispatch(hydrateEntities({
      payload: [
        {type: _User, entities: users}
      ]
    }));
    return users;
  }
};
