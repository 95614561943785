import React from "react";
import {Red, Orange, Green} from "../utils";
import {Bar} from "react-chartjs-2";
import Color from "color";
import {_progressPlanState} from "../../../../constants/types";

class ProgressPlanNumber extends React.PureComponent {
  render(){
    const {vague: {progressPlanStateDistribution}} = this.props;

    // const notStarted = pps.filter(p => p.progression === 0);
    // const current = pps.filter(p => p.progression > 0 && p.progression < 100);
    // const ended = pps.filter(p => p.progression === 100);

    const data = {
      labels: ['Non débutés', 'En cours', 'Finalisés'],
      datasets: [{
        labels: 'Plans de progrès',
        data: [progressPlanStateDistribution[_progressPlanState.new], progressPlanStateDistribution[_progressPlanState.started], progressPlanStateDistribution[_progressPlanState.finished]],
        backgroundColor: [Color(Red).rgb().string(),Color(Orange).rgb().string(),Color(Green).rgb().string()],
        borderColor: [Red, Orange, Green],
        borderWidth: 1
      }]
    };

    const options = {
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          gridLines: {
            display:false
          }
        }],
        yAxes: [{
          gridLines: {
            display:false
          },
          ticks: {
            beginAtZero: true,
            stepSize: 1
          },
          minBarLength: 3
        }]
      }
    };

    return (
      <div>
        <Bar data={data} options={options}/>
      </div>
    )
  }
}

export {ProgressPlanNumber};
export default ProgressPlanNumber;
