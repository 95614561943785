import {fk, attr, Model} from 'redux-orm';
import {_ProgressPlan, _ProgressClient, _Client} from "../constants/orm";

export class ProgressClient extends Model{
    static parse(data){
        const {responsable, ...item} = data;
        return item;
    }

    static get fields(){
        return {
            id: attr(),
            progre_id: fk(_ProgressPlan, 'progressClient'),
            client_id: fk(_Client),
            progression: attr(),
            created: attr(),
            modified: attr()
        }
    }
}

ProgressClient.modelName = _ProgressClient;
