import {fk, attr, Model} from 'redux-orm';
import {_Brand} from "../constants/orm";

export class Brand extends Model {
  static parse(data){
    return data;
  }

  static get fields(){
    return {
      id: attr(),
      label: attr(),
      path: attr()
    }
  }
}

Brand.modelName = _Brand;