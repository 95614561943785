export const SET_CONSTAT_READABLE = 'SET_CONSTAT_READABLE';
export const SET_CONSTAT_SET = 'SET_CONSTAT_SET';
export const SET_PROGRESS_PLAN_MODEL_CREATE = 'SET_PROGRESS_PLAN_MODEL_CREATE';
export const SET_PROGRESS_PLAN_MODEL_DELETE = 'SET_PROGRESS_PLAN_MODEL_DELETE';
export const SET_PROGRESS_PLAN_MODEL_EDIT = "SET_PROGRESS_PLAN_MODEL_EDIT";
export const SET_CHOOSE_QUESTIONNAIRE_ITEMS = "SET_CHOOSE_QUESTIONNAIRE_ITEMS";
export const SET_CHOOSE_ORGANISATIONS_ITEMS = "SET_CHOOSE_ORGANISATIONS_ITEMS";
export const SET_SETUP_NEW_ZONE_EXPLOITATION_OBJECT = "SET_SETUP_NEW_ZONE_EXPLOITATION_OBJECT";
export const SET_SETUP_NEW_CLIENT_OBJECT = "SET_SETUP_NEW_CLIENT_OBJECT";
export const SET_SETUP_NEW_SITE_OBJECT = "SET_SETUP_NEW_SITE_OBJECT";
export const SET_SETUP_NEW_PRESTATAIRE_OBJECT = "SET_SETUP_NEW_PRESTATAIRE_OBJECT";
export const SET_SETUP_MODIFIED_PRESTATAIRE_OBJECT = "SET_SETUP_MODIFIED_PRESTATAIRE_OBJECT";
export const SET_SETUP_MODIFIED_SITE_OBJECT = "SET_SETUP_MODIFIED_SITE_OBJECT";
export const SET_MODIFY_CLIENT_PRESTATAIRES = "SET_MODIFY_CLIENT_PRESTATAIRES";
export const SET_REMOVE_CLIENT_PRESTATAIRE = "SET_REMOVE_CLIENT_PRESTATAIRE";
export const SET_MODIFY_PRESTATAIRE_CLIENTS = "SET_MODIFY_PRESTATAIRE_CLIENTS";
export const SET_ADD_MODIFY_CLIENT = "SET_ADD_MODIFY_CLIENT";
export const SET_ADD_MODIFY_PRESTATAIRE = "SET_ADD_MODIFY_PRESTATAIRE";
export const SET_ADD_PROFILE = "SET_ADD_PROFILE";
export const SET_ADD_MODIFY_USER = "SET_ADD_MODIFY_USER";
export const SET_COPY_ROOT_CLIENTS_PROGRESS_MODELS = "SET_COPY_ROOT_CLIENTS_PROGRESS_MODELS";
export const SET_CHANGE_USERS_PROFILE = "SET_CHANGE_USERS_PROFILE";
export const SET_CHANGE_USERS_ACTIVE_STATE = "SET_CHANGE_USERS_ACTIVE_STATE";
export const SET_ADD_NEW_TAXONOMY = "SET_ADD_NEW_TAXONOMY";
export const SET_GENERIC_CONFIRMATION = "SET_GENERIC_CONFIRMATION";
export const SET_TAXONOMY_ITEM_SELECTION = "SET_TAXONOMY_ITEM_SELECTION";
export const SET_TAXONOMY_ITEMS_FILTER_SELECT = "SET_TAXONOMY_ITEMS_FILTER_SELECT";
export const SET_VIEW_IMAGE = "SET_VIEW_IMAGE";
export const SET_ADD_MODIFY_BRAND = "SET_ADD_MODIFY_BRAND";
export const SET_REMOVE_BRAND = "SET_REMOVE_BRAND";
export const SET_ZONE_EXPLOITATIONS_SURVEY_INFO = "SET_ZONE_EXPLOITATIONS_SURVEY_INFO";
export const SET_ZONE_EXPLOITATIONS_SURVEY_RESPONSE = "SET_ZONE_EXPLOITATIONS_SURVEY_RESPONSE";
export const SET_ADD_MODIFY_FIRM = "SET_ADD_MODIFY_FIRM";
export const SET_CHOOSE_ZONES_EXPLOITATION_ITEMS = "SET_CHOOSE_ZONES_EXPLOITATION_ITEMS";
export const SET_REPORT_ZONES_EXPLOITATION_OVERVIEW = "SET_REPORT_ZONES_EXPLOITATION_OVERVIEW";
export const SET_VISITS_DATE_CHANGE = "SET_VISITS_DATE_CHANGE";
export const SET_VISITS_AUDITOR_CHANGE = "SET_VISITS_AUDITOR_CHANGE";
export const SET_DATE_RANGE_PICKER = "SET_DATE_RANGE_PICKER";
export const SET_REPORTING_VIEW = "SET_REPORTING_VIEW";
export const SET_WRITE_TEXT = "SET_WRITE_TEXT";
export const SET_WRITE_EDITOR_TEXT = "SET_WRITE_EDITOR_TEXT";
export const SET_UPLOAD_FILE = "SET_UPLOAD_FILE";
export const SET_ADD_MODIFY_QUESTION = "SET_ADD_MODIFY_QUESTION";
export const SET_INPUT_MODAL = "SET_INPUT_MODAL";
export const SET_CHOOSE_QUESTION = "SET_CHOOSE_QUESTION";
export const SET_SETUP_AUDIT_OBJECT = "SET_SETUP_AUDIT_OBJECT";
export const SET_CHOOSE_CLIENT_AUDIT = "SET_CHOOSE_CLIENT_AUDIT";
export const SET_MODIFY_AUDIT_USERS = "SET_MODIFY_AUDIT_USERS";
export const SET_MODAL = "SET_MODAL";
export const SET_REMOVE_ANALYSE = "SET_REMOVE_ANALYSE";
export const SET_EDIT_COEFFICIENT = "SET_EDIT_COEFFICIENT";
export const SET_EDIT_COEFFICIENT_IN_TREE = "SET_EDIT_COEFFICIENT_IN_TREE";
export const SET_EDIT_ANALYSE_VAGUES = "SET_EDIT_ANALYSE_VAGUES";

import {modals} from "../components/modals/modalsDescriber";

export const closeModal = (modalName) => {
  return {
    type: SET_MODAL,
    modalName,
    open: false
  }
};

export const setModal = (modalName, open, modalState) => {
  const modalDescription = modals[modalName];
  const {promiseBased} = modalDescription;

  switch (true) {
    case !open:
      return {
        type: SET_MODAL,
        modalName,
        open
      };
    case !promiseBased:
      return {
        type: SET_MODAL,
        modalName,
        open,
        ...modalState
      };
    default:
      return dispatch => {
        return new Promise((resolve, reject) => {
          dispatch({
            type: SET_MODAL,
            open,
            modalName,
            resolve,
            reject,
            ...modalState
          });
        });
      }
  }
};

export const setModifyAuditUsers = ({open, auditId}) => {
  return {
    type: SET_MODIFY_AUDIT_USERS,
    open,
    auditId
  }
};

export const setChooseClientAudit = ({open, clientId, ignoredAuditIds}) => {
  if (!open)
    return {
      type: SET_CHOOSE_CLIENT_AUDIT,
      open
    };
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_CHOOSE_CLIENT_AUDIT,
        open,
        ignoredAuditIds,
        clientId,
        resolve,
        reject
      })
    })
  }
};

export const setSetupAuditObject = ({open, current}) => {
  if (!open)
    return {
      type: SET_SETUP_AUDIT_OBJECT,
      open
    };
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_SETUP_AUDIT_OBJECT,
        open,
        current,
        resolve,
        reject
      })
    })
  }
};

export const setChooseQuestion = ({open, tree}) => {
  if (!open)
    return {
      type: SET_CHOOSE_QUESTION,
      open
    };
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_CHOOSE_QUESTION,
        open,
        tree,
        resolve,
        reject
      })
    })
  }
};

export const setInputModal = ({open, headerText, labelText, defaultValue}) => {
  if (!open)
    return {
      type: SET_INPUT_MODAL,
      open
    };
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_INPUT_MODAL,
        open,
        headerText,
        labelText,
        defaultValue,
        resolve,
        reject
      })
    })
  }
};

export const setAddModifyQuestion = ({open, current, isGeneric}) => {
  if (!open)
    return {
      type: SET_ADD_MODIFY_QUESTION,
      open
    };
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_ADD_MODIFY_QUESTION,
        open,
        current,
        isGeneric,
        resolve,
        reject
      })
    })
  };
};

export const setUploadFile = ({open, defaultName, defaultPath, defaultType}) => {
  if (!open)
    return {
      type: SET_UPLOAD_FILE,
      open,
      defaultName,
      defaultPath,
      defaultType,
      resolve: undefined,
      reject: undefined
    };
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_UPLOAD_FILE,
        open,
        defaultName,
        defaultPath,
        defaultType,
        resolve,
        reject
      })
    })
  }
};

export const setWriteText = ({open, labelText, defaultValue, headerText, inputType = 'textarea'}) => {
  if (!open)
    return {
      type: SET_WRITE_TEXT,
      open,
      inputType,
      labelText,
      headerText,
      defaultValue
    };
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_WRITE_TEXT,
        open,
        inputType,
        labelText,
        headerText,
        defaultValue,
        resolve,
        reject
      });
    })
  }
};

export const setWriteEditorText = ({open, labelText, defaultValue, headerText}) => {
  if (!open)
    return {
      type: SET_WRITE_EDITOR_TEXT,
      open,
      labelText,
      headerText,
      defaultValue
    };
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_WRITE_EDITOR_TEXT,
        open,
        labelText,
        headerText,
        defaultValue,
        resolve,
        reject
      });
    })
  }
};

export const setReportingView = ({open}) => {
  return {
    type: SET_REPORTING_VIEW,
    open
  }
};

export const setDateRangePicker = ({open}) => {
  if (!open)
    return {
      type: SET_DATE_RANGE_PICKER,
      open
    };
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_DATE_RANGE_PICKER,
        open,
        resolve,
        reject
      });
    })
  }
};

export const setVisitsAuditorChange = ({open, visitIds}) => {
  return {
    type: SET_VISITS_AUDITOR_CHANGE,
    open,
    visitIds
  }
};

export const setVisitsDateChange = ({open, visitIds}) => {
  return {
    type: SET_VISITS_DATE_CHANGE,
    open,
    visitIds
  }
};

export const setReportZonesExplotationOverview = ({open, reportId, visitIds, opener}) => {
  return {
    type: SET_REPORT_ZONES_EXPLOITATION_OVERVIEW,
    open,
    reportId,
    visitIds,
    opener
  }
};

export const setChooseZonesExploitationItems = ({open, clientIds, defaultValues}) => {
  if (!open)
    return {
      type: SET_CHOOSE_ZONES_EXPLOITATION_ITEMS,
      open
    };
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_CHOOSE_ZONES_EXPLOITATION_ITEMS,
        open,
        clientIds,
        defaultValues,
        resolve,
        reject
      })
    });
  }
};

export const setAddModifyFirm = ({open, currentId}) => {
  return {
    type: SET_ADD_MODIFY_FIRM,
    open,
    currentId
  }
};

export const setZoneExploitationsSurveyResponse = ({open, questionnaire_id, zone_exploitation_id, report_id, questionnaireIdsList}) => {
  return {
    type: SET_ZONE_EXPLOITATIONS_SURVEY_RESPONSE,
    open,
    questionnaire_id,
    zone_exploitation_id,
    report_id,
    questionnaireIdsList
  }
};

export const setZoneExploitationsSurveyInfo = ({open, visitId}) => {
  return {
    type: SET_ZONE_EXPLOITATIONS_SURVEY_INFO,
    open,
    visitId
  }
};

export const setRemoveBrand = ({open, brandId}) => {
  return {
    type: SET_REMOVE_BRAND,
    open,
    brandId
  }
};

export const setAddModifyBrand = ({open, currentId}) => {
  return {
    type: SET_ADD_MODIFY_BRAND,
    open,
    currentId
  }
};

export const setViewImage = ({open, url}) => {
  return {
    type: SET_VIEW_IMAGE,
    open,
    url
  }
};

export const setTaxonomyItemsFilterSelect = ({open, taxonomyId, defaultSelected}) => {
  if (!open)
    return {
      type: SET_TAXONOMY_ITEMS_FILTER_SELECT,
      open
    };
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_TAXONOMY_ITEMS_FILTER_SELECT,
        open,
        taxonomyId,
        defaultSelected,
        resolve,
        reject
      });
    })
  }
};

export const setTaxonomyItemSelection = ({open, taxonomyId, defaultSelectedId}) => {
  if (!open)
    return {
      type: SET_TAXONOMY_ITEM_SELECTION,
      open
    };
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_TAXONOMY_ITEM_SELECTION,
        open,
        taxonomyId,
        defaultSelectedId,
        resolve,
        reject
      })
    })
  }
};

export const setGenericConfirmation = ({open, title, body, execFunction}) => {
  if (!open)
    return {
      type: SET_GENERIC_CONFIRMATION,
      open
    };
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_GENERIC_CONFIRMATION,
        open,
        title,
        body,
        execFunction,
        resolve,
        reject
      })
    })
  }
};

export const setAddNewTaxonomy = ({open, clientId, defaultMode, defaultCopyTaxonomyId}) => {
  return {
    type: SET_ADD_NEW_TAXONOMY,
    open,
    clientId,
    defaultMode,
    defaultCopyTaxonomyId
  }
};

export const setChangeUsersActiveState = ({open, userIds}) => {
  return {
    type: SET_CHANGE_USERS_ACTIVE_STATE,
    open,
    userIds
  }
};

export const setChangeUsersProfile = ({open, userIds, targetOrganisationType, targetOrganisationId}) => {
  return {
    type: SET_CHANGE_USERS_PROFILE,
    open,
    userIds,
    targetOrganisationId,
    targetOrganisationType
  }
};

export const setCopyRootClientsProgressModels = ({open, targetClientId}) => {
  return {
    type: SET_COPY_ROOT_CLIENTS_PROGRESS_MODELS,
    open,
    targetClientId
  }
};

export const setAddModifyUser = ({open, targetType, targetId, currentUserId}) => {
  return {
    type: SET_ADD_MODIFY_USER,
    open,
    targetType,
    targetId,
    currentUserId
  }
};

export const setAddProfile = ({open, model, cible, profileIdToClone}) => {
  return {
    type: SET_ADD_PROFILE,
    open,
    model,
    cible,
    profileIdToClone
  }
};

export const setAddModifyPrestataire = ({open, currentPrestataireId}) => {
  return {
    type: SET_ADD_MODIFY_PRESTATAIRE,
    open,
    currentPrestataireId
  }
};

export const setAddModifyClient = ({open, currentClientId}) => {
  return {
    type: SET_ADD_MODIFY_CLIENT,
    open,
    currentClientId
  }
};

export const setModifyPrestataireClients = ({open, prestataireId}) => {
  return {
    type: SET_MODIFY_PRESTATAIRE_CLIENTS,
    open,
    prestataireId
  }
};

export const setRemoveClientPrestataire = ({open, clientId, prestataireId}) => {
  return {
    type: SET_REMOVE_CLIENT_PRESTATAIRE,
    open,
    clientId,
    prestataireId
  }
};

export const setModifyClientPrestataires = ({open, clientId}) => {
  return {
    type: SET_MODIFY_CLIENT_PRESTATAIRES,
    open,
    clientId
  }
};

export const setSetupModifiedSiteObject = ({open, current}) => {
  if (!open)
    return {
      type: SET_SETUP_MODIFIED_SITE_OBJECT,
      open
    };
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_SETUP_MODIFIED_SITE_OBJECT,
        open,
        resolve,
        reject,
        current
      })
    })
  }
};

export const setSetupModifiedPrestataireObject = ({open, current}) => {
  if (!open)
    return {
      type: SET_SETUP_MODIFIED_PRESTATAIRE_OBJECT,
      open
    };
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_SETUP_MODIFIED_PRESTATAIRE_OBJECT,
        open,
        resolve,
        reject,
        current
      })
    })
  }
};

export const setSetupNewPrestataireObject = ({open}) => {
  if (!open)
    return {
      type: SET_SETUP_NEW_PRESTATAIRE_OBJECT,
      open
    };
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_SETUP_NEW_PRESTATAIRE_OBJECT,
        open,
        resolve,
        reject
      })
    })
  }
};

export const setSetupNewSiteObject = ({open, client_id}) => {
  if (!open)
    return {
      type: SET_SETUP_NEW_SITE_OBJECT,
      open
    };
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_SETUP_NEW_SITE_OBJECT,
        open,
        client_id,
        resolve,
        reject
      })
    })
  }
};

export const setSetupNewClientObject = ({open, current}) => {
  if (!open)
    return {
      type: SET_SETUP_NEW_CLIENT_OBJECT,
      open
    };
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_SETUP_NEW_CLIENT_OBJECT,
        open,
        resolve,
        reject,
        current
      })
    })
  }
};

export const setSetupNewZoneExploitationObject = ({open, current, client_id}) => {
  if (!open)
    return {
      type: SET_SETUP_NEW_ZONE_EXPLOITATION_OBJECT,
      open
    };
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_SETUP_NEW_ZONE_EXPLOITATION_OBJECT,
        client_id,
        resolve,
        reject,
        open,
        current
      })
    });
  }
};

export const setChooseOrganisationsItems = ({open, clientId, defaultValues}) => {
  if (!open)
    return {
      type: SET_CHOOSE_ORGANISATIONS_ITEMS,
      open
    };
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_CHOOSE_ORGANISATIONS_ITEMS,
        open,
        clientId,
        defaultValues,
        resolve,
        reject
      })
    });
  }
};

export const setChooseQuestionnaireItems = ({open, clientId, defaultValues, lockedValues, recursiveTree}) => {
  if (!open)
    return {
      type: SET_CHOOSE_QUESTIONNAIRE_ITEMS,
      open
    };
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_CHOOSE_QUESTIONNAIRE_ITEMS,
        open,
        clientId,
        defaultValues,
        lockedValues,
        recursiveTree,
        resolve,
        reject
      });
    });
  }
};

export const setConstatReadable = ({open, text}) => {
  return {
    type: SET_CONSTAT_READABLE,
    open,
    text
  }
};

export const setConstatSet = ({open, onSelect, zoneExploitationsId, reportId, themes, analyseId, vagueId}) => {
  return {
    type: SET_CONSTAT_SET,
    open,
    onSelect,
    zoneExploitationsId,
    reportId,
    themes,
    analyseId,
    vagueId
  }
};

export const setProgressPlanModelCreate = ({open, clientId}) => {
  return {
    type: SET_PROGRESS_PLAN_MODEL_CREATE,
    open,
    clientId
  }
};


export const setProgressPlanModelDelete = ({open, modelId}) => {
  return {
    type: SET_PROGRESS_PLAN_MODEL_DELETE,
    open,
    modelId
  }
};

export const setProgressPlanModelEdit = ({open, modelId}) => {
  return {
    type: SET_PROGRESS_PLAN_MODEL_EDIT,
    open,
    modelId
  }
};

export const setRemoveAnalyse = ({open, analyses, analyseSelected}) => {
  if (!open)
    return {
      type: SET_REMOVE_ANALYSE,
      open
    };
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_REMOVE_ANALYSE,
        open,
        analyses,
        analyseSelected,
        resolve,
        reject
      });
    });
  }
};

export const setEditCoefficient = ({open, datas}) => {
  if (!open)
    return {
      type: SET_EDIT_COEFFICIENT,
      open
    };
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_EDIT_COEFFICIENT,
        open,
        datas,
        resolve,
        reject
      });
    });
  }
};

export const setEditCoefficientInTree = ({open, data}) => {
  if (!open)
    return {
      type: SET_EDIT_COEFFICIENT_IN_TREE,
      open
    };
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_EDIT_COEFFICIENT_IN_TREE,
        open,
        data,
        resolve,
        reject
      });
    });
  }
};

export const setEditAnalyseVagues = ({open, datas, analyse, currentVague}) => {
  if (!open)
    return {
      type: SET_EDIT_ANALYSE_VAGUES,
      open
    };
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_EDIT_ANALYSE_VAGUES,
        open,
        datas,
        analyse,
        currentVague,
        resolve,
        reject
      });
    });
  }
};
