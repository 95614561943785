import React from "react";
import {
  Form,
  FormGroup,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  FormFeedback
} from "reactstrap";
import {connect} from "react-redux";
import LoadingButton from "../common/LoadingButton";
import {updateUser} from "../../actions/orm/User";
import {FormValidator} from "../../tools/formValidator/FormValidator";
import {setChangeUsersActiveState} from "../../actions/modals";
import {tFunction} from "../tools/Translation";

const localFv = new FormValidator([
  {
    field: 'active',
    method: 'isEmpty',
    validWhen: false,
    message: tFunction('modals.field_required')
  }
]);

@connect(({modals: {changeUsersActiveState: {userIds}}}) => ({
  userIds
}))
class ChangeUsersActiveState extends React.PureComponent {
  state = {
    loading: false,
    closing: false,
    active: "",
    validation: localFv.valid()
  };

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const {active} = this.state;
    const {userIds} = this.props;

    const validation = localFv.validate({
      active
    });

    if (!validation.isValid){
      this.setState({validation});
      return;
    }

    this.setState({loading: true});
    const calls = userIds.map(id => {
      this.props.dispatch(updateUser({
        id,
        active
      }));
    });

    try {
      await Promise.all(calls);
    } catch (e) {}

    this.setState({loading: false});
    this.closing();
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setChangeUsersActiveState({
      open: false
    }));
  };

  render(){
    const {closing, loading, active, validation} = this.state;

    return (
      <Modal isOpen={!closing}
             onClosed={this.close}>
        <ModalHeader>
          {t('modals.able_disable_users')}
        </ModalHeader>
        <Form onSubmit={this.handleSubmit}>
          <ModalBody>
            <p class="text-muted">
              {t('modals.choose_able_disable_users')}
            </p>
            <FormGroup>
              <Input
                id="ChangeUserActiveStateModalSelect"
                invalid={validation.active.isInvalid}
                type="select"
                name="active"
                value={active}
                onChange={this.handleChange}>
                <option value="">{t('modals.change_user_state')}</option>
                <option value={false}>{t('common.to_unactive')}</option>
                <option value={true}>{t('common.to_active')}</option>
              </Input>
              <FormFeedback>
                {validation.active.message}
              </FormFeedback>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              type='button'
              disabled={loading}
              onClick={this.closing}>
              {t('common.close')}
            </Button>
            <LoadingButton
              loading={loading}
              disabled={loading}
              color="success">
              {t('common.valid')}
            </LoadingButton>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export {ChangeUsersActiveState};
export default ChangeUsersActiveState;
