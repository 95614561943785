import {fk, attr, Model} from 'redux-orm';
import {_Action, _ProgressPlan} from "../constants/orm";

export class Action extends Model{
  static parse(data){
    return data;
  }

  static get fields() {
    return{
      id: attr(),
      label: attr(),
      created: attr(),
      modified: attr()
    }
  }
}

Action.modelName = _Action;
