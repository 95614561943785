import createSelector from "../../tools/createSelector";

export const createFirmSelectorById = () => {
  return createSelector(
    (orm, {id}) => {
      const firm = orm.Firm.withId(id);

      return !!firm ? firm.ref : null;
    }
  )
};