import React from "react";
import {connect} from "react-redux";
import {setGenericConfirmation} from "../../actions/modals";
import {
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import {LoadingButton} from "../common/LoadingButton";

@connect(({modals: {genericConfirmation: {title, body, execFunction, resolve, reject}}}) => ({
  title,
  body,
  resolve,
  reject,
  execFunction
}))
class GenericConfirmation extends React.PureComponent {
  state = {
    closing: false,
    loading: false
  };

  resolve = async () => {
    const {execFunction} = this.props;

    if (!!execFunction){
      try {
        this.setState({loading: true});
        await execFunction();
        this.props.resolve({worked: true});
        this.setState({loading: false});
        this.closing();
        return;
      } catch (e) {
        this.props.resolve({worked: false, error: e});
        this.setState({loading: false});
        this.closing();
        return;
      }
    }

    this.props.resolve();
    this.closing();
  };

  reject = () => {
    this.props.reject();
    this.closing();
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setGenericConfirmation({
      open: false
    }));
  };

  render(){
    const {title, body} = this.props;
    const {closing, loading} = this.state;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.reject}>
          {title}
        </ModalHeader>
        <ModalBody>
          {body}
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={this.reject}>
            {t('common.cancel')}
          </Button>
          <LoadingButton
            onClick={this.resolve}
            loading={loading}
            disabled={loading}
            color="success">
            {t('common.confirm')}
          </LoadingButton>
        </ModalFooter>
      </Modal>
    )
  }
}

export {GenericConfirmation};
export default GenericConfirmation;
