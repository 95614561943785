import React from "react";
import {connect} from "react-redux";
import {setAddModifyBrand} from "../../actions/modals";
import {
  Form,
  FormGroup,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback
} from "reactstrap";
import {FormValidator} from "../../tools/formValidator/FormValidator";
import LoadingButton from "../common/LoadingButton";
import {ImportFileIconPlaceholder} from "../common/ImagePlaceholders";
import {updateBrand, addBrand} from "../../actions/orm/Brand";
import {createBrandSelectorById} from "../selectors/brand";
import {tFunction} from "../tools/Translation";

const localFv = new FormValidator([
  {
    field: 'label',
    method: 'isEmpty',
    validWhen: false,
    message: tFunction('modals.label_specified')
  }
]);

const brandSelector = createBrandSelectorById();

@connect(({modals: {addModifyBrand: {currentId}}}) => ({
  currentId
}))
class AddModifyBrand extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      closing: false,
      loading: false,
      brand: null,
      validation: localFv.valid(),
      label: '',
      path: ''
    };

    const {currentId} = this.props;
    if (!!currentId){
      const brand = brandSelector({id: currentId});

      this.state = {
        ...this.state,
        brand,
        label: brand.label,
        path: brand.path
      }
    }
  }

  fileInputRef = React.createRef();

  inputLabel = React.createRef();

  componentDidMount() {
    this.inputLabel.focus();
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  handleLogoChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.setState({path: e.target.result});
    };
    reader.readAsDataURL(file);
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const {brand, label, path} = this.state;

    const validation = localFv.validate({
      label
    });

    if (!validation.isValid){
      this.setState({validation});
      return;
    }

    this.setState({loading: true});

    if (!!brand){
      await this.props.dispatch(updateBrand({
        id: brand.id,
        label,
        path
      }));
    } else {
      await this.props.dispatch(addBrand({
        label,
        path
      }));
    }

    this.setState({loading: false});
    this.closing();
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setAddModifyBrand({
      open: false
    }));
  };

  render(){
    const {closing, validation, label, path, brand, loading} = this.state;
    const editMode = !!brand;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.closing}>
          {editMode ?
            `${t('modals.brand_edition')} ${brand.label}` : t('modals.add_brand')}
        </ModalHeader>
        <Form onSubmit={this.handleSubmit}>
          <ModalBody>
            <FormGroup className="text-center">
              <ImportFileIconPlaceholder
                onClick={() => this.fileInputRef.current.click()}
                src={path}/>
              <input
                hidden
                ref={this.fileInputRef}
                type="file"
                name="path"
                accept="image/*"
                onChange={this.handleLogoChange}
                className="custom-file-input"/>
            </FormGroup>
            <FormGroup>
              <Label>
                {t('common.label')}
              </Label>
              <Input
                invalid={validation.label.isInvalid}
                onChange={this.handleChange}
                value={label}
                innerRef={(input) => (this.inputLabel = input)}
                placeholder={t('common.label')}
                name="label"/>
              <FormFeedback>
                {validation.label.message}
              </FormFeedback>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              type='button'
              disabled={loading}
              onClick={this.closing}>
              {t('common.close')}
            </Button>
            <LoadingButton
              loading={loading}
              disabled={loading}
              color="success">
              {t('common.valid')}
            </LoadingButton>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export {AddModifyBrand};
export default AddModifyBrand;
