import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import trans_en from "../locales/en.json"
import trans_fr from "../locales/fr.json"
import trans_es from "../locales/es.json"

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en_US: {
    translation: trans_en
  },
  fr_FR: {
    translation: trans_fr
  },
  es_ES: {
    translation: trans_es
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "fr_FR",

    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });
// i18n.changeLanguage('es');

export default i18n;