import React from "react";
import {connect} from "react-redux";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import LoadingButton from "../common/LoadingButton";
import createSelector from "../../tools/createSelector";
import {setAddNewTaxonomy} from "../../actions/modals";
import {FormValidator} from "../../tools/formValidator/FormValidator";
import {addTaxonomy, cloneTaxonomy, copyTaxonomy, updateTaxonomy} from "../../actions/orm/Taxonomy";
import {tFunction} from "../tools/Translation";

const localFv = new FormValidator([
  {
    field: 'label',
    method: 'isEmpty',
    validWhen: false,
    message: tFunction('modals.label_specified')
  },
  {
    field: "genericTaxonomyId",
    method: "isEmpty",
    validWhen: false,
    message: tFunction('modals.choose_taxonomy_to_copy')
  },
  {
    field: "availableTaxonomyId",
    method: "isEmpty",
    validWhen: false,
    message: tFunction('modals.choose_taxonomy_to_copy')
  }
]);

const genericTaxonomiesSelector = createSelector(
  (orm) => {
    return orm.Taxonomy.all().filter(i => !i.client_id).toRefArray();
  }
);

const availableTaxonomiesSelector = createSelector(
  (orm, {clientId}) => {
    const client = orm.Client.withId(clientId);

    return client.taxonomies.filter(i => !i.parent_id).toRefArray();
  }
);

@connect(
  ({modals: {addNewTaxonomy: {clientId, defaultMode, defaultCopyTaxonomyId}}}) => ({
    clientId,
    defaultMode,
    defaultCopyTaxonomyId,
    genericTaxonomies: genericTaxonomiesSelector({}),
    availableTaxonomies: availableTaxonomiesSelector({clientId})
  })
)
class AddNewTaxonomy extends React.PureComponent {
  constructor(props){
    super(props);
    const {defaultMode, defaultCopyTaxonomyId} = this.props;

    this.state = {
      loading: false,
      closing: false,
      mode: !!defaultMode ? defaultMode : 'new',
      label: '',
      validation: localFv.valid(),
      genericTaxonomyId: "",
      availableTaxonomyId: !!defaultCopyTaxonomyId ? defaultCopyTaxonomyId.toString() : ""
    }
  }

  inputLabel = React.createRef();

  componentDidMount() {
    this.inputLabel.focus();
  }

  setMode = (mode) => {
    this.setState({mode});
  };

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const {label, genericTaxonomyId, mode, availableTaxonomyId} = this.state;
    const {clientId} = this.props;

    const validation = localFv.validate({
      label,
      genericTaxonomyId: mode === "extend" ? genericTaxonomyId : ' ',
      availableTaxonomyId: mode === "copy" ? availableTaxonomyId : ' '
    });

    if (!validation.isValid){
      this.setState({validation});
      return;
    }

    this.setState({loading: true});
    if (mode === 'new'){
      await this.props.dispatch(addTaxonomy({
        client_id: clientId,
        label: label
      }));
    } else if (mode === 'extend') {
      const tax = await this.props.dispatch(cloneTaxonomy({
        client_id: clientId,
        taxonomy_id: parseInt(genericTaxonomyId)
      }));
      await this.props.dispatch(updateTaxonomy({
        id: tax[0].id,
        label
      }))
    } else {
      const tax = await this.props.dispatch(copyTaxonomy({
        client_id: clientId,
        taxonomy_id: parseInt(availableTaxonomyId)
      }));
      await this.props.dispatch(updateTaxonomy({
        id: tax[0].id,
        label
      }))
    }

    this.setState({loading: false});
    this.closing();
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setAddNewTaxonomy({
      open: false
    }))
  };

  render(){
    const {
      closing,
      loading,
      mode,
      validation,
      label,
      genericTaxonomyId,
      availableTaxonomyId
    } = this.state;
    const {genericTaxonomies, availableTaxonomies} = this.props;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.closing}>
          {t('admin.taxonomy.add_taxonomy')}
        </ModalHeader>
        <Form onSubmit={this.handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Nav tabs>
                <NavItem>
                  <NavLink active={mode === 'new'}
                           className="cursor-pointer"
                           onClick={() => this.setMode('new')}>
                    {t('common.new')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink active={mode === 'extend'}
                           className="cursor-pointer"
                           onClick={() => this.setMode('extend')}>
                    {t('modals.use_generic')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink active={mode === 'copy'}
                           className="cursor-pointer"
                           onClick={() => this.setMode('copy')}>
                    {t('modals.copy_taxonomy')}
                  </NavLink>
                </NavItem>
              </Nav>
            </FormGroup>
            {mode === "extend" &&
            <FormGroup>
              <Label>
                {t('common.model')}
              </Label>
              <Input
                invalid={validation.genericTaxonomyId.isInvalid}
                onChange={this.handleChange}
                type="select"
                value={genericTaxonomyId}
                name="genericTaxonomyId">
                <option value="">{t('modals.choose_model')}</option>
                {genericTaxonomies.map(item => (
                  <option
                    key={item.id}
                    value={item.id}>
                    {item.label}
                  </option>
                ))}
              </Input>
              <FormFeedback>
                {validation.genericTaxonomyId.message}
              </FormFeedback>
            </FormGroup>}
            {mode === "copy" &&
            <FormGroup>
              <Label>
                {t('common.taxonomy_to_copy')}
              </Label>
              <Input
                invalid={validation.availableTaxonomyId.isInvalid}
                onChange={this.handleChange}
                type="select"
                value={availableTaxonomyId}
                name="availableTaxonomyId">
                <option value="">{t('common.choose_taxonomy_to_copy')}</option>
                {availableTaxonomies.map(item => (
                  <option
                    key={item.id}
                    value={item.id}>
                    {item.label}
                  </option>
                ))}
              </Input>
              <FormFeedback>
                {validation.availableTaxonomyId.message}
              </FormFeedback>
            </FormGroup>}
            <FormGroup>
              <Label>{t('common.label')}</Label>
              <Input
                name="label"
                innerRef={(input) => (this.inputLabel = input)}
                invalid={validation.label.isInvalid}
                placeholder={t('common.label')}
                onChange={this.handleChange}
                value={label}/>
              <FormFeedback>
                {validation.label.message}
              </FormFeedback>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              type='button'
              disabled={loading}
              onClick={this.closing}>
              {t('common.close')}
            </Button>
            <LoadingButton
              loading={loading}
              disabled={loading}
              color="success">
              {t('common.valid')}
            </LoadingButton>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export {AddNewTaxonomy};
export default AddNewTaxonomy;
