import React from "react";
import {connect} from "react-redux";
import {setModifyAuditUsers} from "../../actions/modals";
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import LoadingButton from "../common/LoadingButton";
import {createSelector} from "../../tools/createSelector";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {DebounceSearchInput} from "../common/DebounceSearchInput";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {fetchPrestataireUsers} from "../../actions/orm/Prestataire";
import {fetchFirmUsers} from "../../actions/orm/Firms";
import {fetchClientUsers} from "../../actions/orm/Client";
import {RowSelectionHeaderItem, RowSelectionItem} from "../tools/DatatableComponents";
import _ from "lodash";
import {startPending, stopPending} from "../../actions/ui";
import {updateAudit} from "../../actions/orm/Audit";
import {getOrm} from "../../redux";

const auditSelector = createSelector(
  (orm, {client_id, id, user_id}) => {

    const connectedUser = orm.User.withId(user_id).ref;
    const audit = orm.Audit.withId(id);

    var auditUser = audit.users.toModelArray();

    if(!!connectedUser.prestataire_id)
    {
      auditUser = auditUser.filter(i => {
        return i.ref.client_id === client_id 
        ||
        (!!i.ref.prestataire_id && i.ref.prestataire_id === connectedUser.prestataire_id)
      })
    }

    const users = _.orderBy(auditUser.map(u => {
      const user_right = audit.user_rights.find(user => u.id === user.user_id);
      return {
        ...u.ref,
        create_report: user_right.create_report,
        validate_rapport: user_right.validate_rapport,
        edit_validate_rapport: user_right.edit_validate_rapport,
        enterprise: !!u.firm_id ? u.firm_id.ref : !!u.prestataire_id ? u.prestataire_id.ref : u.client_id.ref
      }
    }), ['enterprise.label', 'firstname'], 'asc');

    return {
      ...audit.ref,
      users
    }
  }
);

const clientSelector = createSelector(
  (orm, {id}) => {
    const client = orm.Client.withId(id);

    return {
      ...client.ref,
      firms: client.firms.toRefArray().map(f => f.id),
      prestataires: client.prestataires.toRefArray().map(p => p.id)
    }
  }
);

const clientPrestatairesUsersSelector = createSelector(
  (orm, {clientId}) => {
    const client = orm.Client.withId(clientId);
    const prestataires = client.prestataires.toModelArray();
    let users = [];

    prestataires.forEach(p => {
      users = [...users, ...p.users.toModelArray()];
    });
    _.uniqBy(users, 'id');

    return users.map(u => ({
      ...u.ref,
      enterprise: !!u.firm_id ? u.firm_id.ref : !!u.prestataire_id ? u.prestataire_id.ref : u.client_id.ref
    }));
  }
);

const clientUsersSelector = createSelector(
  (orm, {clientId}) => {
    const client = orm.Client.withId(clientId);
    const users = client.users.toModelArray();

    return users.map(u => ({
      ...u.ref,
      enterprise: !!u.firm_id ? u.firm_id.ref : !!u.prestataire_id ? u.prestataire_id.ref : u.client_id.ref
    }));
  }
);

const clientFirmUsersSelector = createSelector(
  (orm, {clientId}) => {
    const client = orm.Client.withId(clientId);
    const firms = client.firms.toModelArray();
    let users = [];

    firms.forEach(p => {
      users = [...users, ...p.users.toModelArray()];
    });
    _.uniqBy(users, 'id');

    return users.map(u => ({
      ...u.ref,
      enterprise: !!u.firm_id ? u.firm_id.ref : !!u.prestataire_id ? u.prestataire_id.ref : u.client_id.ref
    }));
  }
);

@connect(({session: {currentClientId,userId}, modals: {modifyAuditUsers: {auditId}}}) => ({
  currentClientId : currentClientId,
  userId,
  auditId
}))

// @connect((store) => ({
//   userId: store.session.userId
// }))

class ModifyAuditUsers extends React.PureComponent {
  constructor(props) {
    super(props);

    const {auditId, currentClientId, userId} = this.props;

    const audit = auditSelector({client_id: currentClientId,id: auditId, user_id: userId}, true);
    const client = clientSelector({id: audit.client_id}, true);

    this.state = {
      audit,
      client,
      type: audit.type,
      selected: audit.users.map(u => u.id),
      nonSelectable:[userId],
      users: [],
      closing: false,
      loading: false
    };
    this.tableRef = null;
  }

  handleTypeChange = (e) => {
    this.setState({type: e.target.value});
  };

  getClientUsers = async () => {
    const {client} = this.state;

    await this.props.dispatch(fetchClientUsers({client_id: client.id}));
    return clientUsersSelector({clientId: client.id});
  };

  getClientPrestatairesUsers = async () => {
    const {client} = this.state;

    await Promise.all(
      client.prestataires.map(pId => this.props.dispatch(fetchPrestataireUsers({
        prestataire_id: pId
      }))));

    return clientPrestatairesUsersSelector({clientId: client.id});
  };

  getClientFirmUsers = async () => {
    const {client} = this.state;

    await Promise.all(client.firms.map(fId => this.props.dispatch(fetchFirmUsers({
      firm_id: fId
    }))));

    return clientFirmUsersSelector({clientId: client.id});
  };

  getAllUsers = async (orm) => {
    this.props.dispatch(startPending());

    const {currentClientId, userId} = this.props;
    const connectedUser = getOrm().User.withId(userId).ref;
    const [clientUsers, prestataireUsers, firmUsers] = await Promise.all([
      this.getClientUsers(),
      this.getClientPrestatairesUsers(),
      this.getClientFirmUsers()
    ]);

    let allUsers = clientUsers.concat(prestataireUsers).concat(firmUsers);

    if(!!connectedUser.prestataire_id)
    {
      allUsers = allUsers.filter(i => {
        return i.client_id === currentClientId 
        ||
        (!!i.prestataire_id && i.prestataire_id === connectedUser.prestataire_id)
      })
    }

    _.uniqBy(allUsers, 'id');

    console.log(allUsers);


    this.props.dispatch(stopPending());

    return allUsers;
  };

  async componentDidMount(){
    const {type, selected} = this.state;
    let users = await this.getAllUsers();

    // switch (type) {
    //   case 'clients':
    //     users = await this.getClientUsers();
    //     break;
    //   case 'prestataires':
    //     users = await this.getClientPrestatairesUsers();
    //     break;
    //   case 'firms':
    //     users = await this.getClientFirmUsers();
    //     break;
    //   default:
    //     users = [];
    //     break;
    // }

    users = users.sort((a,b) => selected.includes(a.id) - selected.includes(b.id));

    this.setState({users, type});
  }

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setModifyAuditUsers({
      open: false
    }));
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const {selected, type, audit} = this.state;

    this.setState({loading: true});
    await this.props.dispatch(updateAudit({
      audit_id: audit.id,
      users: selected.map(id => {
        const user = !!audit.users.find(u => u.id === id) ? audit.users.find(u => u.id === id) : null;
        return !!user ?
          {
            ...user,
            create_report: !!user.create_report ? 1 : 0,
            validate_rapport: !!user.validate_rapport ? 1 : 0,
            edit_validate_rapport: !!user.edit_validate_rapport ? 1 : 0
          } :
          {id, create_report: 0, validate_rapport: 0, edit_validate_rapport: 0}
      }),
      type
    }));
    this.setState({loading: false});
    this.closing();
  };

  columns = [
    {
      dataField: 'firstname',
      text: t('common.firstname'),
      sort: true
    },
    {
      dataField: 'lastname',
      text: t('common.lastname'),
      sort: true
    },
    {
      dataField: 'enterprise.label',
      text: t('common.enterprise'),
      sort:true
    }
  ];

  setSelection = (selected) => {
    const {nonSelectable} = this.state;
    var selection = selected.concat(nonSelectable);
    this.setState({selected: _.uniq(selection)});
  };

  handleSelectAll = () => {
    setTimeout(() => { 
      this.setSelection([...this.tableRef.selectionContext.state.selected]);
    }, 1);
  };

  handleSelect = () => {
    setTimeout(() => {
      this.setSelection([...this.tableRef.selectionContext.state.selected]);
    }, 1);
  };

  handleCustomSelectAll = () => {
    const displayedData = this.tableRef.paginationContext.props.data.map(i => i.id);
    const {selected} = this.state;
    let newSelected = selected.concat(displayedData);
    newSelected = _.uniq(newSelected);

    this.setSelection(newSelected);
  };

  handleCustomUnselectAll = () => {
    this.setSelection([]);
  };

  sortBySelected = () => {
    const {users, selected} = this.state;
    this.tableRef.sortContext.state.sortOrder = undefined;
    this.tableRef.sortContext.state.sortColumn = undefined;
    const sortedUsers = users.sort((a,b) => {
      return selected.includes(b.id) - selected.includes(a.id);
    });
    this.setState({users: [...sortedUsers]});
  };

  render(){
    const {closing, type, audit, users, loading, selected, nonSelectable} = this.state;

    return (
      <Modal isOpen={!closing}
             onClosed={this.close}>
        <ModalHeader>
          {t('modals.modify_users_of')} {audit.label}
        </ModalHeader>
        <Form onSubmit={this.handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label>
                {t('modals.audit_type')}
              </Label>
              <Input
                value={type}
                onChange={this.handleTypeChange}
                name="type"
                type="select">
                <option value={'clients'}>{t('common.clients')}</option>
                <option value={'prestataires'}>{t('common.prestataires')}</option>
                <option value={'firms'}>{t('common.externe')}</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>
                {t('common.users')}
              </Label>
              <ToolkitProvider
                search
                keyField="id"
                columns={this.columns}
                bootstrap4={true}
                data={users}>
                {props => (
                  <div>
                    <DebounceSearchInput
                      className="mb-2"
                      onChange={(e) => props.searchProps.onSearch(e.target.value)}/>
                    <BootstrapTable
                      ref={n => {this.tableRef = n}}
                      bordered={false}
                      classes={'bulk-selection'}
                      noDataIndication={() => t('common.no_data')}
                      selectRow={{
                        mode: 'checkbox',
                        bgColor: '#e6e6ed',
                        clickToSelect: true,
                        selected: selected,
                        nonSelectable:nonSelectable,
                        onSelect: this.handleSelect,
                        onSelectAll: this.handleSelectAll,
                        selectionHeaderRenderer: (props) => {
                          return (
                            <RowSelectionHeaderItem
                              options={[
                                [
                                  {
                                    label: t('common.select_all'),
                                    onClick: this.handleCustomSelectAll
                                  },
                                  {
                                    label: t('common.unselect_all'),
                                    onClick: this.handleCustomUnselectAll
                                  }
                                ],
                                [
                                  {
                                    label: t('common.sort_by_selection'),
                                    onClick: this.sortBySelected
                                  }
                                ]
                              ]}
                              {...props}/>
                          )
                        },
                        selectionRenderer: (props) => <RowSelectionItem {...props}/>
                      }}
                      pagination={paginationFactory()}
                      {...props.baseProps}/>
                  </div>
                )}
              </ToolkitProvider>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              type='button'
              disabled={loading}
              onClick={this.closing}>
              {t('common.close')}
            </Button>
            <LoadingButton
              loading={loading}
              disabled={loading}
              color="success">
              {t('common.valid')}
            </LoadingButton>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export {ModifyAuditUsers};
export default ModifyAuditUsers;
