import createSelector from "../tools/createSelector";
import {getOrm} from "../redux";

/**
 * return leaves of clients. If a leave is in clients parameters
 * return the client too.
 */
export const clientsLeavesByClients = createSelector(
    (orm, clients) => {
        if(clients !== null) return getLeaves(clients);
        return null;

        function getLeaves(clients){
            let leaves = [];
            let childs = [];

            clients.forEach(client => {
                let localChilds = orm.Client.all().filter(c => c.parent_id === client.id).toModelArray();
                if(localChilds.length === 0) leaves.push(client);
                else childs = childs.concat(localChilds);
            });

            if(childs.length > 0) return leaves.concat(getLeaves(childs));

            return leaves;
        }
    }
);

export const clientById = (clientId) => {
  if(getOrm().Client.idExists(clientId))
    return getOrm().Client.withId(clientId);
  return null;
};

export const organisationByZoneExploitationsAndClient  = createSelector(
  (orm, {zoneExploitations, client}) => {
    if(!!zoneExploitations && client){
      let orga = setupTree([client], 'client');
      const zeIds = zoneExploitations.map(ze => ze.id);

      return process(orga);

      function setupTree(itemList, type){
        return itemList.map(item => {
          const ret = {
            ...item.ref,
            type,
            checked: true,
          };

          if (!!item.sites){
            const sites = item.sites.toModelArray();
            if (!!sites.length)
              ret.children = setupTree(sites, 'site');
          }
          if (!!item.ChildClients){
            const clients = item.ChildClients.toModelArray();
            if (!!clients.length)
              ret.children = setupTree(clients, 'client');
          }
          if (!!item.zone_exploitations){
            const zones = item.zone_exploitations.toModelArray();
            if (!!zones.length)
              ret.children = setupTree(zones, 'zone_exploitation')
          }
          return ret;
        });
      }

      function process(nodes){
        return nodes.filter(node => {
          if (node.type === 'zone_exploitation')
            return zeIds.indexOf(node.id) !== -1;
          if (!!node.children && !!node.children.length){
            node.children = process(node.children);
            return !!node.children.length;
          }
          return false;
        });
      }
    }
    return null;
  }
);
