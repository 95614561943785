import React from "react";
import {connect} from "react-redux";
import {setConstatSet} from "../../actions/modals";
import {ResponsesTree} from "../tools/ResponsesTree";
import {START_PENDING, STOP_PENDING} from "../../actions/ui";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {fetchResponses} from "../../actions/orm/Response";
import {fetchAuditQuestionnaires} from "../../actions/orm/Audit";
import {responseCommentsByReportsSelector} from './selector';
import {createReportsObjectSelector} from "../selectors/report";
import {createAnalysesObjectSelector} from "../selectors/analyse";
import {createAuditsIdByReportsIdSelector} from "../selectors/audit";
import {getReportsAuditsOfAnalyseVague} from "../../tools/helperFunctions";

const reportsSelector = createReportsObjectSelector();
const analysesSelector = createAnalysesObjectSelector();
const treeSelector = responseCommentsByReportsSelector();
const getAuditsIdSelector = createAuditsIdByReportsIdSelector();

@connect((state) => ({
  ...state.modals.constatSet,
  reports: reportsSelector(),
  analyses: analysesSelector(),
}),
dispatch => ({
  startPending: () => {
    dispatch({type: START_PENDING});
  },
  stopPending: () => {
    dispatch({type: STOP_PENDING});
  },
  close: () => {
    dispatch(setConstatSet({
      open: false,
      onSelect: () => '',
    }))
  },
  dispatch
}))
class ConstatSet extends React.Component {
  state = {
    idx: 1,
    reports: [],
    hierarchy: [],
    responsesFetch: [],
    closing: false
  };

  async componentDidMount() {
    const {analyses, analyseId, vagueId, zoneExploitationsId, themes} = this.props;
    this.props.startPending();

    const {reports, auditIds} = await getReportsAuditsOfAnalyseVague(analyses, analyseId, vagueId, zoneExploitationsId);
    // const auditsId = await getAuditsIdSelector({reportsId: reports});

    await Promise.all(auditIds.map(aId => {
      return this.props.dispatch(fetchAuditQuestionnaires({audit_id: aId}));
    }));

    const audits = {};
    reports.map(report => {
      if (!audits[this.props.reports[report].audit_id])
        audits[this.props.reports[report].audit_id] = [];
      audits[this.props.reports[report].audit_id].push(this.props.reports[report].vague);
    });

    setTimeout(async () => {
      const hierarchy = await treeSelector({
        idx: 0,
        reportIds: reports,
        themes: themes,
        zesId: zoneExploitationsId,
        toggled: []
      }, 0);

      this.setState({hierarchy: hierarchy, reports: reports, audits: audits},
        () => this.props.stopPending());
    }, 10);
  }

  fetchResponses = async (node, toggled) => {
    const responsesFetch = this.state.responsesFetch.slice();

    if (node.type === 'zoneExploitation' && !!toggled) {
      const fetched = !responsesFetch.find(r =>
        this.state.audits[r.audit_id].includes(r.vague) &&
        r.question_id === node.parent.id &&
        r.audit_id === node.parent.parent.id &&
        r.zone_exploitation_id === node.id
      );

      if (fetched) {
        this.props.startPending();
        this.state.audits[node.parent.parent.id].map(v => {
          responsesFetch.push({
            vague: v,
            question_id: node.parent.id,
            audit_id: node.parent.parent.id,
            zone_exploitation_id: node.id
          });
        });

        await Promise.all(this.state.audits[node.parent.parent.id].map(v => {
          return this.props.dispatch(fetchResponses({
            data: [{
              vague: v,
              question_id: node.parent.id,
              audit_id: node.parent.parent.id,
              zone_exploitation_id: node.id
            }]
          }));
        }));


        setTimeout(async () => {
          const hierarchy = await treeSelector({
            themes: this.props.themes,
            reportIds: this.state.reports,
            zesId: this.props.zoneExploitationsId,
            toggled: flatTree(this.state.hierarchy).filter(h => !!h.toggled)
          }, this.state.idx);

          this.setState({hierarchy: hierarchy, idx: this.state.idx++, responsesFetch: responsesFetch},
            () => this.props.stopPending());
        }, 10);
      }
    }
  };

  handleCommentsSelect = () => {
    const {hierarchy} = this.state;
    const horizontal = flatTree(hierarchy);
    const checked = horizontal.filter(i => !!i.checked);

    if(checked.length > 0)
      this.props.onSelect(checked.length === 1 ? checked[0].comment :
      checked.reduce((acc, checked) => ({
        comment: (acc.comment === undefined ? '' : acc.comment + '\n') + checked.comment
      }), '').comment);
    this.props.close();
  };

  close = () => {
    this.props.close();
  };

  closing = () => {
    this.setState({closing: true})
  };

  render(){
    const {closing, hierarchy} = this.state;

    return (
      <Modal size='lg' onClosed={this.close} isOpen={!closing}>
        <ModalHeader toggle={this.closing}>{t('modals.constat_edition')}</ModalHeader>
        <ModalBody>
          <ResponsesTree
            data={hierarchy}
            name='tree_constat'
            fetchResponses={this.fetchResponses}/>
        </ModalBody>
        <ModalFooter>
          <button
            type='button'
            onClick={this.handleCommentsSelect}
            className='btn btn-primary btn-success'>
            {t('common.select')}
          </button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ConstatSet;
