import createSelector from "../../tools/createSelector";

export const createProgressPlansSelector = () => {
  return createSelector(
    (orm) => {
      const progressPlans = orm.ProgressPlan.all().toRefArray();

      return !!progressPlans ? progressPlans : null;
    }
  )
};