export const RECEIVE_USER = "RECEIVE_USER";
export const RECEIVE_USER_RIGHTS = "RECEIVE_USER_RIGHTS";
export const RECEIVE_USER_RIGHTS_HIERARCHY = 'RECEIVE_USER_RIGHTS_HIERARCHY';
export const RECEIVE_USER_CLIENTS = "RECEIVE_USER_CLIENTS";
export const CLEAR_SESSION = "CLEAR_SESSION";
export const SWITCH_CLIENT = "SWITCH_CLIENT";
export const START_INIT = 'START_INIT';
export const END_INIT = 'END_INIT';
export const SET_SESSION_USER = 'SET_SESSION_USER';
export const SET_CURRENT_CLIENT_ID = "SET_CURRENT_CLIENT_ID";
import {api} from "../tools/api";
import {CLEAR_DB} from "./orm";
import {CLEAR_UI} from "./ui";
import {actionCallsTracker} from "./actionsTracker";

export const logout = () => {
  return async dispatch => {
    await api.users.logout();

    dispatch({
      type: CLEAR_SESSION
    });
    dispatch({
      type: CLEAR_DB
    });
    dispatch({
      type: CLEAR_UI
    });
    actionCallsTracker.reset();
  }
};
