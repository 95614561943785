import React from "react";
import {TestModal} from "./TestModal";
import {EditGeneralCoefficient} from "./EditGeneralCoefficient";
import {GenericAlert} from "./GenericAlert"
import {AddProgressPlanActions} from "./AddProgressPlanActions";
import {EditAuditUserRights} from "./EditAuditUserRights";
import {ChooseClientThemeTreeItems} from "./ChooseClientThemeTreeItems";
import {AuditsZoneExploitationAffectation} from "./AuditsZoneExploitationAffectation";
import {CopyGenericThemeTreeItems} from "./CopyGenericThemeTreeItems";
import {AddProgressPlanActionsInZone} from "./AddProgressPlanActionsInZone";
import {ModifyProgressPlanAction} from "./ModifyProgressPlanAction";
import {AddZoneExploitationInProgressPlan} from "./AddZoneExploitationInProgressPlan"

const modals = {
  testModal: {
    name: 'testModal',
    state: {
      text: 'text'
    },
    promiseBased: false,
    component: TestModal
  },
  editGeneralCoefficient: {
    name: 'editGeneralCoefficient',
    state: {
      data: 'data',
      resolve: 'resolve',
      reject: 'reject'
    },
    promiseBased: true,
    component: EditGeneralCoefficient
  },
  genericAlert: {
    name: 'genericAlert',
    state: {
      data: 'data',
      resolve: 'resolve',
      reject: 'reject'
    },
    promiseBased: true,
    component: GenericAlert
  },
  addProgressPlanActions: {
    name: 'addProgressPlanActions',
    state: {
      zeIds: 'zeIds',
      resolve: 'resolve',
      reject: 'reject'
    },
    promiseBased: true,
    component: AddProgressPlanActions
  },
  addProgressPlanActionsInZone: {
    name: 'addProgressPlanActionsInZone',
    state: {
      zeId: 'zeId',
      resolve: 'resolve',
      reject: 'reject'
    },
    promiseBased: true,
    component: AddProgressPlanActionsInZone
  },
  modifyProgressPlanAction: {
    name: 'modifyProgressPlanAction',
    state: {
      action: 'action',
      resolve: 'resolve',
      reject: 'reject'
    },
    promiseBased: true,
    component: ModifyProgressPlanAction
  },
  editAuditUserRights: {
    name: 'editAuditUserRights',
    state: {
      user: 'user',
      resolve: 'resolve',
      reject: 'reject'
    },
    promiseBased: true,
    component: EditAuditUserRights
  },
  chooseClientThemeTreeItems: {
    name: 'chooseClientThemeTreeItems',
    state: {
      targetClientId: null
    },
    promiseBased: true,
    component: ChooseClientThemeTreeItems
  },
  auditZoneExploitationAffectation: {
    name: 'auditZoneExploitationAffectation',
    state: {
      zoneExploitationIds: [],
      clientId: -1
    },
    promiseBased:false,
    component: AuditsZoneExploitationAffectation
  },
  copyGenericThemeTreeItems: {
    name: "copyGenericThemeTreeItems",
    state: {
      targetClientId: -1
    },
    promiseBased:true,
    component: CopyGenericThemeTreeItems
  },
  addZoneExploitationInProgressPlan: {
    name: "addZoneExploitationInProgressPlan",
    state: {
      data: 'data',
      user_id: 'user_id'
    },
    promiseBased: true,
    component: AddZoneExploitationInProgressPlan
  }
};

export {modals};
export default modals;
