import api from "../../tools/api";
import {_Parameter} from "../../constants/orm";
import {hydrateEntities, deleteEntity, updateEntities} from "../orm";
import {actionCallsTracker} from "../actionsTracker";

export const fetchAllParameters = () => {
  return async dispatch => {
    if (actionCallsTracker.isSaved('parameters', 'client', 'all'))
      return;

    const parameters = await api.parameter.getAll();

    dispatch(hydrateEntities({
      payload: [
        {type: _Parameter, entities: parameters}
      ]
    }));

    actionCallsTracker.save('parameters', 'client', 'all');
    return parameters;
  }
};

export const addParameter = ({label, cible_id, model}) => {
  return async dispatch => {
    const parameter = await api.parameter.add({
      label,
      cible_id,
      model
    });

    dispatch(hydrateEntities({
      payload: [
        {type: _Parameter, entities: [parameter]}
      ]
    }));

    return parameter;
  }
};

export const removeParameter = ({id}) => {
  return async dispatch => {
    await api.parameter.remove({
      id
    });

    dispatch(deleteEntity({
      type: _Parameter,
      id
    }))
  }
};
