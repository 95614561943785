import createSelector from "../../tools/createSelector";

export function getNumericValue(questionnaire){
  const themeQuestion = questionnaire.themes_question_id;

  if (!themeQuestion)
    return '';

  let numericValue = `${themeQuestion.ref.ranking + 1}`;
  let parentTheme = themeQuestion.theme_id;

  while (!!parentTheme){
    numericValue = `${parentTheme.ref.ranking + 1}.${numericValue}`;
    parentTheme = parentTheme.parent_id;
  }

  return numericValue;
}

export const createNumericValueOfThemeQuestionSelector = () => createSelector(
  (orm, {themeQuestionId}) => {
  const themeQuestion = orm.ThemeQuestion.withId(themeQuestionId);

  if (!themeQuestion)
    return '';
  let numericValue = `${themeQuestion.ref.ranking + 1}`;
  let parentTheme = themeQuestion.theme_id;

  while (!!parentTheme){
    numericValue = `${parentTheme.ref.ranking + 1}.${numericValue}`;
    parentTheme = parentTheme.parent_id;
  }

  return numericValue;
});

export function addNumericalValueInTree(tree) {
  function numeric(children, ranking) {
    children.map(c => {
      c.numericValue = `${ranking}.${c.ranking + 1}`;
      c.numericValueForSort = c.numericValue.replace(/\d+/g, n => +n+100000);

      if (!!c.children && !!c.children.length)
        numeric(c.children, c.numericValue)
    });
  }

  tree.map(t => {
    if (!!t.children && !!t.children.length)
      numeric(t.children, t.ranking + 1);
    return t;
  });

  return tree;
}

export const createQuestionnaireSelectorByAuditId = () => createSelector(
  (orm, {auditId, setNumericValue}) => {
    const audit = orm.Audit.withId(auditId);
    let questionnaires = audit.questionnaires.toModelArray();
    let questionnaireRefs = questionnaires.map(q => {
      return {
        ...q.ref,
        themeQuestionRanking: q.themes_question_id.ref.ranking
      }
    });

    if (!!setNumericValue){
      questionnaires.forEach((q, idx) => {
        questionnaireRefs[idx].numericValue = getNumericValue(q);
      });
    }
    return questionnaireRefs;
  }
);

export const createQuestionnaireByIdSelector = () => createSelector(
  (orm, {id, setNumericValue}) => {
    const questionnaire = orm.Questionnaire.withId(id);

    return !!questionnaire ? {
        ...questionnaire.ref,
        themeQuestionRanking: questionnaire.themes_question_id.ref.ranking,
        numericValue: !!setNumericValue ? getNumericValue(questionnaire) : undefined
      }
      :
      null;
  }
);

export const createQuestionnaireByClientIdSelector = () => createSelector(
  (orm, {client_id, setNumericValue, dispatch}) => {
    const audits = orm.Audit.all().filter(a => a.client_id === client_id).toModelArray();

    let questionnaires = [];
    audits.map(a => questionnaires = questionnaires.concat(a.questionnaires.toModelArray()));

    let questionnaireRefs = questionnaires.map(q => {
      return {
        ...q.ref,
        themes_question: q.themes_question_id.ref,
        themeQuestionRanking: q.themes_question_id.ref.ranking,
        response: orm.Response.all().filter(r => r.questionnaire_id === q.id).toRefArray()
      }
    });

    if (!!setNumericValue){
      questionnaires.forEach((q, idx) => {
        questionnaireRefs[idx].numericValue = getNumericValue(q);
      });
    }

    return questionnaireRefs;
  }
);


export const createQuestionnaireSelectorById = () => createSelector(
  (orm, {id}) => {
    const questionnaire = orm.Questionnaire.withId(id);

    return !!questionnaire ? questionnaire.ref : null;
  }
);