import React from "react";
import "../../../../css/questions_fillin.scss";
import {Col, Row} from 'reactstrap';
import {TotalI2CHeader} from "./TotalI2CHeader";
import {QuestionsTree} from "./DetailedRapport/QuestionsTree";
import {EvolText, I2CText} from "../Reporting/TextRenderers";
import {ColoredProgress} from "../../common/ColoredProgress";
import {Green, Orange, Red} from "../Reporting/utils";
import {SingleValueI2CDoughnut} from "../Reporting/SingleValueI2CDoughnut";

export const Print = ({analyseId, vague, zoneId, analyseLabel, zeLabel, clientLabel, node, passageLow, passageHigh, view}) => {
  const isDetailed = view === 'detailed';

  function childrenComponent (theme, fetchResponses, analyseId, zoneId, themeId) {
    if (theme.type === "theme") {
      return (
        <React.Fragment key={theme.id}>
      {/*<section key={theme.id}>*/}
        {/*<div className='card-body'>*/}
        <div>
          <h4 className='card-title d-inline-flex w-100'>
            <Col sm={7}>{theme.label}</Col>
            <Col sm={3}>
              <ColoredProgress
                style={{width: '100%'}}
                striped={!(theme.type === 'theme')}
                class="ml-2"
                colorLow={Red}
                colorMiddle={Orange}
                colorHigh={Green}
                passageLow={passageLow}
                passageHigh={passageHigh}
                value={theme.value}
                max={100}/>
            </Col>
            <Col sm={1}>
              <I2CText
                style={{width: 50}}
                passageLow={passageLow}
                passageHigh={passageHigh}
                class="ml-2 font-weight-bold text-center"
                value={theme.value}/>
            </Col>
            {!!theme.diff &&
            <Col sm={1}>
              <EvolText
                class="ml-2 font-weight-bold text-center"
                style={{width: 50}}
                value={theme.diff}/>
            </Col>}
          </h4>
          {theme.children.map(item => {
            if (item.type === "theme")
              return childrenComponent(item, fetchResponses, analyseId, zoneId);
            else if (!!fetchResponses) {
              return <QuestionsTree key={item.id} analyseId={analyseId} vague={vague} zoneId={zoneId} row={item} print
                                    detailed={isDetailed} themeId={theme.id}/>;
            }
          })}
        </div>
      {/*</section>*/}
        </React.Fragment>)
    }
    return <QuestionsTree key={theme.id} analyseId={analyseId}
                          vague={vague} zoneId={zoneId} row={theme} print detailed={isDetailed} themeId={themeId}/>;
  }

  return (
    <div className='page d-none' id='printable'>
      <div className='bg-white printable'>
        <Row>
          <div className='d-inline-flex w-100 mb-3'>
            <div className='col-sm-4'>
              <a className='metric metric-bordered align-items-center'>
                <h2 className='metric-label'>{t('common.client')}</h2>
                <p className='metric-value h3'>
                  <span className='value'>{clientLabel}</span>
                </p>
              </a>
            </div>
            <div className='col-sm-4'>
              <a className='metric metric-bordered align-items-center'>
                <h2 className='metric-label'>{t('common.analyse')}</h2>
                <p className='metric-value h3'>
                  <span className='value'>{analyseLabel}</span>
                </p>
              </a>
            </div>
            <div className='col-sm-4'>
              <a className='metric metric-bordered align-items-center'>
                <h2 className='metric-label'>{t('common.exploitation_zone')}</h2>
                <p className='metric-value h3'>
                  <span className='value'>{zeLabel}</span>
                </p>
              </a>
            </div>
          </div>
        </Row>
        <TotalI2CHeader
          print={true}
          passageLow={passageLow}
          passageHigh={passageHigh}/>
        {node.map(item => (
          <div className="mb-4 p-1" key={item.id}>
            <div className="d-flex flex-column justify-content-center" style={{flex: 0.2}}>
              <h3 className='text-center'>{item.label}</h3>
              <div className="d-flex">
                <div className="flex-grow-1" style={{width: '33%'}}/>
                <div className="flex-grow-1 d-flex align-items-center justify-content-center" style={{width: '33%'}}>
                  <SingleValueI2CDoughnut
                    passageLow={passageLow}
                    passageHigh={passageHigh}
                    value={item.value}
                    size={60}
                    fontSize={15}/>
                </div>
                {!!item.diff &&
                <div className="flex-grow-1 d-flex align-items-center justify-content-center"
                     style={{width: '33%'}}>
                  <h5 className="m-0">
                    <EvolText value={item.diff}/>
                  </h5>
                </div>}
              </div>
            </div>
            <div style={{borderLeft: '2px dashed #B8B8B8', flex: 1}}>
              {item.children.map((t, i) => childrenComponent(t, true, analyseId, zoneId, item.id))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
