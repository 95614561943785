import {combineReducers} from "redux";
import {sessionReducer} from "./session";
import {uiReducer} from "./ui";
import {dbReducer} from "./orm";
import {modalsReducer} from "./modals";
import {ocmModalsReducer} from "./ocmModals";
import {reportingReducer} from "./reporting";
import axios from 'axios';
import handleError from '../tools/axios';

const rootReducer = combineReducers({
  session: sessionReducer,
  ui: uiReducer,
  db: dbReducer,
  modals: modalsReducer,
  ocmModals: ocmModalsReducer,
  reporting: reportingReducer
});

export default rootReducer;
