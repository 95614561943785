import {fk, attr, many, Model} from "redux-orm";
import {_Prestataire, _Client, _Profile, _Firm} from "../constants/orm";

export class Profile extends Model {
  static parse(data){
    switch (data.model) {
      case 'prestataires':
        data.prestataire_id = data.cible;
        break;
      case 'clients':
        data.client_id = data.cible;
        break;
      case 'firms':
        data.firm_id = data.cible;
        break;
    }
    if (!!data.permissions)
      data.permissions = data.permissions.map(i => i.module);
    else
      data.permissions = [];
    return data;
  }

  static get fields(){
    return {
      id: attr(),
      model: attr(),
      label: attr(),
      description: attr(),
      prestataire_id: fk(_Prestataire, 'profiles'),
      client_id: fk(_Client, 'profiles'),
      firm_id: fk(_Firm, 'profiles'),
      permissions: attr()
    }
  }
}

Profile.modelName = _Profile;