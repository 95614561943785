import createSelector from "../tools/createSelector";
import shortid from "shortid";
import {getOrm} from "../redux";

export const progressPlanSelectorById = createSelector((orm, {id}) => {
    if(orm.ProgressPlan.idExists(id)){
      const progressPlanORM = orm.ProgressPlan.withId(id);
      let progressPlan = Object.assign({}, progressPlanORM.ref);

      progressPlan.actions = progressPlanORM.actions.toRefArray();
      progressPlan.themes = progressPlanORM.themes.toRefArray();
      progressPlan.themes_questions = progressPlanORM.themes_questions.toModelArray();
      progressPlan.zone_exploitations = progressPlanORM.zone_exploitations.toRefArray();
      progressPlan.themes_questions = progressPlan.themes_questions.map(q => q.question_id.ref);
      progressPlan.parameters = orm.Parameter
        .all()
        .filter(p => p.client_id === progressPlan.client_id)
        .toRefArray()
        .reduce((acc,curr)=> {
          acc[curr.label] = true;
          return acc;
        }, {});

      const notifications = orm.Notification
        .all()
        .filter(n => (
          n.model === 'progres' &&
          n.cible === progressPlan.id))
        .toRefArray()
        .reduce((acc, curr) => {
          acc[curr.user2_id] = curr;
          return acc;
        }, {});

      const ProgressZoneQuery = orm.ProgressZoneExploitation.all();
      const ProgressZones = ProgressZoneQuery.filter(p => p.progre_id === progressPlan.id).toRefArray();

      let responsables = {};
      ProgressZones.forEach(p => {
        const zone = orm.ZoneExploitation.withId(p.zone_exploitation_id).ref;
        if (!p.user_id){
          responsables[shortid.generate()] = {
            user: null,
            notifications: null,
            progress: [
              {
                zone: zone,
                progress: p
              }
            ]
          };
          return;
        }
        if (!responsables[p.user_id])
          responsables[p.user_id] = {
            user: orm.User.withId(p.user_id).ref,
            notification: notifications[p.user_id],
            progress: []
          };
        responsables[p.user_id].progress.push({
          zone: zone,
          progress: p
        });
      });
      progressPlan.responsables = responsables;
      return progressPlan;
    }
    return null;
  }
);

export const progressPlanById = (progressPlanId) => {
  if(getOrm().ProgressPlan.idExists(progressPlanId))
    return getOrm().ProgressPlan.withId(progressPlanId);
  return null;
};
