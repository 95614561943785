import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {cDate} from "../../tools/date";
import * as _Modules from '../../constants/modules';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {fetchUserIfNeeded} from "../../actions/orm/User";
import {fetchMessages, readMessage} from "../../actions/orm/Message";
import {fetchZoneExploitation} from "../../actions/orm/ZoneExploitations";
import {createMessagesSelector} from "../selectors/message";
import {createProgressPlansSelector} from "../selectors/progressPlan";
import {createProgressZonesExploitationSelector} from "../selectors/progressZoneExploitation";
import {createUsersObjectSelector} from "../selectors/user";
import {createAllZonesExploitationObjectSelector} from "../selectors/zoneExploitation";

const usersSelector = createUsersObjectSelector();
const messagesSelector = createMessagesSelector();
const progressPlansSelector = createProgressPlansSelector();
const zonesExploitationSelector = createAllZonesExploitationObjectSelector();
const progressZonesSelector = createProgressZonesExploitationSelector();

@connect((store, ownProps) => ({
    users: usersSelector(),
    progressPlans: progressPlansSelector(),
    zonesExploitation: zonesExploitationSelector(),
    progressZonesExploitation: progressZonesSelector(),
    messages: messagesSelector({user_id: store.session.userId})
  }),
  (dispatch) => ({
    fetchMessages: () => (
      dispatch(fetchMessages())
    ),
    readMessage: (id) => (
      dispatch(readMessage({
        message_id: id
      }))
    ),
    dispatch
  }))
class ConnectedMessages extends React.PureComponent {
  constructor(props){
    super(props);

    this.fetchData();

    this.interval = setInterval(async () => {
      this.fetchData();
    }, 180000);
  }

  fetchData = async () => {
    const users = [];
    const zones = [];
    let fetchUsers = [];
    let fetchZones = [];

    await this.props.fetchMessages();

    this.props.messages.map(message => {
      if (!users.includes(message.user_id) && !this.props.users[message.user_id]) {
        users.push(message.user_id);
        fetchUsers.push(this.props.dispatch(fetchUserIfNeeded({id: message.user_id})));
      }
      if (!zones.includes(message.zoneExploitationId) && !this.props.zonesExploitation[message.zoneExploitationId]) {
        zones.push(message.zoneExploitationId);
        fetchZones.push(this.props.dispatch(fetchZoneExploitation({zone_exploitation_id: message.zoneExploitationId})));
      }
    });

    await Promise.all([...fetchUsers, ...fetchZones]);
  };

  render(){
    let nbNewActivities = 0;

    this.props.messages.forEach(message => {
      if(!message.status)
        nbNewActivities += 1;
    });

    const hasNotified = (nbNewActivities  > 0 ? 'has-notified' : '');

    const restrictedMessages = this.props.messages.filter(m => !m.status).slice(0, 8);
    const messages = restrictedMessages.sort((a, b) => b.created - a.created).map(message => {
      const user = this.props.users[message.user_id];
      const zoneExploitation = this.props.zonesExploitation[message.zoneExploitationId];

      const timeIndicator = cDate.displayDiff(moment.unix(message.created).toDate(), new Date());
      const route = `${_Modules.getRoute('ProPla_Mon_Sin')}/${message.progreId}/${message.zoneExploitationId}`;

      return(
        <Link key={message.id}
              className={'dropdown-item' + (!!message.status ? ' ' : ' unread')} to={route}>
          <div className='user-avatar'>
            {!!user && !!user.path ?
            <img src={'/upload/' + user.path} alt=""/> :
            <span className='user-avatar tile tile-circle bg-muted text-white'>
              <i className='oi oi-person'/>
            </span>}
          </div>
          <div className='dropdown-item-body'>
            <p className='subject'>{!!zoneExploitation ? zoneExploitation.label : ''}</p>
            <p className='text'>{message.title}</p>
            <span className='date'>{timeIndicator}</span>
          </div>
        </Link>
      );
    });

    const {isMobile} = this.props;

    return(
      <li className={"nav-item dropdown header-nav-dropdown " + hasNotified}>
        <a className="nav-link" tabIndex="1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span className="oi oi-envelope-open"/>
        </a>

        {!isMobile &&
        <div className="dropdown-arrow"/>}
        <div
          x-placement="bottom-end"
          className="dropdown-menu dropdown-menu-rich dropdown-menu-right">
          <h6 className='dropdown-header stop-propagation'>
            <span>
              Messages
              <span className='badge'>({nbNewActivities})</span>
            </span>
          </h6>
          <PerfectScrollbar className='dropdown-scroll has-scrollable'>
            {messages}
          </PerfectScrollbar>
        </div>
      </li>
    );
  }
}

const Messageries = (ConnectedMessages);

export default Messageries;