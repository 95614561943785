import validator from "validator";

export const isEmpty = validator.isEmpty;
export const isEmail = validator.isEmail;
export const isMobilePhoneNotMandatory = (value) => { return !value || validator.isEmpty(value) || validator.isMobilePhone(value)};
export const isArrayEmpty = (value) => {
  return !value.length;
};

export const isPresent = (value) => {
  return !!value;
};

export const areThemesSelected = ({horizontal}) => {
  return !!(Object.values(horizontal).filter(o => !!o.checked)).length;
};

export const areTreeItemsSelected = ({horizontal}) => {
  return !!(Object.values(horizontal).filter(o => !!o.checked)).length;
};

export const areTreeLevelsEquals = ({tree}) => {
  let lvls = [];
  let lvl = -1;

  function getLevel(item, lvl){
    if (item.checked){
      lvls.push(lvl);
    } else {
      if (!!item.children && !!item.children.length)
        item.children.forEach(i => {
          getLevel(i, lvl + 1);
        });
    }
  }
  tree.forEach(item => {
    getLevel(item, 0);
  });
  for (let i = 0; i < lvls.length; i++){
    if (lvl === -1)
      lvl = lvls[i];
    else if (lvls[i] !== lvl)
      return false;
  }
  return true;
};

export const areThemesLevelsEquals = ({tree}) => {
  let lvls = [];
  let lvl = -1;

  function getLevel(item, lvl){
    if (item.checked){
      lvls.push(lvl);
    } else {
      if (!!item.ChildThemes && !!item.ChildThemes.length)
        item.ChildThemes.forEach(i => {
          getLevel(i, lvl + 1);
        });
      else if (!!item.questions && !!item.questions.length)
        item.questions.forEach(q => {
          getLevel(q, lvl + 1)
        });
    }
  }
  tree.forEach(item => {
    getLevel(item, 0);
  });
  for (let i = 0; i < lvls.length; i++){
    if (lvl === -1)
      lvl = lvls[i];
    else if (lvls[i] !== lvl)
      return false;
  }
  return true;
};

export const areHorizontalTreeItemsSelected = ({horizontal}) => {
  return !!(Object.values(horizontal).filter(o => !!o.checked)).length;
};

export const areOrganisationsLevelsEquals = ({tree}) => {
  let lvls = [];
  let lvl = -1;

  function getLevel(item, lvl){
    if (item.checked){
      lvls.push(lvl);
    } else {
      if (!!item.ChildClients && !!item.ChildClients.length)
        item.ChildClients.forEach(i => {
          getLevel(i, lvl + 1);
        });
      else if (!!item.sites && !!item.sites.length)
        item.sites.forEach(q => {
          getLevel(q, lvl + 1)
        });
      else if (!!item.zone_exploitations && !!item.zone_exploitations.length)
        item.zone_exploitations.forEach(q => {
          getLevel(q, lvl + 1)
        });
    }
  }
  tree.forEach(item => {
    getLevel(item, 0);
  });
  for (let i = 0; i < lvls.length; i++){
    if (lvl === -1)
      lvl = lvls[i];
    else if (lvls[i] !== lvl)
      return false;
  }
  return true;
};