import React from "react";
import {connect} from "react-redux";
import {setModal} from "../../actions/modals";
import {Modal, Button, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

@connect(({ocmModals: {genericAlert: {data: {title, body, button}, resolve, reject}}}) => ({
  title,
  body,
  button,
  resolve,
  reject
}))
class GenericAlert extends React.PureComponent {
  state = {
    closing: false
  };

  resolve = () => {
    this.props.resolve();
    this.closing();
  };

  reject = () => {
    this.props.reject();
    this.closing();
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setModal('genericAlert', false));
  };

  render(){
    const {title, body, button} = this.props;
    const {closing} = this.state;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.reject}>
          {title}
        </ModalHeader>
        <ModalBody>
          {body}
        </ModalBody>
        <ModalFooter>
          {!!button.cancel &&
          <Button
            color="secondary"
            onClick={this.reject}>
            {t('common.cancel')}
          </Button>}
          {!!button.confirm &&
          <Button
            onClick={this.resolve}
            color="success">
            {t('common.confirm')}
          </Button>}
          {!!button.close &&
          <Button
            color="secondary"
            onClick={this.reject}>
            {t('common.close')}
          </Button>}
        </ModalFooter>
      </Modal>
    )
  }
}

export {GenericAlert};
export default GenericAlert;
