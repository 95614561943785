import api from "../../tools/api";
import {_Taxonomy} from "../../constants/orm";
import {hydrateEntities, updateEntities, deleteEntity} from "../orm";
import {actionCallsTracker} from "../actionsTracker";

export const fetchGenericTaxonomies = () => {
  return async dispatch => {
    if (actionCallsTracker.isSaved('taxonomies', 'client', 'generic'))
      return;
    const taxonomies = await api.taxonomies.getGenerics();

    dispatch(hydrateEntities({
      payload: [
        {type: _Taxonomy, entities: taxonomies}
      ]
    }));

    actionCallsTracker.save('taxonomies', 'client', 'generic');
    return taxonomies
  }
};

export const fetchAllTaxonomies = () => {
  return async dispatch => {
    if (actionCallsTracker.isSaved('taxonomies', 'client', 'all'))
      return;

    const taxonomies = await api.taxonomies.getAll();

    dispatch(hydrateEntities({
      payload: [
        {type: _Taxonomy, entities: taxonomies}
      ]
    }));

    actionCallsTracker.save('taxonomies', 'client', 'all');
    return taxonomies;
  }
};

export const fetchTaxonomy = ({id}) => {
  return async dispatch => {
    const taxonomy = await api.taxonomies.get({id});

    dispatch(hydrateEntities({
      payload: [
        {type: _Taxonomy, entities: [taxonomy]}
      ]
    }));

    return taxonomy;
  }
};

export const addTaxonomy = ({client_id, parent_id, label, freeze}) => {
  return async dispatch => {
    const taxonomy = await api.taxonomies.add({
      client_id,
      parent_id,
      label,
      freeze
    });

    dispatch(hydrateEntities({
      payload: [
        {type: _Taxonomy, entities: [taxonomy]}
      ]
    }));

    return taxonomy;
  }
};

export const updateTaxonomy = ({id, client_id, parent_id, label, freeze}) => {
  return async dispatch => {
    const taxonomy = await api.taxonomies.update({
      id,
      client_id,
      parent_id,
      label,
      freeze
    });

    dispatch(updateEntities({
      payload: [
        {type: _Taxonomy, entities: [taxonomy]}
      ]
    }));

    return taxonomy;
  }
};

export const removeTaxonomy = ({id}) => {
  return async dispatch => {
    const ids = await api.taxonomies.remove({id});

    Object.keys(ids.message).forEach(i => {
      dispatch(deleteEntity({
        type: _Taxonomy,
        id: i
      }));
    });
    return true;
  }
};

export const cloneTaxonomy = ({client_id, taxonomy_id}) => {
  return async dispatch => {
    const taxonomies = await api.taxonomies.clone({
      client_id,
      taxonomy_id
    });

    dispatch(hydrateEntities({
      payload: [
        {type: _Taxonomy, entities: taxonomies}
      ]
    }));

    return taxonomies;
  }
};

export const copyTaxonomy = ({client_id, taxonomy_id}) => {
  return async dispatch => {
    const taxonomies = await api.taxonomies.copy({
      client_id,
      taxonomy_id
    });

    dispatch(hydrateEntities({
      payload: [
        {type: _Taxonomy, entities: taxonomies}
      ]
    }));

    return taxonomies;
  }
};