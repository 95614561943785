import React from "react";
import {connect} from "react-redux";
import {store} from "../store";

export function waitPromiseBeforeMount(promiseFunc){
  return (Comp) => {
    @connect()
    class HOC extends React.PureComponent {
      state = {
        ready: false
      };

      async componentDidMount(){
        await promiseFunc(store.getState(), this.props, this.props.dispatch);
        this.setState({ready: true});
      }

      render(){
        const {ready} = this.state;

        return ready ? <Comp {...this.props}/> : null;
      }
    }

    return HOC;
  }
}

export default waitPromiseBeforeMount;