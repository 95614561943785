import createSelector from "../../tools/createSelector";
import {addNumericalValueInTree} from "./questionnaire";

function sortTreeByRank(tree){
  tree.sort((a,b) => a.ranking - b.ranking);
  tree.forEach(node => {
    if (!!node.children && !!node.children.length)
      sortTreeByRank(node.children);
  });
}

function setupLevelsInTree(tree, lvl = 0){
  tree.forEach(t => {
    t.level = lvl;
    if (!!t.children && !!t.children.length)
      setupLevelsInTree(t.children, lvl + 1);
  })
}

function setupParentNodes(tree, parent = null){
  tree.forEach(node => {
    node.parentNode = parent;
    if (!!node.children && !!node.children.length)
      setupParentNodes(node.children, node);
  });
}

export const createThemeTreeByClientIdSelector2 = () => createSelector(
  (orm, {clientId, sortByRank, setupLevels, setupParents, setNumericValue}) => {
    const rootThemes = orm.Theme.all().filter(t => t.client_id === clientId && !t.parent_id).toModelArray();

    function setupTree(itemList, type){
      return itemList.map(item => {
        let ret = {
          ...item.ref,
          nodeModel: 'theme',
          type: 'theme',
          children: []
        };

        if (!!item.ChildThemes){
          const themes = item.ChildThemes.toModelArray();
          if (!!themes.length)
            ret.children = setupTree(themes, type);
        }
        if (!!item.themes_questions){
          const themeQuestions = item.themes_questions.toModelArray();
          if (!!themeQuestions.length){
            ret.children = themeQuestions.map(tq => {
              const question = tq.question_id.ref;

              return {
                ...tq.ref,
                label: question.title,
                question: {
                  ...question
                },
                type: 'question',
                nodeModel: 'themeQuestion'
              }
            });
          }
        }
        return ret;
      });
    }

    let tree = setupTree(rootThemes, 'theme');

    if (!!sortByRank)
      sortTreeByRank(tree);
    if (!!setupLevels)
      setupLevelsInTree(tree);
    if (!!setupParents)
      setupParentNodes(tree);
    if (!!setNumericValue)
      addNumericalValueInTree(tree);
    return tree;
  }
);

export const createThemeTreeByClientIdSelector = () => createSelector(
  (orm, {clientId, sortByRank, setupLevels, setupParents, setNumericValue}) => {
    const rootThemes = orm.Theme.all().filter(t => t.client_id === clientId && !t.parent_id).toModelArray();

    function setupTree(itemList, type){
      return itemList.map(item => {
        let ret = {
          ...item.ref,
          nodeModel: 'theme',
          type: 'theme',
          children: []
        };

        if (!!item.ChildThemes){
          const themes = item.ChildThemes.toModelArray();
          if (!!themes.length)
            ret.children = setupTree(themes, type);
        }
        if (!!item.themes_questions){
          const themeQuestions = item.themes_questions.toModelArray();
          if (!!themeQuestions.length){
            ret.children = themeQuestions.map(tq => {

              // const questionnaires = tq.questionnaires.toModelArray();
              //
              // let questionnaireRefs = questionnaires.map(q => ({
              //   ...q.ref,
              //   numericValue: null
              // }));
              //
              // if (!!setNumericValue) {
              //   questionnaires.forEach((q, idx) => {
              //     questionnaireRefs[idx].numericValue = getNumericValue(q);
              //   });
              // }

              return {
                ...tq.ref,
                // numericValue: !!setNumericValue && !!questionnaireRefs.length ?
                //   questionnaireRefs[0].numericValue : null,
                question: {
                  ...tq.question_id.ref
                },
                type: 'question',
                nodeModel: 'themeQuestion'
              }
            });
          }
        }
        return ret;
      });
    }

    let tree = setupTree(rootThemes, 'theme');

    if (!!sortByRank)
      sortTreeByRank(tree);
    if (!!setupLevels)
      setupLevelsInTree(tree);
    if (!!setupParents)
      setupParentNodes(tree);
    if (!!setNumericValue)
      addNumericalValueInTree(tree);
    return tree;
  }
);

export const createThemeRankingByIdSelector = () => createSelector(
  (orm, {id}) => {
    return orm.Theme.withId(id)?.ref.ranking;
  }
);
