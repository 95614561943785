import React from "react";
import {
  Form,
  FormGroup,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  FormText
} from "reactstrap";
import {setModal, setVisitsAuditorChange} from "../../actions/modals";
import {connect} from "react-redux";
import {LoadingButton} from "../common/LoadingButton";
import {Select2Wrapper} from "../common/Select2Wrapper";
import {updateVisit} from "../../actions/orm/Visit";
import {FormValidator} from "../../tools/formValidator/FormValidator";
import {createVisitByIdSelector, createVisitModelByIdSelector} from "../selectors/visit";
import {tFunction} from "../tools/Translation";
import {visitsByIds} from '../../ormSelectors/visit';
import {auditsByVisits} from '../../ormSelectors/audit';
import {usersByAudits} from '../../ormSelectors/user';

const localFv = new FormValidator([
  {
    field: 'userId',
    method: 'isPresent',
    validWhen: true,
    message: tFunction('modals.auditor_required')
  }
]);

const getVisitById = createVisitByIdSelector();
const getVisitModelById = createVisitModelByIdSelector();

@connect(({modals: {visitsAuditorChange: {visitIds}}, session: {currentClientId}}) => ({
  visitIds,
  clientId: currentClientId,
  auditUsers: !!visitIds ? usersByAudits(auditsByVisits(visitsByIds(visitIds))) : []
}))
class VisitsAuditorChange extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      closing: false,
      loading: false,
      userId: '',
      validation: localFv.valid(),
      users: props.auditUsers
    }
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const {userId, users} = this.state;
    const {visitIds, dispatch, auditUsers} = this.props;
    const noScope = [];

    const user = users.find(u => u.id === userId);

    const validation = localFv.validate({
      userId
    });

    if (!validation.isValid){
      this.setState({validation});
      return;
    }

    this.setState({loading: true});
    await Promise.all(visitIds.map(visitId => {
      const visit = getVisitById({id: visitId});

      // if (!!visit && !!user.scope_zones_exploitation.find(ze => ze.id === visit.zone_exploitation_id))
        dispatch(updateVisit({
          id: visitId,
          user_id: userId
        }));
      // else
      //   noScope.push(visitId);
    }));
    if (!!noScope.length) {
      let list = '';
      noScope.map(id => {
        list = `${list}${list === '' ? '' : ','} ${getVisitModelById({id}).zone_exploitation_id.ref.label}`;
      });

      dispatch(setModal('genericAlert', true, {
        data: {
          title: `${user.lastname} ${user.firstname} ${t('modals.no_necessary_rights')}`,
          body: <div><b>{list}</b><br/><br/>{t('modals.auditor_not_changed')}</div>,
          button: {close: true}
        }}));
    }
    this.setState({loading: false});
    this.closing();
  };

  close = () => {
    this.props.dispatch(setVisitsAuditorChange({
      open: false
    }));
  };

  closing = () => {
    this.setState({closing: true});
  };

  render(){
    const {closing, loading, userId, validation} = this.state;
    const {users} = this.state;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.closing}>
          {t('modals.choose_auditor')}
        </ModalHeader>
        <Form onSubmit={this.handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label>
                {t('modals.choose_the_auditor_:')}
              </Label>
              <Select2Wrapper
                name="userId"
                data={_.orderBy(users.map(u => ({
                  id: u.id,
                  text: `${u.firstname} ${u.lastname}`
                })), ['text'], 'asc')}
                value={userId}
                options={{
                  placeholder: t('modals.choose_the_auditor')
                }}
                onChange={this.handleChange}/>
              {<FormText color="red">
                {validation.userId.message}
              </FormText>}
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              type='button'
              disabled={loading}
              onClick={this.closing}>
              {t('common.close')}
            </Button>
            <LoadingButton
              loading={loading}
              disabled={loading}
              color="success">
              {t('common.valid')}
            </LoadingButton>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export {VisitsAuditorChange};
export default VisitsAuditorChange;
