import React from "react";
import {connect} from "react-redux";
import {
  UncontrolledButtonDropdown,
  Row,
  Col,
  FormGroup,
  Label,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import Select2 from "react-select2-wrapper";
import {Select2Wrapper} from "../common/Select2Wrapper";
import {setChooseQuestionnaireItems, setChooseOrganisationsItems} from "../../actions/modals";
import createSelector from "../../tools/createSelector";
import {LoadingButton} from "../common/LoadingButton";
import {clientById} from "../../ormSelectors/client";
import {ocmGreen} from "../../constants/ui";
import {createAllZonesExploitationObjectSelector} from "../selectors/zoneExploitation";
import {createAllClientObjectSelector} from "../selectors/clients";
import {createAllSitesObjectSelector} from "../selectors/site";
import update from "immutability-helper";
import {SelectNext} from "@neqo34/ui";
import {Card, CardBody, CardHeader, Collapse} from "reactstrap";

const clientObjectsSelector = createAllClientObjectSelector();
const zoneObjectsSelector = createAllZonesExploitationObjectSelector();
const siteObjectsSelector = createAllSitesObjectSelector();

const FilterSelector = createSelector(
  (orm, {clientId}) => {
    const client = orm.Client.withId(clientId);
    if (!client)
      return {
        audits: [],
        models:  [],
        prestataires: [],
        vagues: []
      };
    const audits = client.audits.toModelArray().filter(a => !!a.scope);
    const analyses = client.analyses.toModelArray();

    let analyseVagues = [];
    let vagues = [];

    audits.forEach(item => {
      item.reports.toModelArray().forEach(item => {
        vagues.push(item.vague);
      });
    });

    analyses.forEach(item => {
      const analyse_id = item.id;
      item.vagues.forEach(item => {
        analyseVagues.push({value: JSON.stringify({'vague': item.vague, 'analyse_id':analyse_id}), label: item.label});
      });
    });

    analyseVagues = [... new Set(analyseVagues)];
    vagues = [... new Set(vagues)];

    return {
      audits: audits.map(i => i.ref),
      models: client.progressModels.toRefArray(),
      prestataires: client.prestataires.toRefArray().filter(p => !!p.scope),
      vagues,
      analyses: analyses.map(i => i.ref),
      analyseVagues
    }
  }
);

const ThemesDropdownMenu = ({themes, themes_questions, resetFilter}) => {
  return (
    <DropdownMenu style={{maxHeight:'300px', overflow: 'auto'}}>
      <DropdownItem onClick={resetFilter}>
        {t('common.reinit_filters')}
      </DropdownItem>
      <DropdownItem divider/>
      <DropdownItem header>
        {t('common.selected_elements')}
      </DropdownItem>
      <DropdownItem header>
        {t('common.themes')}
      </DropdownItem>
      {!themes.length &&
      <DropdownItem disabled>{t('common.no_element_selected')}</DropdownItem>}
      {themes.map((item,idx) => {
        return (
          <DropdownItem disabled key={idx}>{item.label}</DropdownItem>
        )
      })}
      <DropdownItem header>
        Questions
      </DropdownItem>
      {!themes_questions.length &&
      <DropdownItem disabled>{t('common.no_element_selected')}</DropdownItem>}
      {themes_questions.map((item,idx) => {
        return (
          <DropdownItem disabled key={idx}>{!!item.question.title ? item.question.title : item.question.label}</DropdownItem>
        )
      })}
    </DropdownMenu>
  )
};

@connect(() => ({
  clientObjs: clientObjectsSelector(),
  zoneObjs: zoneObjectsSelector(),
  siteObjs: siteObjectsSelector()
}))
class OrganisationsDropdownMenu extends React.PureComponent{
  render(){
    const {clients, sites, zone_exploitations, resetFilter, label_zone_exploitations, chooseOrganisationItems} = this.props;

    return (
      <UncontrolledButtonDropdown direction={"right"}>
        <Button color="primary"
                id="organisationsButton"
                onClick={chooseOrganisationItems}>
          {t('tools.choose_organisations')}
        </Button>
        <DropdownToggle color="primary" caret/>
        <DropdownMenu style={{maxHeight:'300px', overflow: 'auto'}}>
          <DropdownItem onClick={resetFilter}>
            {t('common.reinit_filters')}
          </DropdownItem>
          <DropdownItem divider/>
          <DropdownItem header>
            {t('common.selected_elements')}
          </DropdownItem>
          <DropdownItem header>
            {t('common.clients')}
          </DropdownItem>
          {!clients.length &&
          <DropdownItem disabled>{t('common.no_element_selected')}</DropdownItem>}
          {clients.map((id,idx) => {
            const item = this.props.clientObjs[id];

            return (
              <DropdownItem disabled key={idx}>{!!item ? item.label : id}</DropdownItem>
            )
          })}
          <DropdownItem header>
            {t('common.sites')}
          </DropdownItem>
          {!sites.length &&
          <DropdownItem disabled>{t('common.no_element_selected')}</DropdownItem>}
          {sites.map((id,idx) => {
            const item = this.props.siteObjs[id];
            return (
              <DropdownItem disabled key={idx}>{!!item ? item.label : id}</DropdownItem>
            )
          })}
          <DropdownItem header>
            {label_zone_exploitations}
          </DropdownItem>
          {!zone_exploitations.length &&
          <DropdownItem disabled>{t('common.no_element_selected')}</DropdownItem>}
          {zone_exploitations.map((id,idx) => {
            const item = this.props.zoneObjs[id];

            return (
              <DropdownItem disabled key={idx}>{!!item ? item.label : id}</DropdownItem>
            )
          })}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    )
  }
};


@connect(
  (store, ownProps) => ({
    ...FilterSelector({clientId: ownProps.clientId}),
    currentClient: clientById(ownProps.clientId),
  })
)
class ProgressPlanFilter extends React.PureComponent {
  constructor(props){
    super(props);

    this.status = {
      0: t('common.in_progress'),
      1: t('pp.late'),
      2: t('common.archived')
    };

    this.state = {
      collapsed: true
    };

    this.statusOptions = Object.keys(this.status).map(item => {
      return {
        label: this.status[item],
        value: item
      }
    });

    const {value, analyses, analyseVagues} = this.props;

    if (!value.analyses.length && !value.vague.length && analyses.length === 1){
      const analyseId = [analyses[0].id];
      let vagueId = [];
      let vague = null;

      if (analyseVagues.length)
        vague = analyseVagues[_.maxBy(analyseVagues.map( (item,i) => {return {label: i,value: Number(JSON.parse(item.value).vague)+Number(JSON.parse(item.value).analyse_id)}}), 'value').label];
      if (!!vague)
        vagueId.push(vague.value);

      this.state.collapsed = false;
      this.props.onChange(update(value, {
        vague: {$set: vagueId},
        analyses: {$set: analyseId}
      }), this.props.filter);
    }
  }

  setCollapsed = (value) => {
    this.setState({collapsed: value});
  };

  onChange = ({target: {value, name}}) => {
    const {value: propsValue} = this.props;
    this.props.onChange(update(propsValue, {
      [name]: {$set: value}
    }));
  };

  themesButtonRef = React.createRef();
  selectChange = (event) => {
    const {value} = this.props;
    const eventValue = $(event.target).val().map(i => parseInt(i));

    this.props.onChange(update(value, {
      [event.target.name] : {$set: eventValue}
    }));
  };

  handleChange = (e) => {
    const {value} = this.props;
    this.props.onChange(update(value, {
      [e.target.name]: {$set: e.target.value}
    }));
  };

  resetOrganisations = () => {
    const {value} = this.props;

    this.props.onChange(update(value, {
      clients: {$set: []},
      sites:{$set: []},
      zone_exploitations: {$set: []}
    }));
  };
  resetThemes = () => {
    const {value} = this.props;

    this.props.onChange(update(value, {
      themes: {$set: []},
      themes_questions: {$set: []}
    }));
  };

  chooseQuestionnaireItems = async () => {
    const {dispatch, clientId, value} = this.props;
    const {themes, themes_questions} = value;

    const defaultValues = {
      themes: themes.map(i => i.id),
      themes_questions: themes_questions.map(i => i.id)
    };

    try {
      const {themes, themes_questions} = await dispatch(setChooseQuestionnaireItems({
        open: true,
        clientId,
        defaultValues
      }));

      this.props.onChange(update(value, {
        themes: {$set: themes},
        themes_questions: {$set: themes_questions}
      }));
    } catch (e) {}
  };

  chooseOrganisationsItems = async () => {
    const {dispatch, clientId, value} = this.props;
    const {clients, sites, zone_exploitations} = value;

    const defaultValues = {
      clients: clients,
      sites: sites,
      zone_exploitations: zone_exploitations
    };

    try {
      const {clients, sites, zone_exploitations} = await dispatch(setChooseOrganisationsItems({
        open: true,
        clientId,
        defaultValues
      }));

      this.props.onChange(update(value, {
        clients: {$set: clients},
        sites: {$set: sites},
        zone_exploitations: {$set: zone_exploitations}
      }));
    } catch (e) {}
  };

  render(){
    const {
      filtering,
      level,
      status,
      analyses: selectedAnalyses,
      vague: selectedAnalyseVagues,
      prestataires: selectedPrestataires,
      clients,
      sites,
      zone_exploitations,
      themes,
      themes_questions,
      models: selectedModels
    } = this.props.value;
    const {
      analyses,
      analyseVagues,
      models,
      prestataires,
      currentClient,
      resetFilters,
      filter,
      className
    } = this.props;
    const {collapsed} = this.state;

    return (
      <Card className={className}>
        <CardHeader>
          <button
            className={`btn btn-reset d-flex justify-content-between w-100 ${collapsed ? 'collapsed' : ''}`}
            // data-toggle="collapse"
            //  data-target="#new_typePP"
            //  aria-expanded="false"
            data-toggle="collapse"
            aria-expanded={!collapsed ? "true" : "false"}
            onClick={() => this.setCollapsed(!collapsed)}
            aria-controls="new_typePP">
            <h4 className='card-title m-0'>{t('common.filters')}</h4>
            <span className="collapse-indicator">
                      <i className="fa fa-fw fa-chevron-down"/>
                      </span>
          </button>
        </CardHeader>
        <Collapse isOpen={!collapsed}>
          <CardBody>
            <div id="pdpfilter">
              <Row>
                <Col sm={3}>
                  <FormGroup>
                    <Label>{t('common.organisations')}</Label>
                    <div>
                      <OrganisationsDropdownMenu
                        chooseOrganisationItems={this.chooseOrganisationsItems}
                        resetFilter={this.resetOrganisations}
                        clients={clients}
                        sites={sites}
                        zone_exploitations={zone_exploitations}
                        label_zone_exploitations={currentClient.label_zone_exploitation}/>
                      {(!!clients.length || !!sites.length || !!zone_exploitations.length) &&
                      <i className="ml-2 fa fa-check validation-check" style={{color: ocmGreen}}/>}
                    </div>
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <Label>{t('common.prestataires')}</Label>
                    <SelectNext
                      isMulti={true}
                      name={"prestataires"}
                      onChangeNormalized={this.onChange}
                      value={selectedPrestataires}
                      noOptionsMessage={() => t('common.empty')}
                      options={prestataires.map(i => ({value: i.id, label: i.label}))}
                      placeholder={t('common.prestataires')}
                    />
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <Label>{t('common.analyses')}</Label>
                    <div>
                      <SelectNext
                        isMulti={true}
                        name="analyses"
                        onChangeNormalized={this.onChange}
                        value={selectedAnalyses}
                        noOptionsMessage={() => t('common.empty')}
                        options={analyses.map(i => ({value: i.id, label: i.label}))}
                        placeholder={t('common.analyses')}
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <Label>{t('common.vagues')}</Label>
                    <SelectNext
                      isMulti={true}
                      name="vague"
                      onChangeNormalized={this.onChange}
                      value={selectedAnalyseVagues}
                      options={analyseVagues}
                      noOptionsMessage={() => t('common.empty')}
                      placeholder={t('common.vagues')}/>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <FormGroup>
                    <Label>{t('common.questionnaires')}</Label>
                    <div>
                      <UncontrolledButtonDropdown direction={"right"}>
                        <Button color="primary"
                                id="themesButton"
                                ref={this.themesButtonRef}
                                onClick={this.chooseQuestionnaireItems}>
                          {t('tools.choose_themes')}
                        </Button>
                        <DropdownToggle color="primary" caret/>
                        <ThemesDropdownMenu
                          themes={themes}
                          themes_questions={themes_questions}
                          resetFilter={this.resetThemes}/>
                      </UncontrolledButtonDropdown>
                      {(!!themes.length || !!themes_questions.length) &&
                      <i className="ml-2 fa fa-check validation-check" style={{color: ocmGreen}}/>}
                    </div>
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <Label>{t('common.status')}</Label>
                    <SelectNext
                      isMulti={true}
                      name="status"
                      onChangeNormalized={this.onChange}
                      value={status}
                      options={this.statusOptions}
                      noOptionsMessage={() => t('common.empty')}
                      placeholder={t('common.status')}/>
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <Label>{t('common.priorities')}</Label>
                    <SelectNext
                      isMulti={true}
                      name="level"
                      onChangeNormalized={this.onChange}
                      value={level}
                      options={[
                        { label: '1', value: 1},
                        { label: '2', value: 2},
                        { label: '3', value: 3}
                      ]}
                      noOptionsMessage={() => t('common.empty')}
                      placeholder={t('common.priority')}/>
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <Label>{t('sidebar.models')}</Label>
                    <SelectNext
                      isMulti={true}
                      name="models"
                      onChangeNormalized={this.onChange}
                      value={selectedModels}
                      options={models.map(i => ({value: i.id, label: i.label}))}
                      noOptionsMessage={() => t('common.empty')}
                      placeholder={t('sidebar.models')}/>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="text-right">
                  <Button
                    disabled={filtering}
                    color="secondary"
                    onClick={resetFilters}>
                    {t('common.reinit')}
                  </Button>
                  {' '}
                  <LoadingButton
                    loading={filtering}
                    disabled={filtering}
                    color="success"
                    onClick={filter}>
                    {t('common.to_filter')}
                  </LoadingButton>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Collapse>
      </Card>
    )
  }
}

export {ProgressPlanFilter};
export default ProgressPlanFilter;
