import React from "react";
import {connect} from "react-redux";
import {setViewImage} from "../../actions/modals";
import {Modal, ModalHeader, ModalBody} from "reactstrap";

@connect(({modals: {viewImage: {url}}}) => ({
  url
}))
class ViewImage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      closing: false
    }
  }

  close = () => {
    this.props.dispatch(setViewImage({open: false}));
  };

  closing = () => {
    this.setState({closing: true});
  };

  render(){
    const {url} = this.props;
    const {closing} = this.state;

    return (
      <Modal id="ViewImageModal" isOpen={!closing} style={{minWidth:'60%',minHeight: '60%'}} onClosed={this.close} toggle={this.closing}>
        <ModalHeader>
          <div class="close-button" onClick={this.closing}>
            <i class="fa fa-times"/>
          </div>
        </ModalHeader>
        <ModalBody style={{padding: 0, textAlign: 'center'}}>
          <img src={url} style={{maxWidth:'100%',maxHeight: '80%', height: 800}} alt={"image"}/>
        </ModalBody>
      </Modal>
    )
  }
}

export {ViewImage};
export default ViewImage;