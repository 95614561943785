import React from "react";
import {Button, Form, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {setModal} from "../../actions/modals";
import {connect} from "react-redux";
import {LoadingButton} from "../common/LoadingButton";
import {fetchClientAudits, fetchClientReports} from "../../actions/orm/Client";
import createSelector from "../../tools/createSelector";
import {createZoneExploitationByIdsSelector} from "../selectors/zoneExploitation";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import {updateAudit} from "../../actions/orm/Audit";
import _ from "lodash";
import {addVisit} from "../../actions/orm/Visit";

const clientAuditsSelector = createSelector(
  (orm, {clientId}) => {
    const client = orm.Client.withId(clientId);
    const audits = client.audits.toModelArray().filter(a => !!a.scope);
    return audits.map(audit => {
      return {
        ...audit.ref,
        reports: audit.reports.toRefArray(),
        zone_exploitations: audit.zone_exploitations.toRefArray().map(z => z.id)
      }
    });
  }
);

const zoneExploitationsSelector = createZoneExploitationByIdsSelector();

@connect(({ocmModals: {auditZoneExploitationAffectation: {zoneExploitationIds, clientId}}, session: {userId}}) => ({
  zoneExploitationIds,
  clientId,
  userId
}))
class AuditsZoneExploitationAffectation extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      closing: false,
      loading: false,
      dataLoading: true,
      zoneExploitations: [],
      audits: {},
      auditOptions: []
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    const {audits, zoneExploitations} = this.state;
    const {userId} = this.props;

    this.setState({loading: true});

    const calls = Object.values(audits).map(audit => {
      const zones = zoneExploitations.filter(zone => !!zone.audits && !!zone.audits.find(a => a.value === audit.id));

      if (!!zones.length)
        return this.props.dispatch(updateAudit({
          audit_id: audit.id,
          zone_exploitations: _.uniq([...audit.zone_exploitations, ...zones.map(z => z.id)])
        }));
    });

    await Promise.all(calls);

    let visitCalls = [];

    zoneExploitations.forEach(zone => {
      zone.audits.forEach(({value: auditId}) => {
        const audit = audits[auditId];
        const lastVague = _.maxBy(audit.reports, 'vague');
        if (!!lastVague && lastVague.status < 2){
          visitCalls.push(this.props.dispatch(addVisit({
            report_id: lastVague.id,
            zone_exploitation_id: zone.id,
            passage: lastVague.end_date,
            user_id: userId,
            status: 0,
            comment: ''
          })));
        }
      });
    });

    await Promise.all(visitCalls);

    this.setState({loading: false});
    this.closing();
  };

  chooseZoneExploitationAudit = (zId, value) => {
    const {zoneExploitations} = this.state;

    const newZoneExploitations = zoneExploitations.map(z => {
      return z.id === zId ? {
        ...z,
        audits: value
      } : z;
    });

    this.setState({zoneExploitations: newZoneExploitations});
  };

  async componentDidMount(){
    const {zoneExploitationIds, clientId} = this.props;

    await Promise.all([
      this.props.dispatch(fetchClientAudits({client_id: clientId})),
      this.props.dispatch(fetchClientReports({client_id: clientId}))
    ]);

    const audits = clientAuditsSelector({clientId: clientId}).filter(a => !a.freeze);
    let zoneExploitations = zoneExploitationsSelector({zeIds: zoneExploitationIds});

    zoneExploitations = zoneExploitations.map(zone => {
      return {
        ...zone,
        audits: []
      }
    });

    this.setState({
      dataLoading: false,
      zoneExploitations: zoneExploitations,
      audits: audits.reduce((acc, curr) => {
        acc[curr.id] = curr;

        return acc;
      }, {}),
      auditOptions: audits.map(a => ({value: a.id, label: a.label}))
    });
  }

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setModal('auditZoneExploitationAffectation', false));
  };

  columns = [
    {
      dataField: 'label',
      sort: true,
      text: t('common.label')
    },
    {
      dataField: 'audits',
      text: t('common.audits'),
      formatter: (col, row) => {
        return (
          <Select
            onChange={value => this.chooseZoneExploitationAudit(row.id, value)}
            defaultValue={row.audits}
            isMulti
            options={this.state.auditOptions}
            components={makeAnimated()}/>
        )
      }
    }
  ];

  render(){
    const {loading, closing, zoneExploitations, audits} = this.state;

    return (
      <Modal size="lg" isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.closing}>
          {t('modals.add_new_zones_in_audits')}
        </ModalHeader>
        <Form onSubmit={this.handleSubmit}>
          <ModalBody>
            <ToolkitProvider
              search
              keyField="id"
              bootstrap4={true}
              columns={this.columns}
              data={zoneExploitations}>
              {props => (
                <BootstrapTable
                  bordered={false}
                  pagination={paginationFactory()}
                  {...props.baseProps}/>
              )}
            </ToolkitProvider>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              type='button'
              disabled={loading}
              onClick={this.closing}>
              {t('common.close')}
            </Button>
            <LoadingButton
              loading={loading}
              disabled={loading}
              color="success">
              {t('common.valid')}
            </LoadingButton>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export default AuditsZoneExploitationAffectation;
export {AuditsZoneExploitationAffectation};
