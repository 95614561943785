import React from "react";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import {defaultPaginationFactory} from "../../constants/ui";
import {setAddProfile} from "../../actions/modals";
import LoadingButton from "../common/LoadingButton";
import {connect} from "react-redux";
import {FormValidator} from "../../tools/formValidator/FormValidator";
import {addProfile} from "../../actions/orm/Profile";
import createSelector from "../../tools/createSelector";
import {DebounceSearchInput} from "../common/DebounceSearchInput";
import {tFunction} from "../tools/Translation";

const localFv = new FormValidator([
  {
    field: 'label',
    method: 'isEmpty',
    validWhen: false,
    message: tFunction('modals.label_specified')
  }
]);

const profileSelector = createSelector(
  (orm, {profileId}) => {
    return {
      ...orm.Profile.withId(profileId).ref
    };
  }
);

const scopeClientsProfilesSelector = createSelector(
  (orm) => {
    return orm.Profile.all().toRefArray();
  }
);

const columns = [
  {
    dataField: 'label',
    text: tFunction('common.label'),
    sort: true
  }
];

@connect(({modals: {addProfile : {model, cible, profileIdToClone}}}) => ({
  model,
  cible,
  profileIdToClone
}))
class AddProfile extends React.PureComponent {
  state = {
    loading: false,
    closing: false,
    label: '',
    validation: localFv.valid(),
    mode: 'new',
    allProfiles: scopeClientsProfilesSelector({})
  };
  tableRef = null;

  inputLabel = React.createRef();

  componentDidMount() {
    this.inputLabel.focus();
  }

  setMode = (mode) => {
    this.setState({mode});
  };

  handleCopyProfiles = async () => {
    const {allProfiles} = this.state;
    const {model, cible} = this.props;

    const selectedProfiles = this.tableRef.selectionContext.state.selected
      .map(id => allProfiles.find(i => i.id === id));

    const calls = selectedProfiles.map(item => {
      return this.props.dispatch(addProfile({
        cible,
        model,
        label: (this.state.label === "") ? item.label : this.state.label,
        permissions: item.permissions
      }));
    });
    this.setState({loading: true});
    await Promise.all(calls);
    this.setState({loading: false});
    this.closing();
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const {label, mode} = this.state;
    const {cible, model, profileIdToClone, dispatch} = this.props;

    if (mode === 'copy'){
      this.handleCopyProfiles();
      return;
    }

    const validations = localFv.validate({
      label
    });

    if (!validations.isValid){
      this.setState({validation: validations});
      return;
    }

    this.setState({loading: true});
    if (!!profileIdToClone){
      const profile = profileSelector({profileId: profileIdToClone});
      await dispatch(addProfile({
        permissions: profile.permissions,
        cible,
        model,
        label
      }))
    }
    else
      await dispatch(addProfile({
        cible,
        model,
        label
      }));
    this.setState({loading: false});
    this.closing();
  };

  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setAddProfile({
      open: false
    }))
  };

  renderProfileTable = () => {
    const {allProfiles} = this.state;

    return (
      <FormGroup>
        <p className="text-muted">
          {t('modals.select_profiles_to_copy')}
        </p>
        <ToolkitProvider
          search
          keyField={'id'}
          columns={columns}
          bootstrap4={true}
          data={allProfiles}>
          {props => (
            <div>
              <DebounceSearchInput
                className="mb-2"
                onChange={(e) => props.searchProps.onSearch(e.target.value)}/>
              <BootstrapTable
                bordered={false}
                ref={ref => this.tableRef = ref}
                rowStyle={{cursor: 'pointer'}}
                noDataIndication={() => t('common.no_data')}
                selectRow={{
                  mode: 'checkbox',
                  clickToSelect: true,
                  bgColor: '#e6e6ed',
                  selectionRenderer: ({ mode, checked, disabled }) => (
                    <div className="cursor-pointer custom-control custom-control-nolabel custom-checkbox">
                      <input readOnly type="checkbox" className="custom-control-input" checked={checked}/>
                      <label className="cursor-pointer custom-control-label"/>
                    </div>
                  ),
                  selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
                    <div className="cursor-pointer custom-control custom-control-nolabel custom-checkbox">
                      <input readOnly indeterminate={indeterminate ? 'true' : 'false'} type="checkbox" className="custom-control-input" checked={checked}/>
                      <label className="cursor-pointer custom-control-label"/>
                    </div>
                  )
                }}
                pagination={defaultPaginationFactory()}
                {...props.baseProps}/>
            </div>
          )}
        </ToolkitProvider>
      </FormGroup>
    )
  };

  render(){
    const {closing, loading, validation, label, mode} = this.state;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.closing}>
          {t('admin.profiles_rights.add_profile')}
        </ModalHeader>
        <Form onSubmit={this.handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className="cursor-pointer"
                    onClick={this.setMode.bind(null, 'new')}
                    active={mode === 'new'}>
                    {t('common.new')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="cursor-pointer"
                    onClick={this.setMode.bind(null, 'copy')}
                    active={mode === 'copy'}>
                    {t('modals.copy_profiles')}
                  </NavLink>
                </NavItem>
              </Nav>
            </FormGroup>
            {mode === 'new' &&
            <FormGroup>
              <Label>
                {t('common.label')}
              </Label>
              <Input
                name="label"
                innerRef={(input) => (this.inputLabel = input)}
                invalid={validation.label.isInvalid}
                placeholder={t('common.label')}
                onChange={this.onChange}
                value={label}/>
              <FormFeedback>
                {validation.label.message}
              </FormFeedback>
            </FormGroup>}
            {mode === 'copy' &&
            this.renderProfileTable()}
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              type='button'
              disabled={loading}
              onClick={this.closing}>
              {t('common.close')}
            </Button>
            <LoadingButton
              loading={loading}
              disabled={loading}
              color="success">
              {t('common.valid')}
            </LoadingButton>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export {AddProfile};
export default AddProfile;
