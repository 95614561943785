import React from "react";
import {connect} from "react-redux";
import _ from "lodash";
import Select from "react-select";
import {setModal} from "../../actions/modals";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Label} from "reactstrap";
import {createClientSelectorById} from "../selectors/clients";
import {createProspectiveResponsablesByZoneIdSelector} from "../selectors/responsable";

const responsablesSelector = createProspectiveResponsablesByZoneIdSelector();
const clientSelector = createClientSelectorById();

@connect(({session: {currentClientId}, ocmModals: {addZoneExploitationInProgressPlan: {data, user_id, resolve, reject}}}) => ({
  data,
  reject,
  resolve,
  user_id,
  client: clientSelector({id: currentClientId})
}))
class AddZoneExploitationInProgressPlan extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      closing: false,
      zoneSelected: null,
      responsables: [],
      responsableSelected: null
    };
  }

  componentDidMount() {
    if (this.props.data.length === 1)
      this.selectZone({value: this.props.data[0].id});
  }

  selectZone = (e) => {
    const users = responsablesSelector({zoneId: e.value, dispatch: this.props.dispatch});

    this.setState({
      zoneSelected: e.value,
      responsables: !!users.length ? users : [],
      responsableSelected: !!this.props.user_id ? this.props.user_id : !!users.length ? users[0].id : null
    });
  };

  selectResponsable = (id) => {
    this.setState({responsableSelected: id});
  };

  resolve = () => {
    this.props.resolve({zoneSelected: this.state.zoneSelected, responsable: this.state.responsableSelected});
    this.closing();
  };

  reject = () => {
    this.props.reject();
    this.closing();
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setModal('addZoneExploitationInProgressPlan', false));
  };

  render() {
    const {data, client: {zoneExploitationLabel}} = this.props;
    const {closing, zoneSelected, responsables, responsableSelected} = this.state;

    let selectedResponsable = null;
    let selectedZone = null;
    const dataOptions = _.orderBy(data, ['i2c', 'label'], 'asc').map(d => {
      if (d.id === zoneSelected)
        selectedZone = {value: d.id, label: d.label};
      return {value: d.id, label: <div>
          {d.label}
          <strong style={{color: d.i2cColor}} className='ml-2'>
            {d.i2c === null ? t('common.not_applicable') : `${d.i2c.toFixed(0)}%`}
          </strong>
        </div>}
    });
    const responsablesOptions = responsables.map(d => {
      if (d.id === responsableSelected)
        selectedResponsable = {value: d.id, label: `${d.firstname} ${d.lastname}`};
      return {value: d.id, label: `${d.firstname} ${d.lastname}`}
    });

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.reject}>
          {t('common.add')} {zoneExploitationLabel}
        </ModalHeader>
        <ModalBody>
          <Label>{zoneExploitationLabel}</Label>
          <Select
            id='zone'
            className='mb-2'
            value={selectedZone}
            options={dataOptions}
            placeholder={t('common.select')}
            onChange={(e) => this.selectZone(e)}/>
          {!!zoneSelected && !!responsables.length &&
          <>
            <Label>{t('common.responsable')}</Label>
            <Select
              id='responsable'
              value={selectedResponsable}
              options={responsablesOptions}
              placeholder={t('common.select')}
              onChange={(e) => this.selectResponsable(e.value)}/>
          </>}
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={this.reject}>
            {t('common.cancel')}
          </Button>
          <Button
            color="success"
            onClick={this.resolve}
            disabled={!zoneSelected || !selectedResponsable}>
            {t('common.confirm')}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export {AddZoneExploitationInProgressPlan};
export default AddZoneExploitationInProgressPlan;