import createSelector from "../../tools/createSelector";

export const organisationsByClientId = createSelector(
  (orm, {clientId, setupChecked}) => {
    const client = orm.Client.withId(clientId);

    function setupTree(itemList, type){
      return itemList.map(item => {
        const ret = {...item.ref};
        ret.type = type;
        if (type === 'zone_exploitations'){
          ret.prestataires = item.prestataires.toRefArray().map(i => i.id);
          ret.taxonomies = item.taxonomies.toRefArray().map(i => i.id);
        }
        if (!!item.ChildClients)
          ret.ChildClients = setupTree(item.ChildClients.toModelArray(), 'client');
        if (!!item.sites)
          ret.sites = setupTree(item.sites.toModelArray(), 'site');
        if (!!item.zone_exploitations)
          ret.zone_exploitations = setupTree(item.zone_exploitations.toModelArray(), 'zone_exploitations');
        if (!!setupChecked)
          ret.checked = false;
        return ret;
      })
    }
    return setupTree([client], 'client');
  }
);

export const organisationsByClientId2 = createSelector(
  (orm, {clientId, setupChecked}) => {
    const client = orm.Client.withId(clientId);
    const rootClientRef = client.ref;

    function setupTree(itemList, type){
      return itemList.map(item => {
        const ret = {...item.ref};
        ret.type = type;
        ret.rootClient = rootClientRef;

        if (type === 'zone_exploitations') {
          const prestataire =  item.prestataires.toRefArray();

          ret.prestataires = prestataire.map(i => i.id);
          ret.prestataire_label = !!prestataire.length ? prestataire[0].label : '';
        }
        if (!!item.sites){
          const sites = item.sites.toModelArray();
          if (!!sites.length)
            ret.children = setupTree(sites, 'site');
        }
        if (!!item.ChildClients){
          const clients = item.ChildClients.toModelArray();
          if (!!clients.length)
            ret.children = setupTree(clients, 'client');
        }
        if (!!item.zone_exploitations){
          const zones = item.zone_exploitations.toModelArray();
          if (!!zones.length)
            ret.children = setupTree(zones, 'zone_exploitations')
        }
        if (!!setupChecked)
          ret.checked = false;
        return ret;
      });
    }
    return setupTree([client], 'client');
  }
);

export const parentOrganisations = createSelector(
  (orm) => {
    const clients = orm.Client.all().filter(i => (!i.parent_id && !!i.scope));
    const prestataires = orm.Prestataire.all().filter(i => (!i.parent_id && !!i.scope));
    const firms = orm.Firm.all();
    return {
      clients: clients.toRefArray(),
      prestataires: prestataires.toRefArray(),
      firms: firms.toRefArray()
    }
  }
);

