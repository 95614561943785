import React from "react";
import {Row, Col, Button, Card} from "reactstrap";
import {connect} from "react-redux";
import {progressPlanSelectorById} from "../../../../ormSelectors/progressPlan";
import {fetchProgressPlanImprove2} from "../../../../reducers/progressPlan/monitoring";
import {startPending, stopPending} from "../../../../actions/ui";
import {Link} from "react-router-dom";
import {getRoute, ProPla_Mon_Sin} from "../../../../constants/modules";
import moment from "moment";

@connect((store, ownProps) => ({
  progressPlans: ownProps.progressPlanIds.map(id => progressPlanSelectorById({id}))
}))
class extendedBrowseView extends React.Component {
  loading = false;

  componentDidMount(){
    const {progressPlans, progressPlanIds, dispatch} = this.props;
    this.loading = true;
    let calls = [];
    if (progressPlanIds.length && progressPlans.indexOf(null) !== -1) {
      calls.push(dispatch(fetchProgressPlanImprove2(progressPlanIds)));
      dispatch(startPending());

      Promise.all(calls).then(response => {
        dispatch(stopPending());
        this.loading = false;
      }).catch(e => {
        this.loading = false;
      });
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps){
    if (this.props !== nextProps && !this.loading){
      this.loading = true;
      const {progressPlans, progressPlanIds, dispatch} = nextProps;
      if (progressPlans.indexOf(null) !== -1){
        let calls = [];
        calls.push(dispatch(fetchProgressPlanImprove2(progressPlanIds)));
        // progressPlans.forEach((pp,i) => {
        //   if (!pp)
        //     calls.push(dispatch(fetchProgressPlan(progressPlanIds[i])))
        // });
        dispatch(startPending());
        Promise.all(calls).then(response => {
          dispatch(stopPending());
          this.loading = false;
        }).catch(e => {
          this.loading = false;
        });
      }
    }
  }
  renderPps = () => {
    const {progressPlans, search, zeIds} = this.props;

    let data = progressPlans.filter(p => p !== null);

    return progressPlans.filter(p => p !== null).map(pp => {
      const themes = !!pp.themes && pp.themes.length ? pp.themes.map((t,i) => <li key={i}>{t.label}</li>) : pp.themes_questions.map((tq, i) => <li key={i}>{tq.title}</li>);
      // const actions = pp.actions.map(a => <li key={a.id}>{a.label}</li>);
      const actions = pp.preconisation;
      const responsables = Object.values(pp.responsables).filter(user =>
        user.user !== null && (!zeIds || zeIds.find(id =>
          user.progress.find(progres => id === progres.zone.id)))).map(r => {
        return <li key={r.user.id}>{`${r.user.firstname} ${r.user.lastname}`}</li>
      });
      const endDate = !!pp.end_date ? moment.unix(pp.end_date).format('L') : null;

      return (
        <Card key={pp.id} body className="extendedProgressPlanView">
          <Row>
            <Col sm={2}>
              <p class="text-uppercase font-weight-bold">
                {moment.unix(pp.created).format('L')}
              </p>
              <p class="text-uppercase font-weight-bold">{t('common.thematic')}</p>
              <p>
                {!!themes.length ? themes : t('common.none_masculine')}
              </p>
            </Col>
            <Col sm={8}>
              <p class="text-uppercase font-weight-bold">{t('common.constat')}</p>
              <p>{pp.constat}</p>
              <p class="text-uppercase font-weight-bold">{t('pp.actions_to_enforce')}</p>
              <p>
                {!!actions.length ? actions: t('common.none_feminine')}
              </p>
            </Col>
            <Col sm={2}>
              <p class="text-uppercase font-weight-bold">{t('common.responsables')}</p>
              <p>
                {!!responsables.length ? responsables : t('common.none_masculine')}
              </p>
              <p class="text-uppercase font-weight-bold">{t('pp.time_limit')}</p>
              <p>{!!endDate ? endDate : t('common.not_precised')}</p>
              <Button tag={Link} to={`${getRoute(ProPla_Mon_Sin)}/${pp.id}`}>
                {t('common.browse')}
              </Button>
            </Col>
          </Row>
        </Card>
      )
    });
  };
  render(){
    return (
      <React.Fragment>
        {this.renderPps()}
      </React.Fragment>
    )
  }
}

export default extendedBrowseView;
