import {api} from "../../tools/api";
import {_Response, _Visit} from "../../constants/orm";
import {forceUpdateEnitities, hydrateEntities, updateEntities} from "../orm";
import {createResponseByVisitIdSelector, createVisitByResponseIdSelector} from "../../components/selectors/visit";

const getVisitByResponseId = createVisitByResponseIdSelector();
const getResponsesByVisitId = createResponseByVisitIdSelector();

export const fetchResponses = ({data}) => {
  return async dispatch => {
    const responses = await api.constats.fetch({data});

    await dispatch(hydrateEntities({
      payload: [
        {type: _Response, entities: responses}
      ]
    }));

    return responses;
  }
};

export const newNbResponses = ({response}) => {
  let answered = 0;

  const visit = getVisitByResponseId({id: response.id});
  const responses = getResponsesByVisitId({id: visit.id});

  responses.map(r => {
    if (r.id === response.id && (response.valid === true || !!response.value))
      answered++;
    else if (r.id !== response.id && (r.valid === true || !!r.value))
      answered++;
  });
  visit.nb_responses = answered;

  return visit;
};

export const sendResponse = ({questionnaire_id, zone_exploitation_id, report_id, value, files, photos, comment, valid}) => {
  return async dispatch => {
    const response = await api
      .questionnaire
      .sendResponse({
        questionnaire_id,
        zone_exploitation_id,
        report_id,
        value,
        files,
        photos,
        comment,
        valid
      });

    dispatch(forceUpdateEnitities({
      payload: [
        {type: _Response, entities: [response]}
      ]
    }));

    const visit = newNbResponses({response});

    dispatch(updateEntities({
      payload: [
        {type: _Visit, entities: [visit]}
      ]
    }));

    return response;
  }
};