import React from "react";
import {connect} from "react-redux";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label,
  Form
} from "reactstrap";
import {setModifyClientPrestataires} from "../../actions/modals";
import {LoadingButton} from "../common/LoadingButton";
import createSelector from "../../tools/createSelector";
import {Select2Wrapper} from "../common/Select2Wrapper";
import {updateClient} from "../../actions/orm/Client";

const prestatairesSelector = createSelector(
  (orm, {clientId}) =>{
    const client = orm.Client.withId(clientId);
    const prestataires = orm.Prestataire.all().filter(i => (!i.parent_id)).toRefArray();

    return {
      prestataires : prestataires,
      clientPrestataires : client.prestataires.toRefArray()
    }
  }
);

@connect(
  ({modals: {modifyClientPrestataires: {clientId}}}) => ({
    clientId
  })
)
class ModifyClientPrestataires extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {
      closing: false,
      loading: false,
      selectedPrestataires: [],
      prestataires: []
    };
    const {prestataires, clientPrestataires} = prestatairesSelector({clientId: this.props.clientId});

    this.state.selectedPrestataires = clientPrestataires.map(i => i.id);
    this.state.prestataires = _.orderBy(prestataires.map(i => ({id : i.id,text: i.label})), ['text'], 'asc');
  }

  onChange = (e) => {
    this.setState({[e.target.name] : e.target.value})
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const {selectedPrestataires} = this.state;
    const {clientId} = this.props;

    this.setState({loading: true});
    await this.props.dispatch(updateClient({
      id: clientId,
      prestataireIds: selectedPrestataires
    }));
    this.setState({loading: false});
    this.closing();
  };

  close = () => {
    this.props.dispatch(setModifyClientPrestataires({
      open: false
    }));
  };

  closing = () => {
    this.setState({closing: true});
  };

  render(){
    const {closing, loading, prestataires, selectedPrestataires} = this.state;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.closing}>{t('modals.modify_prestas')}</ModalHeader>
        <Form onSubmit={this.onSubmit}>
          <ModalBody>
            <FormGroup>
              <Label>
                {t('common.prestataires')}
              </Label>
              <Select2Wrapper
                multiple
                name="selectedPrestataires"
                data={prestataires}
                value={selectedPrestataires}
                options={{
                  placeholder: t('common.prestataires')
                }}
                onChange={this.onChange}/>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary"
                    type="button"
                    disabled={loading}
                    onClick={this.closing}>
              {t('common.close')}
            </Button>
            <LoadingButton color="success"
                           loading={loading}
                           disabled={loading}
                           type="submit">
              {t('common.valid')}
            </LoadingButton>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export {ModifyClientPrestataires};
export default ModifyClientPrestataires;
