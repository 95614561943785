import {fk, attr, many, Model} from 'redux-orm';
import {_Client, _Prestataire, _Responsable, _User} from "../constants/orm";

export class Client extends Model{
  static parse(data){
    delete data._matchingData;
    delete data._joinData;
    delete data.ChildClients;
    delete data.sites;

    if(data.label_i2c === '')
      data.label_i2c = 'I2C';
    if(data.label_zone_exploitation === '' || data.label_zone_exploitation === null)
      data.label_zone_exploitation = t('common.exploitation_zone');
    if (!data.label_audit)
      data.label_audit = t('common.audit');

    const responsables = (data.responsables !== undefined ? data.responsables.map(responsable => responsable.id) : []);
    const main_responsables = (data.responsables !== undefined && !!data.responsables.length ?
      data.responsables.filter(r => !!r.main).map(responsable => responsable.id) : []);
    const prestataires = (data.prestataires !== undefined ? data.prestataires.map(prestataire => prestataire.id) : []);

    return {
      ...data,
      characteristics: !!data.characteristics ? data.characteristics : [],
      main_responsables: main_responsables,
      responsables: responsables,
      prestataires: prestataires,
      zoneExploitationLabel: !!data.label_zone_exploitation ? data.label_zone_exploitation : t('common.exploitation_zone'),
      auditLabel: !!data.label_audit ? data.label_audit : t('common.audit')
    }
  }

  static get fields() {
    return {
      id: attr(),
      parent_id: fk(_Client, 'ChildClients'),
      prestataires: many(_Prestataire, 'clients'),
      label: attr(),
      adresse: attr(),
      code_postal: attr(),
      ville: attr(),
      pays: attr(),
      phone: attr(),
      created: attr(),
      modified: attr(),
      path: attr(),
      scope: attr(),
      label_i2c: attr(),
      label_zone_exploitation: attr(),
      label_audit: attr(),
      zoneExploitationsLabel: attr(),
      auditLabel: attr(),
      responsables: many(_Responsable, 'clients'),
      main_responsables: many(_Responsable, 'clientsMainResponsables'),
      characteristics: attr(),
      numerical: attr()
    };
  }
}

Client.modelName = _Client;
