import React from "react";
import {connect} from "react-redux";
import {Modal, ModalBody} from "reactstrap";
import {setReportingView} from "../../actions/modals";

@connect()
class ReportingView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      closing: false
    };
  }

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setReportingView({
      open: false
    }));
  };

  render(){
    const {closing} = this.state;

    return (
      <Modal isOpen={!closing}
             id="reportingViewModal"
             onClosed={this.close}>
        <div class="close-button" onClick={this.closing}>
          <i class="fa fa-times"/> {t('common.close')}
        </div>
        <ModalBody className="d-flex p-0">
          <iframe src={'https://neqo-demo.forepaas.io/ocm-app/#/qualite'}
                  style={{
                    width: '100%',
                    border: 'none',
                    margin: 0
                  }}/>
        </ModalBody>
      </Modal>
    )
  }
}

export {ReportingView};
export default ReportingView;
