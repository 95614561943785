import React from "react";
import {connect} from "react-redux";
import {FormFeedback, Alert, Button, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Editor} from 'react-draft-wysiwyg';
import {EditorState} from 'draft-js';
import {convertToHTML} from "draft-convert";
import {setProgressPlanModelEdit, setWriteEditorText} from "../../actions/modals";
import {updateProgressModel} from "../../actions/orm";
import {Form, Input, FormGroup, Label} from "reactstrap";
import createSelector from "../../tools/createSelector";
import {LoadingButton} from "../common/LoadingButton";
import fv from "../../tools/formValidator/FormValidator";
import {createThemeTreeByClientIdSelector} from "../selectors/theme";
import ThemesHierarchyTree from "../tools/ThemesHierarchyTree";
import {tFunction} from "../tools/Translation";
import {createClientSelectorById} from "../selectors/clients";

const localFv = new fv([
  {
    field: 'label',
    method: 'isEmpty',
    validWhen: false,
    message: tFunction('modals.label_specified')
  },
  {
    field: 'themes',
    method: "areTreeItemsSelected",
    validWhen: true,
    message: tFunction('modals.choose_theme_or_question')
  },
  {
    field: 'themes',
    method: 'areTreeLevelsEquals',
    validWhen: true,
    message: tFunction('choose_only_same_level_theme')
  }
]);

const progressPlanModelSelector = createSelector(
  (orm, {modelId}) => {
    if (orm.ProgressModel.idExists(modelId)){
      const pm = orm.ProgressModel.withId(modelId);
      return {
        ...pm.ref,
        actions: pm.modelActions.toRefArray(),
        themes: pm.themes.toRefArray(),
        themes_questions: pm.themes_questions.toRefArray()
      };
    }
    return null;
  }
);

const themesTreeSelector = createThemeTreeByClientIdSelector();
const clientSelector = createClientSelectorById();

@connect(({session: {currentClientId}, modals: {progressPlanModelEdit : {modelId}}}) => ({
  modelId,
  client: clientSelector({id: currentClientId})
}),
dispatch => ({
  close: () => {
    dispatch(setProgressPlanModelEdit({
      open: false
    }))
  },
  update: (props) => {
    return dispatch(updateProgressModel(props));
  },
  updateAction: ({open, labelText, defaultValue, headerText}) => {
    return dispatch(setWriteEditorText({open, labelText, defaultValue, headerText}));
  }
}))
export default class ProgressPlanModelEdit extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {
      themes: [],
      model: null,
      level: 1,
      label: '',
      actions: [],
      removedActions: [],
      newActions: [],
      updateActions: [],
      action: '',
      theme: [],
      question: [],
      loading: false,
      errors: localFv.valid(),
      closing: false,
      editorState: EditorState.createEmpty()
    };
  }

  inputLabel = React.createRef();

  componentDidMount() {
    this.inputLabel.focus();
  }

  onSubmit = (e) => {
    e.preventDefault();
    const {themes} = this.state;
    const horizontal = flatTree(themes);
    const validation = localFv.validate({
      label: this.state.label,
      themes: {
        tree: themes,
        horizontal: horizontal
      }
    });

    if (!validation.isValid){
      this.setState({errors: validation});
      return;
    }

    const checked = Object.values(horizontal).filter(i => !!i.checked);
    let type = 'themeQuestion';

    for (let i = 0; i < checked.length; i++){
      if (checked[i].nodeModel === 'theme'){
        type = 'theme';
        break;
      }
    }
    if (type === 'theme'){
      let minLevel = 999;
      const themes = checked.filter(c => c.nodeModel === 'theme');
      themes.forEach(t => {
        if (t.level < minLevel)
          minLevel = t.level;
      });
      const filteredThemes = themes.filter(t => t.level === minLevel);
      this.processRequest({
        themes: filteredThemes.map(t => t.id)
      });
    } else {
      this.processRequest({
        themes_questions: checked.map(c => c.id)
      });
    }
  };

  processRequest = ({themes, themes_questions}) => {
    const {update, modelId} = this.props;
    const {label, level, removedActions, newActions, updateActions} = this.state;

    this.setState({loading: true});
    update({
      id: modelId,
      label,
      level,
      removedActions,
      newActions,
      updateActions,
      themes,
      themes_questions
    }).then(r => {
      this.setState({loading: false});
      this.closing();
    })
  };

  UNSAFE_componentWillMount(){
    const {modelId, client} = this.props;
    const model = progressPlanModelSelector({modelId});
    let themes = themesTreeSelector({
      clientId: model.client_id,
      sortByRank: true,
      setupLevels: true,
      setNumericValue: !!client.numerical
    }, true);

    let horizontal = flatTree(themes);

    model.themes.forEach(t => {
      let item = horizontal.find(i => (i.nodeModel === 'theme' && i.id === t.id));

      if (!!item)
        item.checked = true;
    });
    model.themes_questions.forEach(q => {
      let item = horizontal.find(i => (i.nodeModel === 'themeQuestion' && i.id === q.id));

      if (!!item)
        item.checked = true;
    });

    this.setState({
      themes: themes,
      level: model.level,
      label: model.label,
      actions: model.actions,
      model: model
    });
  }

  onChange = ({target: {name, value}}) => {
    this.setState({[name]: value});
  };

  handleEditorStateChange = (editorState) => {
    const action = convertToHTML(editorState.getCurrentContent());
    this.setState({
      editorState,
      action: action
    });
  };

  addAction = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const {action} = this.state;

    if (!!action.length) {
      this.setState({
        newActions: [...this.state.newActions, this.state.action],
        editorState: ''})
    }
  };

  removeExistingAction = (index) => {
    this.setState({
      removedActions: [...this.state.removedActions, this.state.actions[index]],
      actions: this.state.actions.filter((a,i) => i !== index)
    })
  };

  removeAction = (index) => {
    this.setState({newActions: this.state.newActions.filter((a,i) => i !== index)});
  };

  updateAction = async (aId, aLabel) => {
    const {updateAction} = this.props;
    try {
      const newText = await this.props.updateAction({
        open: true,
        defaultValue: aLabel,
        labelText: t('common.label'),
        headerText: t('modals.profil_edition')
      });

      const label = convertToHTML(newText.getCurrentContent());
      if (!!label.length)
      {
        let a = this.state.actions.slice();
        const index = a.findIndex(i => (i.id === aId))

        if(typeof index !=='undefined'){
          a[index]["label"] = label;
          this.setState({
            updateActions: [...this.state.updateActions, this.state.actions[index]],
            actions: a
          });
        }
      }

    } catch (e) {}
  };

  close = () => {
    this.props.close();
  };

  closing = () => {
    this.setState({closing: true});
  };

  render(){
    const {closing, errors, themes, level, label, actions, newActions, loading} = this.state;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.closing}>{t('modals.edit_pp_model')}</ModalHeader>
        <Form onSubmit={this.onSubmit} id="editModelModalForm">
          <ModalBody>
            <FormGroup>
              <ThemesHierarchyTree
                name={"modelEditQtTree"}
                data={themes}/>
            </FormGroup>
            <FormGroup>
              <Label>{t('common.label')}</Label>
              <Input type="text"
                     innerRef={(input) => (this.inputLabel = input)}
                     invalid={errors.label.isInvalid}
                     value={label}
                     onChange={this.onChange}
                     placeholder={t('common.label')}
                     name="label"/>
              {errors.label.isInvalid &&
              <FormFeedback>
                {errors.label.message}
              </FormFeedback>}
            </FormGroup>
            <FormGroup>
              <Label>{t('common.priority')}</Label>
              <Input type="select"
                     placeholder={t('common.priority')}
                     name="level"
                     value={level}
                     onChange={this.onChange}>
                {[1,2,3].map((o, i) => <option key={i}>{o}</option>)}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>{t('modals.add_action_preconisation')}</Label>
              <div>
              <Editor 
                    placeholder={t('common.action_preconisation')}
                    editorState={this.state.editorState}
                    onEditorStateChange={this.handleEditorStateChange}
                    toolbar={{
                      options: ['inline', 'list', 'blockType'],
                      inline: {
                        inDropdown: false,
                        options: ['bold', 'italic', 'underline', 'strikethrough']
                      },
                      blockType: {
                        inDropdown: true,
                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6']
                      },
                      list: {
                        inDropdown: false,
                        options: ['unordered', 'ordered']
                      }
                    }}/>
                  <Button color="primary"
                          type="button"
                          onClick={this.addAction}>{t('common.add')}</Button>
              </div>
            </FormGroup>
            <FormGroup>
              <ListGroup className="list-group-bordered">
                <div class="list-group-header">
                  {t('common.action_preconisation')}
                </div>
                {actions.map((a,i) => (
                  <ListGroupItem key={a.id}>
                    <div className="list-group-item-body" dangerouslySetInnerHTML={{__html: a.label}} />
                    <div class="list-group-item-figure">
                      <button className="btn btn-sm btn-light"
                              type="button"
                              onClick={(e) => {e.stopPropagation();this.updateAction(a.id, a.label)}}>
                        <i className="fa fa-edit"/>
                      </button>
                      <button className="btn btn-sm btn-light"
                              type="button"
                              onClick={() => this.removeExistingAction(i)}>
                        <i className="fa fa-times"/>
                      </button>
                    </div>
                  </ListGroupItem>
                ))}
                {newActions.map((a,i) => (
                  <ListGroupItem key={i}>
                    <div className="list-group-item-body" dangerouslySetInnerHTML={{__html: a}} />
                    <div className="list-group-item-figure">
                    <button className="btn btn-sm btn-light"
                              type="button"
                              onClick={(e) => {e.stopPropagation();this.updateAction(a.id, a.label)}}>
                        <i className="fa fa-edit"/>
                      </button>
                      <button className="btn btn-sm btn-light"
                              type="button"
                              onClick={() => this.removeAction(i)}>
                        <i className="fa fa-times"/>
                      </button>
                    </div>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </FormGroup>
            {errors.themes.isInvalid &&
            <Alert color="danger">
              {errors.themes.message}
            </Alert>}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary"
                    type="button"
                    disabled={loading}
                    onClick={this.closing}>
              {t('common.close')}
            </Button>
            <LoadingButton color="success"
                           loading={loading}
                           disabled={loading}
                           type="submit">
              {t('common.valid')}
            </LoadingButton>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}
