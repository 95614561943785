import * as _Redux from "../../redux";
import axios from 'axios';
import {HYDRATE_ENTITIES, UPDATE_ENTITIES} from "../../actions/orm";
import {START_PENDING, STOP_PENDING} from "../../actions/ui";
import {_Message, _ProgressPlan, _Action, _ModelAction, _Report, _Site, _Theme,_User, _ThemeQuestion, _ZoneExploitation, _ProgressModel, _ProgressClient, _ProgressSite, _ProgressZoneExploitation, _Responsable, _Notification} from "../../constants/orm";
import {fetchUserIfNeeded} from "../../actions/orm/User";
import api from "../../tools/api";
import {fetchQuestionnaires} from "./create";
import {hydrateEntities} from "../../actions/orm";

const hydrateProgressZone = (zone) => {
  return dispatch => {
    const {zone_exploitation_id, client_id, site_id, responsable} = zone;

    // if (!!responsable) {
    //   dispatch({
    //     type: HYDRATE_ENTITIES,
    //     payload: [
    //       {type: _User, entities: [responsable.user]},
    //       {type: _Responsable, entities: [responsable]}
    //     ]});
    // }
    switch (true){
      case (!!zone_exploitation_id):
        dispatch({type: HYDRATE_ENTITIES,
          payload: [
            {type: _User, entities: [zone.user]},
            {type: _ProgressZoneExploitation, entities: [zone]}
          ]
        });
        break;
      case (!!client_id):
        dispatch({type: HYDRATE_ENTITIES, payload: [{type: _ProgressClient, entities: [zone]}]});
        break;
      case (!!site_id):
        dispatch({type: HYDRATE_ENTITIES, payload: [{type: _ProgressSite, entities: [zone]}]});
        break;
    }
  }
};

export function fetchProgressPlanImprove2(ids){
  return async (dispatch) => {
    try {
      let {progres, notifications} = await api.progress.getMultiple({ids});
      let filters = {clients: [_Redux.getStore('session').currentClientId]};
      let authorIds = [];
      let reportIds = [];

      await Promise.all(progres.map(async p => {
        await Promise.all([
          !authorIds.find(id => p.author_id === id) ? dispatch(fetchUserIfNeeded({id: p.author_id})) : null,
          // !reportIds.find(id => p.report_id === id) ? fetchQuestionnaires(dispatch, p.report.audit_id, p.report_id) : null,
          authorIds.push(p.author_id),
          reportIds.push(p.analyse_id)
        ]);
        p.responsables.forEach(p => {
          dispatch(hydrateProgressZone(p));
        });

        let progressPlanActions = [];

        p.progres_zone_exploitations.map(pz => {
          pz.actions.map(a => {
            progressPlanActions.push({
              ...a,
              zeId: pz.zone_exploitation_id,
              files: a.action_uploads.filter(u => u.type === 'files'),
              photos: a.action_uploads.filter(u => u.type === 'photos')
            });
          });
        });

        p.actions = progressPlanActions;


        // if (p.themes.length > 0) filters.themes = p.themes.map(theme => theme.id);
        // else filters.themes_questions = p.themes_questions.map(themes_question => themes_question.id);
        // const modelsData = await api.progressModel.get({...filters});
        // let actions = [];
        // await modelsData.forEach(function(model){
        //   actions = actions.concat(model.type_actions);
        // });
        //
        // dispatch({
        //   type: HYDRATE_ENTITIES,
        //   payload: [
        //     {type: _ModelAction, entities: actions},
        //     {type: _ProgressModel, entities: modelsData}
        //   ]});
        dispatch({
          type: HYDRATE_ENTITIES,
          payload: [
            {type: _Action, entities: p.actions},
//          {type: _ZoneExploitation, entities: progresData.zone_exploitations},
//          {type: _Report, entities: [p.report]},
//          {type: _Site, entities: progresData.sites},
            {type: _Theme, entities: p.themes},
            {type: _ThemeQuestion, entities: p.themes_questions},
            {type: _ProgressPlan, entities: [p]}
          ]
        });
      }));

      const modelsData = await api.progressModel.get({});

      let actions = [];
      await modelsData.forEach(function(model){
        actions = actions.concat(model.type_actions);
      });

      dispatch({
        type: HYDRATE_ENTITIES,
        payload: [
          {type: _ModelAction, entities: actions},
          {type: _ProgressModel, entities: modelsData}
        ]});
      // const modelsData = await getTypeProgress();

      notifications.forEach(n => {
        dispatch({type: HYDRATE_ENTITIES, payload: [{type: _User, entities: [n.sender, n.receiver]}]});
        dispatch({
          type: HYDRATE_ENTITIES,
          payload: [
            {type: _Message, entities: n.messages.flat()}
          ]});
      });
      dispatch({
        type: HYDRATE_ENTITIES,
        payload: [
          {type: _Notification, entities: notifications}
        ]});
    } catch (e) {
      console.log(e);
      throw e;
    }
  }
}

export function fetchProgressPlan(id, filters = []){
  return async (dispatch) => {
    try {
      let [progresData, progressZones, notifications] = (await Promise.all([
        api.progress.get({id}),
        api.progress.getResponsables({progress_id: id}),
        api.progress.getNotifications({progress_id: id})
      ]));

      await Promise.all([
        dispatch(fetchUserIfNeeded({id: progresData.author_id}))
//        fetchQuestionnaires(dispatch, progresData.report.audit_id, progresData.report.id)
      ]);

      progressZones.forEach(p => {
        dispatch(hydrateProgressZone(p));
      });

      filters = {clients: [_Redux.getStore('session').currentClientId]};
      const modelsData = await api.progressModel.get({...filters});

      let actions = [];
      modelsData.forEach(function(model){
        actions = actions.concat(model.type_actions);
      });

      dispatch({
        type: HYDRATE_ENTITIES,
        payload: [
          {type: _ModelAction, entities: actions},
          {type: _ProgressModel, entities: modelsData}
        ]});

      if (progresData.themes.length > 0) filters.themes = progresData.themes.map(theme => theme.id);
      else filters.themes_questions = progresData.themes_questions.map(themes_question => themes_question.id);

      let progressPlanActions = [];

      progresData.progres_zone_exploitations.map(p => {
        p.actions.map(a => {
          progressPlanActions.push({
            ...a,
            zeId: p.zone_exploitation_id,
            files: a.action_uploads.filter(u => u.type === 'files'),
            photos: a.action_uploads.filter(u => u.type === 'photos')
          });
        });
      });

      progresData.actions = progressPlanActions;

      // const calls = notifications.map(n => {
      //   return api.notifications.getMessages({
      //     notification_id: n.id
      //   });
      // });
      // const messages = await Promise.all(calls);
      notifications.forEach(n => {
        dispatch({type: HYDRATE_ENTITIES, payload: [{type: _User, entities: [n.sender, n.receiver]}]});
      });
      // dispatch({
      //   type: HYDRATE_ENTITIES,
      //   payload: [
      //     {type: _Message, entities: messages.flat()}
      //   ]});
      dispatch({
        type: HYDRATE_ENTITIES,
        payload: [
          {type: _Notification, entities: notifications}
        ]});
      dispatch({
        type: HYDRATE_ENTITIES,
        payload: [
          {type: _Action, entities: progressPlanActions},
          {type: _ZoneExploitation, entities: progresData.zone_exploitations},
          {type: _Report, entities: [progresData.report]},
          // {type: _Site, entities: progresData.sites},
          {type: _Theme, entities: progresData.themes},
          {type: _ThemeQuestion, entities: progresData.themes_questions},
          {type: _ProgressPlan, entities: [progresData]}
        ]
      });
      return progresData;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }
}

export function uploadImport(file){
  let data = new FormData();
  data.append('file', file);

  return (dispatch) => {
    axios.post('/files', data);
  };
}

export function editProgressPlan(id, datas){
  return async dispatch => {
    try {
      dispatch({type: START_PENDING});

      const updatedProgres = await axios.put('/api/progres/edit/' + id, datas);

      let progressPlanActions = [];

      updatedProgres.data.progres_zone_exploitations.map(p => {
        p.actions.map(a => {
          progressPlanActions.push({
            ...a,
            zeId: p.zone_exploitation_id,
            files: a.action_uploads.filter(u => u.type === 'files'),
            photos: a.action_uploads.filter(u => u.type === 'photos')
          });
        });
      });

      updatedProgres.data.actions = progressPlanActions;

      dispatch({type: HYDRATE_ENTITIES, payload: [{type: _Action, entities: updatedProgres.data.actions}]});
      dispatch({type: UPDATE_ENTITIES, payload: [{type: _ProgressPlan, entities: [updatedProgres.data]}]});
      dispatch({type: STOP_PENDING});
    } catch (error) {
      console.log(error);
      dispatch({type: STOP_PENDING});
    }
  }
}
