import React from "react";
import {api} from "./tools/api";
import {LoadingButton} from "./components/common/LoadingButton";
import {FormText, Input} from "reactstrap";
import {fetchSessionUser} from "./actions/orm/User";
import {connect} from "react-redux";
import {Redirect, Route, Switch} from "react-router";
import {FormValidator} from "./tools/formValidator/FormValidator";
import {NavLink} from "react-router-dom";
import {getRoute} from "./constants/modules";

const loginFv = new FormValidator([
  {
    field: 'email',
    method: 'isEmpty',
    validWhen: false,
    message: 'Email doit être spécifié.'
  },
  {
    field: 'email',
    method: 'isEmail',
    validWhen: true,
    message: 'Vous devez indiquer une adresse email.'
  },
  {
    field: 'password',
    method: 'isEmpty',
    validWhen: false,
    message: 'Mot de passe ne peut pas être vide.'
  }
]);

const resetFv = new FormValidator([
  {
    field: 'email',
    method: 'isEmpty',
    validWhen: false,
    message: 'Email doit être spécifié.'
  },
  {
    field: 'email',
    method: 'isEmail',
    validWhen: true,
    message: 'Vous devez indiquer une adresse email.'
  },
]);

const changeFv = new FormValidator([
  {
    field: 'password',
    method: 'isEmpty',
    validWhen: false,
    message: 'Mot de passe ne peut pas être vide'
  }
]);

@connect()
class ChangePasswordForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      password: ''
    }
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const {password} = this.state;
    const {match: {params: {code}}, location} = this.props;

    const validation = changeFv.validate({
      password
    });

    if (!validation.isValid){
      this.setState({validation});
      return;
    }

    this.setState({error: false, loading: true});
    try {
      await api.users.setNewPassword({
        password: password,
        token: code
      });
    } catch (e) {
      this.setState({loading: false, error: true});
      return;
    }

    this.props.history.replace('/login');
  };

  render(){
    const {loading, error, password} = this.state;

    return (
      <form className="auth-form mt-2" onSubmit={this.handleSubmit}>
        <img className="logo" src="/img/logo-ocm.png" alt="" height="72"/>
        <div className="form-group">
          Renseigner votre nouveau mot de passe.
        </div>
        <div className="form-group">
          <div className="form-label-group">
            <input id="inputUser"
                   onChange={this.handleChange}
                   value={password}
                   name="password"
                   type="password"
                   className="form-control"
                   placeholder={"Mot de passe"}
                   required
                   autoFocus=""/>
            <label htmlFor="inputUser">
              {"Mot de passe"}
            </label>
          </div>
        </div>
        {error &&
        <div className="form-group">
          <FormText color="red">
            {"Vous ne pouvez pas réinitialiser votre mot de passe via ce lien. Veuillez demander une réinitialisation de mot de passe à nouveau."}
          </FormText>
        </div>}
        <div className="form-group">
          <LoadingButton className="btn btn-lg btn-primary btn-block"
                         loading={loading}
                         disabled={loading}
                         color="primary"
                         type="submit">
            {"Valider"}
          </LoadingButton>
        </div>
        <div className="text-center pt-3">
          <NavLink className="link" to={'/login'}>
            Se connecter
          </NavLink>
        </div>
      </form>
    )
  }
}

@connect()
class ResetPasswordForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      email: '',
      error: false,
      success: false,
      validation: resetFv.valid()
    }
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const {email} = this.state;

    const validation = resetFv.validate({
      email
    });

    if (!validation.isValid){
      this.setState({
        validation,
        error: false,
        success: false
      });
      return;
    }

    this.setState({error: false, success: false, loading: true});
    try {
      await api.users.resetPassword({
        email
      });
    } catch (e) {
      console.error(e);
      this.setState({error:true, success: false, loading: false});
      return;
    }
    this.setState({loading: false, success: true, error: false});
  };

  render(){
    const {loading, email, error, success, validation} = this.state;

    return (
      <form className="auth-form mt-2" onSubmit={this.handleSubmit}>
        <img className="logo" src="/img/logo-ocm.png" alt="" height="72"/>
        <div className="form-group">
          Vous allez recevoir un lien dans votre boîte mail qui vous permettra de réinitialiser votre mot de passe.
        </div>
        <div className="form-group">
          <div className="form-label-group">
            <Input
              id="inputUser"
              invalid={validation.email.isInvalid}
              onChange={this.handleChange}
              value={email}
              name="email"
              required
              type="email"
              className="form-control"
              placeholder={"Email"}
              autoFocus=""/>
            <label htmlFor="inputUser">
              {"Email"}
            </label>
          </div>
          {validation.email.isInvalid &&
          <FormText color='danger'>
            {validation.email.message}
          </FormText>}
        </div>
        {error &&
        <div className="form-group">
          <FormText color="red">
            {"L'utilisateur avec cet email n'existe pas."}
          </FormText>
        </div>}
        {success &&
        <div className="form-group">
          <FormText color="success">
            {"Vous avez reçu un email avec un lien de confirmation."}
          </FormText>
        </div>}
        <div className="form-group">
          <LoadingButton className="btn btn-lg btn-primary btn-block green-button"
                         loading={loading}
                         disabled={loading}
                         color="primary"
                         type="submit">
            {"Valider"}
          </LoadingButton>
        </div>
        <div className="text-center pt-3">
          <NavLink className="link" to={'/login'}>
            Se connecter
          </NavLink>
        </div>
      </form>
    )
  }
}

@connect()
class LoginForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      error: false,
      loading: false,
      validation: loginFv.valid()
    }
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const {email, password} = this.state;
    const {state = {}} = this.props.location;
    const {prevLocation} = state;

    const validation = loginFv.validate({
      email,
      password
    });

    if (!validation.isValid){
      this.setState({validation});
      return;
    }

    this.setState({loading: true, error: false});
    try {
      await api.users.login({
        email,
        password
      });
    } catch (e) {
      this.setState({loading: false, error: true});
      return;
    }

    const user = await this.props.dispatch(fetchSessionUser());

    this.props.history.replace(!!prevLocation && user.modules.find(m => getRoute(m) === prevLocation) ? prevLocation : '/');
  };

  render(){
    const {loading, error, email, password, validation} = this.state;

    return (
      <form className="auth-form" onSubmit={this.handleSubmit}>
        <img className="logo" src="/img/logo-ocm.png" alt="" height="72"/>
        <div className="form-group">
          <div className="form-label-group">
            <input id="inputUser"
                   onChange={this.handleChange}
                   value={email}
                   name="email"
                   type="email"
                   className="form-control"
                   placeholder={t('common.email')}
                   required
                   autoFocus=""/>
            <label htmlFor="inputUser">{t('common.email')}</label>
          </div>
          {validation.email.isInvalid &&
          <FormText color='danger'>
            {validation.email.message}
          </FormText>}
        </div>
        <div className="form-group">
          <div className="form-label-group">
            <input type="password"
                   id="inputPassword"
                   onChange={this.handleChange}
                   value={password}
                   className="form-control"
                   placeholder={t('common.password')}
                   name="password"
                   required/>
            <label htmlFor="inputPassword">{t('common.password')}</label>
          </div>
          {validation.password.isInvalid &&
          <FormText color='danger'>
            {validation.password.message}
          </FormText>}
        </div>
        {error &&
        <div className="form-group">
          <FormText color="red">
            {t('common.email_or_pw_not_valid')}
          </FormText>
        </div>}
        <div className="form-group">
          <LoadingButton className="btn btn-lg btn-primary btn-block green-button"
                         loading={loading}
                         disabled={loading}
                         color="primary"
                         type="submit">
            {t('common.sign_in')}
          </LoadingButton>
        </div>
        <div className="text-center pt-3">
          <NavLink className="link" to={'/login/reset'}>
            Mot de passe oublié ?
          </NavLink>
        </div>
      </form>
    )
  }
}

@connect(({session: {userId}}) => ({
  userId
}))
class LoginView extends React.PureComponent {
  render(){
    const {userId} = this.props;

    if (!!userId)
      return <Redirect to={'/'}/>;
    return (
      <main className="auth">
        {/*<header id="auth-header" className="auth-header"*/}
                {/*style={{*/}
                  {/*backgroundColor: '#98b7e1',*/}
                  {/*backgroundImage: "url(/img/loginViewIllustation.png)"*/}
                {/*}}>*/}
          {/*<h1 style={{marginBottom: 30}}>*/}
            {/*<span className="sr-only">Sign In</span>*/}
          {/*</h1>*/}
          {/*/!*<p> Don't have a account? <a href="auth-signup.html">Create One</a>*!/*/}
          {/*/!*</p>*!/*/}
          {/*<canvas className="particles-js-canvas-el" width="1903" height="261"*/}
                  {/*style={{*/}
                    {/*width: '100%',*/}
                    {/*height: '100%'*/}
                  {/*}}*/}
          {/*/>*/}
        {/*</header>*/}
        {/*<img src="/img/logo-ocm.png" alt="" height="72" style={{marginBottom: 30}}/>*/}
        <Switch>
          <Route exact path={'/login'} component={LoginForm}/>
          <Route path={'/login/reset'} component={ResetPasswordForm}/>
          <Route path={'/login/password/:code'} component={ChangePasswordForm}/>
        </Switch>
        <footer className="auth-footer">
          {t('common.footer')} ©
        </footer>
      </main>
    )
  }
}

export {LoginView};
export default LoginView;
