import React from "react";
import {connect} from "react-redux";
import {
  Form,
  FormGroup,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback
} from "reactstrap";
import {FormValidator} from "../../tools/formValidator/FormValidator";
import {setSetupNewClientObject} from "../../actions/modals";
import {ImportFileIconPlaceholder} from "../common/ImagePlaceholders";
import {ResponsablesTable} from "../common/ResponsablesTable";
import createSelector from "../../tools/createSelector";
import {allClientPrestataires, getPrestataireById} from "../selectors/prestataires";
import {fetchPrestataireUsers} from "../../actions/orm/Prestataire";
import {mainResponsableSelectorByZoneInObject, responsableSelectorByZoneInObject} from "../../ormSelectors/responsable";
import {tFunction} from "../tools/Translation";

function findClientRoot(client) {
  if (client.parent_id === null)
    return client.id;
  else
    return findClientRoot(client.parentNode);
}

const PrestataireUsersSelector = createSelector(
  (orm, {current}) => {
    const client = orm.Client.withId(findClientRoot(current));

    if (!!client) {
      const clientPrestataires = client.prestataires.toModelArray();
      const responsables = {};

      clientPrestataires.forEach(p => {
        responsables[p.id] = _.orderBy(p.users.toRefArray().map(u => {
          return {
            id: u.id,
            text: `${u.firstname} ${u.lastname}`
          }
        }), ['text'], 'asc');
      });
      return responsables;
    }
    return {};
  }
);

const AllPrestatairesUseInZones = ({current}) => {
    let prestataires = [];

    function findSite(client) {
      client.children.map(item => {
        if (item.type === 'client')
          findSite(item);
        else
          client.children.map(site => {
            site.children.map(zone => {
              if (!!zone.prestataires && !!zone.prestataires.length
                && !prestataires.find(prestataire => {return zone.prestataires[0] === prestataire.id;}))
                prestataires.push(getPrestataireById({id: zone.prestataires[0]}));
            });
          });
      });
    }

    findSite(current);
    return prestataires;
};

const localFv = new FormValidator([
  {
    field: 'label',
    method: 'isEmpty',
    validWhen: false,
    message: tFunction('modals.label_specified')
  }
]);

@connect(
  ({modals: {setupNewClientObject : {resolve, reject, current}}}) => ({
    resolve,
    reject,
    current,
    clientRootId: !!current ? findClientRoot(current) : null,
    responsables: !!current ? PrestataireUsersSelector({current}) : {},
    prestataires: !!current ? AllPrestatairesUseInZones({current}) : [],
    responsablesSelected: !!current && !!current.id ? responsableSelectorByZoneInObject({zone_type: 'client', zone_id: current.id}) : {},
    mainResponsablesSelected: !!current && !!current.id ? mainResponsableSelectorByZoneInObject({zone_type: 'client', zone_id: current.id}) : {}
  })
)
class SetupNewClientObject extends React.PureComponent {
  constructor(props){
    super(props);
    const {current, responsablesSelected, mainResponsablesSelected} = this.props;

    if (!!current)
      this.state = {
        closing: false,
        label: current.label,
        adresse: current.adresse,
        code_postal: current.code_postal,
        ville: current.ville,
        pays: current.pays,
        phone: current.phone,
        path: current.path,
        validation: localFv.valid(),
        selectedResponsables: !!current.updated ? current.responsables : responsablesSelected,
        mainResponsables: !!current.updated ? current.main_responsables : mainResponsablesSelected
      };
    else
      this.state = {
        closing: false,
        label: '',
        adresse: '',
        code_postal: '',
        ville: '',
        pays: '',
        phone: '',
        path: null,
        label_i2c: '',
        label_zone_exploitation: '',
        validation: localFv.valid(),
        selectedResponsables: {},
        mainResponsables: {}
      };
  }

  fileInputRef = React.createRef();
  inputLabel = React.createRef();

  loadData = async () => {
    await Promise.all(allClientPrestataires({clientId: this.props.clientRootId}).map(item => {
      this.props.dispatch(fetchPrestataireUsers({prestataire_id: item.id}));
    }));
  };

  async componentDidMount() {
    if (!!this.props.current && !!this.props.current.id)
      await this.loadData();
    this.inputLabel.focus();
  }

  onChange = (e) => {
    this.setState({[e.target.name] : e.target.value});
  };

  onMainResponsableChange = (e, id) => {
    if (!!e.target.value || e.target.value === 0) {
      let mainResponsables = {...this.state.mainResponsables};

      mainResponsables[id] = e.target.value;

      this.setState({mainResponsables});
    }
  };

  onResponsableChange = (e, id) => {
    if (!!e.target.value || e.target.value === []) {
      let selectedResponsables = {...this.state.selectedResponsables};
      let mainResponsables = {...this.state.mainResponsables};

      selectedResponsables[id] = e.target.value;
      mainResponsables[id] = !e.target.value.length ? null : e.target.value.includes(mainResponsables[id]) ? mainResponsables[id] : e.target.value[0];

      this.setState({selectedResponsables, mainResponsables});
    }
  };

  objectMainResponsables = () => {
    const mainResponsables = {};

    Object.keys(this.state.mainResponsables).map(key => {
      if (this.state.mainResponsables[key] !== null)
        mainResponsables[key] = this.state.mainResponsables[key]
    });
    return mainResponsables;
  };

  objectResponsables = () => {
    const selectedResponsables = {};

    Object.keys(this.state.selectedResponsables).map(key => {
      if (this.state.selectedResponsables[key] !== [])
        selectedResponsables[key] = this.state.selectedResponsables[key]
    });
    return selectedResponsables;
  };

  onLogoChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.setState({path: e.target.result});
    };
    reader.readAsDataURL(file);
  };

  resolve = (e) => {
    e.preventDefault();
    const {label, adresse, code_postal, ville, pays, phone, path} = this.state;

    const validations = localFv.validate({label});
    if (!validations.isValid){
      this.setState({validation: validations});
      return;
    }
    this.props.resolve({
      label,
      adresse,
      code_postal,
      ville,
      pays,
      phone,
      path,
      type: 'client',
      responsables: this.objectResponsables(),
      main_responsables: this.objectMainResponsables()
    });
    this.closing();
  };

  reject = () => {
    this.props.reject();
    this.closing();
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setSetupNewClientObject({
      open: false
    }));
  };

  render(){
    const {
      validation,
      closing,
      label,
      adresse,
      code_postal,
      ville,
      pays,
      phone,
      path,
      selectedResponsables,
      mainResponsables
    } = this.state;
    const {current, prestataires, responsables} = this.props;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.reject}>
          {!!current ? t('common.modify') : t('common.create')} {t('modals.a_client')}
        </ModalHeader>
        <Form onSubmit={this.resolve}>
          <ModalBody>
            <FormGroup className="text-center">
              <ImportFileIconPlaceholder
                onClick={() => this.fileInputRef.current.click()}
                src={path}/>
              <input
                hidden
                ref={this.fileInputRef}
                type="file"
                name="path"
                accept="image/*"
                onChange={this.onLogoChange}
                className="custom-file-input"/>
            </FormGroup>
            <FormGroup>
              <Label>
                {t('common.label')}
              </Label>
              <Input
                name="label"
                innerRef={(input) => (this.inputLabel = input)}
                invalid={validation.label.isInvalid}
                placeholder={t('common.label')}
                onChange={this.onChange}
                value={label}/>
              <FormFeedback>
                {validation.label.message}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label>
                {t('common.address')}
              </Label>
              <Input
                onChange={this.onChange}
                value={adresse}
                name="adresse"
                placeholder={t('common.address')}/>
            </FormGroup>
            <FormGroup>
              <Label>
                {t('modals.postal')}
              </Label>
              <Input
                onChange={this.onChange}
                value={code_postal}
                name="code_postal"
                placeholder={t('modals.postal')}/>
            </FormGroup>
            <FormGroup>
              <Label>
                {t('modals.town')}
              </Label>
              <Input
                onChange={this.onChange}
                value={ville}
                name="ville"
                placeholder={t('modals.town')}/>
            </FormGroup>
            <FormGroup>
              <Label>
                {t('modals.country')}
              </Label>
              <Input
                onChange={this.onChange}
                value={pays}
                name="pays"
                placeholder={t('modals.country')}/>
            </FormGroup>
            <FormGroup>
              <Label>
                {t('modals.phone')}
              </Label>
              <Input
                onChange={this.onChange}
                value={phone}
                name="phone"
                placeholder={t('modals.phone')}/>
            </FormGroup>
            {!!prestataires.length &&
            <React.Fragment>
              <div className="log-divider">
                <span className="bg-light"><i className="fa fa-fw fa-clock-o"/> {t('common.responsables')}</span>
              </div>
              <FormGroup>
                <ResponsablesTable
                  prestataires={prestataires}
                  responsablesChoices={responsables}
                  onChange={this.onResponsableChange}
                  onChangeMain={this.onMainResponsableChange}
                  mainResponsables={mainResponsables}
                  selectedResponsables={selectedResponsables}/>
              </FormGroup>
            </React.Fragment>}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" type='button' onClick={this.reject}>{t('common.close')}</Button>
            <Button color="success">{t('common.valid')}</Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export {SetupNewClientObject};
export default SetupNewClientObject;
