import _ from "lodash";
import createSelector from "../../../tools/createSelector";
import {getZoneRootClientModel} from "../../selectors/zoneExploitation";
import {createThemeTreeByClientIdSelector2} from "../../selectors/theme";

const questionnaireTreeSelector = createThemeTreeByClientIdSelector2();

export const analysesSelector = createSelector(
  async (orm, {clients}) => {
    const analyses = orm.Analyse.all().filter(a => clients.includes(a.client_id)).toModelArray();

    return analyses.map(a => ({
      value: a.id,
      label: a.label
    }))
  }
);

export const analyseVaguesSelector = createSelector(
  async (orm, {analyses}) => {
    const selectedAnalyses = orm.Analyse.all().filter(a => analyses.includes(a.id)).toModelArray();

    var vagues = selectedAnalyses.reduce((acc, curr) => {
      return acc.concat(curr.ref.vagues);
    }, []);

    const vaguesSort = vagues.sort((v1,v2) => (v1.vague < v2.vague) ? 1 : ((v2.vague < v1.vague) ? -1 : 0));
    
    return vagues.map(v => ({
      value: v.vague,
      label: v.label
    }));
  }
);

export const taxonomyTreeSelector = createSelector(
  async (orm, {clients}) => {
    let rootTaxonomies = [];
    if (!!clients && !!clients.length){
      rootTaxonomies = orm.Taxonomy.all().filter(t => !t.parent_id && clients.includes(t.client_id)).toModelArray();
    } else {
      rootTaxonomies = orm.Taxonomy.all().filter(t => !t.parent_id).toModelArray();
    }

    function setupTree(nodes){
      return nodes.map(node => {
        return {
          ...node.ref,
          toggled: true,
          type: 'taxonomy',
          children: setupTree(node.children.toModelArray())
        }
      });
    }

    return setupTree(rootTaxonomies);
  }
);

export const clientSelector = createSelector(
  async (orm) => {
    const clients = orm.Client.all().filter(c => !c.parent_id);

    return clients.toRefArray().map(c => ({
      label: c.label,
      value: c.id
    }))
  }
);

export const prestataireSelector = createSelector(
  async (orm, {clients}) => {
    let prestataires = orm.Prestataire.all().filter(p => !p.parent_id && p.scope).toModelArray();

    if (!!clients && !!clients.length)
      prestataires = prestataires.filter(p => {
        const pClients = p.clients.toRefArray();
        for (let i = 0; i < pClients.length; i++) {
          if (clients.includes(pClients[i].id))
            return true;
        }
        return false;
      });

    return prestataires.map(p => {
      return {
        value: p.id,
        label: p.label
      }
    })
  }
);

const getRouteTaxonomyId = (orm, id) => {
  let t = orm.Taxonomy.withId(id);

  while (!!t.parent_id)
    t = t.parent_id;

  return t.id;
};

export const zonesExploitationSelector = createSelector(
  async (orm, {prestataires, clients, taxonomies,analyses}) => {
    let zoneExploitations = orm.ZoneExploitation.all().toModelArray();

    if (!!clients && !!clients.length){
      zoneExploitations = zoneExploitations.filter(z => {
        const clientModel = getZoneRootClientModel(z);

        return clients.includes(clientModel.id);
      })
    }

    if (!!prestataires && !!prestataires.length){
      zoneExploitations = zoneExploitations.filter(z => {
        const zPrestataires = z.prestataires.toRefArray();
        const prestataire_id = !!zPrestataires.length ? zPrestataires[0].id : null;

        return prestataires.includes(prestataire_id);
      });
    }

    if (!!taxonomies && !!taxonomies.length){
      let roots = {};

      taxonomies.forEach(tId => {
        let rootId = getRouteTaxonomyId(orm, tId);

        if (!roots[rootId])
          roots[rootId] = [];
        roots[rootId].push(tId)
      });

      const rootsList = Object.values(roots);

      zoneExploitations = zoneExploitations.filter(z => {
        const taxs = z.taxonomies.toRefArray().map(i => i.id);

        for (let i = 0; i < rootsList.length; i++) {
          let valid = false;
          for (let j = 0; j < rootsList[i].length; j++) {
            if (!!taxs.includes(rootsList[i][j])){
              valid = true;
              break;
            }
          }
          if (!valid)
            return false;
        }

        return true;
      });
    }

    if (!!analyses && !!analyses.length){

      let selectedZones = [];
      const selectedAudits = orm.Analyse.withId(analyses).sources.filter(s => s.model === 'Audit').map(s => Number(s.id));

      if(!!selectedAudits && !!selectedAudits.length)
      {
        selectedAudits.map(i => {
          if (orm.Audit.withId(i)) {
            orm.Audit.withId(i).zone_exploitations.toRefArray().map(i => selectedZones.push(i.id))
          }
        });
        zoneExploitations = zoneExploitations.filter(z => selectedZones.includes(z.id)); 
      }
    }
   
    return _.orderBy(zoneExploitations.map(z => {
      return {
        value: z.id,
        label: z.label
      }
    }), ['label'], 'asc');
  }
);

export const clientQuestionnaireTreeSelector = async (filters) => {
  const clientId = filters.clients[0];

  const questionnaireTree = questionnaireTreeSelector({
    clientId: clientId,
    sortByRank: true,
    setupLevels: true
  }, true);

  return questionnaireTree;
};

export const organisationTreeSelector = createSelector(
  async (orm, {clients, prestataires, taxonomies, analyses}) => {
    const clientId = clients[0];
    const client = orm.Client.withId(clientId);
    const rootClientRef = client.ref;

    let selectedAudits = [];
    orm.Analyse.all().filter(a => analyses.includes(a.id)).toModelArray().map(u => 
        u.sources.filter(s => s.model === 'Audit').map(s => selectedAudits.push(Number(s.id)))
    );
    const selectedZones = [];
    orm.AuditZone_exploitations.all().filter(a => selectedAudits.includes(a.fromAuditId)).toModelArray().map(u => 
      selectedZones.push(Number(u.toZoneExploitationId.id))
    );

    function setupTree(itemList, type){
      return itemList.map(item => {
        const ret = {...item.ref};
        ret.type = type;
        ret.rootClient = rootClientRef;

        if (type === 'zone_exploitations')
          ret.prestataires = item.prestataires.toRefArray().map(i => i.id);
        if (!!item.sites){
          const sites = item.sites.toModelArray();
          if (!!sites.length)
            ret.children = setupTree(sites, 'site');
        }
        if (!!item.ChildClients){
          const clients = item.ChildClients.toModelArray();
          if (!!clients.length)
            ret.children = setupTree(clients, 'client');
        }
        if (!!item.zone_exploitations){
          let zones = item.zone_exploitations.all().filter(a => selectedZones.includes(a.id)).toModelArray()

          if (!!prestataires && !!prestataires.length){
            zones = zones.filter(z => {
              const zPrestataires = z.prestataires.toRefArray();
              const prestataire_id = !!zPrestataires.length ? zPrestataires[0].id : null;

              return prestataires.includes(prestataire_id);
            });
          }

          if (!!taxonomies && !!taxonomies.length){
            zones = zones.filter(z => {
              const taxs = z.taxonomies.toRefArray();

              for (let i = 0; i < taxs.length; i++) {
                if (taxonomies.includes(taxs[i].id))
                  return true;
              }
              return false;
            });
          }
          if (!!zones.length)
            ret.children = setupTree(zones, 'zone_exploitations')
        }

        if (type === 'client' && !!ret.children){
          ret.children = ret.children.filter(c => {
            return !!c.children && !!c.children.length;
          })
        }
        return ret;
      });
    }
    let tree = setupTree([client], 'client');

    tree = tree.filter(c => {
      return !!c.children && !!c.children.length;
    });

    return tree;
  }
);

export const rootThemesSelector = createSelector(
  async (orm, {clients}) => {
    const themes = orm.Theme.all().filter(i => ((!i.parent_id && clients.includes(i.client_id)))).toRefArray();

    return themes.map(t => ({
      label: t.label,
      value: t.id
    }));
  }
);

export const subThemesSelector = createSelector(
  async (orm, {clients}) => {
    const themes = orm.Theme.all().filter(i => ((!!i.parent_id && clients.includes(i.client_id)))).toRefArray();

    return themes.map(t => ({
      label: t.label,
      value: t.id
    }));
  }
);

export const themeQuestionsSelector = createSelector(
  async (orm, {clients}) => {
    const themes = orm.Theme.all().filter(i => (!!i.parent_id && clients.includes(i.client_id))).toModelArray();

    let themeQuestions = themes.reduce((acc, curr) => {
      return acc.concat(curr.themes_questions.toModelArray().map(i => ({
        label: i.question_id.title,
        value: i.id
      })))
    }, []);

    return themeQuestions;
  }
);
