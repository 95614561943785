import React from "react";
import {connect} from "react-redux";
import {
  Form,
  FormGroup,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback
} from "reactstrap";
import {FormValidator} from "../../tools/formValidator/FormValidator";
import {setSetupModifiedSiteObject} from "../../actions/modals";
import {ResponsablesTable} from "../common/ResponsablesTable";
import {allClientPrestataires, getPrestataireById} from "../selectors/prestataires";
import {fetchPrestataireUsers} from "../../actions/orm/Prestataire";
import createSelector from "../../tools/createSelector";
import {mainResponsableSelectorByZoneInObject, responsableSelectorByZoneInObject} from "../../ormSelectors/responsable";
import {tFunction} from "../tools/Translation";

function findClientRoot(client) {
  if (client.parent_id === null)
    return client.id;
  else
    return findClientRoot(client.parentNode);
}

const PrestataireUsersSelector = createSelector(
  (orm, {current}) => {
    const client = orm.Client.withId(findClientRoot(current));

    if (!!client) {
      const clientPrestataires = client.prestataires.toModelArray();
      const responsables = {};

      clientPrestataires.forEach(p => {
        responsables[p.id] = _.orderBy(p.users.toRefArray().map(u => {
          return {
            id: u.id,
            text: `${u.firstname} ${u.lastname}`
          }
        }), ['text'], 'asc');
      });
      return responsables;
    }
    return {};
  }
);

const AllPrestatairesUseInZones = ({children}) => {
  let prestataires = [];

  children.map(zone => {
    if (!!zone.prestataires.length
      && !prestataires.find(prestataire => {
        return zone.prestataires[0] === prestataire.id;
      }))
      prestataires.push(getPrestataireById({id: zone.prestataires[0]}));
  });
  return prestataires;
};

const localFv = new FormValidator([
  {
    field: 'label',
    method: 'isEmpty',
    validWhen: false,
    message: tFunction('modals.label_specified')
  },
  {
    field: 'adresse',
    method: (value, state) => {
      if (!value.length)
        return true;
      else if (state.adresseValidated)
        return true;
      return false;
    },
    validWhen: true,
    message: tFunction('modals.address_required')
  }
]);

@connect(
  ({modals: {setupModifiedSiteObject : {resolve, reject, current}}}) => ({
    resolve,
    reject,
    current,
    clientRootId: findClientRoot(current),
    responsables: !!current ? PrestataireUsersSelector({current}) : {},
    prestataires: !!current ? AllPrestatairesUseInZones({children: current.children}) : [],
    responsablesSelected: !!current && !!current.id ? responsableSelectorByZoneInObject({zone_type: 'site', zone_id: current.id}) : {},
    mainResponsablesSelected: !!current && !!current.id ? mainResponsableSelectorByZoneInObject({zone_type: 'site', zone_id: current.id}) : {}
  })
)
class SetupModifiedSiteObject extends React.Component {
  constructor(props) {
    super(props);
    const {current, responsablesSelected, mainResponsablesSelected} = this.props;

    this.state = {
      closing: false,
      label: current.label,
      adresse: current.adresse,
      latitude: current.latitude,
      longitude: current.longitude,
      adresseValidated: !!current.adresse && !!current.adresse.length,
      validation: localFv.valid(),
      selectedResponsables: (typeof current.updated !== 'undefined') ? (!current.updated && !!current.responsable_ids && Object.keys(current.responsable_ids).length > 0) ? current.responsable_ids : (typeof current.updated !== 'undefined' && current.updated) ? current.responsables : responsablesSelected : responsablesSelected,
      mainResponsables: !!current.updated ? current.main_responsables : mainResponsablesSelected
    };
  }
  addressInputRef = null;

  loadData = async () => {
    await Promise.all(allClientPrestataires({clientId: this.props.clientRootId}).map(item => {
      this.props.dispatch(fetchPrestataireUsers({prestataire_id: item.id}));
    }));
  };

  setAddressGoogleListener = () => {
    const options = {
      types: ['address']
    };

    const autocomplete = new google.maps.places.Autocomplete(this.addressInputRef, options);
    autocomplete.setFields(['formatted_address', 'geometry']);
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      const {formatted_address, geometry} = place;

      this.setState({
        adresse: formatted_address,
        adresseValidated: true,
        latitude: geometry.location.lat(),
        longitude: geometry.location.lng()
      });
    });
  };

  inputLabel = React.createRef();

  async componentDidMount() {
    if (!!this.props.current && !!this.props.current.client_id)
      await this.loadData();
    this.setAddressGoogleListener();
    this.inputLabel.focus();
  }

  onChange = (e) => {
    this.setState({[e.target.name] : e.target.value});
  };

  handleAddressChange = (e) => {
    this.setState({
      [e.target.name] : e.target.value,
      adresseValidated: false
    });
  };

  onMainResponsableChange = (e, id) => {
    if (!!e.target.value || e.target.value === 0) {
      let mainResponsables = {...this.state.mainResponsables};

      mainResponsables[id] = e.target.value;

      this.setState({mainResponsables});
    }
  };

  onResponsableChange = (e, id) => {
    if (!!e.target.value || e.target.value === []) {
      let selectedResponsables = {...this.state.selectedResponsables};
      let mainResponsables = {...this.state.mainResponsables};

      selectedResponsables[id] = e.target.value;
      mainResponsables[id] = !e.target.value.length ? null : e.target.value.includes(mainResponsables[id]) ? mainResponsables[id] : e.target.value[0];

      this.setState({selectedResponsables, mainResponsables});
    }
  };

  objectMainResponsables = () => {
    const mainResponsables = {};

    Object.keys(this.state.mainResponsables).map(key => {
      if (this.state.mainResponsables[key] !== null)
        mainResponsables[key] = this.state.mainResponsables[key]
    });
    return mainResponsables;
  };

  objectResponsables = () => {
    const selectedResponsables = {};

    Object.keys(this.state.selectedResponsables).map(key => {
      if (this.state.selectedResponsables[key] !== [])
        selectedResponsables[key] = this.state.selectedResponsables[key]
    });
    return selectedResponsables;
  };

  resolve = (e) => {
   e.preventDefault();
    const {label, adresse, adresseValidated, latitude, longitude} = this.state;

    const validations = localFv.validate({
      label,
      adresseValidated: true,
      adresse
    });
    if (!validations.isValid) {
      this.setState({validation: validations});
      return;
    }

    this.props.resolve({
      label,
      adresse,
      latitude,
      longitude,
      type: 'site',
      responsables: this.objectResponsables(),
      main_responsables: this.objectMainResponsables()
    });
    this.closing();
  };

  reject = () => {
    this.props.reject();
    this.closing();
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setSetupModifiedSiteObject({
      open: false
    }));
  };

  render(){
    const {
      closing,
      label,
      adresse,
      validation,
      selectedResponsables,
      mainResponsables
    } = this.state;
    const {prestataires, responsables} = this.props;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.reject}>
          {t('modals.modify_site')}
        </ModalHeader>
        <Form onSubmit={this.resolve}>
          <ModalBody>
            <FormGroup>
              <Label>
                {t('common.label')}
              </Label>
              <Input
                name="label"
                innerRef={(input) => (this.inputLabel = input)}
                invalid={validation.label.isInvalid}
                placeholder={t('common.label')}
                onChange={this.onChange}
                value={label}/>
              <FormFeedback>
                {validation.label.message}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label>
                {t('common.address')}
              </Label>
              <Input
                innerRef={ref => this.addressInputRef = ref}
                // invalid={validation.adresse.isInvalid}
                name="adresse"
                value={adresse}
                placeholder={t('common.address')}
                onChange={this.handleAddressChange}/>
              <FormFeedback>
                {validation.adresse.message}
              </FormFeedback>
            </FormGroup>
            {!!prestataires.length &&
            <React.Fragment>
              <div className="log-divider">
                <span className="bg-light"><i className="fa fa-fw fa-clock-o"/> {t('common.responsables')}</span>
              </div>
              <FormGroup>
                <ResponsablesTable
                  prestataires={prestataires}
                  responsablesChoices={responsables}
                  onChange={this.onResponsableChange}
                  onChangeMain={this.onMainResponsableChange}
                  mainResponsables={mainResponsables}
                  selectedResponsables={selectedResponsables}/>
              </FormGroup>
            </React.Fragment>}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" type='button' onClick={this.reject}>{t('common.close')}</Button>
            <Button color="success">{t('common.valid')}</Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export {SetupModifiedSiteObject};
export default SetupModifiedSiteObject;
