import React from 'react';
import {connect} from 'react-redux';
import {fetchNotifications, readNotification} from "../../reducers/ui";
import {Link} from 'react-router-dom';
import * as _Modules from '../../constants/modules';
import {notificationsSelector} from "./selectors";
import {cDate} from "../../tools/date";
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';

const mapStateToProps = state => {
  return {notifications: notificationsSelector(state)};
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchNotifications: () => dispatch(fetchNotifications()),
    readNotification: (notification) => dispatch(readNotification(notification)),
  }
};

class ConnectedNotifications extends React.Component{
  constructor(props){
    super(props);

    this.props.fetchNotifications();

    this.interval = setInterval(() => {
      this.props.fetchNotifications();
    }, 180000);
  }

  render(){
    let nbNewActivities = 0;

    this.props.notifications.forEach(notification => {
      if(!notification.read) nbNewActivities += 1;
    });

    const hasNotified = (nbNewActivities  > 0 ? 'has-notified' : '');

    const restrictedNotifications = this.props.notifications.slice(0, 8);
    const notifications = restrictedNotifications.map(notification => {

      const timeIndicator = cDate.displayDiff(moment.unix(notification.created).toDate(), new Date());
      let route = '';

      if(notification.module_label !== null) route = _Modules.getRoute(notification.module_label) + (notification.object_id !== null ? '/' + notification.object_id : '');

      return(
        <Link key={notification.id} onClick={() => this.props.readNotification(notification)} className={'dropdown-item' + (notification.read ? ' ' : ' unread')} to={route}>
          <div className='user-avatar'>
            <img src={'/upload/' + notification.sender.path}/>
          </div>
          <div className='dropdown-item-body'>
            <p className='text'>{notification.title}</p>
            <span className='date'>{timeIndicator}</span>
          </div>
        </Link>
      );
    });

    const {isMobile} = this.props;

    return(
      <li className={"nav-item dropdown header-nav-dropdown " + hasNotified}>
        <a className="nav-link" tabIndex="1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span className="oi oi-pulse"/>
        </a>

        {!isMobile &&
        <div className="dropdown-arrow"/>}
        <div
          x-placement="bottom-end"
          className="dropdown-menu dropdown-menu-rich dropdown-menu-right">
          <h6 className='dropdown-header stop-propagation'>
                        <span>
                            {t('sidebar.activities')}
                            <span className='badge'>({nbNewActivities})</span>
                        </span>
          </h6>
          <PerfectScrollbar className='dropdown-scroll has-scrollable'>
            {notifications}
          </PerfectScrollbar>
          <Link className='dropdown-footer' to='/activities'>
            {t('common.all_activities')}&emsp;
            <i className='oi oi-arrow-right'/>
          </Link>
        </div>
      </li>
    );
  }
}

const Notifications = connect(mapStateToProps, mapDispatchToProps)(ConnectedNotifications);

export default Notifications;