import createSelector from "../../tools/createSelector";

export const createProgressZonesExploitationSelector = () => {
  return createSelector(
    (orm) => {
      const progressZones = orm.ProgressZoneExploitation.all().toRefArray();

      return !!progressZones ? progressZones : null;
    }
  )
};

export const createProgressZonesExploitationByProgressPlanIdSelector = () => {
  return createSelector(
    (orm, {progressPlanId}) => {
      const progressZones = orm.ProgressZoneExploitation.all().filter(pz => pz.progre_id === progressPlanId).toRefArray();

      return !!progressZones ? progressZones : null;
    }
  )
};