import React from "react";
import {connect} from "react-redux";
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {setUploadFile} from "../../actions/modals";
import {api} from "../../tools/api";
import {FileInput} from "@neqo34/ui";
import {LoadingButton} from "../common/LoadingButton";

@connect(({modals: {uploadFile: {defaultName, defaultPath, defaultType, resolve, reject}}}) => ({
  defaultName,
  defaultPath,
  defaultType,
  resolve,
  reject
}))
class UploadFile extends React.PureComponent {
  constructor(props) {
    super(props);

    const {defaultName, defaultType} = this.props;

    this.state = {
      closing: false,
      fileData: null,
      trueFilename: !!defaultName ? defaultName : '',
      filename: !!defaultName ? defaultName : '',
      loading: false,
      type: !!defaultType ? defaultType : 1,
      extension: null
    }
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const {defaultPath} = this.props;
    const {filename, fileData, type} = this.state;

    let path = defaultPath;

    if (!!fileData)
      path = (await api.file.upload({data: fileData})).path;

    this.props.resolve({
      filename,
      type,
      path
    });
    this.closing();
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setUploadFile({
      open: false
    }));
  };

  reject = () => {
    this.props.reject();
    this.closing();
  };

  uploadFile = (e, item) => {
    const file = e.target.files[0];

    const extension = file.name.split(".");
    let defaultName = "";
    extension.map((n, i) => {
      if (i !== extension.length - 1)
        defaultName += i === 0 ? n : `.${n}`;
    });

    const reader = new FileReader();
    reader.onload = async (e) => {
      this.setState({
        fileData: `extension:${extension[extension.length - 1]};${e.target.result}`,
        filename: !!this.state.filename ? this.state.filename : defaultName,
        trueFilename: file.name,
        extension: extension[extension.length - 1]
      });
    };
    reader.readAsDataURL(file);
  };

  render() {
    const {closing, fileData, filename, trueFilename, loading} = this.state;
    const {defaultPath} = this.props;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.reject}>
          {t('common.import')} {this.state.type === 1 ? t('modals.a_file') : t('modals.a_photo')}
        </ModalHeader>
        <Form onSubmit={this.handleSubmit}>
          <ModalBody>
            <FormGroup>
              {!!trueFilename &&
              <div class="text-success">{trueFilename}</div>}
              {this.state.type === 1 &&
              <FileInput
                as={Button}
                size="sm"
                inputProps={{
                  onChange: this.uploadFile,
                  name: 'path',
                }}>
                {t('common.choose_file')}
              </FileInput>}
              {this.state.type === 2 &&
              <FileInput as={Button}
                            inputProps={{
                              name: 'path',
                              accept: "image/*",
                              onChange: this.uploadFile
                            }}
                            size="sm">
                {t('modals.add_photo')}
              </FileInput>}
            </FormGroup>
            <FormGroup>
              <Label>
                {t('modals.filename')}
              </Label>
              <Input name="filename"
                     type="text"
                     onChange={this.handleChange}
                     placeholder={t('common.name')}
                     value={filename}/>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              type='button'
              onClick={this.reject}>
              {t('common.close')}
            </Button>
            <LoadingButton
              loading={loading}
              disabled={(!fileData && !defaultPath) || !filename}
              color="success">
              {t('common.apply')}
            </LoadingButton>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export {UploadFile};
export default UploadFile;
