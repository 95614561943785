import createSelector from "../tools/createSelector";
import {clientsLeavesByClients} from "./client";


/**
 * clients must not be leaves
 */
export const sitesByClients = createSelector(
    (orm, clients) => {
        if(clients !== null){
            const clientLeaves = clientsLeavesByClients(clients);
            return orm.Site.all().filter(site  => getIndexById(site.client_id, clientLeaves) !== null);
        }
        return null;
    }
);