import * as _Redux from "../redux";
import axios from 'axios';
import {HYDRATE_ENTITIES, UPDATE_ENTITIES} from "../actions/orm";
import {_Notification} from "../constants/orm";
//import {fetchOnSwitchClient} from "./session";

const uiStateDEFAULT =  {isPending: false, alertIdCount: 0, alerts: [], hasSidebar: false, showSidebar: false, showSideMenu: false};

export const uiReducer = _Redux.createReducer(uiStateDEFAULT, {
  START_PENDING: startPending,
  STOP_PENDING: stopPending,
  ADD_ALERT: addAlert,
  REMOVE_ALERT: removeAlert,
  TOGGLE_SIDEBAR: toggleSidebar,
  TOGGLE_SIDEMENU: toggleSideMenu,
  HAS_SIDEBAR: hasSidebar,
  CLEAR_UI: clear
});

function startPending(uiState){
  if(!uiState.isPending) return _Redux.updateObject(uiState, {isPending: true});
  return uiState;
}

function stopPending(uiState){
  if(uiState.isPending) return _Redux.updateObject(uiState, {isPending: false});
  return uiState;
}

function addAlert(uiState, action){
  let alerts = uiState.alerts.slice();
  const alert = {id: uiState.alertIdCount + 1, ...action.payload};
  alerts.push(alert);

  return _Redux.updateObject(uiState, {alerts: alerts, alertIdCount: alert.id});
}

function removeAlert(uiState, action){
  let alerts = uiState.alerts.slice();
  alerts.splice(getIndexById(action.payload, uiState.alerts), 1);

  return _Redux.updateObject(uiState, {alerts: alerts});
}

function toggleSidebar(uiState, action){
  return _Redux.updateObject(uiState, {showSidebar: action.payload});
}

function hasSidebar(uiState, action){
  return _Redux.updateObject(uiState, {hasSidebar: action.payload});
}

function toggleSideMenu(uiState, action){
  return _Redux.updateObject(uiState, {showSideMenu: action.payload});
}

function clear(){
  return uiStateDEFAULT;
}

export function readNotification(notification){
  return dispatch => {
//    if(notification.client_id !== _Redux.getStore('session').currentClientId) fetchOnSwitchClient(notification.client_id)(dispatch);
    if(notification.user_id !== _Redux.getStore('session').userId){ //only change status if the receiver is read it
      return axios.get('/api/notifications/read/' + notification.id).then(function(){
        let updatedNotification = Object.assign({}, notification);
        updatedNotification.read = true;

        dispatch({type: UPDATE_ENTITIES, payload: [{type: _Notification, entities: [updatedNotification]}]});
      }).catch(function(error){
        console.log(error);
      });
    }
  };
}

export function fetchNotifications(){
  return dispatch => {
    return axios.get('/api/notifications').then(function(notifications){
      dispatch({type: HYDRATE_ENTITIES, payload: [{type: _Notification, entities: notifications.data}]});
    }).catch(function(error){
      console.log(error);
    });
  };
}

