import React from "react";
import {connect} from "react-redux";
import {setTaxonomyItemsFilterSelect} from "../../actions/modals";
import {
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import {CheckableTree} from "../tools/CheckableTree";
import {createTaxonomyTreeSelector} from "../selectors/taxonomy";

const taxonomyTreeSelector = createTaxonomyTreeSelector();

@connect(({modals: {taxonomyItemsFilterSelect: {taxonomyId, defaultSelected, resolve, reject}}}) => ({
  taxonomyId,
  defaultSelected,
  resolve,
  reject
}))
class TaxonomyItemsFilterSelect extends React.PureComponent {
  constructor(props) {
    super(props);

    const {taxonomyId, defaultSelected} = this.props;

    this.state = {
      closing: false,
      search: '',
      taxonomyTree: taxonomyTreeSelector({taxId: taxonomyId})
    };

    if (!!defaultSelected && !!defaultSelected.length){
      const horizontal = flatTree(this.state.taxonomyTree);

      defaultSelected.forEach(id => {
        const target = horizontal.find(i => i.id === id);
        if (!!target)
          target.checked = true;
      });
    }
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  resolve = () => {
    const {taxonomyTree} = this.state;
    const horizontal = flatTree(taxonomyTree);
    const selected = horizontal
      .filter(i => !!i.checked);

    this.props.resolve(selected);
    this.closing();
  };

  reject = () => {
    this.props.reject();
    this.closing();
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setTaxonomyItemsFilterSelect({
      open: false
    }));
  };

  render(){
    const {closing, taxonomyTree} = this.state;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.reject}>
          {t('modals.choose_taxonomy_elements')} "{taxonomyTree[0].label}"
        </ModalHeader>
        <ModalBody>
          <CheckableTree data={taxonomyTree}/>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={this.reject}>
            {t('common.cancel')}
          </Button>
          <Button
            onClick={this.resolve}
            color="success">
            {t('common.confirm')}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export {TaxonomyItemsFilterSelect};
export default TaxonomyItemsFilterSelect;
