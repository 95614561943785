import React from "react";
import $ from "jquery";

export const RowSelectionItem = ({ mode, checked, disabled }) => {
  return (
    <div className="cursor-pointer custom-control custom-control-nolabel custom-checkbox">
      <input readOnly
             type="checkbox"
             className="custom-control-input"
             checked={checked}
             disabled={disabled}/>
      <label className="cursor-pointer custom-control-label"/>
    </div>
  )
};

export const RowSelectionHeaderItem = ({mode, checked, indeterminate, options}) => {
  return (
    <div className="thead-dd dropdown" onClick={e => e.stopPropagation()}>
      <span className="custom-control custom-control-nolabel custom-checkbox">
        <input type="checkbox"
               readOnly
               className="cursor-pointer custom-control-input"
               onClick={e => {$(e.target).closest('th').click()}}
               indeterminate={!!indeterminate ? 'true' : 'false'}
               checked={checked}/>
          <label
            className="custom-control-label cursor-pointer"
            onClick={e => {$(e.target).closest('th').click()}}/>
      </span>
      <div className="thead-btn" data-toggle="dropdown" aria-haspopup="true"
           aria-expanded="false">
        <span className="caret"/>
      </div>
      {!!options && !!options.length &&
      <div className="dropdown-menu">
        {options.map((o, idx) => {
          return (
            <React.Fragment key={idx}>
              {o.map((o, idx) => {
                const {label, onClick, disabled} = o;

                return (
                  <a tabIndex={idx}
                     key={idx}
                     onClick={onClick}
                     className={`dropdown-item ${!!disabled ? 'disabled' : ''}`}>
                    {label}
                  </a>
                )
              })}
              {idx < (options.length - 1) &&
              <div className="dropdown-divider"/>}
            </React.Fragment>
          )
        })}
      </div>}
    </div>
  )
};
