import {attr, fk, Model} from 'redux-orm';
import {_Client, _Question} from "../constants/orm";

export class Question extends Model{
  static parse(data){
    delete data.responses;
    delete data.theme_id;
    delete data.theme_question_id;
    delete data.questionnaire_id;

    return data;
  }

  static get fields(){
    return {
      id: attr(),
      created: attr(),
      modified: attr(),
      client_id: fk(_Client, 'clientQuestions'),
      generic: fk(_Question, 'linkedQuestions'),
      label: attr(),
      faq: attr(),
      title: attr(),
      type: attr(),
      ranging: attr(),
      answer: attr(),
      photo: attr(),
      file: attr(),
      sample_comment: attr(),
      sample_files: attr(),
      sample_photos: attr(),
      sample_value: attr()
    }
  }
}

Question.modelName = _Question;

export default Question;