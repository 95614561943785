import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {defaultPaginationFactory} from "../../../../constants/ui";
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import {I2CText, EvolText} from "../TextRenderers";
import {ProgressBarMulti} from "../../../common/ProgressBarMulti";
import {Red,Orange, Green} from "../utils";
import {_progressPlanState} from "../../../../constants/types";
import {DebounceInput} from "@neqo34/ui";
import {Link} from "react-router-dom";
import {getRoute, ProPla_Mon} from "../../../../constants/modules";
import qs from "query-string";
const browseUrl = getRoute(ProPla_Mon);

class ProgressPlanAdvancementTable extends React.PureComponent {
  render(){
    const {client, data, passageLow, passageHigh, analyses, vagues} = this.props;
    var columns = [
      {
        dataField: 'label',
        text: t('common.label'),
        sort: true,
        formatter: (col, row) => {
          return (
            <Link
              style={{color: 'black'}}
              target="_blank"
              to={{
                pathname: browseUrl,
                search: qs.stringify({
                  zones_exploitation: [row.id],
                  analyses: analyses,
                  vagues: vagues
                }, {arrayFormat: 'bracket'})
              }}>
              {row.label}
            </Link>
          )
        }
      },
      {
        dataField: "progressPlanStateDistribution",
        text: t('reporting.actions_number'),
        align: 'center',
        headerAlign: 'center',
        formatter: (cell, row) => {
          const {progressPlanStateDistribution} = row;
          const notStartedNb = progressPlanStateDistribution[_progressPlanState.new];
          const currentNb = progressPlanStateDistribution[_progressPlanState.started];
          const finishedNb = progressPlanStateDistribution[_progressPlanState.finished];

          return <ProgressBarMulti values={[
            {
              color: Red,
              value: notStartedNb,
              content: notStartedNb,
              tooltip: 'non débutées'
            },
            {
              color: Orange,
              value: currentNb,
              content: currentNb,
              tooltip: t('common.in_progress')
            },
            {
              color: Green,
              value: finishedNb,
              content: finishedNb,
              tooltip: 'finalisées'
            }
          ]} max={notStartedNb + currentNb + finishedNb}
          />
        }
      },
      {
        dataField: 'progression',
        text: `${t('common.progress')} PP`,
        sort: true,
        align: 'center',
        headerAlign: 'center',
        formatter: (cell, row) => {
          return <strong><I2CText value={row.progression} passageHigh={passageHigh} passageLow={passageLow}/></strong>;
        }
      },
      
    ];
    if(!!client.i2cE_enable){
      columns.push(
        {
          dataField: 'value',
          text: `${Labels.i2c} actuel`,
          sort: true,
          align: 'center',
          headerAlign: 'center',
          formatter: (cell, row) => {
            return <strong><I2CText value={row.value} passageHigh={passageHigh} passageLow={passageLow}/></strong>;
          }
        },
        {
          dataField: 'valueEstimated',
          text: t('common.i2c_estimated', {i2c: Labels.i2c}),
          sort: true,
          align: 'center',
          headerAlign: 'center',
          hidden: !(!!client.i2cE_enable),
          formatter: (cell, row) => {
            return <strong>
              <I2CText value={row.valueEstimated} passageHigh={passageHigh} passageLow={passageLow}/>
            </strong>;
          }
        },
        {
          dataField: 'evolEstimated',
          text: 'Evolution estimée',
          sort: true,
          align: 'center',
          headerAlign: 'center',
          formatter: (cell, row) => {
            return <strong>
              <EvolText value={row.evolEstimated} nullSign={'0'}/>
            </strong>
          }
        })
    }
    const defaultSort = [(this.props.defaultSort) ? this.props.defaultSort : {dataField : 'label',order : 'asc' }];
    return (
      <div>
        <ToolkitProvider
          search
          keyField="id"
          columns={columns}
          bootstrap4={true}
          data={data}>
          {props => (
            <div className="d-flex flex-column">
              <DebounceInput
                className="mb-2"
                onChange={(e) => props.searchProps.onSearch(e.target.value)}/>
              <BootstrapTable
                defaultSorted = {defaultSort}
                bordered={false}
                wrapperClasses="flex-grow-1 overflow-table"
                pagination={defaultPaginationFactory()}
                {...props.baseProps}/>
            </div>
          )}
        </ToolkitProvider>
      </div>
    )
  }
}

export {ProgressPlanAdvancementTable};
export default ProgressPlanAdvancementTable;
