import api from "../../tools/api";
import {hydrateEntities} from "../orm";
import {_RapportsProcessing} from "../../constants/orm";

export const fetchForePaasProcessing = () => {
  return async dispatch => {
    const rapportsProcessing = await api.rapportsProcessing.getProcessing();
    dispatch(hydrateEntities({
      payload: [
        {type: _RapportsProcessing, entities: rapportsProcessing}
      ]
    }));

    return rapportsProcessing;
  }
};

export const addForePaasProcessing = ({id, analyse_id, vague, zone_exploitation_id}) => {
  return async dispatch => {
    const rapportsProcessing = await api.rapportsProcessing.forceProcessing({
      id,
      analyse_id,
      vague,
      zone_exploitation_id
    });

    await api.forepaas.actions.getToken().then(response => {
      const token = response.token
      if (token) {
        api.forepaas.actions.run({token});
      }
    });

    dispatch(hydrateEntities({
      payload: [
        {type: _RapportsProcessing, entities: [rapportsProcessing]}
      ]
    }));

    return rapportsProcessing;

  }
};