import React from "react";
import {connect} from "react-redux";
import {Button, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {setChooseClientAudit} from "../../actions/modals";
import {createAuditsByClientIdSelector} from "../selectors/audit";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {DebounceSearchInput} from "../common/DebounceSearchInput";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import memoize from "memoize-one";
const auditsSelector = createAuditsByClientIdSelector();

@connect(({modals: {chooseClientAudit: {clientId, ignoredAuditIds, resolve, reject}}}) => ({
  ignoredAuditIds,
  audits: auditsSelector({clientId: clientId}),
  resolve,
  reject
}))
class ChooseClientAudit extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      closing: false,
      selected: []
    }
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const audit = this.props.audits.find(a => a.id === this.state.selected[0]);

    this.props.resolve(audit);
    this.closing();
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setChooseClientAudit({
      open: false
    }));
  };

  reject = () => {
    this.props.reject();
    this.closing();
  };

  handleRowSelection = (row) => {
    this.setState({selected: [row.id]});
  };

  columns = [
    {
      dataField: 'label',
      text: t('common.label'),
      sort: true
    },
    {
      dataField: 'type',
      text: t('common.cible'),
      sort: true,
      formatter: (cell, row) => {
        switch (row.type) {
          case 'clients':
            return t('common.client');
          case 'firms':
            return t('common.externe');
          case 'prestataires':
            return t('common.prestataire');
          default:
            return t('audit_edit_view.not_defined');
        }
      }
    }
  ];

  filterAudits = memoize((audits, ignoredAuditIds) => {
    return audits.filter(a => ignoredAuditIds.indexOf(a.id) === -1);
  });

  render(){
    const {closing, selected} = this.state;
    const {audits, ignoredAuditIds} = this.props;

    let filteredAudits = audits;

    if (!!ignoredAuditIds)
      filteredAudits = this.filterAudits(audits, ignoredAuditIds);

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.reject}>
          {t('modals.choose_audit')}
        </ModalHeader>
        <Form onSubmit={this.handleSubmit}>
          <ModalBody>
            <FormGroup>
              <ToolkitProvider
                search
                keyField="id"
                columns={this.columns}
                bootstrap4={true}
                data={filteredAudits}>
                {props => (
                  <div>
                    <DebounceSearchInput
                      className="mb-2"
                      onChange={(e) => props.searchProps.onSearch(e.target.value)}/>
                    <BootstrapTable
                      bordered={false}
                      rowStyle={{cursor: 'pointer'}}
                      selectRow={{
                        selected: selected,
                        mode: 'radio',
                        clickToSelect: true,
                        bgColor: '#e6e6ed',
                        onSelect: this.handleRowSelection
                      }}
                      pagination={paginationFactory()}
                      {...props.baseProps}/>
                  </div>
                )}
              </ToolkitProvider>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              type='button'
              onClick={this.reject}>
              {t('common.close')}
            </Button>
            <Button
              disabled={!selected.length}
              color="success">
              {t('common.valid')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export {ChooseClientAudit};
export default ChooseClientAudit;
