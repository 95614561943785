import React from "react";
import {Redirect, Route} from "react-router";

const ProtectedRoute = ({component: Comp, loggedIn, path, ...rest}) => {
  return (
    <Route
      path={path}
      {...rest}
      render={props => {
        return loggedIn ?
          <Comp {...props}/> :
          <Redirect to={{
            pathname: "/login",
            state: {
              prevLocation: props.location.pathname
            }
          }}/>
      }}
    />
  )
};

export {ProtectedRoute};
export default ProtectedRoute;