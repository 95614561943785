import React from "react";
import {Button, Input, ListGroup, ListGroupItem} from "reactstrap";
import {getT, tFunction} from "../tools/Translation";

const days = {
  0: tFunction('common.monday'),
  1: tFunction('common.tuesday'),
  2: tFunction('common.wednesday'),
  3: tFunction('common.thursday'),
  4: tFunction('common.friday'),
  5: tFunction('common.saturday'),
  6: tFunction('common.sunday')
};

function decodeWeekTableData(data){
  let ret = [[],[],[],[],[],[],[]];

  data.forEach(time => {
    ret[parseInt(time.day)].push({
      start: hoursToDate(time.start),
      finish: hoursToDate(time.finish)
    });
  });

  ret.forEach(day => {
    if (!day.length)
      day.push({
        start: null,
        finish: null
      })
  });

  return ret;
}

function encodeWeekTable(data){
  let ret = [];

  data.forEach((day, dayIdx) => {
    day.forEach(time => {
      if (time.start !== null && time.finish !== null){
        const finish = time.start.getTime() < time.finish.getTime() ? time.finish : time.start;
        const start = time.start.getTime() < time.finish.getTime() ? time.start : time.finish;

        ret.push({
          day: dayIdx,
          start: getHoursString(start),
          finish: getHoursString(finish)
        })
      }
    })
  });

  return ret;
}

function getHoursString(date){
  let minutes = date.getMinutes().toString();
  let hours = date.getHours().toString();

  if (minutes.length === 1)
    minutes = '0' + minutes;

  return `${hours}:${minutes}`;
}

function hoursToDate(date){
  const [hours, minutes] = date.split(':');
  let newDate = new Date();
  newDate.setHours(parseInt(hours));
  newDate.setMinutes(parseInt(minutes));

  return newDate;
}

class WeekTimetableReadable extends React.PureComponent {
  constructor(props) {
    super(props);
    const {value} = this.props;

    this.state = {
      value: decodeWeekTableData(value)
    }
  }

  render(){
    const {value} = this.state;

    return (
      <ListGroup className={'list-group-bordered weekTimetable'}>
        {value.map((v, dayIdx) => {
          const dayName = days[dayIdx];
          return (
            <ListGroupItem key={dayIdx}>
              <div class="dayName">
                {dayName} :
              </div>
              <div>
                {v.map(({start, finish}, idx) => {
                  if (!start || !finish)
                    return (
                      <div className="d-flex timeRange" key={idx}>
                        {t('audit_edit_view.not_defined')}
                      </div>
                    );
                  return (
                    <div class="d-flex timeRange" key={idx}>
                      {getHoursString(start)}
                      <span class="mr-2 ml-2">-</span>
                      {getHoursString(finish)}
                    </div>
                  )
                })}
              </div>
            </ListGroupItem>
          )
        })}
      </ListGroup>
    )
  }
}

export {WeekTimetableReadable};
export default WeekTimetableReadable;