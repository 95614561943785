import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {setProgressPlanModelDelete} from "../../actions/modals";
import {connect} from "react-redux";
import {deleteProgressModel} from "../../actions/orm";
import createSelector from "../../tools/createSelector";
import {LoadingButton} from "../common/LoadingButton";

const progressPlanModelSelector = createSelector(
  (orm, {modelId}) => {
    if (orm.ProgressModel.idExists(modelId))
      return orm.ProgressModel.withId(modelId);
    return null;
  }
);

@connect(
  ({modals: {progressPlanModelDelete: {modelId, open}}}) => ({
    modelId
  })
)
export default class ProgressPlanModelDelete extends React.PureComponent {
  state = {
    loading: false,
    progressModel: progressPlanModelSelector({modelId: this.props.modelId}),
    closing: false
  };
  onSubmit = (e) => {
    e.preventDefault();

    this.setState({loading: true});
    this.props.dispatch(deleteProgressModel({
      id: this.state.progressModel.id
    })).then(resp => {
      this.setState({loading: false});
      this.closing();
    });
  };
  close = () => {
    this.props.dispatch(setProgressPlanModelDelete({
      open: false
    }))
  };
  closing = () => {
    this.setState({closing: true});
  };
  render(){
    const {loading, progressModel, closing} = this.state;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.closing}>{t('common.model_suppression')}</ModalHeader>
        <ModalBody>
          {`${t('modals.remove_question')} ${progressModel.label} ?`}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary"
                  disabled={loading}
                  onClick={this.closing}>
            {t('common.close')}
          </Button>
          <LoadingButton color="danger"
                         disabled={loading}
                         loading={loading}
                         onClick={this.onSubmit}>
            {t('common.remove')}
          </LoadingButton>
        </ModalFooter>
      </Modal>
    )
  }
}
