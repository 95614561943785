import React from 'react';

export class StateBtnGroup extends React.Component{
    constructor(props){
        super(props);

        const btnClass = this.props.buttons.map((button, index) => 'btn ' +  (this.props.defaultActive === index ? this.props.activeClass + ' active' : button.class + (this.props.isState === true ? ' disabled' : '')));

        this.state = {
            isState: (this.props.isState !== undefined ? this.props.isState : false),
            btnClass: btnClass,
            activeButtonIndex: this.props.defaultActive,
        };

        this.handleButtonClick = this.handleButtonClick.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(this.props.defaultActive !== undefined && this.props.defaultActive !== nextProps.defaultActive){
            let state = Object.assign({}, this.state);

            state.btnClass[state.activeButtonIndex] = 'btn ' + nextProps.buttons[state.activeButtonIndex].class + (this.props.isState === true ? ' disabled' : '');
            state.btnClass[nextProps.defaultActive] = 'btn ' + this.props.activeClass;
            state.activeButtonIndex = nextProps.defaultActive;

            this.setState(state);
        }

        this.props = nextProps;
    }

    handleButtonClick(btnIndex){
        if(this.state.activeButtonIndex !== btnIndex && !this.state.btnClass[btnIndex].includes('disabled')){
            let state = Object.assign({}, this.state);

            state.btnClass[state.activeButtonIndex] = 'btn ' + this.props.buttons[state.activeButtonIndex].class;

            state.activeButtonIndex = btnIndex;
            state.btnClass[btnIndex] = 'btn ' + this.props.activeClass + ' active';

            this.setState(state);
            this.props.callback(btnIndex);
        }
    }

    render(){
        const buttons = this.props.buttons.map((button, index) =>  {

            return(
                <label key={index} onClick={() => this.handleButtonClick(index)} className={this.state.btnClass[index]} data-toggle='buttons'>
                    <input type='radio'/>
                    {button.label}
                </label>
            )
        });

        return(
            <div className='btn-group btn-group-toggle' data-toggle='buttons'>
                {buttons}
            </div>
        )
    }
}