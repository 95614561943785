import store from "./store";
import orm from "./orm";

export function updateObject(oldObject, newValues){
    return Object.assign({}, oldObject, newValues);
}

export function updateItemInArray(array, itemId, updateItemCallback){
    const updatedItems = array.map(item => {
        if(item.id !== itemId){
            return item;
        }

        const updatedItem = updateItemCallback(item);
        return updatedItem;
    });

    return updatedItems;
}

export function createReducer(initialState, handlers) {
    return function reducer(state = initialState, action) {
        if (handlers.hasOwnProperty(action.type)) {
            return handlers[action.type](state, action)
        } else {
            return state
        }
    }
}

/**
 * allow acces interesting part of the store
 * for reducers
 * @param reducer is String
 */
export function getStore(reducer){
    return store.getState()[reducer];
}

export const getOrm = () => {
    return orm.session(getStore('db'));
};