import createSelector from "../../tools/createSelector";

export const createAllSitesObjectSelector = () => createSelector(
  (orm) => {
    return orm.Site.all().toRefArray().reduce((acc, curr) => {
      acc[curr.id] = curr;

      return acc;
    }, {});
  }
);
