import {fk, attr, Model} from 'redux-orm';
import {_Responsable, _ZoneExploitation, _Client, _Site, _Prestataire, _User} from "../constants/orm";

export class Responsable extends Model {
  static parse(data) {
    const {user, cible_id, model, ...responsable} = data;

    switch (model) {
      case ('zone_exploitations'):
        responsable.zone_exploitation_id = cible_id;
        break;
      case ('sites'):
        responsable.site_id = cible_id;
        break;
      case ('clients'):
        responsable.client_id = cible_id;
        break;
    }
    return responsable;
  }

  static get fields() {
    return {
      id: attr(),
      prestataire_id: fk(_Prestataire, 'responsable'),
      zone_exploitation_id: fk(_ZoneExploitation, 'responsable'),
      client_id: fk(_Client, 'responsable'),
      site_id: fk(_Site, 'responsable'),
      user_id: fk(_User, 'responsable')
    }
  }
}

Responsable.modelName = _Responsable;
