import createSelector from "../tools/createSelector";
import {getOrm} from "../redux";

export const visitsByIds = createSelector(
    (orm, visitIds) => {
      if(!!visitIds)
        return visitIds.map(id => visitById(id));
      return null;
    }
  );

  export const visitById = (visitId) => {
    return getOrm().Visit.idExists(visitId) ? getOrm().Visit.withId(visitId) : null;
  };