import {WebsocketClient} from "./tools/WebsocketClient";
import {store} from "./store";
import {genericHydrateEntities} from "./actions/orm";
import {actionCallsTracker} from "./actions/actionsTracker";
import axios from "axios";

export const OBJECT_CREATED = 'OBJECT_CREATED';
export const INVALIDATE_MODEL_CACHE = "INVALIDATE_MODEL_CACHE";
export const SET_WEBSOCKET_CLIENT_ID = "SET_WEBSOCKET_CLIENT_ID";

let ocmWebsocketClient = new WebsocketClient(_ENV.hasOwnProperty('BROKER_URL') ? `${_ENV.BROKER_URL}` : 'ws://localhost:8000');

ocmWebsocketClient.on(OBJECT_CREATED, (data) => {
  store.dispatch(genericHydrateEntities({
    payload: data
  }));
});

ocmWebsocketClient.on(INVALIDATE_MODEL_CACHE, ({client_id}) => {
  actionCallsTracker.resetClient(client_id);
});

ocmWebsocketClient.on(SET_WEBSOCKET_CLIENT_ID, (id) => {
  axios.defaults.headers = {
    WebsocketClientId: id
  }
});

export {ocmWebsocketClient};
export default ocmWebsocketClient;
