import {fk, attr, Model} from 'redux-orm';
import {_ThemeQuestion, _Theme, _Question} from "../constants/orm";

export class ThemeQuestion extends Model{
  static parse(data){

    return data;
  }

  static get fields(){
    return{
      id: attr(),
      theme_id: fk(_Theme, 'themes_questions'),
      question_id: fk(_Question, 'themes_questions'),
      ranking: attr()
    }
  }
}

ThemeQuestion.modelName = _ThemeQuestion;
