import api from "../../tools/api";
import {
  _Complement,
  _Report,
  _Visit,
  _Audit,
  _Questionnaire,
  _QuestionnaireSection
} from "../../constants/orm";
import {
  createBasicCreateAction,
  createBasicFetchAction,
  createBasicUpdateAction,
  hydrateEntities,
  forceUpdateEnitities,
  updateEntities
} from "../orm";
import {actionCallsTracker} from "../actionsTracker";

export const fetchAuditComplements = ({audit_id}) => {
  return async dispatch => {
    const complements = await api.audits.getAuditComplements({audit_id});

    dispatch(hydrateEntities({
      payload: [
        {type: _Complement, entities: complements}
      ]
    }));
    return complements;
  }
};

export const addAuditReport = ({audit_id, start_date, end_date, status}) => {
  return async dispatch => {
    const report = await api.audits.addReport({
      audit_id,
      start_date,
      end_date,
      status
    });

    const visits = report.rapports;

    delete report.rapports;

    dispatch(hydrateEntities({
      payload: [
        {type: _Report, entities: [report]},
        {type: _Visit, entities: visits}
      ]
    }));
    return report;
  }
};

export const fetchAuditQuestionnaires = ({audit_id}) => {
  return async dispatch => {
    if (actionCallsTracker.isSaved('questionnaires', 'audit', audit_id))
      return;

    const questionnaires = await api.audits.getQuestionnairesList({audit_id});

    dispatch(forceUpdateEnitities({
      payload: [
        {type: _Questionnaire, entities: questionnaires}
      ]
    }));

    actionCallsTracker.save('questionnaires', 'audit', audit_id);
    return questionnaires;
  }
};

export const fetchAuditSections = ({audit_id}) => {
  return async dispatch => {
    if (actionCallsTracker.isSaved('sections', 'audit', audit_id))
      return;

    const sections = await api.audits.getSections({audit_id});

    dispatch(forceUpdateEnitities({
      payload: [
        {type: _QuestionnaireSection, entities: sections}
      ]
    }));

    actionCallsTracker.save('sections', 'audit', audit_id);
    return sections;
  }
};

export const updateAudit = createBasicUpdateAction(_Audit, api.audits.update);
export const addAudit = createBasicCreateAction(_Audit, api.audits.add);
export const freezeAudit = createBasicUpdateAction(_Audit, api.audits.freeze);
