import api from "../../tools/api";
import {_ProgressZoneExploitation} from "../../constants/orm";
import {hydrateEntities} from "../orm";

export const updateProgressZone = ({id, zone_type, ...params}) => {
  return async dispatch => {
    let ret;

    ret = await api.progressZoneExploitation.update({
      id,
      ...params
    });

    dispatch(hydrateEntities({
      payload: [{type: _ProgressZoneExploitation, entities: [ret]}]
    }));
  }
};
