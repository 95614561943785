import {api} from "../../../tools/api";
import {createSelector} from "../../../tools/createSelector";
import {createThemeTreeByClientIdSelector2} from "../../selectors/theme";
import {_questionnaireItemType} from "../../../constants/types";

const themeTreeSelector = createThemeTreeByClientIdSelector2();

const transformLabel = (item) => item.label;
const transformThemeQuestionLabel = (item) => {
  const question = item.question_id;

  return !!question ? question.title : item.id;
};

export const setOrmLabels = createSelector(
  (orm, {modelName, items, transformFunc}) => {
    let labelFunc = !!transformFunc ? transformFunc : modelName === 'ThemeQuestion' ? transformThemeQuestionLabel : transformLabel;

    items.forEach(i => {
      const item = orm[modelName].withId(i.id);

      i.label = !!item ? labelFunc(item) : i.id;
    });
  }
);

const setOrmAnalyseVagueLabels = createSelector(
  (orm, {vagues, analyseId}) => {
    const analyse = orm.Analyse.withId(analyseId);

    vagues.forEach(vague => {
      const aVague = analyse.vagues.find(a => a.vague === vague.vague);

      vague.label = !!aVague ? aVague.label : vague.vague
    });
  }
);

export function formatFilters(filters){
  let queryFilters = {};

  for(let key in filters){
    switch(key){
      case "zones_exploitation":
        queryFilters['zoneExploitationIds'] = filters[key];
        break;

      case "analyses":
        queryFilters['analyseIds'] = filters[key];
        break;

      case "prestataires":
        queryFilters['prestataireIds'] = filters[key];
        break;

      case "taxonomies":
        queryFilters['taxonomyIds'] = filters[key];
        break;

      case "themes":
        queryFilters['cible_id'] = filters[key];
        break;

      case "questions":
        queryFilters['cible_id'] = filters[key];
        break;

      case "questionnaireItems":
        queryFilters['cible_id'] = filters[key];
        break;

      default:
        queryFilters[key] = filters[key];
        break;
    }
  }
  return queryFilters;
}

export const byItemI2cFetch = async ({params, level : filterLevel, withLabels, labelModelName, by, returnType = 'list', filters = {}}) => {
  let formattedFilters = formatFilters(filters);
  const level = filters.level || filterLevel;
  const type = filters.type || undefined;

  formattedFilters['level'] = level;

  if (!level && !type){
    if (!filters.themes && !filters.questions){
      if (by === 'questionnaireItem' || by.startsWith('progressPlanData'))
        formattedFilters['level'] = {not_in: [-1]};
      else
        formattedFilters['level'] = [1];
    }
    else if (!!filters.themes)
      formattedFilters['type'] = [_questionnaireItemType.theme];
    else if (!!filters.questions)
      formattedFilters['type'] = [_questionnaireItemType.question];
  }

  const resp = await api.forepaas.getValueBy(by, {
    ...params
  }, formattedFilters);

  let items = [];

  if (by === 'questionnaireItem')
    Object.values(resp).forEach(level => {
      items = items.concat(Object.values(level));
    });
  else
    items = Object.values(resp);

  if (!!withLabels){
    if (by === 'vague' || by === 'progressPlanDataByVague')
      setOrmAnalyseVagueLabels({
        vagues: items,
        analyseId: filters.analyses[0]
      });
    else if (by === 'vagueByQuestionnaireItem'){
      setOrmAnalyseVagueLabels({
        vagues: items,
        analyseId: filters.analyses[0]
      });
      items.forEach(vague => {
        let items = [];

        Object.values(vague.items).forEach(level => {
          items = items.concat(Object.values(level));
        });
        if (returnType === 'list')
          vague.items = items;

        setOrmLabels({
          modelName: labelModelName,
          items: items
        });
      });
    } else
      setOrmLabels({
        modelName: labelModelName,
        items: items
      });
  }

  return returnType === 'list' ? items : resp;
};

export const createItemI2cFetch = ({params, level, withLabels, labelModelName, by, returnType = 'list'}) => {
  return async (filters) => {
    return byItemI2cFetch({
      params,
      level,
      withLabels,
      labelModelName,
      by,
      returnType,
      filters
    });
  }
};

export const questionnaireTreeI2cFetch = async (filters) => {
  let formattedFilters = formatFilters(filters);
  const clientId = filters.clients[0];

  const fpResp = await api.forepaas.getValueBy('questionnaireItem',{
    lastVagueValue: true,
    lastVagueDiff: true
  }, formattedFilters);

  let themeTree = themeTreeSelector({
    clientId: clientId,
    sortByRank: true,
    setNumericValue: true
  }, true);

  function filterTree(tree) {
    return tree.filter(t => !!fpResp[t.type] && !!fpResp[t.type][t.id]).map(t => {
      if (!!t.children && !!t.children.length)
        t.children = filterTree(t.children);
      return t;
    });
  }

  themeTree = filterTree(themeTree);

  flatTree(themeTree).forEach(i => {
    const values = !!fpResp[i.type] ? fpResp[i.type][i.id] : null;

    if (!values){
      i.value = null;
      i.diff = null;
      i.lastVagueValue = null;
    } else {
      i.value = values.value;
      i.diff = values.lastVagueDiff;
      i.lastVagueValue = values.lastVagueValue;
    }
  });

  return themeTree;
};
