import React from "react";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import {setSetupAuditObject} from "../../actions/modals";
import {connect} from "react-redux";
import {FormValidator} from "../../tools/formValidator/FormValidator";
import {tFunction} from "../tools/Translation";

const localFv = new FormValidator([
  {
    field: 'label',
    method: 'isEmpty',
    validWhen: false,
    message: tFunction('modals.label_required')
  }
]);

@connect(({modals: {setupAuditObject: {current, resolve, reject}}}) => ({
  current,
  resolve,
  reject
}))
class SetupAuditObject extends React.PureComponent {
  constructor(props) {
    super(props);
    const {current} = this.props;

    this.state = {
      closing: false,
      label: '',
      type: 'clients',
      numerical: false,
      validation: localFv.valid()
    };

    if (!!current){
      this.state = {
        ...this.state,
        label: !!current.label ? current.label : '',
        type: !!current.type ? current.type: 'clients',
        numerical: !!current.numerical ? current.numerical : false
      }
    }
  }

  inputLabel = React.createRef();

  componentDidMount() {
    this.inputLabel.focus();
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const {label, type, numerical} = this.state;

    const validation = localFv.validate({label});

    if (!validation.isValid){
      this.setState({
        validation
      });
      return;
    }

    this.props.resolve({
      label,
      type,
      numerical
    });

    this.closing();
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setSetupAuditObject({
      open: false
    }));
  };

  reject = () => {
    this.props.reject();
    this.closing();
  };

  render(){
    const {closing, label, type, numerical, validation} = this.state;
    const {current} = this.props;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.reject}>
          {!!current ? t('modals.modify_audit') : t('modals.create_audit')}
        </ModalHeader>
        <Form onSubmit={this.handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label>
                {t('common.label')} :
              </Label>
              <Input name="label"
                     innerRef={(input) => (this.inputLabel = input)}
                     invalid={validation.label.isInvalid}
                     type="text"
                     onChange={this.handleChange}
                     placeholder={t('common.label')}
                     value={label}/>
              <FormFeedback>
                {validation.label.message}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label>
                {t('modals.audit_type')}
              </Label>
              <div>
                <label className="custom-control custom-control-inline custom-radio">
                  <input name="type"
                         type="radio"
                         className="custom-control-input"
                         value={'clients'}
                         onChange={this.handleChange}
                         checked={type === 'clients'}/>
                  <div className="custom-control-label">
                    {t('common.client')}
                  </div>
                </label>
                <label className="custom-control custom-control-inline custom-radio">
                  <input name="type"
                         type="radio"
                         className="custom-control-input"
                         value={'prestataires'}
                         onChange={this.handleChange}
                         checked={type === 'prestataires'}/>
                  <div className="custom-control-label">
                    {t('common.prestataire')}
                  </div>
                </label>
                <label className="custom-control custom-control-inline custom-radio">
                  <input name="type"
                         type="radio"
                         className="custom-control-input"
                         value={'firms'}
                         onChange={this.handleChange}
                         checked={type === 'firms'}/>
                  <div className="custom-control-label">
                    {t('common.externe')}
                  </div>
                </label>
              </div>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              type='button'
              onClick={this.reject}>
              {t('common.close')}
            </Button>
            <Button
              color="success">
              {t('common.valid')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export {SetupAuditObject};
export default SetupAuditObject;
