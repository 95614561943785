class tracker {
  state = {};

  isSaved = (model, filter, id) => {
    return (!!this.state[model] &&
      !!this.state[model][filter] &&
      !!this.state[model][filter][id])
  };

  save = (model, filter, id) => {
    if (!this.state[model])
      this.state[model] = {};
    if (!this.state[model][filter])
      this.state[model][filter] = {};
    this.state[model][filter][id] = true;
  };

  reset = () => {
    this.state = {};
  };

  resetClient = (client_id) => {
    Object.keys(this.state).forEach(i => {
      if (!!this.state[i].client && !!this.state[i].client[client_id])
        this.state[i].client[client_id] = false;
    });
  }
}

const actionCallsTracker = new tracker();

export {actionCallsTracker};
export default actionCallsTracker;