import createSelector from "../tools/createSelector";
import {getOrm} from "../redux";

export const responsableSelectorById = (id) => {
  if (getOrm().Responsable.idExists(id))
    return getOrm().Responsable.withId(id).ref;
  return null;
};

export const responsableSelectorByZone = createSelector((orm, {zone_type, zone_id}) => {
  return orm.Responsable.all().filter(r => r[`${zone_type}_id`] === zone_id).toRefArray();
});

export const responsableSelectorByZoneInObject = createSelector((orm, {zone_type, zone_id}) => {
  const responsables = {};

  orm.Responsable.all().filter(r => r[`${zone_type}_id`] === zone_id).toRefArray().map(responsable => {
    if (!responsables[responsable.prestataire_id])
      responsables[responsable.prestataire_id] = [responsable.user_id];
    else
      responsables[responsable.prestataire_id].push(responsable.user_id);
  });

  return responsables;
});

export const mainResponsableSelectorByZoneInObject = createSelector((orm, {zone_type, zone_id}) => {
  const responsables = {};

  orm.Responsable.all().filter(r => r[`${zone_type}_id`] === zone_id).toRefArray().map(responsable => {
    if (responsable.main)
      responsables[responsable.prestataire_id] = responsable.user_id;
  });

  return responsables;
});

export const mainResponsableSelectorByZoneUserId = createSelector((orm, {zone_type, zone_id}) => {
  let responsable_id = null;

  orm.Responsable.all().filter(r => r[`${zone_type}_id`] === zone_id).toRefArray().map(responsable => {
    if (responsable.main)
      responsable_id = responsable.user_id;
  });

  return responsable_id;
});

export const responsablesSelectorByZoneUserId = createSelector((orm, {zone_type, zone_id}) => {
  let responsable_ids = [];

  orm.Responsable.all().filter(r => r[`${zone_type}_id`] === zone_id).toRefArray().map(responsable => {
    responsable_ids.push(responsable.user_id);
  });

  return responsable_ids;
});