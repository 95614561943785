import moment from 'moment';

export class cDate{
    static datePickerToSubmit(date, format){
        let momentDate = moment(date);
        return format === 'd-m-Y' ? momentDate.hours(11).toISOString() : momentDate.toISOString();
    }

    /**
     * return the number of full month (e.g.,
     * excluding the month each date is in)
     * @param date1
     * @param date2
     * @returns {*}
     * @constructor
     */
    static monthDiff(date1, date2){
        let months;
        months = (date2.getFullYear() - date1.getFullYear()) * 12;
        months -= date1.getMonth() + 1;
        months += date2.getMonth();
        return months <= 0 ? 0 : months;
    }

    static dayDiff(date1, date2, calendar = false){
        let oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds

        return Math.round(Math.abs((date1.getTime() - date2.getTime())/(oneDay))) - 1;
    }


    static displayDiff(oldestDate, nearestDate){

        const timeSpent = Math.round((nearestDate.getTime() - oldestDate.getTime()) / (1000*60));

        if(timeSpent < 5) return t('common.now');
        else if(timeSpent < 60) return timeSpent + t('common.minutes');
        else if(timeSpent < 60 * 24) return Math.round(timeSpent/60) + t('common.hours');
        else if(timeSpent < 60 * 24 * 7) return Math.round(timeSpent/(24*60)) + t('common.days');
        else if(timeSpent > 60 * 24 * 7 && timeSpent < 60 * 24 * 30 - 1) return Math.round(timeSpent/(24*60*7)) + t('common.days');
        else if(timeSpent > 60 * 24 * 30) return Math.round(timeSpent/(24*60*7*30)) + t('common.months');
    }

    static dateToFRString(date, hours = false, long = false){
        function add0onDate(string){
            if(String(string).length > 1 ) return string;
            else return '0' + String(string);
        }

        let dateF = '';
        if(!long) dateF = add0onDate(date.getUTCDate()) + "-" + add0onDate(date.getUTCMonth()+1) + "-" + String(date.getUTCFullYear());
        else dateF = add0onDate(date.getUTCDate()) + " " + this.monthIntToString(date.getMonth()) + ", " + String(date.getUTCFullYear());

        if(hours) dateF += ' ' + add0onDate(date.getHours()) + ':' + add0onDate(date.getMinutes()) + ':' + add0onDate(date.getSeconds());
        return dateF;
    }

    static dateStringFRtoEN(date){
        return date.split('-')[2] + '-' + date.split('-')[1] + '-' + date.split('-')[0];
    }

    static dateToENString(date){
        return this.dateStringFRtoEN(this.dateToFRString(date));
    }

    /**
     * return the french name of the month regard to its javascript number
     * @param monthInt
     * @param length a value of -2 often used french custom substring
     * @returns {string}
     */
    static monthIntToString(monthInt, length = -1){
        let monthString = '';

        switch(monthInt) {
            case 0:
                monthString = t('months.january');
                if(length == -2) monthString = monthString.substring(0, 3);
                break;

            case 1:
                monthString = t('months.february');
                if(length == -2) monthString = monthString.substring(0, 3);
                break;

            case 2:
                monthString = t('months.march');
                break;

            case 3:
                monthString = t('months.april');
                break;

            case 4:
                monthString = t('months.may');
                break;

            case 5:
                monthString = t('months.june');
                break;

            case 6:
                monthString = t('months.july');
                if(length == -2) monthString = monthString.substring(0, 4);
                break;

            case 7:
                monthString = t('months.august');
                break;

            case 8:
                monthString = t('months.september');
                if(length == -2) monthString = monthString.substring(0, 4);
                break;

            case 9:
                monthString = t('months.october');
                if(length == -2) monthString = monthString.substring(0, 3);
                break;

            case 10:
                monthString = t('months.november');
                if(length == -2) monthString = monthString.substring(0, 3);
                break;

            case 11:
                monthString = t('months.december');
                if(length == -2) monthString = monthString.substring(0, 3);
                break;
        }

        return (length == -1 ? monthString : monthString.substring(0, length));
    }
}
