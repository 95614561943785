import randomColor from "randomcolor";

let colors = {};

export const getColor = (model, id) => {
  if (!colors[model])
    colors[model] = {};
  if (!colors[model][id])
    colors[model][id] = randomColor();

  return colors[model][id];
};
