import {attr, fk, Model} from 'redux-orm';
import {_Report, _Audit} from "../constants/orm";
import moment from "moment";

export class Report extends Model {
  static parse(data){
    data.label = `${data.vague}`;
    if (!!data.start_date)
      data.start_date = data.start_date * 1000;
    if (!!data.end_date) {
      data.end_date = data.end_date * 1000;
      data.label = `${moment(data.end_date).format('MM')}-${moment(data.end_date).format('YYYY')}`
    }

    return data;
  }

  static get fields(){
    return {
      id: attr(),
      label: attr(),
      audit_id: fk(_Audit, 'reports'),
      start_date: attr(),
      end_date: attr(),
      vague: attr(),
      status: attr()
    }
  }
}

Report.modelName = _Report;
