import React from "react";
import {connect} from "react-redux";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form
} from "reactstrap";
import createSelector from "../../tools/createSelector";
import {setRemoveClientPrestataire} from "../../actions/modals";
import {LoadingButton} from "../common/LoadingButton";
import {updateClient} from "../../actions/orm/Client";

const clientPrestataireSelector = createSelector(
  (orm, {clientId, prestataireId}) => {
    const client = orm.Client.withId(clientId);
    const clientRef = {
      ...client.ref,
      prestataires: client.prestataires.toRefArray()
    };

    const prestataire = orm.Prestataire.withId(prestataireId).ref;
    return {
      client : clientRef,
      prestataire
    }
  }
);

@connect(({modals: {removeClientPrestataire: {clientId, prestataireId}}}) => ({
  clientId,
  prestataireId
}))
class RemoveClientPrestataire extends React.PureComponent {
  state = {
    closing: false,
    loading: false,
    ...clientPrestataireSelector({
      clientId: this.props.clientId,
      prestataireId: this.props.prestataireId
    })
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const {client, prestataire} = this.state;

    let prestataireIds = client.prestataires.map(i => i.id);

    prestataireIds.splice(prestataireIds.indexOf(prestataire.id), 1);

    this.setState({loading: true});
    await this.props.dispatch(updateClient({
      id: client.id,
      prestataireIds
    }));
    this.setState({loading: false});
    this.closing();
  };

  close = () => {
    this.props.dispatch(setRemoveClientPrestataire({
      open: false
    }));
  };

  closing = () => {
    this.setState({closing: true});
  };

  render(){
    const {client, prestataire, loading, closing} = this.state;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.closing}>{t('modals.modify_prestas')}</ModalHeader>
        <Form onSubmit={this.onSubmit}>
          <ModalBody>
            {t('modals.remove_question')} {prestataire.label} {t('modals.of_prestas_list')} {client.label} ?
          </ModalBody>
          <ModalFooter>
            <Button color="secondary"
                    type="button"
                    disabled={loading}
                    onClick={this.closing}>
              {t('common.no')}
            </Button>
            <LoadingButton color="danger"
                           loading={loading}
                           disabled={loading}
                           type="submit">
              {t('common.yes')}
            </LoadingButton>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export {RemoveClientPrestataire};
export default RemoveClientPrestataire;
