import * as _Redux from "../../redux";
import {HYDRATE_ENTITIES} from "../../actions/orm";
import {START_PENDING, STOP_PENDING} from "../../actions/ui";
import axios from "axios/index";
import {Axios} from "../../tools/axios";
import {_Report, _Theme, _Question, _Response, _ThemeQuestion, _Questionnaire, _Audit, _ProgressModel, _ModelAction} from "../../constants/orm";
import orm from "../../orm";
import {editProgressPlan, fetchProgressPlan} from "./monitoring";

export function submit(datas){
  return async dispatch => {
    try{
      dispatch({type: START_PENDING});

      if(datas.hasOwnProperty('newProgress')) {
        let newProgress = Object.assign({}, datas.newProgress);
        delete datas.newProgress;
        delete newProgress.id;

        newProgress.client_id = _Redux.getStore('session').currentClientId;
        newProgress.actions = newProgress.actions.map(a => a.label);
        if (datas.questions.length > 0)
          newProgress.themes_questions = datas.questions.slice();
        else
          newProgress.themes = datas.themes.slice();

        const progressModel = await axios.post('/api/type-progres', newProgress);
        datas.type_progre_id = progressModel.data.id;
      }

      if(datas.hasOwnProperty('questions')){
        Object.defineProperty(datas, 'themes_questions',
          Object.getOwnPropertyDescriptor(datas, 'questions'));
        delete datas['questions'];
      }

      datas.zone_exploitations = datas.inCharges;
      delete datas.inCharges;

      datas.actions = [];

      const response = await axios.post('/api/progres', datas);
      const progressPlan = await dispatch(fetchProgressPlan(response.data.id));
      dispatch({type: STOP_PENDING});
    } catch(error){
      dispatch({type: STOP_PENDING});
      console.log(error);
      throw error;
    }
  }
}

export function fetchModels(perimeterDatas){
  return async dispatch =>{
    const filters = {clients: [_Redux.getStore('session').currentClientId]};
    const query = perimeterDatas.themes.length > 0 ? Axios.filtersToQuery({...filters, themes: perimeterDatas.themes}) : Axios.filtersToQuery({...filters, themes_questions: perimeterDatas.questions});
    await axios.get('/api/type-progres' + query).then(function(models){

      let actions = [];
      models.data.forEach(function(model){
        actions = actions.concat(model.type_actions);
      });

      dispatch({type: HYDRATE_ENTITIES, payload: [{type: _ModelAction, entities: actions}, {type: _ProgressModel, entities: models.data}]});
    }).catch(function(error){
      console.log(error);
      throw error;
    });
  }
}

export async function fetchQuestionnaires(dispatch, auditId, reportId){
  if(auditId !== null && reportId !== null){
    try{
      let [questions] = (await Promise.all([
        axios.get('/api/reports/' + reportId + '/questions').then(questions => questions),
      ]));

      let themesQuestions = [];
      let questionnaires = [];
      let responses = [];

      questions.data.forEach(question => {
        themesQuestions.push({id: question.theme_question_id, theme_id: question.theme_id, question_id: question.id});
        questionnaires.push(question.questionnaire);
        question.responses.forEach(response => {
          responses.push(response);
        });
      });

      dispatch({type: HYDRATE_ENTITIES, payload: [{type: _Question, entities: window.cloneArrayofObjects(questions.data)},
          {type: _ThemeQuestion, entities: themesQuestions}, {type: _Questionnaire, entities: questionnaires},
          {type: _Response, entities: responses}]});

    } catch(error){
      console.log(error);
      throw error;
    }
  }
}

export function fetchAudit({type, id}){
  return async dispatch => {
    try {
      let ret = {reportId: null, auditId: null};

      if (type === 'client') {
        const report = await axios.get('/api/clients/' + id + '/reports').then(report => report);
        if (report.data.length > 0) {
          dispatch({type: HYDRATE_ENTITIES, payload: [{type: _Report, entities: report.data}]});

          ret.auditId = report.data[0].audit_id;
          await fetchQuestionnaires(dispatch, report.data[0].audit_id, arr_end(report.data.filter(d => d.audit_id === ret.auditId)).id);
          ret.reportId = arr_end(report.data.filter(d => d.audit_id === ret.auditId)).id;
        } else {
          const audits = orm.session(_Redux.getStore('db')).Audit.all().toRefArray();
          if (audits.length > 0)
            ret.auditId = audits[0].id;
        }
      } else {
        const report = await axios.get('/api/audits/' + id + '/last-report').then(report => report);
        if (report.data.length > 0) {
          const zoneExploitations = await axios.get('/api/audits/' + id + '/zone-exploitations').then(zoneExploitations => zoneExploitations);

          let audit = orm.session(_Redux.getStore('db')).Audit.withId(report.data[0].audit_id).ref;
          audit.prestataires = orm.session(_Redux.getStore('db')).Audit.withId(report.data[0].audit_id).prestataires.all().toRefArray();
          audit.clients = orm.session(_Redux.getStore('db')).Audit.withId(report.data[0].audit_id).clients.all().toRefArray();

          delete zoneExploitations.data._matchingData;
          audit.zoneExploitations = zoneExploitations.data;

          dispatch({
            type: HYDRATE_ENTITIES,
            payload: [{type: _Audit, entities: [audit]},
              {type: _Report, entities: report.data}]
          });
          ret.reportId = report.data[0].id;

          await fetchQuestionnaires(dispatch, id, report.data[0].id);
        }
        ret.auditId = id;
      }

      return ret;
    } catch(error){
      console.log(error);
      throw error;
    }
  }
}
