import {fk, attr, Model} from 'redux-orm';
import {_Questionnaire, _Report, _ZoneExploitation, _Response} from "../constants/orm";

export class Response extends Model {
  static parse(data){
    if (!data.files)
      data.files = [];
    if (!data.photos)
      data.photos = [];

    return data;
  }

  static get fields(){
      return {
        id: attr(),
        value: attr(),
        comment: attr(),
        photos: attr(),
        files: attr(),
        valid: attr(),
        questionnaire_id: fk(_Questionnaire, 'responses'),
        zone_exploitation_id: fk(_ZoneExploitation, 'responses'),
        report_id: fk(_Report)
      }
  }
}

Response.modelName = _Response;
