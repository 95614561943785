import {fk, attr, Model} from 'redux-orm';
import {_Theme, _Client} from '../constants/orm';

export class Theme extends Model{
  static parse(data){
    delete data.ChildThemes;
    delete data.questions;

    return data;
  }

  static get fields(){
    return {
      id: attr(),
      parent_id: fk(_Theme, 'ChildThemes'),
      generic: fk(_Theme, 'linkedThemes'),
      client_id: fk(_Client, 'themes'),
      ranking: attr(),
      label: attr(),
      created: attr(),
      modified: attr()
    }
  }
}

Theme.modelName = _Theme;

export default Theme;
