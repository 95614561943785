import createSelector from "../tools/createSelector";
import {questionnairesByThemesandAudit, questionnairesByThemeQuestionsandAudit} from "./questionnaire";

const inQuestionnaires = (response, questionnaires) => getIndexById(response.questionnaire_id, questionnaires) !== null;
const inZoneExploitations = (response, zoneExploitations) => getIndexById(response.zone_exploitation_id, zoneExploitations) !== null;
const hasReport = (response, report) => response.report_id === report.id;

export const responsesByQuestionnairesandZesandReport = createSelector(
  (orm, {report, zoneExploitations, questionnaires}) => {
    if(questionnaires !== null && zoneExploitations !== null && report !== null)
      return orm.Response.all().filter(response =>
        inQuestionnaires(response, questionnaires) && inZoneExploitations(response, zoneExploitations) && hasReport(response, report)).toModelArray();
    return null;
  }
);

export const responsesByThemesandZesandReport = createSelector(
  (orm, {report, zoneExploitations, themes}) => {

    if(themes !== null && zoneExploitations !== null && report !== null){
      const questionnaires = questionnairesByThemesandAudit({themes: themes, audit: report.audit_id});
      return responsesByQuestionnairesandZesandReport({
        report: report,
        zoneExploitations: zoneExploitations,
        questionnaires: questionnaires
      });
    }

    return null;
  }
);

export const responsesByThemeQuestionsandZesandReport = createSelector(
  (orm, {report, zoneExploitations, themeQuestions}) => {
    if(themeQuestions !== null && zoneExploitations !== null && report !== null){
      const questionnaires = questionnairesByThemeQuestionsandAudit({themeQuestions: themeQuestions, audit: report.audit_id});
      return responsesByQuestionnairesandZesandReport({
        report: report,
        zoneExploitations: zoneExploitations,
        questionnaires: questionnaires
      });
    }

    return null;
  }
);

export const responsesByZe = createSelector(
  (orm, zoneExploitation) => {
    if(zoneExploitation !== null) {
      return zoneExploitation.responses.toModelArray();
    }
    return null;
  }
);

