import React from "react";
import {
  FormGroup,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav, NavItem, NavLink, ListGroup, ListGroupItem
} from "reactstrap";
import {connect} from "react-redux";
import {setZoneExploitationsSurveyInfo} from "../../actions/modals";
import {WeekTimetableReadable} from "../common/WeekTimetableReadable";
import createSelector from "../../tools/createSelector";
import moment from "moment";

const visitSelector = createSelector(
  (orm, {id}) => {
    const visit = orm.Visit.withId(id);
    let zone = visit.zone_exploitation_id;
    zone = {
      ...zone.ref,
      site: zone.site_id.ref,
      brand: !!zone.brand_id ? zone.brand_id.ref : null,
      prestataires: zone.prestataires.toRefArray()
    };

    const audit = visit.report_id.audit_id.ref;
    const client = visit.report_id.audit_id.client_id.ref;

    let allVisits = orm.Visit.all().filter(v => {
      return v.zone_exploitation_id === zone.id
    }).toModelArray();

    allVisits.filter(v => (v.report_id.ref.audit_id === audit.id));
    allVisits = allVisits.map(i => i.ref);
    allVisits.sort((a,b) => {
      return b.passage - a.passage;
    });
    const currentIdx = allVisits.findIndex(v => v.id === id);
    const lastVisit = currentIdx > 0 ? allVisits[currentIdx - 1] : null;

    return {
      zone: zone,
      audit: audit,
      client: client,
      visit: visit.ref,
      lastVisit
    }
  }
);

@connect(
  ({modals: {zoneExploitationsSurveyInfo: {visitId}}}) => ({
    ...visitSelector({id: visitId})
  })
)
class ZoneExploitationsSurveyInfo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      closing: false,
      displayTab: 'general'
    }
  }

  changeTab = (tabName) => {
    this.setState({displayTab: tabName});
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setZoneExploitationsSurveyInfo({
      open: false
    }));
  };

  render(){
    const {closing, displayTab} = this.state;
    const {zone, audit, client, visit, lastVisit} = this.props;
    const {auditLabel, zoneExploitationLabel} = client;

    const characteristics = client.characteristics.map(ch => {
      const zoneChLabel = zone.zone_characteristics.find(c => c.characteristic_id === ch.id);

      return {
        label: ch.label,
        content: !!zoneChLabel ? zoneChLabel.label : ''
      }
    }).filter(i => !!i.content);

    const caracteristicsReact = !!characteristics.length ?
      <ListGroup className="list-group-bordered">
        {characteristics.map(ch => {
          return (
            <React.Fragment>
              <div className="list-group-header">{ch.label} :</div>
              <ListGroupItem>{ch.content}</ListGroupItem>
            </React.Fragment>
          )
        })}
      </ListGroup> : <span>{t('modals.Aucune caractéristique définie')}.</span>;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.closing}>
          {t('modals.questionnaire_informations')}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className="cursor-pointer"
                  onClick={this.changeTab.bind(null, 'general')}
                  active={displayTab === 'general'}>
                  {t('common.general')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="cursor-pointer"
                  onClick={this.changeTab.bind(null, 'characteristics')}
                  active={displayTab === 'characteristics'}>
                  {t('common.characteristics')}
                </NavLink>
              </NavItem>
            </Nav>
          </FormGroup>
          {displayTab === 'general' &&
          <FormGroup>
            <ul className="list-group list-group-flush list-group-bordered">
              <li className="list-group-item justify-content-between flex-column">
                <strong className="text-muted text-uppercase">
                  {zoneExploitationLabel} :
                </strong>
                <span>
                {zone.label}
              </span>
              </li>
              <li className="list-group-item justify-content-between flex-column">
                <strong className="text-muted text-uppercase">
                  {t('common.site')} :
                </strong>
                <span>
                {zone.site.label}
              </span>
              </li>
              <li className="list-group-item justify-content-between flex-column">
                <strong className="text-muted text-uppercase">
                  {t('common.address')} :
                </strong>
                <span>
                {zone.site.adresse}
              </span>
              </li>
              <li className="list-group-item justify-content-between flex-column">
                <strong className="text-muted text-uppercase">
                  {t('modals.last')} {auditLabel} :
                </strong>
                <span>
                {!!lastVisit ?
                  moment(lastVisit.passage).format('L') :
                  `${t('common.not_of')} ${auditLabel}`}
              </span>
              </li>
              <li className="list-group-item justify-content-between flex-column">
                <strong className="text-muted text-uppercase">
                  {t('modals.valid_for')}
                </strong>
                <span>
                {moment(visit.passage).format('L')}
              </span>
              </li>
              <li className="list-group-item justify-content-between flex-column">
                <strong className="text-muted text-uppercase">
                  {auditLabel} :
                </strong>
                <span>
                {audit.label}
              </span>
              </li>
              <li className="list-group-item justify-content-between flex-column">
                <strong className="text-muted text-uppercase">
                  {t('common.client')} :
                </strong>
                <span>
                {client.label}
              </span>
              </li>
              <li className="list-group-item justify-content-between flex-column">
                <strong className="text-muted text-uppercase">
                  {t('common.prestataire')} :
                </strong>
                <span>
                {!zone.prestataires.length ?
                  t('common.no_prestataires') :
                  zone.prestataires.map(p => p.label).join(', ')}
              </span>
              </li>
              {!!zone.brand &&
              <li className="list-group-item justify-content-between flex-column">
                <strong className="text-muted text-uppercase">
                  {t('common.brand')} :
                </strong>
                <span>
                {zone.brand.label}
              </span>
              </li>}
              <li className="list-group-item justify-content-between flex-column">
                <strong className="text-muted text-uppercase">
                  {t('common.comment')} :
                </strong>
                <span>
                {!!zone.comment ? zone.comment : t('modals.no_comment')}
              </span>
              </li>
              <li className="list-group-item justify-content-between flex-column">
                <strong className="text-muted text-uppercase mb-2">
                  {t('modals.access_time')} :
                </strong>
                <span>
                <WeekTimetableReadable value={zone.schedules}/>
              </span>
              </li>
            </ul>
          </FormGroup>}
          {displayTab === 'characteristics' &&
          <FormGroup>
            {caracteristicsReact}
          </FormGroup>}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={this.closing}
            color="success">
            {t('common.close')}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export {ZoneExploitationsSurveyInfo};
export default ZoneExploitationsSurveyInfo;
