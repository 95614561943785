import React from "react";
import {Button, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {setChooseOrganisationsItems} from "../../actions/modals";
import {connect} from "react-redux";
import {OrganisationsTree} from "../tools/OrganisationsTree";
import {organisationsByClientId2} from "../selectors/organisations";
import {CardSelect} from "../common/CardSelect";
import BootstrapTable from "react-bootstrap-table-next";
import {defaultPaginationFactory} from "../../constants/ui";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import {DebounceSearchInput} from "../common/DebounceSearchInput";
import {tFunction} from "../tools/Translation";
import {RowSelectionHeaderItem, RowSelectionItem} from "../tools/DatatableComponents";

const columns = [
  {
    dataField: 'label',
    text: tFunction('common.name'),
    sort: true
  }
];

function newTree(tree, defaultValues) {
  return tree.filter(t => {
    if (!!t.children) {
      t.children = newTree(t.children, defaultValues);
      if (!!t.children.length)
        return true;
    }
    return (t.type === 'zone_exploitations' && (!defaultValues.zonesShown || !!defaultValues.zonesShown.includes(t.id)));
  }).map(t => {
    if (!!t.children) {
      if (!!t.children.length)
        t.children = newTree(t.children, defaultValues);
      t.checked = !t.children.find(c => !c.checked);
    }
    if (t.type === 'zone_exploitations')
      t.checked = !!defaultValues.zone_exploitations.includes(t.id);
    return t;
  });
}

@connect(({modals: {chooseOrganisationsItems: {clientId, resolve, reject, defaultValues}}}) => ({
  clientId,
  defaultValues,
  resolve,
  reject
}))
class ChooseOrganisationsItems extends React.PureComponent {
  constructor(props) {
    super(props);
    const {defaultValues} = this.props;
    const selected = (!!defaultValues && !!defaultValues.zone_exploitations) ? [...defaultValues.zone_exploitations] : [];
    const tree = organisationsByClientId2({
      clientId: this.props.clientId,
      setupChecked: true
    });
    const newItem = newTree(copyTree(tree), defaultValues);
    const horizontal = flatTree(newItem);

    let zones = horizontal.filter(i => i.type === 'zone_exploitations');

    if (!!defaultValues.zonesShown && !!defaultValues.zonesShown.length)
      zones = zones.filter(zone => !!defaultValues.zonesShown.includes(zone.id));
    if (!!selected.length)
      zones.sort((a,b) => !!(selected.includes(b.id)) - !!(selected.includes(a.id)));

    this.state = {
      closing: false,
      tree: newItem,
      zones: zones,
      displayMode: 'table',
      selected: selected
    };
    this.tableRef = null;
  }

  setDisplayMode = (mode) => {
    if (mode === 'table'){
      const {tree} = this.state;
      const horizontal = flatTree(tree);
      const zones = horizontal.filter(i => (i.type === 'zone_exploitations' && !!i.checked));

      this.setState({
        displayMode: 'table',
        selected: zones.map(i => i.id)
      });
    } else {
      const {defaultValues: {zonesShown}} = this.props;
      const {tree} = this.state;

      // let horizontal = flatTree(tree);
      const selected = this.tableRef.selectionContext.state.selected;

      const newItem = newTree(copyTree(tree), {zonesShown: zonesShown, zone_exploitations: selected});

      // horizontal.forEach(i => {
      //   if (i.type === 'zone_exploitations' && selected.indexOf(i.id) !== -1)
      //     i.checked = true;
      //   else if (i.type === 'zone_exploitations' && selected.indexOf(i.id) === -1)
      //     i.checked = false;
      // });
      this.setState({
        tree: newItem,
        displayMode: 'tree'
      });
    }
  };

  handleSortByLabel = () => {
    const {zones} = this.state;

    zones.sort((a,b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

    this.setState({zones: [...zones]});
  };

  handleSortBySelected = () => {
    const {zones, selected} = this.state;

    zones.sort((a,b) => !!(selected.includes(b.id)) - !!(selected.includes(a.id)));

    this.setState({zones: [...zones]});
  };

  handleSelectAllData = () => {
    const keyField = this.tableRef.selectionContext.props.keyField;
    const selected = [...this.tableRef.selectionContext.state.selected];
    const displayedItems = this.tableRef.paginationContext.props.data.map(i => i[keyField]);

    displayedItems.forEach(id => {
      if (selected.indexOf(id) === -1)
        selected.push(id);
    });
    this.setState({selected});
  };

  handleUnselectAllData = () => {
    const keyField = this.tableRef.selectionContext.props.keyField;
    const selected = [...this.tableRef.selectionContext.state.selected];
    const displayedItems = this.tableRef.paginationContext.props.data.map(i => i[keyField]);

    displayedItems.forEach(id => {
      const index = selected.indexOf(id);

      if (index !== -1)
        selected.splice(index, 1);
    });
    this.setState({selected});
  };

  handleSelectAll = () => {
    setTimeout(() => {
      const selected = [...this.tableRef.selectionContext.state.selected];

      this.setState({selected: selected});
    }, 1);
  };

  handleSelect = () => {
    setTimeout(() => {
      const selected = [...this.tableRef.selectionContext.state.selected];

      this.setState({selected: selected});
    }, 1);
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setChooseOrganisationsItems({
      open: false
    }));
  };

  resolve = () => {
    const {tree, displayMode, selected, zones} = this.state;

    if (displayMode === 'tree'){
      const horizontal = flatTree(tree);
      const checkedItems = horizontal.filter(i => !!i.checked);

      this.props.resolve({
        clients: checkedItems.filter(i => i.type === 'client').map(i => i.id),
        sites: checkedItems.filter(i => i.type === 'site').map(i => i.id),
        zone_exploitations: checkedItems.filter(i => i.type === 'zone_exploitations').map(i => i.id)
      });
    } else {
      this.props.resolve({
        clients: [],
        sites: [],
        zone_exploitations: zones.filter(z => (selected.indexOf(z.id) !== -1)).map(i => i.id)
      });
    }
    this.closing();
  };

  reject = () => {
    this.props.reject();
    this.closing();
  };

  render(){
    const {closing, tree, selected, zones, displayMode} = this.state;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.reject}>{t('common.select_elements_of_organisations')}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <CardSelect
              hideArrow={true}
              selectTitle={t('common.filter_types')}
              radioInputs={[
                {
                  value: 'table',
                  label: t('common.list')
                },
                {
                  value: 'tree',
                  label: t('common.tree')
                }
              ]}
              value={displayMode}
              handleChange={this.setDisplayMode}/>
          </FormGroup>
          {displayMode === 'tree' &&
          <OrganisationsTree
            name="ChooseOrgsModalTree"
            data={tree}/>}
          {displayMode === 'table' &&
          <ToolkitProvider
            search
            keyField={'id'}
            columns={columns}
            bootstrap4={true}
            data={zones}>
            {props => (
              <div>
                <DebounceSearchInput onChange={(e) => props.searchProps.onSearch(e.target.value)}/>
                <BootstrapTable
                  bordered={false}
                  ref={ref => this.tableRef = ref}
                  classes={'bulk-selection'}
                  rowStyle={{cursor: 'pointer'}}
                  defaultSorted={[{
                    dataField: 'label',
                    order: 'asc'
                  }]}
                  noDataIndication={() => t('common.no_data')}
                  selectRow={{
                    onSelect: this.handleSelect,
                    onSelectAll: this.handleSelectAll,
                    selected: selected,
                    mode: 'checkbox',
                    clickToSelect: true,
                    selectionHeaderRenderer: (props) => {
                      return (
                        <RowSelectionHeaderItem
                          options={[
                            [
                              {
                                label: t('common.select_all'),
                                onClick: this.handleSelectAllData
                              },
                              {
                                label: t('common.unselect_all'),
                                onClick: this.handleUnselectAllData
                              }
                            ],
                            [
                              {
                                label: t('modals.filter_by_selection'),
                                onClick: this.handleSortBySelected
                              },
                              {
                                label: t('modals.filter_by_name'),
                                onClick: this.handleSortByLabel
                              }
                            ]
                          ]}
                          {...props}/>
                      )
                    },
                    selectionRenderer: (props) => <RowSelectionItem {...props}/>
                  }}
                  pagination={defaultPaginationFactory()}
                  {...props.baseProps}/>
              </div>
            )}
          </ToolkitProvider>}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.reject}>{t('common.close')}</Button>
          <Button color="success" onClick={this.resolve}>{t('common.valid')}</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ChooseOrganisationsItems;
