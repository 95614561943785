import React from 'react';
import {connect} from 'react-redux';
import {Parameters} from "../parameters";
import {fetchProgressPlan, editProgressPlan} from "../../../../reducers/progressPlan/monitoring";
import {ADD_ALERT} from "../../../../actions/ui";
import {getRoute, ProPla_Mon} from "../../../../constants/modules";
import {fetchNotifications} from "../../../../reducers/ui";
import {startPending, stopPending} from "../../../../actions/ui";
import moment from 'moment';
import {clientById} from "../../../../ormSelectors/client";
import {progressModelByClient} from "../../../../ormSelectors/progressModel";
import {progressPlanById} from "../../../../ormSelectors/progressPlan";
import {fetchClientOrganisation, fetchClientThemesTree} from "../../../../actions/orm/Client";
import {fetchClientAudits, fetchClientComplements} from "../../../../actions/orm/Client";
import {fetchClientAnalyses} from "../../../../actions/orm/Analyse";
import {createProgressZonesExploitationByProgressPlanIdSelector} from "../../../selectors/progressZoneExploitation";

const progressZonesExploitationSelector = createProgressZonesExploitationByProgressPlanIdSelector();

const mapStateToProps = (state) => {
  return {
    client: clientById(state.session.currentClientId),
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchNotifications: () => dispatch(fetchNotifications()),
    addAlert: alert => dispatch({type: ADD_ALERT, payload: alert}),
    fetchProgressPlan: id => dispatch(fetchProgressPlan(id)),
    editProgressPlan: (id, datas) => dispatch(editProgressPlan(id, datas)),
    startPending: () => dispatch(startPending()),
    stopPending: () => dispatch(stopPending()),
    dispatch: dispatch
  };
};

let _ParametersProps = {
  label : '',
  model: null,
  actions: [],
  models: [],
  constat: '',
  priority: '',
  startDate: '',
  preconisation: '',
  endDate: '',
  inCharges: [],
  zeIds: [],
  analyseId: null,
  vague: null,
  themes: {
    type: 'themes',
    objects: [],
  },
};

class ConnectedEdit extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      state: 1,
      progressPlan: null,
      models: [],
      parametersProps: _ParametersProps,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleStatusClick = this.handleStatusClick.bind(this);
  }

  async componentDidMount(){
    this.props.startPending();
    await Promise.all([
      this.props.fetchProgressPlan(this.props.match.params.id),
      this.props.dispatch(fetchClientOrganisation({client_id: this.props.client.id})),
      this.props.dispatch(fetchClientAudits({client_id: this.props.client.id})),
      this.props.dispatch(fetchClientComplements({client_id: this.props.client.id})),
      this.props.dispatch(fetchClientAnalyses({client_id: this.props.client.id})),
      this.props.dispatch(fetchClientThemesTree({client_id: this.props.client.id})),
    ]);
    this.formatParametersProps();
    this.props.stopPending();
  }

  async handleSubmit(datas){
    datas.status = this.state.state === 3 ? 1 : 0;
    await this.props.editProgressPlan(this.props.match.params.id, datas);
    this.props.addAlert({text: t('pp.the_pp') + this.state.progressPlan.label + t('pp.modified'), class: 'alert-success', icon: 'oi-check', title: ''});
    this.props.history.push(getRoute(ProPla_Mon));
  }

  handleStatusClick(){
    if(this.state.state === 3){
      const today = moment();

      if(this.state.progressPlan.end_date !== null && moment.unix(this.state.progressPlan.end_date).valueOf() < today.valueOf())
        this.setState({state: 2});
      else this.setState({state: 1});
    } else this.setState({state: 3});
  }

  updateProgresZonesExploitation = () => {
    const newParametersProps = this.state.parametersProps;

    newParametersProps.inCharges = progressZonesExploitationSelector({progressPlanId: this.state.progressPlan.id}).map(r => {
      let temp = Object.assign({}, r);
      temp.id = r.zone_exploitation_id;
      return temp;
    });

    this.setState({parametersProps: newParametersProps});
  };

  formatParametersProps = () => {
    let newState = {};

    newState.progressPlan = progressPlanById(this.props.match.params.id);

    newState.models = progressModelByClient(this.props.client);
    const model = newState.progressPlan.type_progre_id !== null ?
      newState.models[getIndexById(newState.progressPlan.type_progre_id.id, newState.models)] : null;

    const today = moment();
    if(newState.progressPlan.end_date !== null && moment.unix(newState.progressPlan.end_date).valueOf() < today.valueOf())
      newState.state = 2;
    if(newState.progressPlan.status === 1)
      newState.state = 3;

    if(!!newState.progressPlan) {
      newState.parametersProps = {
        label: newState.progressPlan.label,
        model: model,
        action: newState.progressPlan.actions.toModelArray(),
        models: newState.models,
        constat: newState.progressPlan.constat,
        preconisation: newState.progressPlan.preconisation,
        priority: newState.progressPlan.level,
        start_date: !!newState.progressPlan.start_date ? new Date(moment.unix(newState.progressPlan.start_date).toDate()) : '',
        end_date: !!newState.progressPlan.start_date ? new Date(moment.unix(newState.progressPlan.end_date).toDate()) : '',
        actions: newState.progressPlan.actions.toRefArray(),
        zeIds: newState.progressPlan.zone_exploitations.toRefArray().map(ze => ze.id),
        analyseId: newState.progressPlan.ref.analyse_id,
        vague: newState.progressPlan.vague,
        inCharges: newState.progressPlan.progressZoneExploitation.toRefArray().map(r => {
          let temp = Object.assign({}, r);
          temp.id = r.zone_exploitation_id;
          return temp;
        }),
      };
      if(newState.progressPlan.themes.toModelArray().length > 0)
        newState.parametersProps.themes = {
          type: 'themes',
          objects: newState.progressPlan.themes.toRefArray(),
        };
      else
        newState.parametersProps.themes = {
          type: 'questions',
          objects: newState.progressPlan.themes_questions.toModelArray().map(i => {
            return {
              ...i.question_id.ref,
              ...i.ref
            }
          }),
        };
    }

    this.setState(newState);
  };

  render(){
    return (
      <div className='page-inner'>
        <header className='page-title-bar'>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <a href='#' className='breadcrumb-link' onClick={this.props.history.goBack}>
                  <i className="breadcrumb-icon fa fa-arrow-circle-left mr-2"/>{t('common.browse')}
                </a>
              </li>
            </ol>
          </nav>
          <h1 className='page-title'>{t('pp.pp_edition')}</h1>
          <p className='text-muted'>{this.state.parametersProps.label}</p>
        </header>
        {!!this.state.progressPlan &&
        <Parameters
          {...this.state.parametersProps}
          hidden=''
          onSubmit={this.handleSubmit}
          defaultState={this.state.state}
          btnSubmitText={t('common.save')}
          btnSubmitClass={'btn btn-success'}
          progressPlan={this.state.progressPlan}
          handleStatus={() => this.handleStatusClick()}
          editProgresZones={this.updateProgresZonesExploitation}
          label_zone_exploitation={this.props.client.label_zone_exploitation}/>
        }
      </div>
    )
  }
}

const Edit = connect(mapStateToProps, mapDispatchToProps)(ConnectedEdit);

export default Edit;
