import React from "react";
import {connect} from "react-redux";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {auditsByClientId} from "../../ormSelectors/audit";
import {setEditAnalyseVagues} from "../../actions/modals";
import {getClientAnalysesSelector} from "../../ormSelectors/analyse";
import {Select2Wrapper} from "../common/Select2Wrapper";
import _ from "lodash";
import {reportById} from "../../ormSelectors/report";

@connect(
  ({session: {currentClientId}, modals: {editAnalyseVagues: {datas, analyse, currentVague, resolve, reject}}}) => ({
    datas,
    audits: auditsByClientId(currentClientId),
    analyses: getClientAnalysesSelector({client_id: currentClientId}),
    analyse,
    currentVague,
    resolve,
    reject
  })
)
class EditAnalyseVagues extends React.PureComponent {
  state = {
    closing: false,
    datas: [],
    datasVagues: {},
    selected: null,
    selectedVagues: {}
  };

  async componentDidMount(){
    const {datas, analyse, analyses, audits, currentVague} = this.props;
    let newDatas = [];
    let newDatasVagues = {};
    let newSelectedVagues = {};

    await datas.map((d, i) => {
      newDatas.push({
        ...d,
        text: d.label,
        id: d.vague,
        sources: d.sources,
        labelforsort: d.label.substring(3, d.label.length).concat(d.label.substring(0, 2)),
      });
    });
  
    await analyse.sources.map(s => {
      if (s.model === 'Audit') {
        newDatasVagues.audits = {
          ...newDatasVagues.audits,
          [s.id]: _.orderBy(audits.find(a => a.id === Number(s.id)).reports.toRefArray().map(r => ({
            ...r, 
            text: r.label,
            labelforsort: r.label.substring(3, r.label.length).concat(r.label.substring(0, 2)),})), ['labelforsort'], 'desc')
        };
        newDatas.map(d => {
          newSelectedVagues[d.id] = {...newSelectedVagues[d.id]};
          newSelectedVagues[d.id].audits = {
            ...newSelectedVagues[d.id].audits,
            [s.id]: []
          }
        });
      }
      else if (s.model === 'Analyse') {
        newDatasVagues.analyses = {
          ...newDatasVagues.analyses,
          [s.id]: _.orderBy(analyses.find(a => a.id === Number(s.id)).vagues.map(r => ({
            ...r, 
            text: r.label, 
            id: r.vague,
            labelforsort: r.label.substring(3, r.label.length).concat(r.label.substring(0, 2)),})), ['labelforsort'], 'desc')
        };
        newDatas.map(d => {
          newSelectedVagues[d.id] = {...newSelectedVagues[d.id]};
          newSelectedVagues[d.id].analyses = {
            ...newSelectedVagues[d.id].analyses,
            [s.id]: []
          }
        });
      }
    });

    await newDatas.map(d =>
      d.sources.map(s => {
        if (s.model === 'Report') {
          const report = reportById(s.id).ref;
          !!newSelectedVagues[d.vague].audits[report.audit_id] ?
            newSelectedVagues[d.vague].audits[report.audit_id].push(s.id) :
            newSelectedVagues[d.vague].audits[report.audit_id] = [s.id]
        }
        else if (s.model === 'AnalyseReport') {
          const analyse_id = s.source;
          !!newSelectedVagues[d.vague].analyses[analyse_id] ?
            newSelectedVagues[d.vague].analyses[analyse_id].push(s.id) :
            newSelectedVagues[d.vague].analyses[analyse_id] = [s.id]
        }
      })
    );

    this.setState({datas: newDatas, datasVagues: newDatasVagues, selectedVagues: newSelectedVagues});
    if (!!currentVague)
      this.selectVague(currentVague);
  }

  selectVague = (value) => {
    const n = this.state.datas.length + 1;

    if (!!value && value < n)
      this.setState({selected: value});
    else if (value === n) {
      const newDatas = this.state.datas.slice();
      let newSelectedVagues = {...this.state.selectedVagues};

      newDatas.push({
        id: n,
        text: n,
        vague: n,
        sources: []
      });
      this.props.analyse.sources.map(s => {
        if (s.model === 'Audit') {
          newSelectedVagues[n] = {...newSelectedVagues[n]};
          newSelectedVagues[n].audits = {
            ...newSelectedVagues[n].audits,
            [s.id]: []
          }
        }
        else if (s.model === 'Analyse') {
          newSelectedVagues[n] = {...newSelectedVagues[n]};
          newSelectedVagues[n].analyses = {
            ...newSelectedVagues[n].analyses,
            [s.id]: []
          }
        }
      });
      this.setState({selected: n, selectedVagues: newSelectedVagues, datas: newDatas});
    }
  };

  selectMultipleVagues = (e, {model, id, s}) => {
    let selectedVagues = this.state.selectedVagues;

    if (model === 'Audit')
      selectedVagues[this.state.selected].audits[id] = e.target.value;
    else if (model === 'Analyse')
      selectedVagues[this.state.selected].analyses[id] = e.target.value;
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setEditAnalyseVagues({
      open: false
    }));
  };

  resolve = () => {
    this.props.resolve({datas: this.state.selectedVagues, currentVague: this.state.selected});
    this.closing();
  };

  reject = () => {
    this.props.reject({currentVague: this.state.selected});
    this.closing();
  };

  render(){
    const {closing, selected,datasVagues, selectedVagues} = this.state;
    const {analyse, analyses, audits} = this.props;
    const datas = _.orderBy(this.state.datas,['labelforsort'],'desc')

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.reject}>{t('admin.analyse.setup_vagues')}</ModalHeader>
        <ModalBody>
          <div>
            <h5>{t('common.vague')}</h5>
            <Select2Wrapper
              name="selected"
              value={selected}
              sort={{disabled:true}}
              data={[...datas, {text: t('common.create_new_vague'), id: datas.length + 1}]}
              options={{placeholder: t('common.search')}}
              onChange={e => this.selectVague(e.target.value)}/>
            {!!selected && analyse.sources.filter(s =>
              s.model === 'Analyse' || s.model === 'Audit').map((s, i) =>
              <div className='mt-2' key={i}>
                <span>{s.model === 'Audit' ?
                  audits.find(a => a.id === Number(s.id)).label :
                  analyses.find(a => a.id === Number(s.id)).label}
                </span>
                <Select2Wrapper
                  multiple
                  sort={{disabled:true}}
                  name={`${s.model}${s.id}`}
                  options={{placeholder: t('common.search')}}
                  data={s.model === 'Audit' ? datasVagues.audits[s.id] : datasVagues.analyses[s.id]}
                  value={s.model === 'Audit' ? selectedVagues[selected].audits[s.id] :
                    selectedVagues[selected].analyses[s.id]}
                  onChange={e => this.selectMultipleVagues(e, {model: s.model, id: s.id, s})}/>
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.reject}>{t('common.close')}</Button>
          <Button color="success" onClick={this.resolve}>{t('common.valid')}</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export {EditAnalyseVagues};
