import orm from '../../orm';
import {createSelector} from 'redux-orm';

const dbStateSelector = state => state.db;

export const clientsSelector = createSelector(orm, dbStateSelector, state => state.session.clientsId,
    (orm, clientsId) => {
        let clients = [];

        orm.Client.all().toModelArray().forEach((client) => {
            if (clientsId.includes(client.id)) clients[client.id] = client;
        });

        return clients;
    }
);

export const userSelector = createSelector(orm, dbStateSelector, state => state.session.userId,
    (orm, userId) => {
        return (orm.User.idExists(userId) ? orm.User.withId(userId) : {});
    }
);

export const notificationsSelector = createSelector(orm, dbStateSelector, state => state.session.userId,
    (orm, userId) => {
        let notificationsReceived = window.cloneArrayofObjects(orm.Notification.all().filter(notification => (notification.user2_id === userId && notification.user2_id !== notification.user_id)).toRefArray());

        let notificationsSent = [];

        orm.Notification.all().filter(notification => (notification.user_id === userId && notification.user2_id === null)).toRefArray().forEach(notification => {
            let temp = Object.assign({}, notification);
            temp.read = 1; //always on status read when user is the sender
            notificationsSent.push(temp);
        });

        let notifications = notificationsReceived.concat(notificationsSent);

        notifications.sort((notif1, notif2) => notif1.created < notif2.created);

        return notifications;
    }
);
