import React from 'react';

export class CardSelect extends React.Component{
  render(){
    const radioInputs = this.props.radioInputs.map(radio => {
      return(
        <div key={radio.value} className="custom-control custom-radio"
             onClick={() => this.props.handleChange(radio.value)}>
          <input readOnly type="radio" className="custom-control-input"
                 value={radio.value}
                 checked={radio.value === this.props.value}/>
          <label className="custom-control-label d-flex justify-content-between">
            <span>{radio.label}</span>
          </label>
        </div>
      )
    });

    const menuPosition = this.props.menuPosition !== undefined ? this.props.menuPosition : 'sm';
    const arrowPosition = this.props.arrowPosition !== undefined ? this.props.arrowPosition : 'sm';
    const hideArrow = this.props.hideArrow;

    return(
      <div className='dropdown'>
        <button className='btn btn-light' data-toggle="dropdown" aria-expanded='false'>
          <span>{this.props.selectTitle}</span>
          <i className='fa fa-fw fa-caret-down'/>
        </button>
        {!hideArrow &&
        <div className={'dropdown-arrow dropdown-arrow-' + arrowPosition}/>}
        <div className={('dropdown-menu stop-propagation dropdown-menu-sm dropdown-menu-' + menuPosition)}
             style={{position: 'absolute', willChange: 'top, left', top: '36px', left: '-178px'}}>
          {radioInputs}
        </div>
      </div>
    );
  }
}