export const _Notification = 'Notification';
export const _Audit = 'Audit';
export const _Prestataire = 'Prestataire';
export const _Client = 'Client';
export const _ProgressPlan = 'ProgressPlan';
export const _ZoneExploitation = 'ZoneExploitation';
export const _User = 'User';
export const _Site = 'Site';
export const _TypeZoneExploitation = 'TypeZoneExploitation';
export const _Report = 'Report';
export const _Theme = 'Theme';
export const _Question = 'Question';
export const _Questionnaire = 'Questionnaire';
export const _Response = 'Response';
export const _ResponseUploads = 'ResponseUploads';
export const _ThemeQuestion = 'ThemeQuestion';
export const _ProgressModel = 'ProgressModel';
export const _ModelAction = 'ModelAction';
export const _Action = 'Action';
export const _Parameter = 'Parameter';
export const _ProgressClient = 'ProgressClient';
export const _ProgressZoneExploitation = 'ProgressZoneExploitation';
export const _ProgressSite = 'ProgressSite';
export const _Responsable = 'Responsable';
export const _Message = 'Message';
export const _Complement = 'Complement';
export const _Profile = 'Profile';
export const _Taxonomy = 'Taxonomy';
export const _Brand = 'Brand';
export const _Visit = "Visit";
export const _QuestionnaireSection = "QuestionnaireSection";
export const _RapportsProcessing = "RapportsProcessing";
export const _Firm = "Firm";
export const _Analyse = "Analyse";
