import {fk, many, attr, Model} from 'redux-orm';
import {_ProgressPlan, _Analyse, _Report, _User, _Client, _ProgressModel, _Site, _ZoneExploitation, _ThemeQuestion, _Theme, _Action, _Notification} from "../constants/orm";

export class ProgressPlan extends Model{
  static parse(data, orm) {

    let actions = [];

    if (!!data.actions)
      actions = data.actions.map(action => action.id);

    let dataParsed = {};

    if (orm.ProgressPlan.idExists(data.id)) { //update actions list
      const actionsLastVersion = window.cloneArrayofObjects(orm.Action.all().filter(a => a.progre_id === data.id).toRefArray());
      actionsLastVersion.forEach(function (action) {
        if (!actions.includes(action.id)) orm.Action.withId(action.id).delete();
      });

      const currentProgress = orm.ProgressPlan.withId(data.id);

      dataParsed = {
        ...data,
        themes: currentProgress.themes.toRefArray().map(theme => theme.id),
        themes_questions: currentProgress.themes_questions.toRefArray().map(theme_question => theme_question.id),
        zone_exploitations: !currentProgress.zone_exploitations.length ?
          data.zone_exploitations.map(ze => ze.id) : currentProgress.zone_exploitations.toRefArray().map(z => z.id),
        actions: actions
      };
    } else
      dataParsed = {
        ...data,
//        report_id: data.report.id,
        themes: data.themes.map(theme => theme.id),
        themes_questions: data.themes_questions.map(theme_question => theme_question.id),
        zone_exploitations: data.zone_exploitations.map(ze => ze.id),
        actions: actions
      };


    delete dataParsed.action;
    delete dataParsed.responsable_id;
    delete dataParsed.report;
    delete dataParsed.list_clients;
    delete dataParsed.sites;

    return dataParsed;
  }

  static get fields(){
    return {
      id: attr(),
//      report_id: fk(_Report, 'progressPlans'),
      analyse_id: fk(_Analyse, 'progressPlans'),
      vague: attr(),
      author_id: fk(_User, 'authorProgressPlan'),
      client_id: fk(_Client, 'progressPlan'),
      level: attr(),
      constat: attr(),
      status: attr(),
      label: attr(),
      progression: attr(),
      created: attr(),
      modified: attr(),
      start_date: attr(),
      end_date: attr(),
      zone_type: attr(),
      type_progre_id: fk(_ProgressModel),
      actions: many(_Action, 'progressPlans'),
      zone_exploitations: many(_ZoneExploitation),
      themes_questions: many(_ThemeQuestion, 'progressPlans'),
      themes: many(_Theme),
      notifications: many(_Notification)
    }
  }
}

ProgressPlan.modelName = _ProgressPlan;
