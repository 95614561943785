import api from "../../tools/api";
import {_Prestataire, _User} from "../../constants/orm";
import {hydrateEntities, updateEntities} from "../orm";
import {actionCallsTracker} from "../actionsTracker";

export const fetchPrestataires = () => {
  return async dispatch => {
    if (actionCallsTracker.isSaved('prestataires', 'client', 'all'))
      return;

    const prestataires = await api.prestataires.get();

    dispatch(hydrateEntities({
      payload: [
        {type: _Prestataire, entities: prestataires}
      ]
    }));

    actionCallsTracker.save('prestataires', 'client', 'all');
    return prestataires;
  }
};

export const updatePrestataire = ({id, label, code_postal, ville, pays, adresse, phone, path}) => {
  return async dispatch => {
    const prestataire = await api.prestataires.update({
      id,
      label,
      code_postal,
      ville,
      pays,
      adresse,
      phone,
      path
    });

    dispatch(updateEntities({
      payload: [
        {type: _Prestataire, entities: [prestataire]}
      ]
    }));
    return prestataire;
  }
};

export const fetchPrestataireUsers = ({prestataire_id}) => {
  return async dispatch => {
    const users = await api.prestataires.getUsers({prestataire_id});

    dispatch(hydrateEntities({
      payload: [
        {type: _User, entities: users}
      ]
    }));

    return users;
  }
};

export const fetchPrestataireUsersResponsables = ({prestataire_id}) => {
  return async dispatch => {
    const users = await api.prestataires.getUsersResponsables({prestataire_id});

    dispatch(hydrateEntities({
      payload: [
        {type: _User, entities: users}
      ]
    }));

    return users;
  }
};

export const addPrestataire = ({label, code_postal, ville, pays, adresse, phone, parent_id, path}) => {
  return async dispatch => {
    const prestataire = await api.prestataires.add({
      label,
      code_postal,
      ville,
      pays,
      adresse,
      phone,
      path,
      parent_id
    });

    dispatch(hydrateEntities({
      payload: [
        {type: _Prestataire, entities: [prestataire]}
      ]
    }));
    return prestataire;
  }
};