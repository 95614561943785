import React from "react";

const ProgressBarMulti = ({className, max, values, ...otherProps}) => {
  return (
    <div className={`progress ${!!className ? className : ''}`}
         {...otherProps}>
      {values.map((v, idx) => {
        const {content, value, color, tooltip, props} = v;
        const percent = value / max * 100;

        return (
          <div
            key={idx}
            style={{
              width: `${percent}%`,
              backgroundColor: color
            }}
            class="progress-bar"
            title={tooltip}
            aria-valuemin={0}
            aria-valuemax={max}
            aria-valuenow={value}
            role="progressbar"
            {...props}>
            {content}
          </div>
        )
      })}
    </div>
  )
};

export {ProgressBarMulti};
export default ProgressBarMulti;