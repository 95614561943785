import {api} from "../../tools/api";
import {hydrateEntities, updateEntities, deleteEntity} from "../orm";
import {_Question, _ThemeQuestion, _Theme} from "../../constants/orm";
import {actionCallsTracker} from "../actionsTracker";

export const addTheme = ({label, ranking, parent_id, client_id, generic}) => {
  return async dispatch => {
    const theme = await api.themes.add({label, ranking, parent_id, client_id, generic});

    dispatch(hydrateEntities({
      payload: [
        {type: _Theme, entities: [theme]}
      ]
    }));
    return theme;
  }
};

export const updateTheme = ({id, label, ranking, parent_id, client_id, generic}) => {
  return async dispatch => {
    const theme = await api.themes.update({id, label, ranking, parent_id, client_id, generic});

    dispatch(updateEntities({
      payload: [
        {type: _Theme, entities: [theme]}
      ]
    }));
    return theme;
  }
};

export const deleteTheme = ({id}) => {
  return async dispatch => {
    await api.themes.remove({id});

    dispatch(deleteEntity({
      type: _Theme,
      id
    }));
  }
};

export const copyThemeTree = ({client_id, themes_id, themes_questions_id}) => {
  return async dispatch => {
    const {themes, themes_questions, questions} = await api.themes.copyTree({
      client_id,
      themes: themes_id,
      themes_questions: themes_questions_id
    });

    dispatch(hydrateEntities({
      payload: [
        {type:_Theme, entities: themes},
        {type: _Question, entities: questions},
        {type: _ThemeQuestion, entities: themes_questions}
      ]
    }));
    return {
      themes,
      themes_questions,
      questions
    }
  }
};

export const fetchGenericThemes = () => {
  return async dispatch => {
    if (actionCallsTracker.isSaved('themes', 'client', 'generic'))
      return;

    const {themes, themes_questions, questions} = await api.themes.getGeneric();

    dispatch(hydrateEntities({
      payload: [
        {type:_Theme, entities: themes},
        {type: _Question, entities: questions},
        {type: _ThemeQuestion, entities: themes_questions}
      ]
    }));
    actionCallsTracker.save('themes', 'client', 'generic');
    return {
      themes,
      themes_questions,
      questions
    }
  }
};
