import React from "react";
import {connect} from "react-redux";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Input} from "reactstrap";
import {auditsByClientId} from "../../ormSelectors/audit";
import {setEditCoefficientInTree} from "../../actions/modals";
import {getClientAnalysesSelector} from "../../ormSelectors/analyse";

@connect(
  ({session: {currentClientId}, modals: {editCoefficientInTree: {data, resolve, reject}}}) => ({
    data,
    audits: auditsByClientId(currentClientId),
    analyses: getClientAnalysesSelector({client_id: currentClientId}),
    resolve,
    reject
  })
)
class EditCoefficientInTree extends React.PureComponent {
  state = {
    closing: false,
    data: []
  };

  componentDidMount(){
    const {data} = this.props;
    const newData = {
      ...data,
      coefficient: data.coefficient
    };

    this.setState({data: newData});
  }

  changeCoefficient = (e) => {
    this.state.data.coefficient = e.target.value;
    this.forceUpdate();
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setEditCoefficientInTree({
      open: false
    }));
  };

  resolve = () => {
    const data = this.state.data;
    if (data.coefficient < 0)
      data.coefficient = 0;

    this.props.resolve({data});
    this.closing();
  };

  reject = () => {
    this.props.reject();
    this.closing();
  };

  render(){
    const {closing, data} = this.state;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.reject}>{t('modals.manage_ponderation_coefficient')}</ModalHeader>
        <ModalBody>
          <div dangerouslySetInnerHTML={{__html: data.label}}/>
          <div key={data.id} className='w-100 mb-3'>
            <Input value={data.coefficient}
                   style={{maxWidth:155, float: 'right'}}
                   bsSize="sm"
                   min={0}
                   name='coefficient'
                   max={100}
                   type="number"
                   placeholder={t('common.coefficient')}
                   onChange={e => this.changeCoefficient(e)}/>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.reject}>{t('common.close')}</Button>
          <Button color="success" onClick={this.resolve}>{t('common.valid')}</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export {EditCoefficientInTree};
