import React from "react";
import {Line} from "react-chartjs-2";
import {createItemI2cFetch} from "../../../tools/Filters/updateFunctions";
import {ocmDarkBlue} from "../../../../constants/ui";
import {FRequest} from "@neqo34/filters";

class GlobalI2CSection extends React.PureComponent {
  updateFunc = createItemI2cFetch({
    by: 'vague',
    level: [1],
    withLabels: true
  });

  render(){
    return (
      <section className="card card-fluid m-0 flex-grow-1 d-flex flex-column">
        <header className="card-header d-flex">
          <span class="flex-grow-1">{t('reporting.global_results')}</span>
        </header>
        <div className="card-body d-flex flex-column" style={{height: 200}}>
          <FRequest
            dynamicParameters={[
              "clients",
              "analyses",
              "zones_exploitation",
              "prestataires"
            ]}
            updateFunc={this.updateFunc}>
            {(result, loading, error) => {
              if (loading && !result)
                return <div>{t('reporting.loading')}</div>;

              if (!result || !result.length)
                return <div class="text-center">{t('reporting.no_data')}</div>;

              const datas = result.map(vague => {
                return {
                  label: vague.label,
                  value: !!vague.value ? Math.round(vague.value) : 0
                }
              });

              const chartData = {
                labels: [],
                datasets:[{
                  data: [],
                  fill: false,
                  borderColor: ocmDarkBlue,
                  backgroundColor: ocmDarkBlue
                }]
              };

              datas.forEach(d => {
                chartData.labels.push(`${d.label}`);
                chartData.datasets[0].data.push(d.value);
              });

              const options = {
                tooltips: {
                  callbacks: {
                    label: (item, data) => {
                      return `${Labels.i2c} : ${data.datasets[item.datasetIndex].data[item.index].toFixed(0)}%`;
                    }
                  }
                },
                maintainAspectRatio: false,
                legend: {
                  display: false
                },
                scales: {
                  yAxes: [{
                    ticks: {
                      suggestedMax: 100,
                      beginAtZero: true
                    }
                  }]
                }
              };

              return (
                <Line
                  data={chartData}
                  options={options}
                  style={{height: '100%'}}/>
              )
            }}
          </FRequest>
        </div>
      </section>
    )
  }
}

export {GlobalI2CSection};
export default GlobalI2CSection;
