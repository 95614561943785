import createSelector from "../tools/createSelector";

export const getClientAnalysesSelector = createSelector(
  (orm, {client_id}) => {
    return orm.Analyse.all().filter(i => i.client_id === client_id).toModelArray().map(a => {
      return {
        ...a.ref,
        users: a.users.toRefArray().map(u => u.id)
      }
    })
  }
);

export const getAnalyseById = createSelector(
  (orm, {analyse_id}) => {
    return orm.Analyse.withId(analyse_id).ref
  }
);
