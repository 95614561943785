import React from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router';
import {NavLink} from 'react-router-dom';
import * as _Modules from "../../constants/modules";
import PerfectScrollbar from 'react-perfect-scrollbar';
import {VelocityTransitionGroup} from 'velocity-react';
import {setReportingView} from "../../actions/modals";
import {userById} from "../../ormSelectors/user";
import {setSideMenu} from "../../actions/ui";

const mapStateToProps = state => {
  return {
    rights: state.session.rights,
    hierarchicRights: state.session.hierarchicRights,
    currentClientId: state.session.currentClientId,
    clients: state.session.clients,
    showSideMenu: state.ui.showSideMenu
  };
};

@connect(({session, ui}) => ({
  userId: session.userId,
  showSideMenu: ui.showSideMenu,
  locale: !!userById(session.userId) ? userById(session.userId).locale : 'fr_FR'
}))
class ConnectedSidebar extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      openClass: [],
      currentViewKey: null,
      slideStyle: [],
      slideHeight: [],
      locale: {
        label: this.props.locale === 'fr_FR' ? t('language.french') :
          this.props.locale === 'en_US' ? t('language.english') : t('language.spanish'),
        value: this.props.locale
      }
    };

    this.handleToggleMenu = this.handleToggleMenu.bind(this);

    this.props.history.listen((location, action) => {
      const route = _Modules.getByRoute(location.pathname);
      if(route !== undefined) this.handleRouteChange(route.id);
    });
  }

  closeSideMenu = () => {
    const {showSideMenu} = this.props;

    if (!!showSideMenu)
      this.props.dispatch(setSideMenu(false));
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.rights.length > 0 && !nextProps.rights.equals(this.props.rights)){
      const route = _Modules.getByRoute(window.location.pathname);
      if(route !== undefined) this.handleRouteChange(route.id);
    }
  }

  handleRouteChange(key){
    let state = Object.assign({}, this.state);
    let parents = [];

    if(this.state.currentViewKey !== null){
      state.openClass[this.state.currentViewKey] = undefined; //unactive the current view

      parents = _Modules.getParents(_Modules.modules[this.state.currentViewKey]);
      parents.forEach((module) => {//close parents menu
        state.openClass[module.id] = undefined;
      });
    }

    state.currentViewKey = key;
    state.openClass[key] = 'has-active';

    parents = _Modules.getParents(_Modules.modules[key]);
    parents.forEach((module) => {
      state.openClass[module.id] = 'has-active has-open';
    });

    this.setState(state);
  }

  handleToggleMenu(event){
    const key = event.target.getAttribute('data-key');

    if(!_Modules.modules[key].isView){
      let state = Object.assign({}, this.state);

      //close all menu of same level
      const evenModules = _Modules.getDirectChilds(_Modules.getDirectParent(_Modules.modules[key]));

      evenModules.forEach(function(module){
        if(module.id !== key && state.openClass[module.id] !== undefined)
          state.openClass[module.id] = (state.openClass[module.id].includes('has-active') ? 'has-active' : undefined);
      });

      switch(state.openClass[key]){
        case 'has-open':
          state.openClass[key] = undefined;
          break;

        case 'has-active has-open':
          state.openClass[key] = 'has-active';
          break;

        case 'has-active':
          state.openClass[key] = 'has-active has-open';
          break;

        case undefined:
          state.openClass[key] = 'has-open';
          break;
      }

      this.setState(state);
    }
  }

  hierarchicRightsToHtml(branch){
    const icon = (_Modules.modules[branch.label].icon != '' ? <span data-key={branch.label} className={'menu-icon ' + _Modules.modules[branch.label].icon}/> : '');
    if(!_Modules.modules[branch.label].isView && branch.children.length != 0) { //explore branch's child only if it's not a view
      const childs = branch.children.sort((a, b) => _Modules.modules[a.label].name > _Modules.modules[b.label].name).map(module => this.hierarchicRightsToHtml(module));

      return (
        <li key={branch.label} data-key={branch.label}
            className={'menu-item has-child ' + (this.state.openClass[branch.label] === undefined || this.props.location.pathname === '/' ? '' : this.state.openClass[branch.label])}
            onClick={branch.label.split('_').length === 1 ? this.handleToggleMenu : () => null}>
          <a className='menu-link' data-key={branch.label}>
            {icon}
            <span className='menu-text' data-key={branch.label}>{_Modules.modules[branch.label].name}</span>
          </a>
          <VelocityTransitionGroup
            class='menu'
            enter={{
              animation: 'slideDown',
              duration: 150
            }}
            leave={{
              animation: 'slideUp',
              duration: 150
            }}>
            {this.state.openClass[branch.label] !== undefined &&
            <ul className='menu' style={this.state.slideStyle[branch.label]}>
              {childs}
            </ul>}
          </VelocityTransitionGroup>
        </li>
      );
    } else if(_Modules.modules[branch.label].isView) {
      return (
        <li key={branch.label} data-key={branch.label}
            className={'menu-item ' + (this.state.openClass[branch.label] === undefined || this.props.location.pathname === '/' ? '' : this.state.openClass[branch.label])}>
          <NavLink
            className='menu-link'
            onClick={this.closeSideMenu}
            data-key={branch.label}
            to={_Modules.getRoute(branch.label)}>
            {icon}
            {_Modules.modules[branch.label].name}
          </NavLink>
        </li>
      );
    }
  }

  openReporting = () => {
    this.props.dispatch(setReportingView({
      open: true
    }));
  };

  render(){
    const menus = this.props.hierarchicRights.map((branch) => this.hierarchicRightsToHtml(branch));

    return (
      <aside className={'app-aside app-aside-expand-md app-aside-light' + (this.props.showSideMenu ? ' show' : '')}>
        <div className='aside-content'>
          <header className='aside-header d-block d-md-none'>
          </header>
          <PerfectScrollbar className='aside-menu'>
            <nav id='stacked-menu' className='stacked-menu stacked-menu-has-collapsible'>
              <ul className='menu'>
                {/* <NavLink
                  to={"/activities"}
                  onClick={this.closeSideMenu}
                  activeClassName={'has-active'}
                  className="menu-item">
                  <span className="menu-link">
                    <span className="menu-icon oi oi-pulse"/>
                    {t('sidebar.activities')}
                  </span>
                </NavLink> */}
                {menus}
              </ul>
            </nav>
          </PerfectScrollbar>
        </div>
      </aside>
    );
  }
}

const Sidebar = withRouter(connect(mapStateToProps)(ConnectedSidebar));

export default Sidebar;
