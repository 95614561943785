import createSelector from "../tools/createSelector";
import {getOrm} from "../redux";

export const auditSelectorByReportId = (reportId) => {
  if (!getOrm().Report.idExists(reportId))
    return null;
  const report = getOrm().Report.withId(reportId).ref;
  if (!getOrm().Audit.idExists(report.audit_id))
    return null;
  return getOrm().Audit.withId(report.audit_id).ref;
};

export const auditById = (auditId) => {
  if(getOrm().Audit.idExists(auditId))
    return getOrm().Audit.withId(auditId);
  return null;
};

export const auditsByClientId = (clientId) => {
  return getOrm().Audit.all().filter(o => o.client_id === clientId).toModelArray();
};

export const auditsByVisits = createSelector(
  (orm, visits) => {
    let audits = [];
    console.log(visits);
    if(!!visits){
      visits.forEach(v => {
        if(getIndexById(v.report_id.audit_id.id, visits) === null){
          audits.push(v.report_id.audit_id); 
        }
      });
      return audits;
    } 
    return null;
  }
);
