import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {defaultPaginationFactory} from "../../../../constants/ui";
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import {DebounceSearchInput} from "../../../common/DebounceSearchInput";
import {EvolText, I2CText} from "../TextRenderers";
import {ColoredProgress} from "../../../common/ColoredProgress";
import {Red, Green, Orange} from "../utils";
import PropTypes from 'prop-types';
import {Link, NavLink} from "react-router-dom";
import {getRoute, Rep_MonSit} from "../../../../constants/modules";
import {Button} from "reactstrap";
import {writeToExcel} from "../../../../tools/helperFunctions";

const monoSiteUrl = getRoute(Rep_MonSit);

class EvolTable extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  getExportColumns = () => {
    const {progressBar, additionalColumns, passageLow, passageHigh, rapportButton, handleChange, dataName} = this.props;

    let columns = [];

    columns.push({
      dataField: 'label',
      text: !!dataName ? dataName : t('common.label')
    });

    if (!!additionalColumns)
      columns = columns.concat(additionalColumns);

    columns.push({
      dataField: 'currentValue',
      text: Labels.i2c,
      sort: true,
      align: 'center',
      headerAlign: 'center',
      formatter: (cell, row) => {
        return (row.currentValue !== null && row.currentValue !== undefined) ? Math.round(row.currentValue) : 'N/C';
      }
    });
    columns.push({
      dataField: 'evol',
      text: 'Evol.',
      sort: true,
      align: 'center',
      headerAlign: 'center',
      formatter: (cell, row) => {
        return (row.evol !== null && row.evol !== undefined) ? Math.round(row.evol) : 'N/C';
      }
    });

    return columns;
  };

  getColumns = () => {
    const {filters, progressBar, additionalColumns, passageLow, passageHigh, rapportButton, handleChange, dataName} = this.props;

    let columns = [];
    let searchParams;

    const analyseId = (!!filters && filters.analyses !== null && filters.analyses !== undefined) ? filters.analyses[0] : null;
    const vague = (!!filters && filters.vagues !== null && filters.vagues !== undefined) ? filters.vagues[0] : null;

    columns.push({
      dataField: 'label',
      text: !!dataName ? dataName : t('common.label'),
      sort: true,
      formatter: (col, row, rowIndex, formatExtraData) => {

        searchParams = new URLSearchParams();
        searchParams.append("zones_exploitation", row.id);
        if(analyseId) searchParams.append("analyses", formatExtraData.analyseId);
        if(vague) searchParams.append("vagues", formatExtraData.vague);

        if (row.type === 'zone_exploitation')
          return <Link style={{color: 'black'}} target="_blank" to={{
            pathname: monoSiteUrl,
            search: searchParams.toString()
          }}>{row.label}</Link>;
        return <><b>{!!row.numericValue && row.model === 'ThemeQuestion' ? `${row.numericValue} ` : ''}</b>{row.label}</>;
      },
      formatExtraData: {
        analyseId: analyseId,
        vague: vague
      }
    });

    if (!!additionalColumns)
      columns = columns.concat(additionalColumns);

    if (!!progressBar)
      columns.push({
        dataField: '',
        isDummyField: true,
        text: '',
        sort: false,
        formatter: (cell, row) => {
          if (row.currentValue === null)
            return null;
          return (
            <ColoredProgress
              max={100}
              colorLow={Red}
              colorHigh={Green}
              colorMiddle={Orange}
              passageLow={passageLow}
              passageHigh={passageHigh}
              value={row.currentValue === 0 ? 1 : row.currentValue}/>
          )
        }
      });
    columns.push({
      dataField: 'currentValue',
      text: Labels.i2c,
      sort: true,
      align: 'center',
      headerAlign: 'center',
      formatter: (cell, row) => <strong><I2CText passageLow={passageLow} passageHigh={passageHigh} value={row.currentValue}/></strong>,
      sortFunc: (a, b, order) => {
        function testNull(operation, sign) {
          if ((!a && a !== 0) && (!b && b !== 0))
            return 0;
          else if (!a && a !== 0)
            return -1 * sign;
          else if (!b && b !== 0)
            return 1 * sign;
          else
            return operation
        }

        if (order === 'asc')
          return testNull(a - b, 1);
        else
          return testNull(b - a, -1);
      }
    });
    columns.push({
      dataField: 'evol',
      text: 'Evol.',
      sort: true,
      align: 'center',
      headerAlign: 'center',
      formatter: (cell, row) => {
        if (!!row.hideEvol)
          return null;
        return (
          <strong><EvolText value={row.evol}/></strong>
        )
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        function testNull(operation, sign) {
          if (((!a && a !== 0) || rowA.hideEvol) && ((!b && b !== 0) || rowB.hideEvol))
            return 0;
          else if ((!a && a !== 0) || rowA.hideEvol)
            return -1 * sign;
          else if ((!b && b !== 0) || rowB.hideEvol)
            return 1 * sign;
          else
            return operation
        }

        if (order === 'asc')
          return testNull(a - b, 1);
        else
          return testNull(b - a, -1);
      }
    });
    if (!!rapportButton)
      columns.push({
        dataField: '',
        isDummyField: true,
        text: '',
        sort: false,
        formatter: (cell, row) => {
          return (
            <Button
              size="sm"
              color={'primary'}
              onClick={() => handleChange({value: row.id, label: row.label})}>
              Rapport
            </Button>
          )
        }
      });

    return columns;
  };

  handleExport = () => {
    const {exportProps: {name}, datas} = this.props;

    writeToExcel({
      name: `${name} ${moment().format('YYYY-MM-DD HH:mm:ss')}`,
      columns: this.getExportColumns(),
      data: _.sortBy(datas, 'label')
    })
  };

  render(){
    const {datas, search, disablePagination, tableProps, exportProps, size} = this.props;
    const columns = this.getColumns();
    const defaultSort = [(this.props.defaultSort) ? this.props.defaultSort : {dataField : 'label',order : 'asc' }];
    return (
      <div>
        <ToolkitProvider
          search
          keyField="id"
          columns={columns}
          bootstrap4={true}
          data={datas}>
          {props => (
            <div className="d-flex flex-column">
              {!!search &&
              <div class="mb-1 d-flex">
                <DebounceSearchInput
                  class={`flex-grow-1 ${!!size ? `input-group-${size}` : ''}`}
                  onChange={(e) => props.searchProps.onSearch(e.target.value)}/>
                {!!exportProps &&
                <Button
                  title="Exporter en excel"
                  class="ml-2"
                  size={size}
                  onClick={this.handleExport}
                  color="success">
                  <i className="far fa-file-excel"/>
                  &nbsp;
                  {t('common.export')}
                </Button>}
              </div>}
              <BootstrapTable
                defaultSorted = {defaultSort}
                bordered={false}
                wrapperClasses="flex-grow-1 overflow-table"
                pagination={!!disablePagination ? undefined : defaultPaginationFactory()}
                {...tableProps}
                {...props.baseProps}/>
            </div>
          )}
        </ToolkitProvider>
      </div>
    )
  }
}

EvolTable.propTypes = {
  progressBar: PropTypes.bool,
  search: PropTypes.bool,
  disablePagination: PropTypes.bool,
  datas: PropTypes.array
};

export {EvolTable};
export default EvolTable;
