import React from "react";
import Header from "./components/default/header";
import Sidebar from "./components/default/sidebar";
import MainContainer from "./components/main";
import {connect} from "react-redux";
import {ocmWebsocketClient} from "./ocmWebsocketClient";

@connect(({session: {currentClientId, websocketJWT}}) => ({
  currentClientId,
  websocketJWT
}))
class MainView extends React.PureComponent {
  state = {
    transition: false
  };

  componentDidUpdate(prevProps, prevState){
    if (this.props.currentClientId !== prevProps.currentClientId)
      this.setState({transition: true});
    else if (this.state.transition && this.props.currentClientId === prevProps.currentClientId)
      this.setState({transition: false});
  }

  componentDidMount(){
    const {websocketJWT} = this.props;

    //ocmWebsocketClient.init(websocketJWT);
  }

  componentWillUnmount(){
    //ocmWebsocketClient.close();
  }

  render(){
    const {transition} = this.state;

    return (
      <React.Fragment>
        <Header />
        <Sidebar />
        {!transition &&
        <MainContainer/>}
      </React.Fragment>
    )
  }
}

export {MainView};
export default MainView;
