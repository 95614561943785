import api from "../../tools/api";
import {_User, _Responsable} from "../../constants/orm";
import {hydrateEntities} from "../orm";
import _ from "lodash";
import {actionCallsTracker} from "../actionsTracker";

export const fetchClientResponsables = ({client_id}) => {
  return async dispatch => {
    if (actionCallsTracker.isSaved('responsables', 'client', client_id))
      return;
    const responsables = await api.client.getResponsables({client_id});
    const users = _.uniqBy(responsables.map(r => r.user), 'id');
    dispatch(hydrateEntities({
      payload: [
        {type: _User, entities: users},
        {type: _Responsable, entities: responsables}
      ]
    }));

    actionCallsTracker.save('responsables', 'client', client_id);
    return responsables;
  }
};

export const fetchAllClientResponsables = ({client_id}) => {
  return async dispatch => {
    const responsables = await api.client.getAllResponsables({client_id});

    dispatch(hydrateEntities({
      payload: [
        {type: _Responsable, entities: responsables}
      ]
    }));
    return responsables;
  }
};
