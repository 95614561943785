import React from "react";
import {connect} from "react-redux";
import {
  Form,
  FormGroup,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback
} from "reactstrap";
import {FormValidator} from "../../tools/formValidator/FormValidator";
import {setAddModifyPrestataire} from "../../actions/modals";
import {addPrestataire, updatePrestataire} from "../../actions/orm/Prestataire";
import createSelector from "../../tools/createSelector";
import LoadingButton from "../common/LoadingButton";
import {ImportFileIconPlaceholder} from "../common/ImagePlaceholders";
import {tFunction} from "../tools/Translation";

const localFv = new FormValidator([
  {
    field: 'label',
    method: 'isEmpty',
    validWhen: false,
    message: tFunction('modals.label_specified')
  }
]);

const prestataireSelector = createSelector(
  (orm, {prestataireId}) => {
    return orm.Prestataire.withId(prestataireId);
  }
);

@connect(({modals: {addModifyPrestataire: {currentPrestataireId}}}) => ({
  currentPrestataireId
}))
class AddModifyPrestataire extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {
      closing: false,
      loading: false,
      label: '',
      adresse: '',
      code_postal: '',
      ville: '',
      pays: '',
      phone: '',
      path: null,
      validation: localFv.valid()
    };

    if (this.props.currentPrestataireId){
      const prestataire = prestataireSelector({prestataireId: this.props.currentPrestataireId});

      this.state.label = prestataire.label;
      this.state.adresse = prestataire.adresse;
      this.state.code_postal = prestataire.code_postal;
      this.state.ville = prestataire.ville;
      this.state.pays = prestataire.pays;
      this.state.phone = prestataire.phone;
      this.state.path = prestataire.path;
    }
  }

  fileInputRef = React.createRef();

  inputLabel = React.createRef();

  componentDidMount() {
    this.inputLabel.focus();
  }

  onChange = (e) => {
    this.setState({[e.target.name] : e.target.value});
  };

  onLogoChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.setState({path: e.target.result});
    };
    reader.readAsDataURL(file);
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const {label, adresse, code_postal, ville, pays, phone, path} = this.state;

    const validations = localFv.validate({label});
    if (!validations.isValid){
      this.setState({validation: validations});
      return;
    }
    this.setState({loading: true});
    if (!this.props.currentPrestataireId)
      await this.props.dispatch(addPrestataire({
        label,
        adresse,
        code_postal,
        ville,
        pays,
        phone,
        path
      }));
    else
      await this.props.dispatch(updatePrestataire({
        id: this.props.currentPrestataireId,
        label,
        adresse,
        code_postal,
        ville,
        pays,
        phone,
        path
      }));
    this.setState({loading: false});
    this.closing();
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setAddModifyPrestataire({
      open: false
    }));
  };

  render(){
    const {
      validation,
      closing,
      loading,
      label,
      adresse,
      code_postal,
      ville,
      pays,
      phone,
      path
    } = this.state;
    const {currentPrestataireId} = this.props;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.closing}>
          {!!currentPrestataireId ? t('common.modify') : t('common.create')} {t('modals.a_presta')}
        </ModalHeader>
        <Form onSubmit={this.onSubmit}>
          <ModalBody>
            <FormGroup class="text-center">
              <ImportFileIconPlaceholder
                onClick={() => this.fileInputRef.current.click()}
                src={path}/>
              <input
                hidden
                ref={this.fileInputRef}
                type="file"
                name="path"
                accept="image/*"
                onChange={this.onLogoChange}
                className="custom-file-input"/>
            </FormGroup>
            <FormGroup>
              <Label>
                {t('common.label')}
              </Label>
              <Input
                name="label"
                innerRef={(input) => (this.inputLabel = input)}
                invalid={validation.label.isInvalid}
                placeholder={t('common.label')}
                onChange={this.onChange}
                value={label}/>
              <FormFeedback>
                {validation.label.message}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label>
                {t('common.address')}
              </Label>
              <Input
                onChange={this.onChange}
                value={adresse}
                name="adresse"
                placeholder={t('common.address')}/>
            </FormGroup>
            <FormGroup>
              <Label>
                {t('modals.postal')}
              </Label>
              <Input
                onChange={this.onChange}
                value={code_postal}
                name="code_postal"
                placeholder={t('modals.postal')}/>
            </FormGroup>
            <FormGroup>
              <Label>
                {t('modals.town')}
              </Label>
              <Input
                onChange={this.onChange}
                value={ville}
                name="ville"
                placeholder={t('modals.town')}/>
            </FormGroup>
            <FormGroup>
              <Label>
                {t('modals.country')}
              </Label>
              <Input
                onChange={this.onChange}
                value={pays}
                name="pays"
                placeholder={t('modals.country')}/>
            </FormGroup>
            <FormGroup>
              <Label>
                {t('modals.phone')}
              </Label>
              <Input
                onChange={this.onChange}
                value={phone}
                name="phone"
                placeholder={t('modals.phone')}/>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              type='button'
              disabled={loading}
              onClick={this.closing}>
              {t('common.close')}
            </Button>
            <LoadingButton
              loading={loading}
              disabled={loading}
              color="success">
              {t('common.valid')}
            </LoadingButton>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export {AddModifyPrestataire};
export default AddModifyPrestataire;
