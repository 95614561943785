import api from "../../tools/api";
import {_ModelAction} from "../../constants/orm";
import {deleteEntity, hydrateEntities, updateEntities} from "../orm";

export const removeModelAction = ({id}) => {
  return dispatch => {
    return api.modelActions.remove({
      id
    }).then(r => {
      dispatch(deleteEntity({
        type: _ModelAction,
        id
      }));
      return r;
    });
  }
};

export const addModelAction = ({modelId, label, date}) => {
  return dispatch => {
    return api.modelActions.add({
      modelId,
      label,
      date
    }).then(r => {
      dispatch(hydrateEntities({
        payload: [
          {type: _ModelAction, entities: [r]}
        ]
      }));
    });
  }
};

export const updateModelAction = ({id, label}) => {
  return async dispatch => {

    const actions = await api.modelActions.update({id, label});

    dispatch(updateEntities({
      payload: [{type: _ModelAction, entities: [actions]}]
    }));

    return actions;
  }
};