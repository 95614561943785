import createSelector from "../tools/createSelector";
import {getOrm} from "../redux";
import {sitesByClients} from "./site";
import {responsesByThemeQuestionsandZesandReport, responsesByThemesandZesandReport} from "./response";
import {themeById} from "./theme";
import {responsesByZe} from "./response";

export const zoneExploitationsByClients = createSelector(
  (orm, clients) => {
    if(clients !== null){
      const sites = sitesByClients(clients).toModelArray();
      return zoneExploitationsBySites(sites);
    }
    return null;
  }
);

export const zoneExploitationsBySites = createSelector(
  (orm, sites) => {
    if(sites !== null) return orm.ZoneExploitation.all().filter(ze => getIndexById(ze.site_id, sites) !== null);
    return null;
  }
);

export const zoneExploitationsByPrestataires = createSelector(
  (orm, prestataires) => {
    if(prestataires !== null){
      let zes = [];
      prestataires.forEach(prestataire => zes = zes.concat(prestataire.zoneExploitations.toModelArray()));
      return zes;
    }
    return null;
  }
);

/**
 * filter zes by models
 * u can pass in models list of zoneExploitations
 * to filter
 */
export const zoneExploitationsBy = createSelector(
  (orm, models) => {
    const {prestataires, audits, taxonomies} = models;
    const zes = models.hasOwnProperty('zoneExploitations') ?
      models.zoneExploitations : orm.ZoneExploitation.all().toModelArray();
    const _Operator = Object.freeze({mono: 0, multi: 1});

    if(prestataires !== null || audits !== null || taxonomies !== null) {
      return zes.filter(ze => {
        let matched = false;
        let operator = _Operator.mono;

        for(let key in models){
          if(key !== 'zoneExploitations'){

            matched = false;
            const objects = models[key];
            let idsToTest = [];

            switch(key){
              case 'prestataires':
                idsToTest = ze.prestataires.toModelArray().map(p => p.id);
                break;

              case 'audits':
                idsToTest = ze.audits.toModelArray().map(a => a .id);
                break;

              case 'taxonomies':
                idsToTest = ze.taxonomies.toModelArray().map(t => t.id);
                operator = _Operator.multi;
                matched = true;
                break;
            }

            switch(operator){
              case _Operator.multi:
                objects.some(taxonomies => {
                  let interMatched = false;
                  if(taxonomies.length === 0)
                    interMatched = true;
                  taxonomies.some((taxonomy) => {
                    if(idsToTest.includes(taxonomy.id)){
                      interMatched = true;
                      return true;
                    }
                  });

                  if(!interMatched){
                    matched = false;
                    return true;
                  }

                });
                break;

              case _Operator.mono:
                objects.some((object) => {
                  if(idsToTest.length > 0 && idsToTest.includes(object.id)){
                    matched = true;
                    return true;
                  }
                });
                break;
            }

            if(!matched)
              break;
          }
        }

        return matched;
      });
    }

    return null;
  }
);

export const zoneExploitationsByAudits = createSelector(
  (orm, audits) => {
    if(audits !== null){
      let zes = [];
      audits.forEach(audit => zes = zes.concat(audit.zone_exploitations.toModelArray()));
      return zes;
    }
    return null;
  }
);

export const zoneExploitationsByReport = createSelector(
  (orm, report) => {
    if(report !== null){
      return report.audit_id.zone_exploitations.toModelArray();
    }
    return null;
  }
);

export const zoneExploitationsWithResponsesByReport = createSelector(
  (orm, report) => {
    if(report !== null)
      return zoneExploitationsByReport(report).filter(ze => responsesByZe(ze).length > 0);
    return null;
  }
);

/**
 * return for each ze a tree of theme + value
 * the tree's root is the theme in parameter
 */
export const i2cByReportandZesandThemes = createSelector(
  (orm, {zes, report, themes}) => {
    if(!!zes && !!report && themes.length > 0){
      const responses = responsesByThemesandZesandReport({zoneExploitations: zes, themes: themes, report: report});
      let zesCpy = {};

      zes.forEach(ze => {
        zesCpy[ze.id] = Object.assign({value: null, count: 0}, ze.ref);
        zesCpy[ze.id].themes = {};
        zesCpy[ze.id].inputThemes = {};
        themes.forEach(t =>
          zesCpy[ze.id].inputThemes[t.id] = Object.assign({children: [], isFound: false, value: null, count: 0}, t.ref));
      });

      //create ze tables with themes value inside
      responses.forEach(r => {
        const rTheme = r.questionnaire_id.themes_question_id.theme_id;
        let zeCpy = zesCpy[r.zone_exploitation_id.id];

        if(isInputTheme(zeCpy, rTheme.id) && !isInputThemeFound(zeCpy, rTheme.id))
          zeCpy.inputThemes[rTheme.id].isFound = true;
        if(!isInputThemeFound(zeCpy, rTheme.id) && !zeCpy.themes.hasOwnProperty(rTheme.id))
          zeCpy.themes[rTheme.id] = Object.assign({value: null, count: 0}, rTheme.ref);


        if(r.value !== null) {
          let theme = null;
          if(isInputThemeFound(zeCpy, rTheme.id))
            theme = zeCpy.inputThemes[rTheme.id];
          else
            theme = zeCpy.themes[rTheme.id];

          theme.value = theme.value !== null ?
            theme.value + r.value : r.value;
          theme.count ++;
        }
      });

      for(let key in zesCpy){
        let ze = zesCpy[key];
        for(let themeKey in ze.inputThemes){
          let theme = ze.inputThemes[themeKey];
          if(theme.isFound){
            const value = theme.value;
            if(value !== null){
              theme.value = Math.round(theme.value / theme.count);
              if(ze.value !== null)
                ze.value += theme.value;
              else
                ze.value = theme.value;
              ze.count ++;
            }
          }
        }

        let inputThemesFound = 0;
        for(let key in ze.inputThemes)
          if(ze.inputThemes[key].isFound)
            inputThemesFound ++;

        if(Object.keys(ze.inputThemes).length > inputThemesFound)
          recursive(ze);

        delete ze.themes;
        if(ze.value !== null){
          ze.value = Math.round(ze.value/ze.count);
          ze.valueDisplayed = ze.value + '%';
        } else
          ze.valueDisplayed = 'NA';
      }

      return zesCpy;
    }
    return null;

    function recursive(ze){
      let parentThemes = {};
      let themeIdsFound = [];

      for(let key in ze.themes){
        const theme = ze.themes[key];
        if(!!theme.value)
          theme.value = Math.round(theme.value/theme.count);

        if(isInputTheme(ze, theme.parent_id) && !isInputThemeFound(ze, theme.parent_id)) {
          ze.inputThemes[theme.parent_id].isFound = true;
          themeIdsFound.push(theme.parent_id);
        }

        if(isInputThemeFound(ze, theme.parent_id) && !parentThemes.hasOwnProperty(theme.parent_id)){
          parentThemes[theme.parent_id] = {
            children: [],
            value: null,
            count: 0,
            ...Object.assign({}, themeById(theme.parent_id).ref)};
        }

        const parentToUpdate = isInputTheme(ze, theme.parent_id) ?
          ze.inputThemes[theme.parent_id] : parentThemes[theme.parent_id];

        parentToUpdate.children.push(theme);
        if(theme.value !== null){
          parentToUpdate.value = parentToUpdate.value !== null ?
            parentToUpdate.value + theme.value : theme.value;
          parentToUpdate.count ++;
        }
      }

      themeIdsFound.forEach(id => {
        if(ze.inputThemes[id].value !== null){
          ze.inputThemes[id].value = Math.round(ze.inputThemes[id].value / ze.inputThemes[id].count);

          if(ze.value !== null)
            ze.value += ze.inputThemes[id].value;
          else
            ze.value = ze.inputThemes[id].value;
          ze.count ++;
        }
      });

      let inputThemesFound = 0;
      for(let key in ze.inputThemes)
        if(ze.inputThemes[key].isFound)
          inputThemesFound ++;

      if(!isEmpty(parentThemes) && inputThemesFound !== Object.keys(ze.inputThemes).length){
        ze.themes = parentThemes;
        recursive(ze);
      }
    }

    function isInputTheme(ze, id){
      return ze.inputThemes.hasOwnProperty(id);
    }

    function isInputThemeFound(ze, id){
      return ze.inputThemes.hasOwnProperty(id) && ze.inputThemes[id].isFound;
    }
  }
);

export const i2cByReportandZesandThemeQuestions = createSelector(
  (orm, {zes, report, themeQuestions}) => {
    if(!!zes && !!report){
      const responses = responsesByThemeQuestionsandZesandReport({zoneExploitations: zes, themeQuestions: themeQuestions, report: report});
      let zesCpy = {};

      zes.forEach(ze => {
        zesCpy[ze.id] = Object.assign({value: null, count: 0}, ze.ref);
        zesCpy[ze.id].questions = {};
        themeQuestions.forEach(tq =>
          zesCpy[ze.id].questions[tq.question_id.id] = Object.assign({responses: {}, value: null, count: 0}, tq.question_id.ref));
      });

      //create ze tables with themes value inside
      responses.forEach(r => {
        let zeCpy = zesCpy[r.zone_exploitation_id.id];
        let question = zeCpy.questions[r.questionnaire_id.themes_question_id.question_id.id];
        question.responses[r.id] = Object.assign({}, r.ref);

        if(r.value !== null) {
          question.value = question.value !== null ?
            question.value + r.value : r.value;
          question.count ++;
        }
      });

      for(let key in zesCpy){
        let ze = zesCpy[key];
        for(let questionKey in ze.questions){
          let question = ze.questions[questionKey];
          if(question.value !== null){
            question.value = Math.round(question.value / question.count);
            if(ze.value !== null)
              ze.value += question.value;
            else
              ze.value = question.value;
            ze.count ++;
          }
        }
        if(ze.value !== null){
          ze.value = Math.round(ze.value/ze.count);
          ze.valueDisplayed = ze.value + '%';
        } else
          ze.valueDisplayed = "NA";
      }

      return zesCpy;
    }
    return null;
  }
);

export const zoneExploitationsByIds = createSelector(
  (orm, zesId) => {
    if(!!zesId)
      return zesId.map(id => zoneExploitationById(id));
    return null;
  }
);

export const zoneExploitationById = (zeId) => {
  return getOrm().ZoneExploitation.idExists(zeId) ? getOrm().ZoneExploitation.withId(zeId) : null;
};