import {api} from "../../tools/api";
import {_Brand} from "../../constants/orm";
import {deleteEntity, updateEntities, HYDRATE_ENTITIES, hydrateEntities} from "../orm";
import {actionCallsTracker} from "../actionsTracker";

export const fetchAllBrands = () => {
  return async dispatch => {
    if (actionCallsTracker.isSaved('brands', 'client', 'all'))
      return;

    const brands = await api.brands.getAll();

    dispatch(hydrateEntities({
      payload: [
        {type: _Brand, entities: brands}
      ]
    }));
    actionCallsTracker.save('brands', 'client', 'all');
    return brands;
  }
};

export const addBrand = ({label, path}) => {
  return async dispatch => {
    const brand = await api.brands.add({
      label,
      path
    });

    dispatch(hydrateEntities({
      payload: [
        {type: _Brand, entities: [brand]}
      ]
    }));

    return brand;
  }
};

export const updateBrand = ({id, label, path}) => {
  return async dispatch => {
    const brand = await api.brands.update({
      id,
      label,
      path
    });

    dispatch(updateEntities({
      payload: [
        {type: _Brand, entities: [brand]}
      ]
    }));

    return brand;
  }
};

export const removeBrand = ({id}) => {
  return async dispatch => {
    await api.brands.remove({id});

    dispatch(deleteEntity({
      type: _Brand,
      id
    }));
  }
};
