import React, {lazy, Suspense} from "react";
import {connect} from "react-redux";
import ConstatReadable from "./ConstatReadable";
import ConstatSet from './constatSet';
import ProgressPlanModelCreate from "./ProgressPlanModelCreate";
import ProgressPlanModelDelete from "./ProgressPlanModelDelete";
import ProgressPlanModelEdit from "./ProgressPlanModelEdit";
import ChooseQuestionnaireItems from "./ChooseQuestionnaireItems";
import ChooseOrganisationsItems from "./ChooseOrganisationsItems";
import SetupNewZoneExploitationObject from "./SetupNewZoneExploitationObject";
import {SetupNewClientObject} from "./SetupNewClientObject";
import {SetupNewSiteObject} from "./SetupNewSiteObject";
import {SetupNewPrestataireObject} from "./SetupNewPrestataireObject";
import {SetupModifiedPrestataireObject} from "./SetupModifiedPrestataireObject";
import {SetupModifiedSiteObject} from "./SetupModifiedSiteObject";
import {ModifyClientPrestataires} from "./ModifyClientPrestataires";
import {RemoveClientPrestataire} from "./RemoveClientPrestataire";
import {ModifyPrestataireClients} from "./ModifyPrestataireClients";
import {AddModifyClient} from "./AddModifyClient";
import {AddModifyPrestataire} from "./AddModifyPrestataire";
import {AddProfile} from "./AddProfile";
import {AddModifyUser} from "./AddModifyUser";
import {CopyRootClientsProgressModels} from "./CopyRootClientsProgressModels";
import {ChangeUsersProfile} from "./ChangeUsersProfile";
import {ChangeUsersActiveState} from "./ChangeUsersActiveState";
import {AddNewTaxonomy} from "./AddNewTaxonomy";
import {GenericConfirmation} from "./GenericConfirmation";
import {TaxonomyItemSelection} from "./TaxonomyItemSelection";
import {TaxonomyItemsFilterSelect} from "./TaxonomyItemsFilterSelect";
import {ViewImage} from "./ViewImage";
import {AddModifyBrand} from "./AddModifyBrand";
import {RemoveBrand} from "./RemoveBrand";
import {ZoneExploitationsSurveyInfo} from "./ZoneExploitationsSurveyInfo";
import {ZoneExploitationsSurveyResponse} from "./ZoneExploitationsSurveyResponse";
import {AddModifyFirm} from "./AddModifyFirm";
import {ChooseZonesExploitationItems} from "./ChooseZonesExploitationItems";
import {ReportZonesExploitationOverview} from "./ReportZonesExploitationOverview";
import {VisitsDateChange} from "./VisitsDateChange";
import {VisitsAuditorChange} from "./VisitsAuditorChange";
import {DateRangePicker} from "./DateRangePicker";
import {ReportingView} from "./ReportingView";
import {WriteText} from "./WriteText";
import {WriteEditorText} from "./WriteEditorText";
import {UploadFile} from "./UploadFile";
import {AddModifyQuestion} from "./AddModifyQuestion";
import {InputModal} from "./InputModal";
import {ChooseQuestion} from "./ChooseQuestion";
import {SetupAuditObject} from "./SetupAuditObject";
import {ChooseClientAudit} from "./ChooseClientAudit";
import {ModifyAuditUsers} from "./ModifyAuditUsers";
import {RemoveAnalyse} from "./RemoveAnalyse";
import {EditCoefficient} from "./EditCoefficient";
import {EditCoefficientInTree} from "./EditCoefficientInTree";
import {EditAnalyseVagues} from "./EditAnalyseVagues";
import {modals} from "./modalsDescriber";
//const DateRangePicker = lazy(() => import('./DateRangePicker'));



@connect(({modals}) => ({
  constatSet: modals.constatSet.open,
  progressPlanModelCreate: modals.progressPlanModelCreate.open,
  progressPlanModelDelete: modals.progressPlanModelDelete.open,
  progressPlanModelEdit: modals.progressPlanModelEdit.open,
  constatReadable: modals.constatReadable.open,
  chooseQuestionnaireItems: modals.chooseQuestionnaireItems.open,
  chooseOrganisationsItems: modals.chooseOrganisationsItems.open,
  setupNewZoneExploitationObject: modals.setupNewZoneExploitationObject.open,
  setupNewClientObject: modals.setupNewClientObject.open,
  setupNewSiteObject: modals.setupNewSiteObject.open,
  setupNewPrestataireObject: modals.setupNewPrestataireObject.open,
  setupModifiedPrestataireObject: modals.setupModifiedPrestataireObject.open,
  setupModifiedSiteObject: modals.setupModifiedSiteObject.open,
  modifyClientPrestataires: modals.modifyClientPrestataires.open,
  removeClientPrestataire: modals.removeClientPrestataire.open,
  modifyPrestataireClients: modals.modifyPrestataireClients.open,
  addModifyClient: modals.addModifyClient.open,
  addModifyPrestataire: modals.addModifyPrestataire.open,
  addProfile: modals.addProfile.open,
  addModifyUser: modals.addModifyUser.open,
  copyRootClientsProgressModels: modals.copyRootClientsProgressModels.open,
  changeUsersProfile: modals.changeUsersProfile.open,
  changeUsersActiveState: modals.changeUsersActiveState.open,
  addNewTaxonomy: modals.addNewTaxonomy.open,
  genericConfirmation: modals.genericConfirmation.open,
  taxonomyItemSelection: modals.taxonomyItemSelection.open,
  taxonomyItemsFilterSelect: modals.taxonomyItemsFilterSelect.open,
  viewImage: modals.viewImage.open,
  addModifyBrand: modals.addModifyBrand.open,
  removeBrand: modals.removeBrand.open,
  zoneExploitationsSurveyInfo: modals.zoneExploitationsSurveyInfo.open,
  zoneExploitationsSurveyResponse: modals.zoneExploitationsSurveyResponse.open,
  addModifyFirm: modals.addModifyFirm.open,
  chooseZonesExploitationItems: modals.chooseZonesExploitationItems.open,
  reportZonesExploitationOverview: modals.reportZonesExploitationOverview.open,
  visitsDateChange: modals.visitsDateChange.open,
  visitsAuditorChange: modals.visitsAuditorChange.open,
  dateRangePicker: modals.dateRangePicker.open,
  reportingView: modals.reportingView.open,
  writeText: modals.writeText.open,
  writeEditorText: modals.writeEditorText.open,
  uploadFile: modals.uploadFile.open,
  addModifyQuestion: modals.addModifyQuestion.open,
  inputModal: modals.inputModal.open,
  chooseQuestion: modals.chooseQuestion.open,
  setupAuditObject: modals.setupAuditObject.open,
  chooseClientAudit: modals.chooseClientAudit.open,
  modifyAuditUsers: modals.modifyAuditUsers.open,
  removeAnalyse: modals.removeAnalyse.open,
  editCoefficient: modals.editCoefficient.open,
  editCoefficientInTree: modals.editCoefficientInTree.open,
  editAnalyseVagues: modals.editAnalyseVagues.open
}))
export default class ModalsContainer extends React.PureComponent {
  render(){
    const {
      constatSet,
      progressPlanModelCreate,
      progressPlanModelEdit,
      progressPlanModelDelete,
      constatReadable,
      chooseQuestionnaireItems,
      chooseOrganisationsItems,
      setupNewZoneExploitationObject,
      setupNewClientObject,
      setupNewSiteObject,
      setupNewPrestataireObject,
      setupModifiedPrestataireObject,
      setupModifiedSiteObject,
      modifyClientPrestataires,
      removeClientPrestataire,
      modifyPrestataireClients,
      addModifyClient,
      addModifyPrestataire,
      addProfile,
      addModifyUser,
      copyRootClientsProgressModels,
      changeUsersProfile,
      changeUsersActiveState,
      addNewTaxonomy,
      genericConfirmation,
      taxonomyItemSelection,
      taxonomyItemsFilterSelect,
      viewImage,
      addModifyBrand,
      removeBrand,
      zoneExploitationsSurveyInfo,
      zoneExploitationsSurveyResponse,
      addModifyFirm,
      chooseZonesExploitationItems,
      reportZonesExploitationOverview,
      visitsDateChange,
      visitsAuditorChange,
      dateRangePicker,
      reportingView,
      writeText,
      writeEditorText,
      uploadFile,
      addModifyQuestion,
      inputModal,
      chooseQuestion,
      setupAuditObject,
      chooseClientAudit,
      modifyAuditUsers,
      removeAnalyse,
      editCoefficient,
      editCoefficientInTree,
      editAnalyseVagues
    } = this.props;

    return (
      <React.Fragment>
        {constatSet &&
        <ConstatSet/>}
        {constatReadable &&
        <ConstatReadable/>}
        {progressPlanModelCreate &&
        <ProgressPlanModelCreate/>}
        {progressPlanModelDelete &&
        <ProgressPlanModelDelete/>}
        {progressPlanModelEdit &&
        <ProgressPlanModelEdit/>}
        {chooseQuestionnaireItems &&
        <ChooseQuestionnaireItems/>}
        {chooseOrganisationsItems &&
        <ChooseOrganisationsItems/>}
        {setupNewZoneExploitationObject &&
        <SetupNewZoneExploitationObject/>}
        {setupNewClientObject &&
        <SetupNewClientObject/>}
        {setupNewSiteObject &&
        <SetupNewSiteObject/>}
        {setupNewPrestataireObject &&
        <SetupNewPrestataireObject/>}
        {setupModifiedPrestataireObject &&
        <SetupModifiedPrestataireObject/>}
        {setupModifiedSiteObject &&
        <SetupModifiedSiteObject/>}
        {modifyClientPrestataires &&
        <ModifyClientPrestataires/>}
        {removeClientPrestataire &&
        <RemoveClientPrestataire/>}
        {modifyPrestataireClients &&
        <ModifyPrestataireClients/>}
        {addModifyClient &&
        <AddModifyClient/>}
        {addModifyPrestataire &&
        <AddModifyPrestataire/>}
        {addProfile &&
        <AddProfile/>}
        {addModifyUser &&
        <AddModifyUser/>}
        {copyRootClientsProgressModels &&
        <CopyRootClientsProgressModels/>}
        {changeUsersProfile &&
        <ChangeUsersProfile/>}
        {changeUsersActiveState &&
        <ChangeUsersActiveState/>}
        {addNewTaxonomy &&
        <AddNewTaxonomy/>}
        {genericConfirmation &&
        <GenericConfirmation/>}
        {taxonomyItemSelection &&
        <TaxonomyItemSelection/>}
        {taxonomyItemsFilterSelect &&
        <TaxonomyItemsFilterSelect/>}
        {addModifyBrand &&
        <AddModifyBrand/>}
        {removeBrand &&
        <RemoveBrand/>}
        {zoneExploitationsSurveyInfo &&
        <ZoneExploitationsSurveyInfo/>}
        {zoneExploitationsSurveyResponse &&
        <ZoneExploitationsSurveyResponse/>}
        {addModifyFirm &&
        <AddModifyFirm/>}
        {viewImage &&
        <ViewImage/>}
        {chooseZonesExploitationItems &&
        <ChooseZonesExploitationItems/>}
        {reportZonesExploitationOverview &&
        <ReportZonesExploitationOverview/>}
        {visitsDateChange &&
        <VisitsDateChange/>}
        {visitsAuditorChange &&
        <VisitsAuditorChange/>}
        {dateRangePicker &&
        <DateRangePicker/>}
        {reportingView &&
        <ReportingView/>}
        {writeText &&
        <WriteText/>}
        {writeEditorText &&
        <WriteEditorText/>}
        {uploadFile &&
        <UploadFile/>}
        {addModifyQuestion &&
        <AddModifyQuestion/>}
        {inputModal &&
        <InputModal/>}
        {chooseQuestion &&
        <ChooseQuestion/>}
        {setupAuditObject &&
        <SetupAuditObject/>}
        {chooseClientAudit &&
        <ChooseClientAudit/>}
        {modifyAuditUsers &&
        <ModifyAuditUsers/>}
        {removeAnalyse &&
        <RemoveAnalyse/>}
        {editCoefficient &&
        <EditCoefficient/>}
        {editCoefficientInTree &&
        <EditCoefficientInTree/>}
        {editAnalyseVagues &&
        <EditAnalyseVagues/>}
      </React.Fragment>
    )
  }
}
