export const _progressPlanState = {
  new: 0,
  started: 1,
  finished: 2
};

export const _forepaasI2cDataFetchName = {
  prestataire: 'valueByPrestataire',
  zoneExploitation: 'valueByZone',
  zoneExploitationNotFilteredByZone: 'valueByZoneNotFilteredByZone',
  questionnaireItem: 'valueByQuestionnaireItem',
  vague: 'valueByVague',
  vagueByQuestionnaireItem: 'valueByVagueByQuestionnaireItem',
  progressPlanDataByVague: 'progressPlanDataByVague',
  progressPlanDataByProgressPlan: 'progressPlanDataByProgressPlan',
  progressPlanDataByZoneExploitation: 'progressPlanDataByZoneExploitation'
};

export const _questionnaireItemType = {
  theme: 0,
  question: 1
};
