import api from "../../tools/api";
import {_Responsable, _Site, _User, _ZoneExploitation} from "../../constants/orm";
import {hydrateEntities, updateEntities, basicUpdateEntitiees, deleteEntity} from "../orm";
import {orm} from "../../orm";

export const fetchZoneExploitation = ({zone_exploitation_id}) => {
  return async dispatch => {
    const zone = await api.zoneExploitations.fetchZoneExploitation({zone_exploitation_id});

    dispatch(hydrateEntities({
      payload: [
        {type: _ZoneExploitation, entities: [zone]}
      ]
    }))
  }
};

export const addZoneExploitations = ({site_id, prestataires, label, taxonomies, comment, schedules, brand_id, responsable_ids, main_responsable_id, zone_characteristics}) => {
  return async (dispatch, getState) => {
    const zone = await api.zoneExploitations.add({
      label,
      prestataires,
      site_id,
      taxonomies,
      comment,
      schedules,
      brand_id,
      responsable_ids,
      main_responsable_id,
      zone_characteristics
    });

    dispatch(hydrateEntities({
      payload: [
        {type: _Responsable, entities: zone.responsables},
        {type: _ZoneExploitation, entities: [zone]}
      ]
    }));

    const store = getState();
    const {userId} = store.session;
    const session = orm.session(store.db);
    const me = session.User.withId(userId);

    dispatch(basicUpdateEntitiees({
      payload: [
        {type: _User, entities: [{id: userId, scope_zones_exploitation: [...me.scope_zones_exploitation.toRefArray().map(i => i.id), zone.id]}]}
      ]
    }));

    return zone;
  }
};

export const addZoneExploitationWithSite = ({label, prestataires, schedules, taxonomies, comment, site_label, site_adresse, site_client_id, site_latitude, site_longitude, site_responsables, site_main_responsables, brand_id, responsable_ids, main_responsable_id, zone_characteristics}) => {
  return async (dispatch, getState) => {
    const zone = await api.zoneExploitations.addWithSite({
      label,
      prestataires,
      taxonomies,
      comment,
      schedules,
      site_label,
      site_adresse,
      site_client_id,
      site_latitude,
      site_longitude,
      site_responsables,
      site_main_responsables,
      brand_id,
      responsable_ids,
      main_responsable_id,
      zone_characteristics
    });

    dispatch(hydrateEntities({
      payload: [
        {type: _Responsable, entities: zone.responsables},
        {type: _Site, entities: [zone.site]},
        {type: _ZoneExploitation, entities: [zone]}
      ]
    }));

    const store = getState();
    const {userId} = store.session;
    const session = orm.session(store.db);
    const me = session.User.withId(userId);

    dispatch(basicUpdateEntitiees({
      payload: [
        {type: _User, entities: [{id: userId, scope_zones_exploitation: [...me.scope_zones_exploitation.toRefArray().map(i => i.id), zone.id]}]}
      ]
    }));

    return zone;
  }
};

export const updateZoneExploitation = ({id, label, prestataires, taxonomies, comment, schedules, brand_id, responsable_ids, main_responsable_id, zone_characteristics}) => {
  return async (dispatch, getState) => {
    const zone = await api.zoneExploitations.update({
      id,
      label,
      prestataires,
      taxonomies,
      comment,
      schedules,
      brand_id,
      responsable_ids,
      main_responsable_id,
      zone_characteristics
    });

    const oldResponsable = Object.keys(getState().db.Responsable.itemsById).filter(r =>
      getState().db.Responsable.itemsById[r].model === 'zone_exploitations' &&
      getState().db.Responsable.itemsById[r].zone_exploitation_id === zone.id);

    if (oldResponsable.length)
      oldResponsable.forEach(r => {
        if (!zone.responsables.find(zr => zr.id === r.id))
          dispatch(deleteEntity({
           type: _Responsable, id: r.id,
          }))
      });

    dispatch(hydrateEntities({
      payload: [
        {type: _Responsable, entities: zone.responsables},
      ]
    }));
    dispatch(updateEntities({
      payload: [
        {type: _ZoneExploitation, entities: [zone]},
      ]
    }));
    return zone;
  }
};

export const removeZoneExploitation = ({id}) => {
  return async dispatch => {
    const res = await api.zoneExploitations.remove({id});

    if (!!res)
      dispatch(deleteEntity({
        type: _ZoneExploitation, id: id
      }));

    return res;
  }
};
