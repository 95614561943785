import api from "../../tools/api";
import {_ProgressPlan, _Question, _Questionnaire, _Theme, _ThemeQuestion, _Response, _QuestionnaireSection} from "../../constants/orm";
import {
  hydrateEntities,
  forceUpdateEnitities,
  createBasicCreateAction,
  createBasicUpdateAction,
  createBasicFetchAction, updateEntities
} from "../orm";
import {actionCallsTracker} from "../actionsTracker";

export const addQuestionnaire = createBasicCreateAction(_Questionnaire, api.questionnaire.add);
export const updateQuestionnaire = createBasicUpdateAction(_Questionnaire, api.questionnaire.update);
export const freezeQuestionnaire = createBasicUpdateAction(_Questionnaire, api.questionnaire.freeze);

export const updateMultipleQuestionnaires = (updatedQuestionnaires) => {
  return async dispatch => {
    const questionnaires = await api.questionnaire.updateMultiple(updatedQuestionnaires);

    dispatch(forceUpdateEnitities({
      payload: [
        {type: _Questionnaire, entities: [questionnaires]}
      ]
    }));

    return questionnaires;
  }
};

export const fetchClientQuestionnaire = ({client_id}) => {
  return async dispatch => {
    if (actionCallsTracker.isSaved('questionnaire', 'client', client_id))
      return;

    const themes = await api.client.getQuestionnaires({client_id});

    let theme = [];
    let questionnaires = [];
    let themeQuestions = [];
    let questions = [];

    function hydrate(themes){
      themes.forEach(t => {
        theme.push(t);
        if (!!t.ChildThemes.length)
          hydrate(t.ChildThemes);
        Object.values(t.questions).forEach(q => {
          questions.push(q);
          themeQuestions.push({
            id: q.themes_question_id,
            theme_id: t.id,
            question_id: q.id
          });
          Object.keys(q.audits).map(questionnaire_id => {
            questionnaires.push({
              id: Number(questionnaire_id),
              audit_id: q.audits[questionnaire_id].id,
              themes_question_id: q.themes_question_id
            });
          });
        });
      });
    }
    hydrate(themes);
    dispatch(hydrateEntities({
      payload: [
        {type: _Theme, entities: theme},
        {type: _Question, entities: questions},
        {type: _ThemeQuestion, entities: themeQuestions},
        {type: _Questionnaire, entities: questionnaires}
      ]
    }));

    actionCallsTracker.save('questionnaire', 'client', client_id);
    return themes;
  }
};

export const fetchAuditQuestionnaire = ({report_id, zone_exploitations_id}) => {
  return async dispatch => {
    const questionnaires = await api.questionnaire.getByReportAndZone({
      report_id,
      zone_exploitations_id
    });
    let responses = [];
    let sections = [];
    let themes_questions = [];
    let questions = [];
    let progressPlans = [];

    questionnaires.forEach(q => {
      if (!!q.response)
        responses.push(q.response);
      if (!!q.progre)
        progressPlans.push(q.progre);
      sections.push(q.section);
      themes_questions.push(q.themes_question);
      questions.push(q.themes_question.question);
    });

    dispatch(forceUpdateEnitities({
      payload: [
        {type: _Response, entities: responses},
        {type: _ProgressPlan, entities: progressPlans},
        {type: _QuestionnaireSection, entities: sections},
        {type: _ThemeQuestion, entities: themes_questions},
        {type: _Question, entities: questions},
        {type: _Questionnaire, entities: questionnaires}
      ]
    }));

    return questionnaires;
  }
};

export const cloneAuditQuestionnaire = ({source_audit_id, target_audit_id}) => {
  return async dispatch => {
    const items = await api.audits.cloneQuestionnaires({source_audit_id, target_audit_id});
    const {
      themes,
      themes_questions,
      questions,
      questionnaires,
      sections
    } = items;

    dispatch(hydrateEntities({
      payload:[
        {type: _Theme, entities: themes},
        {type: _QuestionnaireSection, entities: sections},
        {type: _ThemeQuestion, entities: themes_questions},
        {type: _Question, entities: questions},
        {type: _Questionnaire, entities: questionnaires}
      ]
    }));

    return items;
  }
};

export const cloneAuditQuestionnaireSameClient = ({source_audit_id, target_audit_id}) => {
  return async dispatch => {
    const items = await api.audits.cloneQuestionnairesSameClient({source_audit_id, target_audit_id});
    const {
      themes,
      themes_questions,
      questions,
      questionnaires,
      sections
    } = items;

    dispatch(hydrateEntities({
      payload:[
        {type: _Theme, entities: themes},
        {type: _QuestionnaireSection, entities: sections},
        {type: _ThemeQuestion, entities: themes_questions},
        {type: _Question, entities: questions},
        {type: _Questionnaire, entities: questionnaires}
      ]
    }));

    return items;
  }
};
