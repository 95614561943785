import { Translation } from 'react-i18next';

export function tFunction(id) {
  return <Translation>{t => t(id)}</Translation>;
}

export const getT = (id) => {
  return {
    get latest (){
      if (!!window.t)
        return window.t(id);
      return null;
    }
  };
};