import paginationFactory from "react-bootstrap-table2-paginator";
import {createClientSelectorById} from "../components/selectors/clients";
import {store} from "../store";
import {tFunction} from "../components/tools/Translation";

const clientSelector = createClientSelectorById();

export const questions_type = {
  free: tFunction('visits_view.free_percentage'),
  select: tFunction('admin.audit_view.multiple_choice'),
  bool: tFunction('common.yes/no')
};

export const languageDataTable = {
  autowidth: true,
  decimal:        "",
  emptyTable:     "Ce tableau ne contient aucune donnée.",
  info:           "Affiche _START_ de _END_ sur _TOTAL_ lignes",
  infoEmpty:      "Pas de résultat",
  infoFiltered:   "(filtered from _MAX_ total entries)",
  infoPostFix:    "",
  thousands:      ",",
  lengthMenu:     "",
  loadingRecords: "En cours de chargement...",
  processing:     "En cours de chargement...",
  search:         "Recherche:",
  zeroRecords:    "No matching records found",
  aria: {
    sortAscending:  ": activate to sort column ascending",
    sortDescending: ": activate to sort column descending"
  },
  paginate: {
    previous: '<i class="fa fa-lg fa-angle-left"/>',
    next: '<i class="fa fa-lg fa-angle-right"/>'
  }
};

//theme color
export const ocmDarkBlue = '#274f87';
export const ocmBlue = '#7bc8f7';
export const ocmGreen = '#57a806';
export const ocmRed = '#e55849';
export const ocmYellow = '#fcd102';
export const ocmOrange = '#ff9900';
export const ocmBlack = '#353535';
export const ocmWhite = '#ffffff';

export const defaultPaginationFactory = () => (
  paginationFactory({
    sizePerPageList: [
      {text: '10', value: 10},
      {text: '50', value: 50},
      {text: '100', value: 100},
    ]
  })
);

export const visitColorByStatus = {
  0: ocmRed,
  1: ocmOrange,
  2: ocmGreen,
  3: ocmDarkBlue
};

window.Labels = {
  get i2c(){
    return clientSelector({id: store.getState().session.currentClientId}).label_i2c;
  },
  get ze(){
    return clientSelector({id: store.getState().session.currentClientId}).label_zone_exploitation;
  },
  get audit(){
    return clientSelector({id: store.getState().session.currentClientId}).label_audit;
  }
};
