import { ORM } from 'redux-orm';
import {User} from './models/user';
import {Client} from './models/client';
import {Audit} from './models/audit';
import {Prestataire} from "./models/prestataire";
import {ZoneExploitation} from "./models/zoneExploitation";
import {ProgressPlan} from "./models/progressPlan";
import {Question} from "./models/question";
import {Questionnaire} from "./models/questionnaire";
import {Report} from "./models/report";
import {Response} from "./models/response";
import {Site} from "./models/site";
import {Theme} from "./models/theme";
import {TypeZoneExploitation} from "./models/typeZoneExploitation";
import {Notification} from "./models/notification";
import {ResponseUploads} from './models/responseUploads';
import {ThemeQuestion} from "./models/themeQuestion";
import {ProgressModel} from './models/progressModel';
import {ModelAction} from './models/modelAction';
import {Action} from './models/action';
import {Parameter} from "./models/parameter";
import {ProgressClient} from "./models/progressClient";
import {ProgressZoneExploitation} from "./models/progressZoneExploitation";
import {ProgressSite} from "./models/progressSite";
import {Responsable} from "./models/responsable";
import {Message} from "./models/message";
import {Complement} from "./models/complement";
import {Profile} from "./models/profile";
import {Taxonomy} from "./models/taxonomy";
import {Brand} from "./models/brand";
import {Visit} from "./models/visit";
import {QuestionnaireSection} from "./models/questionnaireSection";
import {RapportsProcessing} from "./models/rapportsProcessing";
import {Firm} from "./models/firm";
import {Analyse} from "./models/analyse";

const orm = new ORM();
orm.register(
  Prestataire,
  Client,
  Parameter,
  User,
  Site,
  TypeZoneExploitation,
  ZoneExploitation,
  Audit,
  Report,
  Theme,
  Question,
  ThemeQuestion,
  Questionnaire,
  Action,
  ProgressModel,
  ModelAction,
  ProgressClient,
  ProgressZoneExploitation,
  ProgressSite,
  Responsable,
  Message,
  ProgressPlan,
  Response,
  ResponseUploads,
  Notification,
  Complement,
  Profile,
  Taxonomy,
  Brand,
  Visit,
  QuestionnaireSection,
  RapportsProcessing,
  Firm,
  Analyse);

export {orm};
export default orm;
