import {createSelector as ormCreateSelector} from "redux-orm";
import store from "../store";
import shallowequal from "shallowequal";
import orm from "../orm";

export const createSelector = (reduceFunction) => {
  let lastProps = null;
  const selector = ormCreateSelector(
    orm,
    (state) => state,
    (state, props) => props,
    (state, props, isGetter) => isGetter,
    reduceFunction
  );

  return (props, isGetter) => {
    if (!shallowequal(props, lastProps))
      lastProps = props;
    return selector(store.getState().db, lastProps, isGetter ? {} : undefined);
  }
};

export default createSelector;